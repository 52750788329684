import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { RadioGroupOption } from '../components/RadioGroup/RadioGroupOption.props';
import {
  PurchaseStatus,
  QuoteStatus,
  QuoteTypeOfBuy,
  UserRoleCategory,
} from '../enums';

export const QUOTE_PRODUCT_MAX_QUANTITY = 999999;

const CommonCurrentTabStatuses: QuoteStatus[] = [
  QuoteStatus.Submitted,
  QuoteStatus.InProgress,
  QuoteStatus.Quoted,
];

export const QuoteCurrentTabStatuses: Readonly<
  Record<UserRoleCategory, QuoteStatus[]>
> = {
  [UserRoleCategory.Admin]: [
    ...CommonCurrentTabStatuses,
    QuoteStatus.PartiallyWonPending,
  ],
  [UserRoleCategory.Agent]: [
    ...CommonCurrentTabStatuses,
    QuoteStatus.Draft,
    QuoteStatus.PartiallyWonPending,
  ],
  [UserRoleCategory.Customer]: [...CommonCurrentTabStatuses, QuoteStatus.Draft],
};

const CommonHistoryTabStatuses: QuoteStatus[] = [
  QuoteStatus.Cancelled,
  QuoteStatus.Expired,
  QuoteStatus.Won,
  QuoteStatus.PartiallyWon,
  QuoteStatus.Lost,
];

export const QuoteHistoryTabStatuses: Readonly<
  Record<UserRoleCategory, QuoteStatus[]>
> = {
  [UserRoleCategory.Admin]: CommonHistoryTabStatuses,
  [UserRoleCategory.Agent]: CommonHistoryTabStatuses,
  [UserRoleCategory.Customer]: [
    ...CommonHistoryTabStatuses,
    QuoteStatus.PartiallyWonPending,
  ],
};

export const PricedQuoteStatuses: Readonly<QuoteStatus[]> = [
  QuoteStatus.Quoted,
  QuoteStatus.Expired,
  QuoteStatus.Won,
  QuoteStatus.PartiallyWonPending,
  QuoteStatus.PartiallyWon,
  QuoteStatus.Lost,
];

export const UnquotedQuoteStatuses: Readonly<QuoteStatus[]> = [
  QuoteStatus.Draft,
  QuoteStatus.InProgress,
  QuoteStatus.Submitted,
];

export const QuoteTypeOfBuyOptions: Readonly<RadioGroupOption[]> = [
  {
    label: `quotes.edit.summary.quoteDetails.typeOfBuy.${QuoteTypeOfBuy.InventoryBuy}`,
    key: QuoteTypeOfBuy.InventoryBuy,
  },
  {
    label: `quotes.edit.summary.quoteDetails.typeOfBuy.${QuoteTypeOfBuy.ProjectBid}`,
    key: QuoteTypeOfBuy.ProjectBid,
  },
  {
    label: `quotes.edit.summary.quoteDetails.typeOfBuy.${QuoteTypeOfBuy.ProjectBuy}`,
    key: QuoteTypeOfBuy.ProjectBuy,
  },
];

export const QuoteStatusColors: Record<QuoteStatus, string> = {
  cancelled: '#C45403',
  draft: '#7E7E7E',
  expired: '#171717',
  inProgress: '#FBB040',
  quoted: '#28A00A',
  submitted: '#EC0730',
  lost: '#EC0730',
  won: '#28A00A',
  partiallyWonPending: '#28A00A',
  partiallyWon: '#28A00A',
};

export const QuoteProductPurchaseStatusMapping = {
  [PurchaseStatus.Won]: {
    icon: <CheckCircleIcon sx={{ color: '#28A00A' }} />,
    i18nKey: 'markAsWon',
  },
  [PurchaseStatus.Lost]: {
    icon: <CancelIcon sx={{ color: 'secondary.main' }} />,
    i18nKey: 'markAsLost',
  },
  [PurchaseStatus.Pending]: {
    icon: <></>,
    i18nKey: 'markAsPending',
  },
};
