import { Grid } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Timestamp } from 'firebase/firestore';

import ExtendIcon from '../../assets/ExtendIcon';
import { usePortalSettings, useUser } from '../../hooks';
import { ReservationStatus } from '../../enums';
import { translateString } from '../../i18n';
import { formatPhoneNumber } from '../../utils/phoneNumber';
import {
  ContrastButtonMain,
  ContrastButtonSecondary,
  ReelQuantity,
  ReelWeight,
  Show,
  TableRowDialog,
} from '..';
import { ReservationRowDialogProps } from './ReservationRowDialog.props';

const TEMPORARY_HOLD_DELAY_IN_HOURS = 24;

export const ReservationRowDialog = ({
  isAdmin,
  disabled,
  mode,
  reservation,
  onCancel,
  onClose,
  onExtend,
  unitsType,
}: ReservationRowDialogProps) => {
  const { t, i18n } = useTranslation();
  const userId = reservation.madeForId;
  const { item: user } = useUser(userId);
  const { item: portalSettings } = usePortalSettings();

  return (
    <TableRowDialog
      buttons={
        (mode === 'extend' || mode === 'cancel') && (
          <>
            <ContrastButtonMain
              onClick={() => (mode === 'cancel' ? onCancel() : onExtend())}
              disabled={disabled}
              variant="contained"
            >
              {t('common.boolean.true')}
            </ContrastButtonMain>

            <ContrastButtonSecondary
              onClick={() => onClose()}
              disabled={disabled}
              variant="outlined"
            >
              {t('common.boolean.false')}
            </ContrastButtonSecondary>
          </>
        )
      }
      icon={mode === 'extend' ? <ExtendIcon color="#F6992B" /> : null}
      title={
        mode === 'extend' ? (
          <Trans
            t={t}
            i18nKey="reservations.extendReservation.message"
            count={
              reservation.status === ReservationStatus.TemporarilyHeld ||
              (reservation.expirationTime < Timestamp.now() &&
                reservation.releaseTime &&
                reservation.releaseTime > Timestamp.now())
                ? TEMPORARY_HOLD_DELAY_IN_HOURS
                : user?.holdDelay
            }
          />
        ) : mode === 'cancel' ? (
          t('reservations.cancelReservation.message')
        ) : (
          ''
        )
      }
    >
      {mode === 'details' && (
        <Grid container my={3} flexDirection="column">
          <Grid item>
            <strong>
              {`${t('inventory.fields.reelId')}: ${reservation.reel.id} - ${
                reservation.reel.reel
              } - ${t('inventory.fields.partNumber')}: ${
                reservation.reel.description
              } - ${reservation.reel.cableRef.id}
              ${t('inventory.fields.quantity')}: `}
              <ReelQuantity item={reservation.reel} unitsType={unitsType} />
              <Show if={reservation.reel.weight > -1}>
                {` ${t('inventory.fields.weight')}: `}
                <ReelWeight item={reservation.reel} unitsType={unitsType} />
              </Show>
            </strong>
          </Grid>
          <Show if={!!reservation.note?.trim()}>
            <Grid item>
              <strong>{t('reservations.fields.reservationNote')}: </strong>
              {reservation.note}
            </Grid>
          </Show>
          <Show if={!!reservation.agencyName}>
            <Grid item>
              <strong>{t('reservations.fields.reservedByAgency')}: </strong>
              {reservation.agencyName}
            </Grid>
          </Show>
          <Show if={isAdmin}>
            <Grid item>{reservation.newErpComment}</Grid>
          </Show>
          <Show
            if={!isAdmin && reservation.status === ReservationStatus.Unknown}
          >
            <Grid item>
              {t('reservations.unknownStatusMessage', {
                contactName: translateString(portalSettings?.contactName, i18n),
                contactPhoneNumber: formatPhoneNumber(
                  translateString(portalSettings?.contactPhoneNumber, i18n)
                ),
              })}
            </Grid>
          </Show>
        </Grid>
      )}
    </TableRowDialog>
  );
};

export default ReservationRowDialog;
