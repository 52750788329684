import { UserPermission, UserRole } from '../enums/';

export enum PermissionGroup {
  NorthernCables = 'northernCablesPermissions',
  AgencyUsers = 'agencyUserPermissions',
  Customers = 'customerPermissions',
}

export enum PermissionSection {
  Default = 'default',
  Agency = 'agency',
  Reservations = 'reservations',
  Companies = 'companies',
  Company = 'company',
  Users = 'users',
  PortalSettings = 'portalSettings',
  Quotes = 'quotes',
  Reports = 'reports',
}

export const Permissions = [
  {
    group: PermissionGroup.NorthernCables,
    subGroups: [
      {
        section: PermissionSection.Default,
        permissions: [
          UserPermission.ViewInventory,
          UserPermission.ViewOrders,
          UserPermission.ManageReelReturns,
          UserPermission.ManageQuotes,
        ],
      },
      {
        section: PermissionSection.Reservations,
        permissions: [
          UserPermission.ViewAllReservations,
          UserPermission.ImpersonateCustomer,
        ],
      },
      {
        section: PermissionSection.Companies,
        permissions: [
          UserPermission.ManageCompanies,
          UserPermission.ManageOrderFilesAccess,
        ],
      },
      {
        section: PermissionSection.Users,
        permissions: [
          UserPermission.ManageCustomerUsers,
          UserPermission.ManageNCUsers,
          UserPermission.ManageAgencyUsers,
          UserPermission.ManageTwoFactorAuthSettings,
        ],
      },
      {
        section: PermissionSection.PortalSettings,
        permissions: [
          UserPermission.ManagePortalSettings,
          UserPermission.ManageInventoryClasses,
          UserPermission.ManageRolesAndPermissions,
          UserPermission.ManageAgentBulletinBoard,
        ],
      },
      {
        section: PermissionSection.Reports,
        permissions: [
          UserPermission.ViewLogs,
          UserPermission.ViewReservationReports,
          UserPermission.ViewReelReturns,
          UserPermission.ViewQuoteReports,
          UserPermission.ViewInventorySearchReports,
        ],
      },
      {
        section: PermissionSection.Agency,
        permissions: [UserPermission.ManageAgencies],
      },
    ],
    roles: [UserRole.NciAdmin, UserRole.NciCustomerService, UserRole.NciStaff],
  },
  {
    group: PermissionGroup.AgencyUsers,
    subGroups: [
      {
        section: PermissionSection.Default,
        permissions: [UserPermission.ViewInventory, UserPermission.ViewOrders],
      },
      {
        section: PermissionSection.Reservations,
        permissions: [
          UserPermission.ManageOwnReservations,
          UserPermission.ManageAgencyCustomerReservations,
        ],
      },
      {
        section: PermissionSection.Quotes,
        permissions: [
          UserPermission.RequestQuotes,
          UserPermission.ViewAgencyQuotes,
        ],
      },
      {
        section: PermissionSection.Agency,
        permissions: [
          UserPermission.ManageAgency,
          UserPermission.ManageAgencyUsers,
        ],
      },
      {
        section: PermissionSection.Reports,
        permissions: [
          UserPermission.ViewSalesReports,
          UserPermission.ViewCommissionsReports,
          UserPermission.ViewReservationConversionRatesReports,
          UserPermission.ViewQuoteReports,
        ],
      },
    ],
    roles: [UserRole.AgencyAdmin, UserRole.AgencyCustomerService],
  },
  {
    group: PermissionGroup.Customers,
    subGroups: [
      {
        section: PermissionSection.Default,
        permissions: [
          UserPermission.ViewInventory,
          UserPermission.ManageReelReturns,
        ],
      },
      {
        section: PermissionSection.Reservations,
        permissions: [
          UserPermission.ManageOwnReservations,
          UserPermission.ManageCompanyReservations,
        ],
      },
      {
        section: PermissionSection.Quotes,
        permissions: [
          UserPermission.RequestQuotes,
          UserPermission.ViewCompanyQuotes,
        ],
      },
      {
        section: PermissionSection.Company,
        permissions: [
          UserPermission.ViewCompanyUsers,
          UserPermission.DisableCompanyUsers,
          UserPermission.ManageOrderFilesAccess,
        ],
      },
    ],
    roles: [UserRole.Manager, UserRole.Buyer, UserRole.Sales, UserRole.General],
  },
];
