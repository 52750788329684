import { Button } from '@mui/material';
import * as Sx from './FileDownloadOutput.styles';
import { FileDownloadOutputProps } from './FileDownloadOuput.props';

export const FileDownloadOutput = ({
  children,
  onClick,
  uppercase = false,
}: FileDownloadOutputProps) => (
  <Button
    variant="contained"
    onClick={onClick}
    sx={Sx.downloadButton(uppercase)}
  >
    {children}
  </Button>
);
