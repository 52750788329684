import { t } from 'i18next';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { DashboardCard } from '../../../components';
import { useAgencyOutstandingQuoteRequests } from '../../../hooks';
import * as Sx from './Dashboard.styles';

export const AgencyOutstandingQuoteRequestsCard: React.FC = () => {
  const { list: quotes } = useAgencyOutstandingQuoteRequests();

  return (
    <DashboardCard
      icon={<RequestQuoteIcon sx={Sx.icon} />}
      link="/agent/quotes"
      linkText={t('dashboard.quotes')}
      message={quotes?.length?.toString() ?? 0}
      title={t('dashboard.quoteRequests')}
    />
  );
};
