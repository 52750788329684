import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { useLocalStorage, usePageTitle } from '../../hooks';
import {
  AgentReservationConversionRateFilters,
  Container,
  Show,
} from '../../components';
import { UserPermission } from '../../enums';
import { getPageTitle } from '../../utils/pages';
import { RequirePermission } from '../../security';
import {
  ReservationConversionRateFilters as Filters,
  Nullable,
} from '../../types';
// eslint-disable-next-line max-len
import { AgentReservationConversionRate } from '../../components/ReservationConversionRate/AgentReservationConversionRate';

const ReservationConversionRate: React.FC = () => {
  usePageTitle(getPageTitle('reports.reservationConversionRate'));
  const {
    item: filters,
    setItem: setFilters,
    isLoaded,
  } = useLocalStorage<Filters>('reservationConversionRates', 'conversion');
  const { item: expandedCompanies, setItem: setExpandedCompanies } =
    useLocalStorage<string[]>(
      'reservationConversionRates',
      'expandedCompanies'
    );

  if (typeof filters?.startDate === 'string') {
    filters.startDate = DateTime.fromISO(filters.startDate);
  }
  if (typeof filters?.endDate === 'string') {
    filters.endDate = DateTime.fromISO(filters.endDate);
  }

  const handleSearch = (newFilters: Nullable<Filters>) => {
    if (filters) {
      const { startDate, endDate } = filters;
      if (startDate && typeof startDate === 'string') {
        filters.startDate = DateTime.fromISO(startDate);
      }

      if (endDate && typeof endDate === 'string') {
        filters.endDate = DateTime.fromISO(endDate);
      }

      if (!isEqual(newFilters, filters)) {
        setExpandedCompanies([]);
      }
    }
    setFilters(newFilters);
  };

  return isLoaded ? (
    <RequirePermission
      oneOf={[UserPermission.ViewReservationConversionRatesReports]}
    >
      <AgentReservationConversionRateFilters
        onSearch={handleSearch}
        loadedFilters={filters}
      />
      <Show if={!!filters}>
        <Container>
          <AgentReservationConversionRate
            filters={filters}
            expandedCompanies={expandedCompanies}
            setExpandedCompanies={setExpandedCompanies}
          />
        </Container>
      </Show>
    </RequirePermission>
  ) : null;
};

export default ReservationConversionRate;
