import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { DocumentData, doc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import { ReservationLogsFilters as Filters } from '../../types';
import { useUsers } from '../../hooks';
import formatReservationNumber from '../../utils/formatReservationNumber';
import { FilterContainer } from '..';
import AsyncAutocomplete from '../AsyncAutocomplete/AsyncAutocomplete.component';
import { CompanyType } from '../../enums';
import { ReservationLogFiltersProps } from './ReservationLogFilters.props';

const emptyFilters = {
  companyId: null,
  userId: null,
  reservationNumber: null,
  startDate: DateTime.now().plus({ month: -1 }),
  endDate: DateTime.now(),
};

interface UserSelectProps {
  companyId: string;
  filters: Filters;
  setFilters: Dispatch<SetStateAction<Filters>>;
}

// Prevent the list of users from being entirely loaded when no company is selected
const UserSelect = ({ companyId, filters, setFilters }: UserSelectProps) => {
  const { t } = useTranslation();
  const firestore = useFirestore();
  const { list: users } = useUsers({
    filters: [['companyRef', '==', doc(firestore, 'Companies', companyId)]],
  });

  return (
    <Autocomplete
      popupIcon={<KeyboardArrowDownIcon />}
      getOptionLabel={(user) => user.name || ''}
      options={users ?? []}
      value={users.find((u) => u.id === filters.userId) ?? null}
      onChange={(_, user) =>
        setFilters((prev) => {
          return { ...prev, userId: user?.id || null };
        })
      }
      sx={{ flex: '1 1 0' }}
      renderInput={(params) => (
        <TextField {...params} label={t('reports.userActivity.userName')} />
      )}
    />
  );
};

export const ReservationLogsFilters = ({
  onSearch,
}: ReservationLogFiltersProps) => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState<Filters>(emptyFilters);
  const [selectedCompany, setSelectedCompany] = useState<DocumentData | null>(
    null
  );

  useEffect(() => {
    if (filters.reservationNumber) {
      setFilters((prev) => {
        return { ...prev, companyId: null, userId: null };
      });
    }
  }, [filters.reservationNumber, setFilters]);

  const clearFilters = () => {
    setFilters(emptyFilters);
    setSelectedCompany(null);
    onSearch(null);
  };

  return (
    <FilterContainer
      buttonsDisabled={!filters.startDate || !filters.endDate}
      title={t('reports.reservationLogs.title')}
      onSearch={() => onSearch(filters)}
      onClear={() => clearFilters()}
    >
      <Grid
        sx={{
          my: { xs: 2, md: 4 },
        }}
      >
        <Grid display="flex" justifyContent="space-evenly" gap="1em">
          <DatePicker
            label={t('reports.userActivity.startDate')}
            value={filters.startDate}
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
              textField: {
                fullWidth: true,
                required: true,
                sx: { flex: '1 1 0' },
              },
            }}
            onChange={(value) =>
              setFilters((prev) => {
                return { ...prev, startDate: value };
              })
            }
            format={t('formats.date')}
            maxDate={filters.endDate || DateTime.now()}
          />

          <DatePicker
            label={t('reports.userActivity.endDate')}
            value={filters.endDate}
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
              textField: {
                fullWidth: true,
                required: true,
                sx: { flex: '1 1 0' },
              },
            }}
            onChange={(value) =>
              setFilters((prev) => {
                return { ...prev, endDate: value };
              })
            }
            format={t('formats.date')}
            minDate={filters.startDate || undefined}
            maxDate={DateTime.now()}
          />

          <TextField
            disabled={!!filters.companyId}
            value={
              filters.reservationNumber
                ? formatReservationNumber(filters.reservationNumber)
                : ''
            }
            label={t('reports.reservationLogs.reservationNumber')}
            sx={{ flex: '1 1 0' }}
            onChange={(e) =>
              setFilters((prev) => {
                return {
                  ...prev,
                  reservationNumber: Number(
                    e.target.value.replace(/[^0-9]/g, '')
                  ),
                };
              })
            }
          />

          <AsyncAutocomplete
            collectionName="Companies"
            disabled={!!filters.reservationNumber}
            displayFieldName="name"
            normalizedFieldName="nameNormalized"
            onChange={(_, company) =>
              setFilters((prev) => {
                return { ...prev, companyId: company?.id || null };
              })
            }
            queryFilters={[
              ['isDeleted', '==', false],
              ['type', '==', CompanyType.Customer],
            ]}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('reports.userActivity.company')}
              />
            )}
            selectedDocument={selectedCompany}
            setSelectedDocument={setSelectedCompany}
            sx={{ flex: '1 1 0' }}
            visible={true}
          />

          {!filters.reservationNumber && filters.companyId ? (
            <UserSelect
              companyId={filters.companyId}
              filters={filters}
              setFilters={setFilters}
            />
          ) : (
            <Autocomplete
              disabled={true}
              popupIcon={<KeyboardArrowDownIcon />}
              options={[]}
              value={null}
              sx={{ flex: '1 1 0' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('reports.userActivity.userName')}
                />
              )}
            />
          )}
        </Grid>
      </Grid>
    </FilterContainer>
  );
};
