import { DateTime } from 'luxon';
import { TFunction } from 'i18next';
import { SanitizedProductSearchStats } from '../../models';
import { Nullable } from '../../types';
import report from './report';

const PREFIX = 'NC_InventorySearches';
const NAMESPACE = 'reports.inventorySearches';
const EXPORTED_KEYS = [
  'inventory',
  'productType',
  'productSubType',
  'material',
  'voltage',
  'numberOfConductors',
  'gauge',
  'nbOfSearches',
  'emptyResults',
  'lowInventory',
  'reservationWasMade',
];

class inventorySearchesCsv extends report<SanitizedProductSearchStats> {
  private readonly data: SanitizedProductSearchStats[];
  private readonly fromDate: Nullable<DateTime>;
  private readonly toDate: Nullable<DateTime>;

  constructor(
    data: SanitizedProductSearchStats[],
    fromDate: Nullable<DateTime>,
    toDate: Nullable<DateTime>,
    t: TFunction<'translation', undefined>
  ) {
    super(PREFIX, NAMESPACE, EXPORTED_KEYS, t);

    this.data = data;
    this.fromDate = fromDate;
    this.toDate = toDate;
  }

  protected override getFilename(): string {
    const fromDate = this.fromDate?.toFormat('LLddyyyy');
    const toDate = this.toDate?.toFormat('LLddyyyy');

    return `${PREFIX}_${fromDate}_${toDate}.csv`;
  }

  protected getSanitizedData(): SanitizedProductSearchStats[] {
    return this.data.map((value) => {
      return {
        ...value,
        inventory: this.t(`inventoryType.${value.standardType}`),
        productType: this.t(`productTypes.${value.productType}`),
        productSubType: value.productSubType
          ? this.t(`productSubTypes.${value.productSubType}`)
          : '',
        material: this.t(`materials.${value.material}`),
        voltage: value.voltage ? value.voltage : 'N/A',
      };
    });
  }

  static generate(
    data: SanitizedProductSearchStats[],
    fromDate: Nullable<DateTime>,
    toDate: Nullable<DateTime>,
    t: TFunction<'translation', undefined>
  ) {
    const report = new inventorySearchesCsv(data, fromDate, toDate, t);
    report.generateCSV();
  }
}

export default inventorySearchesCsv;
