import { CssBaselineProps, styled } from '@mui/material';
import { Box, SxProps } from '@mui/system';

import theme from '../../theme';

export const StyledHeaderContainer = styled(Box)(() => {
  return {
    backgroundColor: '#2C2C2C',
    padding: '25px 10px 25px 20px',
  };
});

export const StyledQuoteNotesContainer = styled(Box)(() => {
  return {
    height: '100%',
    overflowY: 'auto',
  };
});

export const drawerPaper: SxProps = {
  bottom: 0,
  top: 'unset',
  borderRadius: '10px 10px 0 0',
  width: '368px',
  height: '655px',
  overflow: 'hidden',
};

export const buttonsSection: SxProps = {
  minHeight: '70px',
  height: '70px',
  borderTopWidth: '1px',
  borderTopStyle: 'solid',
  borderTopColor: theme.palette.primary.contrastText,
  padding: 2,
  '& .MuiInputBase-root.MuiFilledInput-root': {
    backgroundColor: 'black',
  },
};

export const borderBottom: SxProps = {
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: theme.palette.primary.contrastText,
};

export const StyledNoteContainer = styled(Box)(() => {
  return {
    padding: '10px',
    ...(borderBottom as CssBaselineProps),
  };
});
