import { useCompanies } from '../../hooks';
import { CompanyType } from '../../enums';
import { ReservationConversionRateFiltersProps } from './ReservationConversionRateFilters.props';
import { ReservationConversionRateFilters } from './ReservationConversionRateFilters.component';

export const AdminReservationConversionRateFilters = (
  props: Omit<
    ReservationConversionRateFiltersProps,
    'companies' | 'loadingCompanies'
  >
) => {
  const { list: companies, loading: loadingCompanies } = useCompanies({
    filters: [['type', '==', CompanyType.Customer]],
  });

  return (
    <ReservationConversionRateFilters
      {...props}
      companies={companies}
      loadingCompanies={loadingCompanies}
    />
  );
};
