import { where } from 'firebase/firestore';

import { Quote } from '../models';
import { CollectionQueryOptions } from '../types';
import { PricedQuoteStatuses } from '../mappings';
import { useCollection } from './useCollection';

export function usePricedQuotes(
  options?: Omit<CollectionQueryOptions, 'sorting' | 'pagination'>
) {
  return useCollection<Quote>(
    'Quotes',
    [where('status', 'in', PricedQuoteStatuses)],
    undefined,
    undefined,
    options?.filters,
    options?.compositeFilters
  );
}
