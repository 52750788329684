import { useCallback, useMemo } from 'react';
import {
  getAuth,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
  User,
  AuthError,
  AuthErrorCodes,
} from 'firebase/auth';

import { UpdatePassword } from '../models';
import { Persistable } from '../types';

class PasswordError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'CustomError';
  }
}
interface UpdatePasswordResponse extends Persistable<UpdatePassword> {
  tryUpdatePassword: (
    item: UpdatePassword,
    reauthenticate: boolean
  ) => Promise<void>;
}

export function useUpdatePassword(): UpdatePasswordResponse {
  const item = useMemo(() => {
    return { oldPassword: '', newPassword: '', confirmPassword: '' };
  }, []);

  const save = useCallback(async (item: UpdatePassword) => item, []);

  const tryUpdatePassword = useCallback(
    async (item: UpdatePassword, reauthenticate: boolean) => {
      const auth = getAuth();
      const user = auth.currentUser as User;

      if (reauthenticate) {
        const credentials = EmailAuthProvider.credential(
          user?.email || '',
          item.oldPassword
        );
        try {
          await reauthenticateWithCredential(user, credentials);
        } catch (e) {
          const authError = e as AuthError;
          if (authError.code === AuthErrorCodes.INVALID_PASSWORD) {
            throw new PasswordError('users.errors.invalidPassword');
          }
        }
      }

      await updatePassword(user, item.newPassword);
    },
    []
  );

  return {
    item,
    save,
    tryUpdatePassword,
  };
}
