import { RefObject, useState, useEffect } from 'react';

import Formable from '../types/Formable';

export function useDebouncedFormFieldValue<T, K>(
  form: Formable<T>,
  field: keyof T
) {
  const [value, setInternalValue] = useState<K>(
    form.item?.[field] as unknown as K
  );

  useEffect(() => {
    setInternalValue(form.item?.[field] as unknown as K);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.item?.[field], field]);

  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const updateFieldValue = (value: K) => {
    const newValues = {} as Partial<T>;
    newValues[field] = value as Extract<K, T[keyof T]>;
    form.setItem({ ...form.item, ...(newValues as T) });
  };

  const setDelayedValue = (
    value: K,
    fieldRef?: RefObject<HTMLInputElement>,
    forceDelay?: boolean
  ) => {
    if (
      !forceDelay &&
      fieldRef?.current?.hasAttribute('autocompleted') &&
      fieldRef.current.type !== 'textarea'
    ) {
      return setValue(value);
    }

    setInternalValue(value);
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    setDebounceTimeout(setTimeout(() => updateFieldValue(value), 500));
  };

  const setValue = (value: K) => {
    updateFieldValue(value);
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
  };

  return { value, setValue, setDelayedValue };
}
