import { Theme } from '@mui/material';

const BaseGlobalStyledConsentNotice = (theme: Theme) => {
  return {
    // eslint-disable-next-line max-len
    '.didomi-popup-container, .didomi-consent-popup-header, .didomi-consent-popup-footer, .didomi-consent-popup__exterior-border, .didomi-popup-container, .didomi-popup-view':
      {
        borderRadius: '8px !important',
      },
    '.didomi-popup__exterior-border, .didomi-consent-popup__exterior-border': {
      borderColor: 'transparent !important',
    },
    '.didomi-popup-backdrop:first-of-type': {
      background: 'rgba(0, 0, 0, 0.5) !important',
    },
    '.didomi-popup-backdrop:not(:first-of-type)': {
      background: 'transparent !important',
    },
  };
};

export const GlobalStyledConsentNotice = (theme: Theme) =>
  BaseGlobalStyledConsentNotice(theme);
