import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PickersDayProps,
  DatePicker,
  PickersActionBarProps,
  DateValidationError,
} from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { DialogActions } from '@mui/material';

import { ContrastButtonMain } from '../ContrastButton/ContrastButton.component';
import { CustomPickersDay } from './ReelReturnDatePicker.styles';
import { ReelReturnDatePickerProps } from './ReelReturnDatePicker.props';
import { formatDateSpan } from './utils';

const isCurrentDay = (dayA: DateTime, dayB: DateTime | null | undefined) => {
  if (!dayB) {
    return false;
  }

  return dayA.hasSame(dayB, 'day');
};

const isTheFollowingWeek = (
  dayA: DateTime,
  dayB: DateTime | null | undefined
) => {
  if (!dayB) {
    return false;
  }

  const dayDiff = dayA.diff(dayB, 'days').days;
  return dayDiff >= 0 && dayDiff <= 6;
};

const Day = (
  props: PickersDayProps<DateTime> & {
    selectedDay?: DateTime | null;
  }
) => {
  const { day, selectedDay, ...other } = props;

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={isCurrentDay(day, selectedDay)}
      isTheFollowingWeek={isTheFollowingWeek(day, selectedDay)}
    />
  );
};

function CustomActionBar(props: PickersActionBarProps) {
  const { onAccept, className } = props;
  const { t } = useTranslation();
  return (
    <DialogActions className={className}>
      <ContrastButtonMain onClick={onAccept} sx={{ mr: 1 }}>
        {t('reelReturns.edit.apply')}
      </ContrastButtonMain>
    </DialogActions>
  );
}

export const ReelReturnDatePicker = ({
  displayError = false,
  label,
  minDate,
  onDateChange,
  value,
}: ReelReturnDatePickerProps) => {
  const { i18n, t } = useTranslation();

  const [error, setError] = useState<DateValidationError | null>(null);

  const errorMessage = useMemo(() => {
    switch (error) {
      case 'minDate': {
        return 'reelReturns.edit.date.minDate';
      }

      case 'invalidDate': {
        return 'reelReturns.edit.date.invalid';
      }

      default: {
        return '';
      }
    }
  }, [error]);

  return (
    <DatePicker
      value={value}
      label={label}
      onChange={onDateChange}
      minDate={minDate}
      onError={(newError) => setError(newError)}
      showDaysOutsideCurrentMonth
      closeOnSelect={false}
      slots={{ day: Day, actionBar: CustomActionBar }}
      slotProps={{
        toolbar: {
          hidden: true,
        },
        actionBar: {
          actions: ['accept'],
        },
        textField: {
          required: true,
          fullWidth: true,
          sx: { flex: '1 1 0' },
          error: displayError && !!errorMessage,
          helperText: displayError && t(errorMessage),
          InputProps: {
            value: formatDateSpan(i18n.language, value),
          },
        },
        day: () =>
          ({
            selectedDay: value,
          } as never),
      }}
    />
  );
};
