import { Container as MuiContainer } from '@mui/material';

import { useAuthentication } from '../../hooks';
import { ContainerProps } from './Container.props';
import * as Sx from './Container.styles';

export const Container = ({
  background,
  backgroundColor,
  children,
  fullWidth,
  maxWidth,
  role,
  roundedTopCorners,
  subSx,
  sx,
}: ContainerProps) => {
  const { isAdmin, isAgent } = useAuthentication();

  return (
    <MuiContainer
      maxWidth={false}
      role={role}
      sx={Sx.container(sx, background, backgroundColor, roundedTopCorners)}
    >
      <MuiContainer
        maxWidth={maxWidth ?? 'xl'}
        sx={Sx.wrapper(subSx, !fullWidth && (!!isAdmin || !!isAgent))}
      >
        {children}
      </MuiContainer>
    </MuiContainer>
  );
};
