import { DateTime } from 'luxon';
import { i18n, TFunction } from 'i18next';

import reservation_icon from '../../assets/ReservationIcon.png';
import { TimeZone } from '../../enums';
import { UserActivity } from '../../models';
import { Nullable } from '../../types';
import report, { MARGIN, PDF_CONFIG } from './report';

class userActivitiesPdf extends report {
  private readonly activities: UserActivity[];
  private readonly fromDate: Nullable<DateTime>;
  private readonly toDate: Nullable<DateTime>;
  private readonly DEFAULT_ROW_HEIGHT = 13;

  constructor(
    activities: UserActivity[],
    fromDate: Nullable<DateTime>,
    toDate: Nullable<DateTime>,
    t: TFunction<'translation', undefined>,
    i18n: i18n
  ) {
    super('reports.userActivity', t, i18n);

    this.activities = activities;
    this.fromDate = fromDate?.setLocale('en') ?? null;
    this.toDate = toDate?.setLocale('en') ?? null;
  }

  protected getFilename(): string {
    const from = this.fromDate?.toFormat('LLddyyyy');
    const to = this.toDate?.toFormat('LLddyyyy');
    return `NC_UserActivityLog_${from}_${to}.pdf`;
  }

  protected buildHeader(): void {
    this.outputDocumentDate();

    this.curHeight += PDF_CONFIG.LINE_SIZE + 5;

    this.outputImage(reservation_icon, MARGIN.X, this.curHeight, 10, 10);

    const formattedTitle = this.t('reports.userActivity.title');
    const formattedFilters = this.t('reports.userActivity.dateFilter', {
      from: this.fromDate?.toLocaleString(),
      to: this.toDate?.toLocaleString(),
    });
    this.outputH1(formattedTitle, this.curWidth + 15, this.curHeight + 7);

    this.curHeight += PDF_CONFIG.LINE_SIZE / 2 + 2;

    this.outputH3(formattedFilters, this.curWidth, this.curHeight + 7);

    this.curHeight += PDF_CONFIG.LINE_SIZE / 2 + 5;
  }

  protected buildBody(): void {
    this.drawLine(0.5);

    this.curHeight += 5;

    this.activities.forEach((activity, index) =>
      this.buildRow(activity, index)
    );
  }

  private buildRow(activity: UserActivity, index: number): void {
    const { companyName, date, email, name } = activity;

    const formattedDate = DateTime.fromMillis(date.toMillis())
      .setLocale('en')
      .setZone(TimeZone.EasternTime)
      .toLocaleString(DateTime.DATETIME_MED);

    this.addDataColumn('email', email, MARGIN.X, 45);
    this.addDataColumn('name', name || 'N/A', MARGIN.X + 50, 25);
    this.addDataColumn('company', companyName || 'N/A', MARGIN.X + 80, 25);
    this.addDataColumn('date', formattedDate, MARGIN.X + 110);
    this.addDataColumn('success', null, MARGIN.X + 150);
    this.checkbox(MARGIN.X + 150, this.curHeight + 2, activity.success);

    this.curHeight += 9;

    this.drawLine(0.1);

    this.curHeight += 6;

    this.nextRow(this.DEFAULT_ROW_HEIGHT, index, this.activities.length);
  }

  static generate = (
    activities: UserActivity[],
    fromDate: Nullable<DateTime>,
    toDate: Nullable<DateTime>,
    t: TFunction<'translation', undefined>,
    i18n: i18n
  ) => {
    const report = new userActivitiesPdf(activities, fromDate, toDate, t, i18n);

    report.generate();
  };
}

export default userActivitiesPdf;
