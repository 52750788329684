import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { RequirePermission } from '../security';
import { ConsultationMode, UserPermission } from '../enums';
import {
  usePageTitle,
  useAuthentication,
  usePermission,
  useRouterOutlet,
  useCurrentUser,
  useCompany,
} from '../hooks';
import { getPageTitle } from '../utils/pages';
import {
  Container,
  ContrastButtonMain,
  CurrentQuotesTable,
  DecoratedHeader,
  QuotesFilters,
  QuotesHistoryTable,
} from '../components';
import { QuotesFilters as QuotesFiltersType } from '../types';
import { Tab } from '../navigation';
import { getSelectableCompanies } from '../utils';

const defaultQuoteFilters: QuotesFiltersType = {
  companyId: null,
  endDate: null,
  requestorId: null,
  searchField: null,
  startDate: null,
  status: null,
  region: null,
  expirationTime: null,
};

interface Props {
  mode?: ConsultationMode;
}

const Quotes: React.FC<Props> = ({
  mode = ConsultationMode.Current,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const showCurrent = mode === ConsultationMode.Current;
  const { showOutletOnly } = useRouterOutlet();
  const { isAgent } = useAuthentication();
  const { hasPermission } = usePermission();
  const [filters, setFilters] =
    useState<QuotesFiltersType>(defaultQuoteFilters);

  const currentUser = useCurrentUser();
  const { item: company } = useCompany(currentUser.companyRef.id);

  const getPageTitleKey = (): string =>
    showCurrent ? 'quotes' : 'quotes.history';
  usePageTitle(getPageTitle(getPageTitleKey()));

  const currentQuotesPath = isAgent ? '/agent/quotes' : '/quotes';
  const historyQuotesPath = isAgent
    ? '/agent/quotes/history'
    : '/quotes/history';

  useEffect(() => {
    setFilters({
      ...defaultQuoteFilters,
      startDate: showCurrent
        ? null
        : DateTime.now()
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .minus({ month: 1 }),
      endDate: showCurrent ? null : DateTime.now(),
    });
  }, [showCurrent]);

  const associatedCompanies = useMemo(() => {
    if (!isAgent || !company?.associatedCompanies) {
      return [];
    }
    return getSelectableCompanies(Object.values(company.associatedCompanies));
  }, [isAgent, company?.associatedCompanies]);

  return (
    <RequirePermission
      oneOf={[
        UserPermission.RequestQuotes,
        UserPermission.ViewAgencyQuotes,
        UserPermission.ViewCompanyQuotes,
      ]}
    >
      {showOutletOnly || (
        <>
          <Container>
            <DecoratedHeader
              icon={<RequestQuoteIcon />}
              title={t('navigation.quotes')}
              to={showCurrent ? currentQuotesPath : historyQuotesPath}
              variant="colored"
            >
              <Tab
                title={t('quotes.current')}
                to={currentQuotesPath}
                match={[
                  currentQuotesPath,
                  currentQuotesPath + '/:quoteId/delete',
                ]}
              />
              <Tab title={t('quotes.history')} to={historyQuotesPath} />
            </DecoratedHeader>
          </Container>
          <Container
            backgroundColor="secondary.light"
            roundedTopCorners={true}
            sx={{ pt: { xs: showCurrent ? 2 : 0, md: 0 } }}
          >
            <Stack
              direction="row"
              sx={{ justifyContent: { xs: 'flex-end', md: 'space-between' } }}
            >
              <Typography
                variant="h2"
                sx={{ mt: 4, mb: 3, display: { xs: 'none', md: 'block' } }}
              >
                {showCurrent ? t('quotes.current') : t('quotes.history')}
              </Typography>
              {showCurrent && hasPermission(UserPermission.RequestQuotes) && (
                <ContrastButtonMain
                  onClick={() => navigate(currentQuotesPath + '/new')}
                  variant="contained"
                  sx={{ mt: 4, mb: 3 }}
                >
                  {t('quotes.build')}
                </ContrastButtonMain>
              )}
            </Stack>
          </Container>
          <QuotesFilters
            filters={filters}
            setFilters={setFilters}
            mode={mode}
            companies={associatedCompanies}
          />
          <Container>
            {showCurrent ? (
              <CurrentQuotesTable mode={mode} filters={filters} />
            ) : (
              <QuotesHistoryTable mode={mode} filters={filters} />
            )}
          </Container>
        </>
      )}
      <Outlet />
    </RequirePermission>
  );
};

export default Quotes;
