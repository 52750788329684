import { Autocomplete, Box, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useFormFieldValue } from '../../../../../hooks';
import { Quote as QuoteModel } from '../../../../../models';
import { Nullable } from '../../../../../types';
import {
  QuoteExpirationTimeOption,
  QuoteTypeOfBuy,
} from '../../../../../enums';
import { TextField as TextFieldForm } from '../../../../TextField/TextField.component';
import { EditableQuoteStyledStack } from './../OtherDetails.styles';
import { DetailsProps } from './Details.props';

export const EditableDetails = ({ form, quoteNotesElement }: DetailsProps) => {
  const { t } = useTranslation();

  const { value: expirationTimeOption, setValue: setExpirationTimeOption } =
    useFormFieldValue<QuoteModel, Nullable<QuoteExpirationTimeOption>>(
      form,
      'expirationTimeOption'
    );

  const { value: typeOfBuy } = useFormFieldValue<
    QuoteModel,
    Nullable<QuoteTypeOfBuy>
  >(form, 'typeOfBuy');

  const showNciProjectId = useMemo(
    () => !!typeOfBuy && typeOfBuy !== QuoteTypeOfBuy.InventoryBuy,
    [typeOfBuy]
  );

  return (
    <EditableQuoteStyledStack>
      {showNciProjectId && (
        <TextFieldForm
          form={form}
          label={t('quotes.edit.summary.quoteDetails.nciProjectId')}
          field="nciProjectId"
          maxLength={60}
        />
      )}
      <Autocomplete
        sx={{ mt: { lg: showNciProjectId ? 0.5 : 0, md: 0 } }}
        fullWidth
        popupIcon={<KeyboardArrowDownIcon />}
        getOptionLabel={(expirationTime) =>
          t(
            `quotes.edit.summary.quoteDetails.expirationTime.options.${expirationTime}`
          )
        }
        options={Object.values(QuoteExpirationTimeOption)}
        value={expirationTimeOption}
        onChange={(_, expirationTime) =>
          setExpirationTimeOption(expirationTime)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label={t('quotes.filters.expirationTime.title')}
            error={!!form.errors.expirationTimeOption}
            helperText={t(form.errors.expirationTimeOption || '')}
          />
        )}
      />
      <Box mt={1}>{quoteNotesElement}</Box>
    </EditableQuoteStyledStack>
  );
};
