import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import { DateTime } from 'luxon';
import { isEqual } from 'lodash';
import {
  ConsultationMode,
  QuoteExpirationTimeFilters,
  QuoteTypeOfBuy,
  Region,
  UserPermission,
} from '../../enums';
import { FilterContainer } from '..';
import { useAuthentication, usePermission } from '../../hooks';
import {
  QuoteCurrentTabStatuses,
  QuoteHistoryTabStatuses,
} from '../../mappings';
import { QuotesFiltersProps } from './QuotesFilters.props';
import { QuotesFiltersRequestors } from './QuotesFiltersRequestors.component';
import { QuotesFiltersEmployee } from './QuotesFiltersEmployees.component';

export const QuotesFilters = ({
  mode,
  filters,
  maxWidth,
  setFilters,
  companies,
}: QuotesFiltersProps) => {
  const { t } = useTranslation();
  const { isAgent, isAdmin, userRoleCategory } = useAuthentication();
  const { hasPermission } = usePermission();

  const [searchField, setSearchField] = useState<string | null>(
    filters.searchField
  );
  const [debouncedSearchField] = useDebounce(searchField, 500);

  useEffect(() => {
    setFilters((prev) => {
      const newFilters = { ...prev, searchField: debouncedSearchField };
      return isEqual(prev, newFilters) ? prev : newFilters;
    });
  }, [debouncedSearchField, setFilters]);

  useEffect(() => {
    setSearchField(null);
  }, [mode]);

  const quoteStatusFilters = useMemo(() => {
    const values =
      mode === ConsultationMode.Current
        ? QuoteCurrentTabStatuses[userRoleCategory]
        : QuoteHistoryTabStatuses[userRoleCategory];

    return [...values].sort();
  }, [mode, userRoleCategory]);

  return (
    <>
      <FilterContainer maxWidth={maxWidth}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{ flexBasis: { md: isAdmin ? '21%' : '15%' } }}
          >
            <TextField
              fullWidth
              label={t(`quotes.filters.searchField${isAdmin ? 'NCI' : ''}`)}
              value={searchField ?? ''}
              onChange={(e) => setSearchField(e.currentTarget.value)}
              onBlur={(e) => setSearchField(e.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={12} sx={{ flexBasis: { md: '15%' } }}>
            <Autocomplete
              fullWidth
              popupIcon={<KeyboardArrowDownIcon />}
              getOptionLabel={(status) => t(`quotes.status.${status}`)}
              options={quoteStatusFilters}
              value={filters.status}
              onChange={(_, status) =>
                setFilters((prev) => {
                  return { ...prev, status };
                })
              }
              sx={{ flex: '1 1 0' }}
              renderInput={(params) => (
                <TextField {...params} label={t('quotes.filters.status')} />
              )}
            />
          </Grid>
          {!!companies?.length && (isAgent || isAdmin) && (
            <Grid item xs={12} sx={{ flexBasis: { md: '15%' } }}>
              <Autocomplete
                fullWidth={true}
                popupIcon={<KeyboardArrowDownIcon />}
                options={companies ?? []}
                getOptionLabel={(option) => option.name}
                value={
                  companies?.find((c) => c.id === filters.companyId) ?? null
                }
                onChange={(_, value) => {
                  setFilters((prevValue) => {
                    return {
                      ...prevValue,
                      companyId: value?.id || null,
                      requestorId:
                        isAdmin && prevValue.companyId !== value?.id
                          ? null
                          : prevValue.requestorId,
                    };
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} label={t('quotes.filters.company')} />
                )}
              />
            </Grid>
          )}
          {(isAdmin ||
            hasPermission(UserPermission.ViewAgencyQuotes) ||
            hasPermission(UserPermission.ViewCompanyQuotes)) && (
            <QuotesFiltersRequestors
              filters={filters}
              setFilters={setFilters}
            />
          )}
          {isAdmin && (
            <>
              <Grid item xs={12} sx={{ flexBasis: { md: '15%' } }}>
                <Autocomplete
                  fullWidth
                  popupIcon={<KeyboardArrowDownIcon />}
                  getOptionLabel={(region) => t(`regions.${region}`)}
                  options={Object.values(Region)}
                  value={filters.region}
                  onChange={(_, region) =>
                    setFilters((prev) => {
                      return { ...prev, region };
                    })
                  }
                  sx={{ flex: '1 1 0' }}
                  renderInput={(params) => (
                    <TextField {...params} label={t('quotes.filters.region')} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={{ flexBasis: { md: '15%' } }}>
                <QuotesFiltersEmployee
                  filters={filters}
                  setFilters={setFilters}
                />
              </Grid>
              <Grid item xs={12} sx={{ flexBasis: { md: '15%' } }}>
                <Autocomplete
                  fullWidth
                  popupIcon={<KeyboardArrowDownIcon />}
                  getOptionLabel={(typeOfBuy) =>
                    t(`quotes.edit.summary.quoteDetails.typeOfBuy.${typeOfBuy}`)
                  }
                  options={Object.values(QuoteTypeOfBuy)}
                  value={filters.typeOfBuy}
                  onChange={(_, typeOfBuy) =>
                    setFilters((prev) => {
                      return { ...prev, typeOfBuy };
                    })
                  }
                  sx={{ flex: '1 1 0' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('quotes.filters.typeOfBuy')}
                    />
                  )}
                />
              </Grid>
            </>
          )}
          {mode === ConsultationMode.History && (
            <>
              <Grid item xs={12} sx={{ flexBasis: { md: '15%' } }}>
                <DatePicker
                  label={t('quotes.filters.startDate')}
                  value={filters.startDate}
                  slotProps={{
                    actionBar: { actions: ['clear'] },
                    textField: { fullWidth: true },
                  }}
                  onChange={(startDate: DateTime | null) =>
                    setFilters((prev) => {
                      return { ...prev, startDate };
                    })
                  }
                  closeOnSelect
                  format={t('formats.date')}
                  maxDate={filters.endDate || DateTime.now()}
                />
              </Grid>
              <Grid item xs={12} sx={{ flexBasis: { md: '15%' } }}>
                <DatePicker
                  label={t('quotes.filters.endDate')}
                  value={filters.endDate}
                  slotProps={{
                    actionBar: { actions: ['clear'] },
                    textField: { fullWidth: true },
                  }}
                  onChange={(endDate: DateTime | null) =>
                    setFilters((prev) => {
                      return { ...prev, endDate };
                    })
                  }
                  closeOnSelect
                  format={t('formats.date')}
                  minDate={filters.startDate || undefined}
                  maxDate={DateTime.now()}
                />
              </Grid>
            </>
          )}
          {mode === ConsultationMode.Current && (
            <Grid item xs={12} sx={{ flexBasis: { md: '15%' } }}>
              <Autocomplete
                fullWidth
                popupIcon={<KeyboardArrowDownIcon />}
                getOptionLabel={(expirationTime) =>
                  t(`quotes.filters.expirationTime.${expirationTime}`)
                }
                options={Object.values(QuoteExpirationTimeFilters)}
                value={filters.expirationTime}
                onChange={(_, expirationTime) =>
                  setFilters((prev) => {
                    return { ...prev, expirationTime };
                  })
                }
                sx={{ flex: '1 1 0' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('quotes.filters.expirationTime.title')}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      </FilterContainer>
    </>
  );
};
