import { Stack, Typography } from '@mui/material';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Outlet } from 'react-router-dom';
import {
  Container,
  DecoratedHeader,
  CurrentReservationTable,
  ReservationFilters,
  ReservationHistoryTable,
} from '../../components';
import { RequirePermission } from '../../security';
import { CompanyType, ConsultationMode, UserPermission } from '../../enums/';
import { useCompanies, usePageTitle } from '../../hooks/';
import { Tab } from '../../navigation';
import {
  Filter,
  ReservationFilters as ReservationFiltersType,
} from '../../types';
import { getPageTitle } from '../../utils/pages';
import { getDefaultReservationFilters } from '../../utils/reservationFilters';

interface Props {
  mode?: ConsultationMode;
}

const Reservations: React.FC<Props> = ({
  mode = ConsultationMode.Current,
}: Props) => {
  usePageTitle(
    mode === ConsultationMode.Current
      ? getPageTitle('reservations')
      : getPageTitle('reservations.history')
  );
  const { t } = useTranslation();
  const [filters, setFilters] = useState<ReservationFiltersType>(
    getDefaultReservationFilters()
  );
  const { list: companies } = useCompanies({
    filters: [['type', '==', CompanyType.Customer] as Filter],
  });

  return (
    <>
      <RequirePermission oneOf={[UserPermission.ViewAllReservations]}>
        <Container>
          <DecoratedHeader
            icon={<HourglassTopIcon />}
            title={t('navigation.reservations')}
            variant="colored"
          >
            <Tab title={t('reservations.current')} to={'/admin/reservations'} />
            <Tab
              title={t('reservations.history')}
              to={'/admin/reservations/history'}
            />
          </DecoratedHeader>
        </Container>
        <Container
          backgroundColor="secondary.light"
          roundedTopCorners={true}
          sx={{ pt: { xs: mode === ConsultationMode.Current ? 2 : 0, md: 0 } }}
        >
          <Stack>
            <Typography
              variant="h2"
              sx={{ mt: 4, mb: 3, display: { xs: 'none', md: 'block' } }}
            >
              {mode === ConsultationMode.Current
                ? t('reservations.current')
                : t('reservations.history')}
            </Typography>
          </Stack>
        </Container>
        <ReservationFilters
          isAdmin
          companies={companies}
          filters={filters}
          setFilters={setFilters}
          mode={mode}
        />
        {mode === ConsultationMode.Current ? (
          <CurrentReservationTable
            // Key is used to force the data to be reset when switching between
            // permission modes, as useLocalCollection attempts to filter
            // locally
            key={filters.showOnlyMyReservations.toString()}
            isAdmin
            filters={filters}
            mode={mode}
          />
        ) : (
          <ReservationHistoryTable isAdmin filters={filters} mode={mode} />
        )}
        <Outlet />
      </RequirePermission>
    </>
  );
};

export default Reservations;
