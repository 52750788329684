import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { usePagination, useSorting } from '../../hooks';
import { DataTable, DataTableColumn } from '../../tables';
import {
  reservationConversionRateReportCsv,
  reservationConversionRateReportPdf,
} from '../../reports';

import { Show } from '../Show/Show.component';
import { ExportMenu } from '../ExportMenu/ExportMenu.component';

import { SanitizedReservationConversionRate } from '../../models';
import { useDataCollection } from '../../hooks/useDataCollection';
import { ReservationConversionRateTableProps } from './ReservationConversionRateTable.props';
import * as Sx from './ReservationConversionRateTable.styles';
import { BreakdownRowCollapse } from './BreakdownRowCollapse/BreakdownRowCollapse.component';

export const ReservationConversionRateTable = ({
  filters,
  expandedCompanies,
  setExpandedCompanies,
  conversionRates,
}: ReservationConversionRateTableProps) => {
  const [openedCollapses, setOpenedCollapes] = useState<string[]>(
    expandedCompanies ?? []
  );
  const { t, i18n } = useTranslation();
  const pagination = usePagination();
  const sorting = useSorting('companyName', { sortDirection: 'asc' });
  const { list, unpaginatedResults } = useDataCollection(
    conversionRates,
    sorting,
    pagination
  );

  const exportCSV = async () => {
    if (!filters) return;

    reservationConversionRateReportCsv.generate(unpaginatedResults, filters, t);
  };

  const exportPDF = async () => {
    if (!filters) return;

    reservationConversionRateReportPdf.generate(
      unpaginatedResults,
      filters,
      t,
      i18n
    );
  };

  const totalConversion = useMemo(() => {
    const { purchased, total } = list.reduce(
      (acc, current) => {
        acc.purchased += current.purchased;
        acc.total += current.cancelled + current.expired + current.purchased;
        return acc;
      },
      { purchased: 0, total: 0 }
    );

    return total !== 0 ? Math.round((purchased / total) * 100) : 0;
  }, [list]);

  useEffect(() => {
    setOpenedCollapes(expandedCompanies ?? []);
  }, [expandedCompanies]);

  return (
    <>
      <Show if={list.length > 0}>
        <Grid container justifyContent="space-between">
          <Grid item sx={Sx.totalConversionRateBox}>
            <Typography fontSize="1em" fontWeight="500">
              {t('reports.reservationConversionRate.totalConversion')}
            </Typography>
            <Typography fontSize="3em" fontWeight="500">
              {totalConversion}%
            </Typography>
          </Grid>
          <Grid item display="flex" alignItems="end" mb={0}>
            <ExportMenu
              onCSVClicked={() => exportCSV()}
              onPDFClicked={() => exportPDF()}
            />
          </Grid>
        </Grid>
      </Show>

      <DataTable
        data={list}
        pagination={pagination}
        sorting={sorting}
        rowCollapseContent={(item: SanitizedReservationConversionRate) => (
          <BreakdownRowCollapse
            companyId={item.id}
            conversionRateFilters={filters}
            sanitizedReservationConversionRate={item}
          />
        )}
        rowCollapseOpened={(item: SanitizedReservationConversionRate) =>
          !!openedCollapses && openedCollapses.indexOf(item.id) > -1
        }
        rowOnClick={(item: SanitizedReservationConversionRate) => {
          if (!!openedCollapses && openedCollapses.indexOf(item.id) === -1) {
            setExpandedCompanies([...openedCollapses, item.id]);
          } else {
            setExpandedCompanies(
              openedCollapses?.filter((i) => i !== item.id) ?? [item.id]
            );
            item.userConversionStatistics = [];
          }
        }}
      >
        <DataTableColumn
          property="companyName"
          title={t('reports.reservationConversionRate.company')}
          output={(value) => value || 'N/A'}
          width="20%"
        />

        <DataTableColumn
          property="reserved"
          title={t('reports.reservationConversionRate.reservations')}
          width="15%"
        />

        <DataTableColumn
          property="cancelled"
          title={t('reports.reservationConversionRate.cancelled')}
          width="15%"
        />

        <DataTableColumn
          property="expired"
          title={t('reports.reservationConversionRate.expired')}
          width="15%"
        />

        <DataTableColumn
          property="purchased"
          title={t('reports.reservationConversionRate.purchased')}
          width="15%"
        />

        <DataTableColumn
          property="rate"
          title={t('reports.reservationConversionRate.rate')}
          output={(value) => `${value}%`}
          width="15%"
        />
        <DataTableColumn
          property="expand"
          output={(_, item) => (
            <KeyboardArrowDownIcon
              sx={{
                transform: `rotate(${
                  !!openedCollapses && openedCollapses.indexOf(item.id) > -1
                    ? '-180deg'
                    : '0'
                })`,
                transition: 'transform 0.1s',
              }}
            />
          )}
          width="5%"
        />
      </DataTable>
    </>
  );
};
