import { where } from 'firebase/firestore';

import { UserStatus } from '../enums';
import { User } from '../models';
import { CollectionQueryOptions } from '../types';
import { useCollection } from './useCollection';

export function useNcEmployeeUsers(options?: CollectionQueryOptions) {
  return useCollection<User>(
    'Users',
    [
      where('status', '==', UserStatus.Active),
      where('isNCEmployee', '==', true),
    ],
    options?.sorting || { sortField: 'name', sortDirection: 'asc' },
    options?.pagination,
    options?.filters
  );
}
