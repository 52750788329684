import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { doc, getDoc, Timestamp } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import {
  useAuthentication,
  useCurrentUser,
  useFormFieldValue,
  useQuoteDetails,
  useQuoteFields,
} from '../../../../../hooks';
import { Company, Quote as QuoteModel } from '../../../../../models';
import { ReadyOnlyTypography } from '../../../QuoteSummaryDetails.styles';
import { FormattedTimestamp } from '../../../../FormattedTimestamp/FormattedTimestamp.component';
import { Nullable } from '../../../../../types';
import {
  ReadonlyInnerStack,
  ReadonlyQuoteStyledStack,
} from './../OtherDetails.styles';
import { DetailsProps } from './Details.props';

export const ReadonlyDetails = ({ form, quoteNotesElement }: DetailsProps) => {
  const { t } = useTranslation();
  const firestore = useFirestore();
  const { isAdmin } = useAuthentication();
  const currentUser = useCurrentUser();
  const [company, setCompany] = useState<Company | null>(null);

  const { quoteIsPricedOrCancelled } = useQuoteDetails(form);
  useEffect(() => {
    (async () => {
      if (!form.item?.companyId) {
        setCompany(null);
      } else if (company?.id !== form.item?.companyId) {
        const companyRef = doc(firestore, 'Companies', form.item?.companyId);
        const company = (await getDoc(companyRef)).data();
        setCompany(company as Company);
      }
    })();
  }, [company?.id, firestore, form.item?.companyId]);

  const { getTotalCost } = useQuoteFields(form, company);

  const { value: expirationTime } = useFormFieldValue<
    QuoteModel,
    Nullable<Timestamp>
  >(form, 'expirationTime');

  const expirationTimeElement = () =>
    expirationTime ? (
      <ReadyOnlyTypography>
        <b>{t('quotes.edit.summary.quoteDetails.expirationTime.label')}</b>
        <FormattedTimestamp
          showTime={false}
          timestamp={expirationTime}
          timeZone={currentUser.timeZone}
        />
      </ReadyOnlyTypography>
    ) : null;

  return (
    <ReadonlyQuoteStyledStack isPricedOrCancelled={quoteIsPricedOrCancelled}>
      {quoteIsPricedOrCancelled && (
        <ReadonlyInnerStack isAdmin={isAdmin}>
          <ReadyOnlyTypography>
            {t('quotes.edit.summary.quoteDetails.totalCost')}
          </ReadyOnlyTypography>
          <Typography variant="h1">
            {getTotalCost(form?.item as QuoteModel)}
          </Typography>
          {!isAdmin && expirationTimeElement()}
        </ReadonlyInnerStack>
      )}
      <Stack sx={{ rowGap: { xs: 1, lg: 4 } }}>
        {isAdmin && expirationTimeElement()}
        {quoteNotesElement}
      </Stack>
    </ReadonlyQuoteStyledStack>
  );
};
