import {
  Badge,
  MenuItem as MuiMenuItem,
  ListItemIcon,
  ListItemText,
  BadgeOrigin,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { useMatchLocation } from '../../hooks';
import { TabProps } from './Tab.props';
import * as Sx from './Tab.styles';

export const Tab = ({
  active,
  disabled,
  icon,
  match,
  title,
  to,
  onClick,
  children,
  withBadge,
}: TabProps) => {
  const isMatch = useMatchLocation(to, match);

  const anchorOrigin: BadgeOrigin = {
    vertical: 'top',
    horizontal: 'right',
  };

  return (
    <Badge
      anchorOrigin={anchorOrigin}
      color="secondary"
      invisible={withBadge !== true}
      sx={Sx.badge}
      variant="dot"
    >
      <MuiMenuItem
        tabIndex={0}
        component={to ? Link : 'a'}
        to={to ? to : undefined}
        onClick={onClick}
        disabled={disabled}
        sx={Sx.menuItem(active || !!isMatch)}
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        {!children && (
          <ListItemText
            primaryTypographyProps={{
              sx: Sx.menuItemTypography,
            }}
          >
            {title}
          </ListItemText>
        )}
        {children}
      </MuiMenuItem>
    </Badge>
  );
};
