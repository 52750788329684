import { Timestamp } from 'firebase/firestore';
import { DateTime } from 'luxon';

export const isTodayOrAfter = (date: Timestamp) => {
  try {
    const selectedDate = DateTime.fromJSDate(date.toDate());
    const today = DateTime.now();
    return selectedDate >= today || today.hasSame(selectedDate, 'day');
  } catch {
    return false;
  }
};

export const isSameMonthOrAfter = (date: number) =>
  date >= dateToYearMonth(DateTime.now());

export const dateToYearMonth = (date: DateTime) =>
  parseInt(
    date.year.toString().padEnd(4, '0') + date.month.toString().padStart(2, '0')
  );

export const yearMonthToDate = (yearMonth: number) => {
  const yearMonthString = yearMonth.toString();
  return DateTime.fromObject({
    year: parseInt(yearMonthString.substring(0, 4)),
    month: parseInt(yearMonthString.substring(4, 6)),
  });
};
