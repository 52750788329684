import Box from '@mui/material/Box';

import {
  FOOTER_HEIGHT,
  HEADER_CONTENT_HEIGHT,
} from '../../navigation/Navigation/constants';
import { MainContentLayoutProps } from './MainContentLayout.props';

export const MainContentLayout = ({ children }: MainContentLayoutProps) => (
  <Box
    sx={{
      mt: HEADER_CONTENT_HEIGHT,
      height: {
        xs: `calc(100vh - ${HEADER_CONTENT_HEIGHT})`,
        md: `calc(100vh - ${HEADER_CONTENT_HEIGHT} - ${FOOTER_HEIGHT})`,
      },
      display: 'inline-block',
      overflowY: 'auto',
    }}
  >
    {children}
  </Box>
);
