import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
  usePagination,
  useQuoteProductConversionRates,
  useSorting,
} from '../../hooks';
import { DataTable, DataTableColumn } from '../../tables';
import { QuoteProductConversionRateTableProps } from './QuoteProductConversionRateTable.props';

export const QuoteProductConversionRateTable = ({
  productTypes,
  setConversionData,
  quotes,
  companyIds,
}: QuoteProductConversionRateTableProps) => {
  const { t } = useTranslation();
  const pagination = usePagination();
  const sorting = useSorting('name', { sortDirection: 'asc' });

  const {
    list: quoteConversionRate,
    unpaginatedResults,
    loading,
  } = useQuoteProductConversionRates(quotes, companyIds, productTypes, {
    sorting,
    pagination,
  });

  useEffect(() => {
    setConversionData && setConversionData(unpaginatedResults);
  }, [setConversionData, unpaginatedResults]);

  return (
    <DataTable
      loading={loading}
      data={quoteConversionRate}
      sorting={sorting}
      pagination={pagination}
    >
      <DataTableColumn
        property="name"
        title={t('reports.quoteConversionRate.productType')}
        width="20%"
      />
      <DataTableColumn
        property="quoted"
        title={t('reports.quoteConversionRate.quoted')}
        width="8%"
      />
      <DataTableColumn
        property="expired"
        title={t('reports.quoteConversionRate.expired')}
        width="8%"
      />
      <DataTableColumn
        property="won"
        title={t('reports.quoteConversionRate.won')}
        width="8%"
      />
      <DataTableColumn
        property="lost"
        title={t('reports.quoteConversionRate.lost')}
        width="8%"
      />
      <DataTableColumn
        property="rate"
        title={t('reports.quoteConversionRate.rate')}
        output={(value) => `${value}%`}
        width="10%"
      />
      <DataTableColumn
        property="totalQuotedFormatted"
        title={t('reports.quoteConversionRate.totalQuoted')}
        width="10%"
      />
      <DataTableColumn
        property="totalWonFormatted"
        title={t('reports.quoteConversionRate.totalWon')}
        width="10%"
      />
      <DataTableColumn
        property="totalWonRate"
        title={t('reports.quoteConversionRate.totalWonRate')}
        output={(value) => `${value}%`}
        width="16%"
      />
    </DataTable>
  );
};
