import { Stack, styled } from '@mui/material';

export const StyledStack = styled(Stack)(() => {
  return {
    flexDirection: 'column',
    height: '100%',

    '& .MuiBox-root': {
      width: '100%',
      textWrap: 'pretty',
      wordBreak: 'break-word',
    },
  };
});
