import { useMemo } from 'react';
import { ProvinceOrState } from '../enums';
import { ShipToEnum, ShipTo } from '../enums/ShipTo';

export const useShipToValues = () =>
  useMemo(
    () =>
      [
        ...Object.values(ShipToEnum),
        ...Object.values(ProvinceOrState),
      ] as ShipTo[],
    []
  );
