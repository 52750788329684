import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { CollectionQueryOptions, Nullable } from '../types';
import { Company, Quote, SanitizedQuoteConversionRate } from '../models';
import { Country, ProductType, PurchaseStatus, QuoteStatus } from '../enums';
import { CurrencyFormatter } from '../utils';
import { getQuoteProductExtendedPrice } from '../utils/quote';
import { getCompanyLocation } from '../utils/company';
import { useDataCollection } from './useDataCollection';

export function useQuoteProductConversionRates(
  quotes: Quote[],
  companyIds?: Nullable<string[]>,
  productTypes?: Nullable<ProductType[]>,
  options?: Pick<CollectionQueryOptions, 'pagination' | 'sorting'>
) {
  const { t } = useTranslation();

  const quoteConversionRates = useMemo(() => {
    const result = quotes.reduce((acc, cur) => {
      if (
        !cur.companyId ||
        (companyIds && !companyIds.includes(cur.companyId))
      ) {
        return acc;
      }

      const companyLocation = getCompanyLocation({
        accountNumber: cur.companyAccountNumber,
      } as Company);

      cur.products.forEach((product) => {
        const type = product.productType;
        if (!type || (productTypes && !productTypes?.includes(type))) {
          return acc;
        }

        const productTypeLocation = product.isCSA
          ? Country.Canada
          : Country.UnitedStates;
        const currencyFormatter = new CurrencyFormatter(
          productTypeLocation,
          Number.MAX_VALUE
        );

        const record = acc[type] || new SanitizedQuoteConversionRate();
        if (!record.id) {
          record.id = type.toString();
          record.name = t(`productTypes.${type}`);
        }
        const extendedPrice = getQuoteProductExtendedPrice(
          product,
          companyLocation,
          cur.measurementUnits
        );

        record.totalQuoted += extendedPrice;
        record.quoted++;
        if (cur.status === QuoteStatus.Expired) {
          record.expired++;
        } else if (product.purchaseStatus === PurchaseStatus.Lost) {
          record.lost++;
        } else if (product.purchaseStatus === PurchaseStatus.Won) {
          record.won++;
          record.totalWon += extendedPrice;
        }

        record.rate = record.quoted
          ? Math.round((record.won / record.quoted) * 100)
          : 0;
        record.totalWonRate =
          record.totalWon && record.totalQuoted
            ? Math.round((record.totalWon / record.totalQuoted) * 100)
            : 0;
        record.totalQuotedFormatted = currencyFormatter.format(
          record.totalQuoted
        );
        record.totalWonFormatted = currencyFormatter.format(record.totalWon);

        acc[type] = record;
      });
      return acc;
    }, {} as Record<string, SanitizedQuoteConversionRate>);

    return Object.values(result);
  }, [companyIds, productTypes, quotes, t]);

  return useDataCollection(
    quoteConversionRates,
    options?.sorting,
    options?.pagination
  );
}
