import { Autocomplete, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useTranslation } from 'react-i18next';
import { doc } from 'firebase/firestore';
import { useAuthentication, useUsers } from '../../hooks';
import firebaseHooks from '../../firebase/hooks';
import { QuotesFiltersRequestorsByCompanyProps } from './QuotesFiltersRequestorsByCompany.props';

export const QuotesFiltersRequestorsByCompany = ({
  filters,
  setFilters,
  companyId,
}: QuotesFiltersRequestorsByCompanyProps) => {
  const { t } = useTranslation();
  const { isAdmin } = useAuthentication();
  const firestore = firebaseHooks.useFirestore();

  const { list: requestors } = useUsers({
    filters: [['companyRef', '==', doc(firestore, 'Companies', companyId)]],
  });

  return (
    <Autocomplete
      disabled={isAdmin && !filters.companyId}
      popupIcon={<KeyboardArrowDownIcon />}
      getOptionLabel={(user) => user.name || ''}
      options={requestors ?? []}
      value={requestors?.find((u) => u.id === filters.requestorId) ?? null}
      onChange={(_, user) =>
        setFilters((prev) => {
          return { ...prev, requestorId: user?.id || null };
        })
      }
      sx={{ flex: '1 1 0' }}
      renderInput={(params) => (
        <TextField {...params} label={t('quotes.filters.requestor')} />
      )}
    />
  );
};
