import { useEffect, useRef, useState } from 'react';
import {
  collection,
  doc,
  setDoc,
  deleteDoc as deleteDocument,
} from 'firebase/firestore';
import { Model } from '../types';
import firebaseHooks from '../firebase/hooks';

/**
 * Access a persistable Firestore document
 *
 * @param path - Firestore path
 * @param pathSegments - Firestore path segments (if one includes `'new'`, it will be
 * assumed to be a new document
 */
export function useDocument<T extends Model>(
  path: string,
  ...pathSegments: string[]
) {
  const isNewSegment = pathSegments.some((s) => s === 'new');

  const firestore = firebaseHooks.useFirestore();
  const ref = doc(firestore, path, ...pathSegments);
  const { status, data: item } = firebaseHooks.useFirestoreDocData(ref, {
    idField: '',
  });
  const [loadedPath, setLoadedPath] = useState<string | null>(null);
  const isDeleted = useRef(false);

  // Store the loaded path when we receive an item, so we can check if
  // the path arguments change
  useEffect(() => {
    if (item) {
      setLoadedPath(`${path}/${pathSegments.join('/')}`);
    }
    // Should only be called when item changes, therefore:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const loading = status === 'loading';

  const createDoc = async (newItem: T) => {
    const docRef = doc(collection(firestore, path));

    newItem['id'] = docRef.id;

    await setDoc(docRef, newItem);

    return newItem;
  };

  const saveDoc = async (newItem: T) => {
    await setDoc(ref, newItem, { merge: true });

    return newItem;
  };

  const deleteDoc = async () => {
    isDeleted.current = true;
    await deleteDocument(ref);
  };

  // Throw a non found error so we can show an error message to the user, but
  // this should only happen if the loadedPath is the same as the requested one
  // in case the arguments to this hook change
  if (
    !isDeleted.current &&
    status === 'success' &&
    !isNewSegment &&
    !item &&
    loadedPath === `${path}/${pathSegments.join('/')}`
  ) {
    throw new Error('not-found: Document does not exist');
  }

  return {
    item: item as T | null,
    save: isNewSegment ? createDoc : saveDoc,
    loading,
    deleteDoc,
  };
}
