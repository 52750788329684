import { TFunction } from 'i18next';

abstract class report<T> {
  protected readonly prefix: string;
  protected readonly namespace: string;
  protected readonly keys: string[];
  protected readonly t: TFunction<'translation', undefined>;

  constructor(
    prefix: string,
    namespace: string,
    keys: string[],
    t: TFunction<'translation', undefined>
  ) {
    this.prefix = prefix;
    this.namespace = namespace;
    this.keys = keys;
    this.t = t;
  }

  protected abstract getSanitizedData(): T[];

  protected getFilename(): string {
    return `${this.prefix}_${new Date()
      .toLocaleString()
      .replace(/[^0-9]/g, '')}.csv`;
  }

  protected generateCSV() {
    const data = this.getSanitizedData();
    const csv = this.getCSVData(data);
    const filename = this.getFilename();

    this.output(csv, filename);
  }

  protected getHeaders(): string[] {
    return this.keys.map((key) => `"${this.t(`${this.namespace}.${key}`)}"`);
  }

  protected getCSVData(data: T[]): string {
    const csvRows = [];
    csvRows.push(this.getHeaders().join(','));

    for (const entry of data) {
      const values = this.keys.reduce((prev, cur, index) => {
        let result = entry[cur as keyof T] as unknown;

        if (result === undefined) {
          result = '';
        }

        if (typeof result === 'string') {
          result = result.replace(/"/g, '""');
        }

        prev += `"${result}"`;

        if (index !== this.keys.length - 1) {
          prev += ',';
        }

        return prev;
      }, '');
      csvRows.push(values);
    }

    return csvRows.join('\r\n');
  }

  protected output(csv: string, filename: string): void {
    const encodedUri = encodeURIComponent(csv);
    const a = document.createElement('a');

    a.setAttribute('href', 'data:text/csv;charset=utf-8,\uFEFF' + encodedUri);
    a.setAttribute('download', filename);
    a.click();
  }
}

export default report;
