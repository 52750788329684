import { Timestamp } from 'firebase/firestore';
import {
  ReservationConversionRateFilters,
  Filter,
  Nullable,
} from '../../types';
import { ReservationStatus } from '../../enums';
import { SanitizedReservationConversionRate } from '../../models/ReservationConversionRate';

export const buildFilters = (
  filters: Nullable<ReservationConversionRateFilters>
): Filter[] => {
  if (!filters) return [];

  const result: Filter[] = [];

  const { startDate, endDate } = filters;

  if (startDate) {
    result.push([
      'date',
      '>=',
      Timestamp.fromMillis(startDate.startOf('day').toMillis()),
    ]);
  }

  if (endDate) {
    result.push([
      'date',
      '<=',
      Timestamp.fromMillis(endDate.endOf('day').toMillis()),
    ]);
  }

  return result;
};

export const updateStatistics = (
  status: ReservationStatus,
  record: SanitizedReservationConversionRate
) => {
  switch (status) {
    case ReservationStatus.Cancelled:
      record.cancelled += 1;
      break;
    case ReservationStatus.Expired:
      record.expired += 1;
      break;
    case ReservationStatus.Purchased:
      record.purchased += 1;
      break;
    case ReservationStatus.Reserved:
      record.reserved += 1;
      break;
  }
  const total = record.cancelled + record.expired + record.purchased;
  record.rate = total !== 0 ? Math.round((record.purchased / total) * 100) : 0;
};
