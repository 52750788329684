import { useMemo } from 'react';

import { useReservationConversionRate } from '../../hooks';
// eslint-disable-next-line max-len
import { ReservationConversionRateTable } from '../ReservationConversionRateTable/ReservationConversionRateTable.component';
import { buildFilters } from './common';
import { ReservationConversionRateProps } from './ReservationConversionRate.props';

export const AdminReservationConversionRate = ({
  filters,
  expandedCompanies,
  setExpandedCompanies,
}: ReservationConversionRateProps) => {
  const queryFilters = useMemo(() => buildFilters(filters), [filters]);

  const list = useReservationConversionRate(
    {
      filters: queryFilters,
    },
    filters?.companyIds
  );

  return (
    <ReservationConversionRateTable
      expandedCompanies={expandedCompanies}
      setExpandedCompanies={setExpandedCompanies}
      filters={filters}
      conversionRates={list}
    />
  );
};
