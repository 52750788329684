import { Box, BoxProps, Stack, Typography, styled } from '@mui/material';

export const ReadyOnlyTypography = styled(Typography)(() => {
  return {
    fontSize: '1rem',
  };
});

interface CustomBoxProps extends BoxProps {
  isReadonly: boolean;
  isAgent: boolean;
  hasCustomerNote: boolean;
}

export const StyledStack = styled(Stack)(({ theme }) => {
  return {
    flexDirection: 'row',
    width: '100%',
    useFlexGap: true,
    flexWrap: 'wrap',
    marginBottom: theme.spacing(1),

    [theme.breakpoints.down('md')]: {
      height: 'unset',
      flexDirection: 'column',
    },

    [theme.breakpoints.down('lg')]: {
      rowGap: theme.spacing(1.5),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(2),
    },
  };
});

export const StyledCustomerDetails = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isReadonly' && prop !== 'isAgent' && prop !== 'hasCustomerNote',
})<CustomBoxProps>(({ theme, isAgent, isReadonly }) => {
  return {
    ...{
      paddingRight: theme.spacing(1),
      width: 'inherit',
      flexDirection: 'column',

      '&.MuiBox-root': {
        '& .MuiBox-root': {
          '&:first-of-type': {
            '& .MuiAutocomplete-root': {
              '& .MuiFormControl-root': {
                marginTop: 0,
              },
            },
          },
        },
        '& .MuiAutocomplete-root': {
          '& .MuiFormControl-root': {
            marginTop: theme.spacing(1),
          },
        },
      },

      [theme.breakpoints.down('md')]: {
        width: '100%',
        maxWidth: '100%',
        padding: 0,
      },
    },
    // AGENT USERS
    ...(isAgent && {
      [theme.breakpoints.only('md')]: {
        maxWidth: '40%',
      },

      [theme.breakpoints.up('lg')]: {
        maxWidth: isReadonly ? '25%' : '30%',
      },
    }),
    // CUSTOMER USERS
    ...(!isAgent && {
      [theme.breakpoints.up('md')]: {
        maxWidth: isReadonly ? '30%' : '40%',
      },

      [theme.breakpoints.down('md')]: {
        padding: 0,
      },
    }),
  };
});

export const StyledQuoteDetails = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isReadonly' && prop !== 'isAgent' && prop !== 'hasCustomerNote',
})<CustomBoxProps>(({ theme, hasCustomerNote, isAgent, isReadonly }) => {
  return {
    ...{
      width: 'inherit',
      flexDirection: 'column',

      [theme.breakpoints.between('xs', 'lg')]: {
        maxWidth: isReadonly ? '50%' : '100%',
      },

      [theme.breakpoints.down('md')]: {
        width: '100%',
        maxWidth: '100%',
      },
    },
    // AGENT USERS
    ...(isAgent && {
      [theme.breakpoints.only('md')]: {
        maxWidth: '60%',
      },

      [theme.breakpoints.up('lg')]: {
        maxWidth: isReadonly ? '25%' : '50%',
      },
    }),
    // CUSTOMER USERS
    ...(!isAgent && {
      [theme.breakpoints.up('md')]: {
        maxWidth: isReadonly ? '30%' : hasCustomerNote ? '25%' : '50%',
        paddingRight: '1rem',
      },
    }),
  };
});

export const StyledOtherDetails = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isReadonly' && prop !== 'isAgent' && prop !== 'hasCustomerNote',
})<CustomBoxProps>(({ theme, hasCustomerNote, isAgent, isReadonly }) => {
  return {
    ...{
      width: 'inherit',
      flexDirection: 'column',

      [theme.breakpoints.down('lg')]: {
        width: '100%',
        maxWidth: '100%',
      },
    },
    // AGENT USERS
    ...(isAgent && {
      [theme.breakpoints.up('lg')]: {
        maxWidth: isReadonly ? '50%' : '30%',
        paddingLeft: theme.spacing(1),
      },

      '& .MuiStack-root>.MuiStack-root:only-child': {
        maxWidth: '100%',
      },
    }),
    // CUSTOMER USERS
    ...(!isAgent && {
      [theme.breakpoints.up('md')]: {
        maxWidth: isReadonly ? '40%' : hasCustomerNote ? '25%' : 0,
      },
    }),
  };
});
