import { Autocomplete, TextField as MuiTextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { input } from '../../pages/admin/modals/ImpersonateCustomer/ImpersonateCustomer.styles';
import { UserSelectProps } from './UserSelect.props';
import { UserSelect } from './UserSelect.component';

export const UserSelectContainer = (props: UserSelectProps) =>
  !props.companyId ? (
    <Autocomplete
      fullWidth
      disabled
      popupIcon={<KeyboardArrowDownIcon />}
      options={[]}
      value={null}
      renderInput={(params) => (
        <MuiTextField {...params} {...props.textFieldProps} sx={input} />
      )}
    />
  ) : (
    <UserSelect {...props} />
  );
