import { DateTime } from 'luxon';
import { TFunction } from 'i18next';
import { Quote } from '../../models';
import { getSanitizedExportQuote } from '../../utils/quote';
import report from './report';

const PREFIX = 'NC_Quotes';
const NAMESPACE = 'quotes.fields';
const EXPORTED_KEYS = [
  'quoteNumber',
  'projectName',
  'nciProjectId',
  'companyName',
  'agencyName',
  'customerName',
  'status',
  'totalSell',
  'lastModified',
  'expirationTime',
  'employeeName',
  'typeOfBuy',
];

class quotesCsv extends report<Quote> {
  private readonly data: Quote[];

  constructor(data: Quote[], t: TFunction) {
    super(PREFIX, NAMESPACE, EXPORTED_KEYS, t);

    this.data = data;
  }

  protected override getFilename(): string {
    const now = DateTime.now().toFormat('LLddyyyyHHmmss');
    return `${PREFIX}_${now}.csv`;
  }

  protected getSanitizedData(): Quote[] {
    return this.data.map(
      (quote) => getSanitizedExportQuote(quote, this.t) as unknown as Quote
    );
  }

  static generate(data: Quote[], t: TFunction) {
    const report = new quotesCsv(data, t);

    report.generateCSV();
  }
}

export default quotesCsv;
