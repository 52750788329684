import {
  usePagination,
  useSorting,
  useQuotes,
  useQuotesFilters,
} from '../../hooks';
import { QuotesTable } from '../QuotesTable/QuotesTable.component';
import { CurrentQuotesTableProps } from './CurrentQuotesTable.props';

export const CurrentQuotesTable = ({
  mode,
  filters,
}: CurrentQuotesTableProps) => {
  const sorting = useSorting('lastModified', { sortDirection: 'desc' });
  const pagination = usePagination();
  const { filter, composite } = useQuotesFilters(filters);
  const { list, loading, getFirestoreQuery } = useQuotes({
    filters: filter,
    sorting,
    pagination,
    compositeFilters: composite,
  });

  return (
    <QuotesTable
      fetchAllQuery={getFirestoreQuery(true)}
      mode={mode}
      pagination={pagination}
      quotes={!loading ? list : undefined}
      sorting={sorting}
    />
  );
};

export default CurrentQuotesTable;
