import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { RequireRole, roles } from '../../security/';
import { AdminMenu, Footer, MenuItem, Navigation } from '../../navigation';
import { Layout } from '../../components';
import { AuthenticatedLayout, MainContentLayout } from '..';
import { defaultLanguage } from '../../types/Languages';
import { useHasSecondAuthFactors, usePortalSettings } from '../../hooks';
import { translateString } from '../../i18n';

export const AdminLayout = () => {
  const { t, i18n } = useTranslation();
  const { item: portalSettings } = usePortalSettings();

  useEffect(() => {
    if (i18n.language !== defaultLanguage) {
      i18n.changeLanguage(defaultLanguage);
    }
  }, [i18n, i18n.language]);

  const hasSecondAuthFactors = useHasSecondAuthFactors();

  return (
    <AuthenticatedLayout>
      <RequireRole roles={roles.AdminRoles}>
        <Layout>
          {hasSecondAuthFactors ? (
            <Navigation variant="side">
              <AdminMenu />
            </Navigation>
          ) : (
            <Navigation hideApplicationName publicFacing>
              <MenuItem
                href={translateString(portalSettings?.contactURL, i18n)}
                target="_blank"
                title={t('contact.title')}
              />
              <MenuItem href="/logout" title={t('navigation.logOut')} />
            </Navigation>
          )}
          <MainContentLayout>
            <Outlet />
          </MainContentLayout>
          <Footer />
        </Layout>
      </RequireRole>
    </AuthenticatedLayout>
  );
};
