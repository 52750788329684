/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    env: any;
  }
}

type ProcessEnv = {
  REACT_APP_SITE_KEY: string;
  REACT_APP_FIREBASE_API_KEY: string;
  REACT_APP_FIREBASE_APP_ID: string;
  REACT_APP_FIREBASE_AUTH_DOMAIN: string;
  REACT_APP_FIREBASE_MEASUREMENT_ID: string;
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: string;
  REACT_APP_FIREBASE_PROJECT_ID: string;
  REACT_APP_FIREBASE_STORAGE_BUCKET: string;
  REACT_APP_DIDOMI_API_KEY: string;
  REACT_APP_DIDOMI_CONSENT_NOTICE: string;
  REACT_APP_DIDOMI_PRIVACY_POLICY_EN_URL: string;
  REACT_APP_DIDOMI_PRIVACY_POLICY_FR_URL: string;
};

export const env: ProcessEnv = { ...process.env, ...window.env };
