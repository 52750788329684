import { Avatar, Drawer, Grid, Typography } from '@mui/material';

import { Container } from '..';

import { BottomDrawerProps } from './BottomDrawer.props';
import * as Sx from './BottomDrawer.styles';

export const BottomDrawer = ({
  children,
  height = '250px',
  icon,
  open,
  rightContent,
  title,
}: BottomDrawerProps) => (
  <Drawer
    anchor="bottom"
    open={open}
    PaperProps={{
      sx: Sx.drawerPaper(height),
    }}
  >
    <Container sx={Sx.container}>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: 2, md: 0 },
        }}
        justifyContent="space-between"
        height="100%"
      >
        <Grid flexGrow="1" sx={{ marginRight: { md: 5, xs: 0 } }}>
          <Grid display="flex" alignItems="center" mb={2} mt={2}>
            <Avatar sx={Sx.avatar}>{icon}</Avatar>
            <Typography variant="h2" marginLeft="20px">
              {title}
            </Typography>
          </Grid>

          {children}
        </Grid>

        <Grid
          sx={{ justifyContent: { xs: 'flex-end', md: 'flex-start' } }}
          display="flex"
          alignItems="end"
        >
          {rightContent}
        </Grid>
      </Grid>
    </Container>
  </Drawer>
);
