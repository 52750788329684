import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import { useTranslation, Trans } from 'react-i18next';
import { Alert, Box, Button, Grid, TextField } from '@mui/material';
import LockRoundedIcon from '@mui/icons-material/LockRounded';

import { AuthErrorCodes } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import {
  DecoratedHeader,
  RecaptchaVerifier,
  Show,
  TwoFactorCodeVerification,
  UnitaryModal,
} from '../components';
import { getPageTitle } from '../utils/pages';
import {
  useNotification,
  usePageTitle,
  usePortalSettings,
  usePublicAuthentication,
} from '../hooks';
import { formatPhoneNumber } from '../utils/phoneNumber';
import { MainContentLayout, TwoFactorLayout } from '../layouts';
import { NotificationLevel } from '../enums';
import { getLanguage } from '../utils/locale';

const Login: React.FC = () => {
  const functions = useFunctions();
  const { t, i18n } = useTranslation();
  const { login, sendSmsMfaCode, verifyMfaCode } = usePublicAuthentication();
  const { item: portalSettings } = usePortalSettings();
  const { addNotification } = useNotification();
  usePageTitle(getPageTitle('login'));
  const logUserActivity = httpsCallable(functions, 'logUserActivity');

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [mfaRequired, setMfaRequired] = useState<boolean>(false);
  const [mfaPhoneNumber, setMfaPhoneNumber] = useState<string>('');

  const lang = getLanguage(i18n);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setLoading(true);
    const result = await login(
      data.get('email') as string,
      data.get('password') as string
    );

    if (result.type === 'mfa-required') {
      setLoading(false);
      setMfaRequired(true);
      setMfaPhoneNumber(result.mfaPhoneNumber ?? '');
      return;
    }

    if (result.type !== 'success') {
      setLoading(false);
      setError(result.type);
    }

    logUserActivity({
      email: data.get('email'),
      success: result.type === 'success',
    });
  };

  const handleResendCode = async () => {
    try {
      await sendSmsMfaCode();
      addNotification(
        t('twoFactorAuth.setup.phoneNumber.codeResent'),
        NotificationLevel.Success
      );
    } catch (error) {
      const fbError = error as FirebaseError;
      if (fbError.code === AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER) {
        addNotification(
          t('twoFactorAuth.setup.phoneNumber.tooManyAttemptsError'),
          NotificationLevel.Error
        );
        return;
      } else if (
        fbError.message ===
        'INVALID_MFA_PENDING_CREDENTIAL : MFA pending credential is expired.'
      ) {
        addNotification(
          t('twoFactorAuth.setup.phoneNumber.codeResentExpiredError'),
          NotificationLevel.Error
        );
        setMfaRequired(false);
        setMfaPhoneNumber('');
        return;
      }
      addNotification(
        t('twoFactorAuth.setup.phoneNumber.codeResentError'),
        NotificationLevel.Error
      );
    }
  };

  const renderError = (error: string | null) => {
    if (!error) {
      return null;
    }
    switch (error) {
      case 'too-many-attempts':
        return t('users.errors.tooManyAttempts');
      case 'disabled':
        return (
          <Trans
            t={t}
            i18nKey="users.errors.userDeactivated"
            values={{
              phone: formatPhoneNumber(
                portalSettings?.contactPhoneNumber[lang] || ''
              ),
              email: portalSettings?.contactEmail[lang],
            }}
          />
        );
      default:
        return t('users.errors.invalidCredentials');
    }
  };

  return (
    <>
      <RecaptchaVerifier />
      {mfaRequired ? (
        <MainContentLayout>
          <TwoFactorLayout title={t('twoFactorAuth.verification.title')}>
            <TwoFactorCodeVerification
              phoneNumber={mfaPhoneNumber}
              onSendCode={handleResendCode}
              onVerifyCode={verifyMfaCode}
            />
          </TwoFactorLayout>
        </MainContentLayout>
      ) : (
        <UnitaryModal>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, maxWidth: '25rem' }}
          >
            <DecoratedHeader
              icon={<LockRoundedIcon titleAccess={t('alt.lockIcon')} />}
              title={
                <>
                  {t('application.title.line1')}
                  <br />
                  {t('application.title.line2')}
                </>
              }
            />
            <Show if={!!error}>
              <Alert severity="error">{renderError(error)}</Alert>
            </Show>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('users.fields.email')}
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('users.fields.password')}
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Grid container justifyContent="flex-end" sx={{ mb: 3 }}>
              <Grid item>
                <Link
                  to="/forgot-password"
                  style={{ color: 'black', fontSize: '0.875rem' }}
                >
                  {t('users.actions.forgotPassword')}
                </Link>
              </Grid>
            </Grid>
            <Button
              disabled={loading}
              fullWidth
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t('users.actions.login')}
            </Button>
            <Button
              color="secondary"
              component={Link}
              fullWidth
              sx={{ mt: 1, mb: 2 }}
              to="/sign-up"
              type="submit"
              variant="outlined"
            >
              {t('users.actions.signUp')}
            </Button>
            <Grid container>
              <Grid item></Grid>
            </Grid>
          </Box>
        </UnitaryModal>
      )}
    </>
  );
};

export default Login;
