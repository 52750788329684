import { Outlet } from 'react-router-dom';

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RequireRole, roles } from '../../security';
import { AgentMenu, Footer, Navigation } from '../../navigation';
import { Layout } from '../../components';
import { AuthenticatedLayout, MainContentLayout } from '..';
import { defaultLanguage } from '../../types/Languages';

export const AgentLayout = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language !== defaultLanguage) {
      i18n.changeLanguage(defaultLanguage);
    }
  }, [i18n, i18n.language]);

  return (
    <AuthenticatedLayout>
      <RequireRole roles={roles.AgentRoles}>
        <Layout>
          <Navigation variant="side">
            <AgentMenu />
          </Navigation>
          <MainContentLayout>
            <Outlet />
          </MainContentLayout>
          <Footer />
        </Layout>
      </RequireRole>
    </AuthenticatedLayout>
  );
};
