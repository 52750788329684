import { Box, Button, Grid, Typography } from '@mui/material';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FormattedTimestamp, MultilineTypography, Show } from '../../../..';
import { useCurrentUser } from '../../../../../hooks';
import { InternalNoteTabProps } from './InternalNoteTab.props';

export const InternalNoteTab = ({
  children: editComponent,
  index,
  isEditing,
  note,
  onDeleteClick,
  onEditClick,
}: InternalNoteTabProps) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const ref = useRef<HTMLDivElement | null>(null);

  const handleDeleteClick = (index: number) => {
    if (ref.current) {
      onDeleteClick(index, ref.current.offsetHeight);
    }
  };

  return (
    <Box ref={ref}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography variant="body2" fontWeight="bold">
          {note.createdByName}
        </Typography>
        <Typography variant="body1">
          <FormattedTimestamp timestamp={note.lastModified} />
        </Typography>
      </Grid>
      {isEditing ? (
        editComponent
      ) : (
        <>
          <MultilineTypography sx={{ mt: 1, fontSize: '0.75rem' }}>
            {note.content}
          </MultilineTypography>
          <Show if={currentUser.id === note.createdById}>
            <Grid container justifyContent="flex-end">
              <Button onClick={() => onEditClick(index)} sx={{ px: 0 }}>
                <Typography variant="body1">
                  {t('quotes.edit.notes.edit')}
                </Typography>
              </Button>
              <Button onClick={() => handleDeleteClick(index)} sx={{ px: 0 }}>
                <Typography variant="body1">
                  {t('quotes.edit.notes.delete')}
                </Typography>
              </Button>
            </Grid>
          </Show>
        </>
      )}
    </Box>
  );
};
