import { useMemo } from 'react';
import {
  InventorySearchDailyStats,
  SanitizedProductSearchStats,
} from '../models';
import { CollectionQueryOptions } from '../types';
import { InventorySearchReportTranslationMap } from '../mappings';
import { useLocalCollection } from './useLocalCollection';
import { useDataCollection } from './useDataCollection';

export function useInventorySearchStats(options?: CollectionQueryOptions) {
  const dateFilters = useMemo(() => {
    if (!options?.filters) {
      return [];
    }
    return options.filters.filter((f) => f && f[0].toString().endsWith('date'));
  }, [options?.filters]);

  const cableFilters = useMemo(() => {
    if (!options?.filters) {
      return [];
    }
    return options.filters.filter(
      (f) => f && !f[0].toString().endsWith('date')
    );
  }, [options?.filters]);

  const { list: dailyStats, loading: dailyStatsLoading } =
    useLocalCollection<InventorySearchDailyStats>(
      'InventorySearchDailyStats',
      [undefined],
      undefined,
      undefined,
      dateFilters
    );

  const searchStats = useMemo(() => {
    if (dailyStatsLoading) {
      return [];
    }
    return Object.values(
      dailyStats.reduce((acc, cur) => {
        Object.entries(cur.products).forEach(([searchString, stats]) => {
          // Split search string into cable properties
          const properties = searchString.split(';').reduce((acc, property) => {
            const [key, value] = property.split('=') as [
              's' | 'p' | 'st' | 'm' | 'v' | 'n' | 'g',
              string
            ];
            if (key) {
              acc[InventorySearchReportTranslationMap[key]] = value;
            }
            return acc;
          }, {} as { [key: string]: string });
          const record =
            acc[searchString] ||
            ({
              id: searchString,
              nbOfSearches: 0,
              emptyResults: 0,
              lowInventory: 0,
              reservationWasMade: 0,
              ...properties,
            } as SanitizedProductSearchStats);
          record.searchString = searchString;
          record.nbOfSearches += stats.nbOfSearches;
          record.emptyResults += stats.emptyResults;
          record.lowInventory += stats.lowInventory;
          record.reservationWasMade += stats.reservationWasMade;
          acc[searchString] = record;
        });
        return acc;
      }, {} as Record<string, SanitizedProductSearchStats>)
    );
  }, [dailyStats, dailyStatsLoading]);

  return useDataCollection(
    searchStats,
    options?.sorting,
    options?.pagination,
    cableFilters
  );
}
