export const CLEAN_PHONE_NUMBER_INPUT_REGEX = /[^()+-. \d]/g;

export const NORTH_AMERICAN_PHONE_NUMBER_PATTERN =
  /^(?:\+1\s)?\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})$/;

export function formatPhoneNumber(value: string | null): string | null {
  if (!value) {
    return value;
  }
  const cleaned = ('' + value).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return value;
}
