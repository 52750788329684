import { TFunction } from 'i18next';
import { DateTime } from 'luxon';

import { ReelSize } from '../../models';
import { ReelReturnsFilters } from '../../types';
import { CompletedReelReturn } from '../../models/CompletedReelReturn';
import { TimeZone } from '../../enums';
import report from './report';

const PREFIX = 'NC_ReelReturnLog_';
const NAMESPACE = 'reports.reelReturns';
const EXPORTED_KEYS = [
  'returnByDate',
  'depotName',
  'nciRmaNumber',
  'clientRmaNumber',
  'companyName',
  'userName',
];
const STATUS_KEYS = [
  'status',
  'receivedDate',
  'cancelReason',
  'cancelCustomReason',
];

class reelReturnsReport extends report<CompletedReelReturn> {
  private reelReturns: CompletedReelReturn[];
  private filters: ReelReturnsFilters;
  private reelSizes: ReelSize[];

  constructor(
    reelReturns: CompletedReelReturn[],
    filters: ReelReturnsFilters,
    reelSizes: ReelSize[],
    t: TFunction<'translation', undefined>
  ) {
    const keys = EXPORTED_KEYS.concat(
      reelSizes.map((size) => size.reelType)
    ).concat(STATUS_KEYS);

    super(PREFIX, NAMESPACE, keys, t);

    this.reelReturns = reelReturns;
    this.filters = filters;
    this.reelSizes = reelSizes;
  }

  protected override getFilename(): string {
    const fromDate = this.filters.startDate?.toFormat('LLddyyyy');
    const toDate = this.filters.endDate?.toFormat('LLddyyyy');
    return `${PREFIX}_${fromDate}_${toDate}.csv`;
  }

  protected override getHeaders(): string[] {
    const reelTypes = this.reelSizes.map((size) => size.reelType);
    return this.keys.map((key) =>
      reelTypes.indexOf(key) === -1
        ? `"${this.t(`${this.namespace}.${key}`)}"`
        : `"${key}"`
    );
  }

  protected getSanitizedData(): CompletedReelReturn[] {
    return this.reelReturns.map(
      (completedReturn) =>
        ({
          id: completedReturn.id,
          clientRmaNumber: completedReturn.clientRmaNumber,
          companyName: completedReturn.companyName,
          userName: completedReturn.creatorName,
          depotName: completedReturn.depotName,
          nciRmaNumber: completedReturn.nciRmaNumber,
          returnByDate: DateTime.fromMillis(
            completedReturn.returnByDate.toMillis()
          )
            .setLocale('en')
            .setZone(TimeZone.EasternTime)
            .toLocaleString(DateTime.DATE_MED),
          status: completedReturn.status,
          cancelReason: completedReturn.cancelReason,
          cancelCustomReason: completedReturn.cancelCustomReason,
          receivedDate:
            completedReturn.receivedDate &&
            DateTime.fromMillis(completedReturn.receivedDate?.toMillis())
              .setLocale('en')
              .setZone(TimeZone.EasternTime)
              .toLocaleString(DateTime.DATE_MED),
          ...Object.fromEntries(this.getReelColumns(completedReturn).entries()),
        } as unknown as CompletedReelReturn)
    );
  }

  private getReelColumns(
    completedReelReturn: CompletedReelReturn
  ): Map<string, number> {
    return new Map(
      this.reelSizes.map((reelSize) => [
        reelSize.reelType,
        completedReelReturn.reels?.[reelSize.id] ?? 0,
      ])
    );
  }

  static generate(
    completedReelReturns: CompletedReelReturn[],
    filters: ReelReturnsFilters,
    reelSizes: ReelSize[],
    t: TFunction<'translation', undefined>
  ) {
    const report = new reelReturnsReport(
      completedReelReturns,
      filters,
      reelSizes,
      t
    );

    report.generateCSV();
  }
}

export default reelReturnsReport;
