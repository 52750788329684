import { QuoteInternalNote } from '../models';
import { CollectionQueryOptions } from '../types';
import { useLocalCollection } from './useLocalCollection';

export function useQuoteInternalNotes(
  quoteId?: string,
  options?: CollectionQueryOptions
) {
  return useLocalCollection<QuoteInternalNote>(
    `/Quotes/${quoteId}/InternalNotes`,
    [],
    options?.sorting || { sortField: 'lastModified', sortDirection: 'desc' },
    options?.pagination,
    options?.filters
  );
}
