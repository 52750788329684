import {
  Box,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { ReelQuantity } from '../../ReelQuantity/ReelQuantity.component';
import { ReelWeight } from '../../ReelWeight/ReelWeight.component';
import { Show } from '../../Show/Show.component';
import { ShoppingTruckCardProps } from './ShoppingTruckCard.props';
import * as Sx from './ShoppingTruckCard.styles';

export const ShoppingTruckCard = ({
  measureUnits,
  reel,
  toggleItemSelection,
}: ShoppingTruckCardProps) => (
  <Card sx={Sx.card}>
    <CardContent sx={Sx.cardContent}>
      <Stack
        direction="row"
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        <Typography
          sx={{ fontWeight: 'bold' }}
        >{`${reel.description} - ${reel.cableRef.id}`}</Typography>
        <Box sx={{ whiteSpace: 'nowrap', ml: 2 }}>
          <ReelQuantity item={reel} unitsType={measureUnits} />
        </Box>
      </Stack>
      <Show if={!!reel.colors}>
        <Typography>{reel.colors}</Typography>
      </Show>

      <Box
        display="flex"
        width="100%"
        columnGap={1}
        justifyContent="space-between"
      >
        <Typography>{reel.reel || '--'}</Typography>
        <Typography>
          {reel.weight > 0 ? (
            <ReelWeight item={reel} unitsType={measureUnits} />
          ) : (
            <Typography>--</Typography>
          )}
        </Typography>
        <Typography>{reel.id}</Typography>
      </Box>
    </CardContent>
    <CardActions>
      <IconButton color="secondary" onClick={() => toggleItemSelection(reel)}>
        <DeleteOutlineIcon />
      </IconButton>
    </CardActions>
  </Card>
);
