import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsSizeOverrides {
    large: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      dark: '#111111',
      main: '#2c2c2c',
      light: '#333333',
      contrastText: '#e5e5e5',
    },
    secondary: {
      main: '#e68008',
      contrastText: '#2c2c2c',
      light: '#F9F9F9',
    },
    success: {
      main: '#2e7d32',
    },
    error: {
      main: '#db2036',
      light: '#fff8f8',
    },
    warning: {
      main: '#e68008',
    },
    info: {
      main: '#2c2c2c',
    },
    background: {
      paper: 'white',
    },
  },
  typography: {
    fontFamily: '"Nunito", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.875rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 400,
    },
    button: {
      textTransform: 'none',
    },
    body1: {
      fontSize: '0.875rem',
    },
    body2: {
      fontSize: '1rem',
    },
    caption: {
      fontWeight: '700',
    },
    subtitle1: {
      fontStyle: 'italic',
      fontWeight: '400',
      fontSize: '0.75rem',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 2000,
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: '0.75rem',
          borderTopRightRadius: '0.75rem',
          borderBottomLeftRadius: '0',
          borderBottomRightRadius: '0',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popupIndicator: {
          color: 'black',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem',
        },
      },
      variants: [
        {
          props: { color: 'secondary', variant: 'outlined' },
          style: {
            color: 'black',
          },
        },
      ],
    },
    MuiFormControl: {
      variants: [
        {
          props: { variant: 'filled' },
          style: {
            backgroundColor: 'inherit',
          },
        },
      ],
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#e68008',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          backgroundColor: '#cf222e',
          borderBottomLeftRadius: '0.25rem',
          borderBottomRightRadius: '0.25rem',
          color: 'white !important',
          margin: '-0.25rem 0 0 0',
          padding: '0 1rem',
          width: '100%',
          zIndex: 1,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#363636',
          fontStyle: 'italic',
          paddingRight: '0.3rem',
        },
        shrink: {
          fontStyle: 'normal',
          padding: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: '#eeeeee',
            color: 'inherit',
          },
        },
        input: {
          '&.Mui-disabled': {
            color: 'inherit',
            WebkitTextFillColor: 'inherit',
          },
        },
        notchedOutline: {
          borderColor: '#979797',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: '1em',
          borderBottomRightRadius: '1em',
          '[data-popper-placement="top"] &': {
            borderTopLeftRadius: '1em',
            borderTopRightRadius: '1em',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
        head: {
          verticalAlign: 'middle !important',
          backgroundColor: '#f9f9f9',
          color: '#646464',
        },
      },
    },
  },
});

// Breakpoint exceptions
theme.typography = {
  ...theme.typography,
  h1: {
    ...theme.typography.h1,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  h3: {
    ...theme.typography.h3,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.125rem',
    },
  },
};

theme.components = {
  ...theme.components,
  MuiOutlinedInput: {
    ...theme.components?.MuiOutlinedInput,
    styleOverrides: {
      ...theme.components?.MuiOutlinedInput?.styleOverrides,
      input: {
        ...(theme.components?.MuiOutlinedInput?.styleOverrides
          ?.input as object),
        [theme.breakpoints.down('sm')]: {
          fontSize: '1rem',
          padding: '15px 14px',
        },
      },
    },
  },
};

export default theme;
