import { useTranslation } from 'react-i18next';
import { PortalSettings, SignUpUser } from '../models';
import { getLanguage } from '../utils/locale';
import { useNewDocumentByFunction } from './useNewDocumentByFunction';

export function useSignUpUser(portalSettings: PortalSettings) {
  const { i18n } = useTranslation();

  return useNewDocumentByFunction(
    'signUp',
    new SignUpUser(
      portalSettings.extensionNotificationDelay,
      portalSettings.holdDelay,
      portalSettings.maxReservationsPerPeriod,
      getLanguage(i18n),
      null
    )
  );
}
