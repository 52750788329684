import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import { MenuItem } from '..';
import { ConsentNoticeMenuItemProps } from './ConsentNoticeMenuItem.props';

export const ConsentNoticeMenuItem = (props: ConsentNoticeMenuItemProps) => {
  const { t } = useTranslation();

  const openDidomiPreferences = () => {
    window.Didomi?.preferences.show();
  };

  return (
    <Box sx={props.sx}>
      <MenuItem
        onClick={openDidomiPreferences}
        title={t('consentNotice.title')}
      />
    </Box>
  );
};
