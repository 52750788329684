import { Stack, useTheme } from '@mui/system';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { QuoteStatus, UserPermission } from '../../../../enums';
import {
  useAuthentication,
  useCurrentUser,
  usePermission,
} from '../../../../hooks';
import { PricedQuoteStatuses } from '../../../../mappings';
import { UnderlinedLink } from '../../../UnderlinedLink/UnderlinedLink.component';
import { CUSTOM_PART_NUMBER } from '../../constants';
import { QuoteProductPartNumberProps } from './QuoteProductPartNumber.props';

export const QuoteProductPartNumber = ({
  row,
  isValid,
  status,
}: QuoteProductPartNumberProps) => {
  const { t } = useTranslation();
  const { isAdmin, isAgent } = useAuthentication();
  const currentUser = useCurrentUser();
  const { palette } = useTheme();
  const { hasOneOfPermissions, hasPermission } = usePermission();

  const value = row.partNumber;

  const inventoryLink = `/${
    isAgent ? 'agent/' : isAdmin ? 'admin/' : ''
  }inventory/${row.isCSA && currentUser.canSeeCSA ? 'csa' : 'ul'}?part=${
    row.normalizedId
  }`;

  const showLink = Boolean(
    status &&
      [QuoteStatus.Cancelled, ...PricedQuoteStatuses].includes(status) &&
      hasPermission(UserPermission.ViewInventory) &&
      hasOneOfPermissions([
        UserPermission.ManageQuotes,
        UserPermission.RequestQuotes,
        UserPermission.ViewAgencyQuotes,
        UserPermission.ViewCompanyQuotes,
      ]) &&
      ((row.isCSA && currentUser.canSeeCSA) ||
        (row.isUL && currentUser.canSeeUL)) &&
      row.partNumber !== CUSTOM_PART_NUMBER
  );

  return (
    <Stack spacing={1} direction="row" alignItems="center">
      {showLink ? (
        <UnderlinedLink openInNewTab to={inventoryLink}>
          {value}
        </UnderlinedLink>
      ) : (
        <Typography>{value}</Typography>
      )}
      {!isValid(row) && (
        <Tooltip
          title={t('quotes.edit.products.table.validation.invalidMarketSingle')}
        >
          <ErrorOutlineIcon htmlColor={palette.error.main} fontSize="small" />
        </Tooltip>
      )}
    </Stack>
  );
};
