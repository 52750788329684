import { Typography } from '@mui/material';
import { renderToString } from 'react-dom/server';
import { MultilineTypographyProps } from './MultilineTypography.props';

export const MultilineTypography = ({
  sx,
  children,
  variant,
}: MultilineTypographyProps) => (
  <Typography
    variant={variant}
    sx={{ ...sx, wordBreak: 'break-all' }}
    dangerouslySetInnerHTML={{
      __html: renderToString(children).replace(/(\n)+/g, '<br />'),
    }}
  />
);
