import { Box, Typography } from '@mui/material';
import * as Sx from './QuoteStatusBadge.style';
import QuoteStatusBadgeProps from './QuoteStatusBadge.props';

export const QuoteStatusBadge = ({ status, text }: QuoteStatusBadgeProps) => (
  <Box ml={{ xs: 2, xl: 3 }}>
    <Typography sx={Sx.statusDot(status)} />
    <Typography
      id="quote-badge"
      px={1}
      py={0.5}
      variant="body1"
      sx={Sx.statusText}
    >
      {text}
    </Typography>
  </Box>
);
