import { useMemo } from 'react';
import { Quote } from '../models';
import { Formable } from '../types';
import {
  PricedQuoteStatuses,
  QuoteCurrentTabStatuses,
  QuoteHistoryTabStatuses,
} from '../mappings';
import { PurchaseStatus, QuoteStatus, UserPermission } from '../enums';
import { usePermission } from './usePermission';
import { useAuthentication } from '.';

export const useQuoteDetails = (form: Formable<Quote>) => {
  const { hasPermission } = usePermission();
  const { isAdmin, userRoleCategory } = useAuthentication();

  const quoteIsPriced = useMemo(
    () =>
      Boolean(
        form.item?.status && PricedQuoteStatuses.includes(form.item.status)
      ),
    [form.item?.status]
  );

  const quoteIsPricedOrCancelled = useMemo(
    () =>
      Boolean(
        form.item?.status &&
          (PricedQuoteStatuses.includes(form.item.status) ||
            form.item.status === QuoteStatus.Cancelled)
      ),
    [form.item?.status]
  );

  const isReadonly = useMemo(() => {
    const hasEditPermission = hasPermission(
      isAdmin ? UserPermission.ManageQuotes : UserPermission.RequestQuotes
    );

    const editableStatus = isAdmin
      ? [undefined, QuoteStatus.Submitted, QuoteStatus.InProgress]
      : [undefined, QuoteStatus.Draft];

    return !(hasEditPermission && editableStatus.includes(form.item?.status));
  }, [form.item?.status, hasPermission, isAdmin]);

  const canSubmit = useMemo(
    () => !isReadonly && !!form.item?.products?.length,
    [form.item?.products, isReadonly]
  );

  const isPartialOrComplete = useMemo(
    () =>
      (form.item?.products ?? []).some(
        (p) =>
          p.purchaseStatus !== undefined &&
          p.purchaseStatus !== PurchaseStatus.Pending
      ),
    [form.item?.products]
  );

  const isCurrentTab = useMemo(
    () =>
      Boolean(
        form.item?.status &&
          QuoteCurrentTabStatuses[userRoleCategory].includes(form.item.status)
      ),
    [form.item?.status, userRoleCategory]
  );

  const isHistoryTab = useMemo(
    () =>
      Boolean(
        form.item?.status &&
          QuoteHistoryTabStatuses[userRoleCategory].includes(form.item.status)
      ),
    [form.item?.status, userRoleCategory]
  );

  const canCloneQuote = useMemo(() => {
    if (!isAdmin) {
      return hasPermission(UserPermission.RequestQuotes);
    }

    return hasPermission(UserPermission.ManageQuotes) && quoteIsPriced;
  }, [hasPermission, isAdmin, quoteIsPriced]);

  return {
    canCloneQuote,
    canSubmit,
    isCurrentTab,
    isHistoryTab,
    isPartialOrComplete,
    isReadonly,
    quoteIsPriced,
    quoteIsPricedOrCancelled,
  };
};
