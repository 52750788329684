import { useTranslation } from 'react-i18next';
import { convertReelQuantity } from '../../utils/units';
import { Show } from '..';
import formatPackagedReelQuantity from '../../utils/formatPackagedReelQuantity';
import { ReelQuantityProps } from './ReelQuantity.props';

export const ReelQuantity = ({ item, unitsType }: ReelQuantityProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Show if={!!item.numberOfPieces}>
        {t('inventory.fields.randomQuantity', {
          pieces: item.numberOfPieces,
          min: convertReelQuantity(
            unitsType,
            item.minimumLength,
            item.productType
          ),
          max: convertReelQuantity(
            unitsType,
            item.maximumLength,
            item.productType
          ),
          quantity: convertReelQuantity(
            unitsType,
            item.quantity,
            item.productType
          ),
          count: item.numberOfPieces,
          unit: t(`units.${unitsType}.length`),
        })}
      </Show>
      <Show if={!item.numberOfPieces}>
        <Show if={!!item.packageLength}>
          {formatPackagedReelQuantity(unitsType, item)}
          {t(`units.${unitsType}.length`)}
        </Show>
        <Show if={!item.packageLength}>
          {convertReelQuantity(unitsType, item.quantity, item.productType)}
          {t(`units.${unitsType}.length`)}
        </Show>
      </Show>
    </>
  );
};
