import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useQuoteInternalNote } from '../../../../../hooks/useQuoteInternalNote';
import { useNotification, useProgress } from '../../../../../hooks';
import { NotificationLevel } from '../../../../../enums';
import { InternalNoteDeleteProps } from './InternalNoteDelete.props';

export const InternalNoteDelete = ({
  deletingNoteIndex,
  height,
  noteId,
  onCancelClick,
  onDelete,
  quoteId,
  draftInternalNotes,
  setDraftInternalNotes,
}: InternalNoteDeleteProps) => {
  const { t } = useTranslation();
  const { showProgress } = useProgress();
  const { deleteDoc, item, loading } = useQuoteInternalNote(quoteId, noteId);

  const { addNotification } = useNotification();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    showProgress(disabled);
    return () => {
      showProgress(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  const handleOnDelete = async () => {
    if (quoteId === 'new') {
      setDraftInternalNotes(
        draftInternalNotes.filter((note, index) => index !== deletingNoteIndex)
      );
      onCancelClick();
      return;
    }

    setDisabled(true);
    let success = true;

    try {
      await deleteDoc();
      addNotification(
        t('quotes.edit.notes.internal.delete.success'),
        NotificationLevel.Success
      );
    } catch (error) {
      success = false;
      addNotification(
        t('quotes.edit.notes.internal.delete.error'),
        NotificationLevel.Error
      );
    }

    setDisabled(false);
    onDelete(success);
  };

  const isDisabled = useMemo(
    () => disabled || (quoteId !== 'new' && (loading || !item)),
    [disabled, quoteId, loading, item]
  );

  return (
    <Grid
      container
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      rowGap={2}
      sx={{ height }}
    >
      <Typography>
        <b>{`${t('quotes.edit.notes.internal.delete.title')} `}</b>
        {t('quotes.edit.notes.internal.delete.subtitle')}
      </Typography>
      <Grid item container spacing={2} justifyContent="space-between">
        <Grid item xs={6}>
          <Button
            disabled={isDisabled}
            variant="outlined"
            color="primary"
            fullWidth
            onClick={onCancelClick}
          >
            <Typography lineHeight="1.75">
              {t('quotes.edit.notes.cancel')}
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            disabled={isDisabled}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleOnDelete}
          >
            <Typography color="white" lineHeight="1.75">
              {t('quotes.edit.notes.internal.delete.confirm')}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
