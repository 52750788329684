import { DidomiSDK } from '@didomi/react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { env } from '../../env';
import { Language } from '../../enums';
import theme from '../../theme';

export const ConsentNotice = () => {
  const { i18n } = useTranslation();
  const language = i18n.language as Language;
  const [incrementalKey, setIncrementalKey] = useState(0);

  /**
   * When the Didomi component is reloaded on the language changed, it does not fetch the new language translations.
   * We need to force the Didomi component to fetch the new language scripts by removing the Didomi object from the
   * window and the spcloader script from the dom.
   * This is because the Didomi component prevents the reload if the script is already in the page.
   */
  useEffect(() => {
    if (window.Didomi && !window.Didomi.notice.isVisible()) {
      window.Didomi = undefined;
      window.didomiOnReady = [];
      window.didomiEventListeners = [];
      document.getElementById('spcloader')?.remove();
      setIncrementalKey((prev) => prev + 1);
    }
  }, [language]);

  return (
    <DidomiSDK
      key={incrementalKey}
      apiKey={env.REACT_APP_DIDOMI_API_KEY}
      noticeId={env.REACT_APP_DIDOMI_CONSENT_NOTICE}
      config={{
        app: {
          privacyPolicyURL:
            language.toLocaleLowerCase() === Language.French.toLocaleLowerCase()
              ? env.REACT_APP_DIDOMI_PRIVACY_POLICY_FR_URL
              : env.REACT_APP_DIDOMI_PRIVACY_POLICY_EN_URL,
        },
        languages: {
          enabled: [language],
          default: language,
        },
        theme: {
          buttons: {
            regularButtons: {
              borderRadius: '4px',
            },
            highlightButtons: {
              backgroundColor: theme.palette.secondary.main,
              borderRadius: '4px',
            },
          },
        },
      }}
    />
  );
};
