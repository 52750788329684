import { Navigate, useLocation } from 'react-router';

import { useAuthentication } from '../../hooks';

import { UserRoleCategoryPath } from '../../enums';

export const CommonRedirect = () => {
  const { userRoleCategory, user } = useAuthentication();
  const { pathname } = useLocation();

  const rolePath = UserRoleCategoryPath[userRoleCategory];
  const redirectPath = user ? pathname.replace('/common', rolePath) : '/login';

  return <Navigate to={redirectPath} replace />;
};
