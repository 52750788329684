import {
  Button,
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useRef, useState } from 'react';
import { SplitButtonProps } from './SplitButton.props';

export const SplitButton = ({ label, options }: SplitButtonProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleMenuItemClick = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        ref={anchorRef}
        sx={{ pl: 2, pr: 1.5 }}
        onClick={handleToggle}
        endIcon={<KeyboardArrowDownIcon fontSize="small" />}
      >
        {label}
      </Button>
      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        style={{ width: anchorRef?.current?.clientWidth }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={handleClose}
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
              >
                <MenuList autoFocusItem>
                  {options(handleMenuItemClick)}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
