import { memo } from 'react';
import { useFormFieldValue } from '../../../../hooks';
import { Quote as QuoteModel } from '../../../../models';
import { Show } from '../../../Show/Show.component';
import { QuoteNotes } from '../QuoteNotes/QuoteNotes.component';
import { OtherDetailsProps } from './OtherDetails.props';
import { ReadonlyDetails } from './components/ReadonlyDetails.component';
import { EditableDetails } from './components/EditableDetails.component';

export const OtherDetails = memo(
  ({
    form,
    isAdmin = false,
    isReadonly = false,
    internalNotesCount = 0,
    setShowNotesDrawer,
  }: OtherDetailsProps) => {
    const { value: id } = useFormFieldValue<QuoteModel, string>(form, 'id');

    const quoteNotesElement = (
      <QuoteNotes
        internalNotesCount={internalNotesCount}
        quoteId={id}
        isAdmin={isAdmin}
        setShowNotesDrawer={setShowNotesDrawer}
      />
    );

    return (
      <>
        <Show if={isReadonly}>
          <ReadonlyDetails form={form} quoteNotesElement={quoteNotesElement} />
        </Show>
        <Show if={!isReadonly && isAdmin}>
          <EditableDetails form={form} quoteNotesElement={quoteNotesElement} />
        </Show>
      </>
    );
  }
);
