import { useTranslation } from 'react-i18next';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Timestamp } from 'firebase/firestore';
import { Show } from '../../../Show/Show.component';
import {
  useCurrentUser,
  useNotification,
  useProgress,
} from '../../../../hooks';
import { QuoteCustomerNote } from '../../../../models/QuoteCustomerNote';
import { NotificationLevel, QuoteStatus } from '../../../../enums';
import { FormattedTimestamp } from '../../..';
import QuoteCustomerNoteTabProps from './QuoteCustomerNoteTab.props';

const CUSTOMER_NOTE_MAX_LENGTH = 200;

export const QuoteCustomerNoteTab = ({
  active,
  quote,
  saveQuote,
}: QuoteCustomerNoteTabProps) => {
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const [inProgress, setInProgress] = useState(false);
  const { showProgress } = useProgress();
  const currentUser = useCurrentUser();

  const [noteFieldContent, setNoteFieldContent] = useState(
    quote?.customerNote?.content || ''
  );
  const [customerNote, setCustomerNote] = useState(quote?.customerNote);
  const [noteError, setNoteError] = useState('');

  useEffect(() => {
    showProgress(inProgress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress]);

  const canEditNote =
    !!quote?.status &&
    [
      QuoteStatus.InProgress,
      QuoteStatus.Submitted,
      QuoteStatus.Quoted,
    ].includes(quote?.status);

  useEffect(() => {
    setCustomerNote(quote?.customerNote);
  }, [quote]);

  const handleSaveClick = async () => {
    if (quote && !noteError) {
      try {
        setInProgress(true);
        const nowTimestamp = Timestamp.now();
        await saveQuote({
          ...quote,
          lastModified: nowTimestamp,
          customerNote: {
            ...customerNote,
            content: noteFieldContent,
            dateCreated: customerNote?.dateCreated || nowTimestamp,
            createdById: customerNote?.createdById || currentUser.id,
            createdByName: customerNote?.createdByName || currentUser.name,
            lastModified: nowTimestamp,
            lastModifiedById: currentUser.id,
            lastModifiedByName: currentUser.name,
          } as QuoteCustomerNote,
        });
        addNotification(
          t('quotes.edit.notes.customer.saveSuccess'),
          NotificationLevel.Success
        );
      } catch (error) {
        addNotification(
          t('quotes.edit.notes.customer.saveError'),
          NotificationLevel.Error
        );
      } finally {
        setInProgress(false);
      }
    }
  };

  const handleCancelClick = () => {
    setNoteFieldContent(quote?.customerNote?.content || '');
    setNoteError('');
  };

  return (
    <Show if={active}>
      <Grid
        container
        sx={{ height: 'calc(100% - 55px)', padding: 1.5 }}
        flexDirection="column"
        justifyContent="space-between"
      >
        <Grid
          container
          width="100%"
          mt={1}
          sx={{ height: 'calc(100% - 65px)' }}
          flexDirection="column"
        >
          <Grid item>
            {canEditNote ? (
              <TextField
                label={t('quotes.edit.notes.customer.label')}
                value={noteFieldContent}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length > CUSTOMER_NOTE_MAX_LENGTH) {
                    setNoteError(
                      t('quotes.edit.notes.customer.errorTooLong', {
                        max: CUSTOMER_NOTE_MAX_LENGTH,
                      })
                    );
                  } else {
                    setNoteError('');
                  }
                  setNoteFieldContent(e.target.value);
                }}
                multiline
                fullWidth
                rows={17}
                variant="outlined"
                error={!!noteError}
                helperText={noteError}
              />
            ) : (
              <Typography variant="body1">{customerNote?.content}</Typography>
            )}
          </Grid>
          <Show if={!!customerNote}>
            <Grid item>
              <Typography variant="caption">
                {t('quotes.edit.notes.customer.lastModified', {
                  name: customerNote?.lastModifiedByName || '',
                })}
                <FormattedTimestamp timestamp={customerNote?.lastModified} />
              </Typography>
            </Grid>
          </Show>
        </Grid>
        <Show if={canEditNote}>
          <Grid container spacing={1} mt={0} justifyContent="space-between">
            <Grid item xs={6}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={handleCancelClick}
                disabled={noteFieldContent === customerNote?.content}
                sx={{ m: 0 }}
              >
                <Typography lineHeight="1.75">
                  {t('quotes.edit.notes.cancel')}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleSaveClick}
                disabled={
                  noteFieldContent === customerNote?.content || !!noteError
                }
                sx={{ m: 0 }}
              >
                <Typography color="white" lineHeight="1.75">
                  {t('quotes.edit.notes.save')}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Show>
      </Grid>
    </Show>
  );
};
