import i18next from 'i18next';
import { Nullable } from '../types';

export class CurrencyFormatter {
  formatter: Intl.NumberFormat;
  compactFormatter: Intl.NumberFormat;
  thresholdValue: Nullable<number>;

  constructor(
    country: 'CA' | 'US' = 'CA',
    thresholdValue: Nullable<number> = 100000
  ) {
    this.formatter = Intl.NumberFormat(i18next.language + '-CA', {
      style: 'currency',
      currency: country === 'CA' ? 'CAD' : 'USD',
    });
    this.compactFormatter = Intl.NumberFormat(i18next.language + '-CA', {
      style: 'currency',
      currency: country === 'CA' ? 'CAD' : 'USD',
      notation: 'compact',
    });

    this.thresholdValue = thresholdValue;
  }

  format(value: number) {
    if (this.thresholdValue && value >= this.thresholdValue) {
      return this.compactFormatter.format(value);
    }

    return this.formatter.format(value);
  }

  getSymbol() {
    return this.compactFormatter
      .formatToParts(1)
      .find((x) => x.type === 'currency')?.value;
  }
}

export const roundPrice = (price: number) =>
  Math.round((price + Number.EPSILON) * 100) / 100;
