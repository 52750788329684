import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { FirebaseAppProvider } from 'reactfire';

import { GlobalStyles } from '@mui/material';
import theme from './theme';
import './validation/yup';
import firebaseConfig from './firebase/config';
import FirebaseProviders from './firebase/FirebaseProviders';
import Routes from './routing/Routes';
import { ScrollToTopOnRouteChange } from './navigation';
import {
  ConsentNotice,
  ErrorBoundary,
  Notification,
  Progress,
} from './components';
import InternationalizationProvider from './providers/InternationalizationProvider';
import { NotificationProvider } from './providers/NotificationProvider';
import { ProgressProvider } from './providers/ProgressProvider/ProgressProvider.component';
import { useDetectAutofill } from './hooks';
import { GlobalStyledConsentNotice } from './components/ConsentNotice/ConsentNotice.styles';

const App: React.FC = () => {
  useDetectAutofill();

  return (
    <InternationalizationProvider>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <FirebaseProviders>
          <ThemeProvider theme={theme}>
            <NotificationProvider>
              <ProgressProvider>
                <CssBaseline />
                <GlobalStyles
                  styles={(theme) => {
                    return { ...GlobalStyledConsentNotice(theme) };
                  }}
                />
                <ScrollToTopOnRouteChange>
                  <ErrorBoundary>
                    <Progress />
                    <Routes />
                    <Notification />
                  </ErrorBoundary>
                </ScrollToTopOnRouteChange>
              </ProgressProvider>
            </NotificationProvider>
          </ThemeProvider>
        </FirebaseProviders>
      </FirebaseAppProvider>
      <ConsentNotice />
    </InternationalizationProvider>
  );
};

export default App;
