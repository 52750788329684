import { Stack, StackProps, styled } from '@mui/material';

interface ReadonlyQuoteStackProps extends StackProps {
  isPricedOrCancelled?: boolean;
}

interface ReadonlyInnerStackProps extends StackProps {
  isAdmin?: boolean;
}

export const EditableQuoteStyledStack = styled(Stack)(({ theme }) => {
  return {
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: theme.spacing(1),

    [theme.breakpoints.between('sm', 'lg')]: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: theme.spacing(1.5),

      '& > *': {
        marginBottom: 0,
      },

      '& > .MuiBox-root': {
        marginTop: 0,
      },

      '& > .MuiFormControl-root': {
        maxWidth: '40%',
      },

      '& .MuiAutocomplete-root': {
        paddingRight: theme.spacing(1.5),
        maxWidth: '40%',
      },
    },
    '& > .MuiTextField-root': {
      marginTop: 0,
    },
  };
});

export const ReadonlyQuoteStyledStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isPricedOrCancelled',
})<ReadonlyQuoteStackProps>(({ theme, isPricedOrCancelled }) => {
  return {
    flexDirection: isPricedOrCancelled ? 'row' : 'column',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

    [theme.breakpoints.down('md')]: {
      ...(isPricedOrCancelled && {
        flexDirection: 'column',
        rowGap: theme.spacing(1.5),
      }),
    },

    [theme.breakpoints.only('md')]: {
      flexDirection: 'row',
      alignItems: isPricedOrCancelled ? 'flex-start' : 'center',
    },

    [theme.breakpoints.up('lg')]: {
      columnGap: theme.spacing(1),
      height: '100%',
    },
  };
});

export const ReadonlyInnerStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isAdmin',
})<ReadonlyInnerStackProps>(({ theme, isAdmin }) => {
  return {
    width: '100%',
    height: 'inherit',
    maxWidth: isAdmin ? '40%' : '100%',

    [theme.breakpoints.only('xs')]: {
      maxWidth: '100%',
    },

    [theme.breakpoints.up('lg')]: {
      justifyContent: 'center',
      paddingLeft: theme.spacing(1),
    },
  };
});
