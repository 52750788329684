import { Card, styled } from '@mui/material';

export const StyledFactorCard = styled(Card)(() => {
  return {
    padding: '16px',
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
  };
});
