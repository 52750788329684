import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { httpsCallable } from 'firebase/functions';
import { useFirestore, useFunctions } from 'reactfire';

import { groupBy, sum } from 'lodash';
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';
import TruckIcon from '../../assets/TruckIcon';
import { Show } from '../Show/Show.component';
import { ContrastButtonMain } from '../ContrastButton/ContrastButton.component';
import { MultipleReservationsParameters } from '../../types/ReservationParameters';
import {
  useAuthentication,
  useNotification,
  usePermission,
  useProgress,
} from '../../hooks';
import {
  NotificationLevel,
  QuoteStatus,
  QuoteTypeOfBuy,
  UserPermission,
} from '../../enums';
import { MultipleReservationsResponse } from '../../types/Response';
import { Cable, Company, Quote, QuoteProduct } from '../../models';
import { convertReelQuantity } from '../../utils/units';
import * as CheckboxSx from '../Checkbox/Checkbox.styles';
import { ShoppingTruckProps } from './ShoppingTruck.props';
import { ShoppingTruckCard } from './components/ShoppingTruckCard.component';
import * as Sx from './ShoppingTruck.styles';

export const ShoppingTruck = ({
  impersonatedCustomer,
  impersonatedUser,
  measureUnits,
  resetShoppingTruck,
  selectedReels,
  toggleItemSelection,
  toggleShoppingTruck,
  user,
}: ShoppingTruckProps) => {
  const { t } = useTranslation();
  const functions = useFunctions();
  const { isAdmin, isAgent } = useAuthentication();
  const { addNotification } = useNotification();
  const { hasOneOfPermissions } = usePermission();
  const multipleReservationsFunction =
    httpsCallable<MultipleReservationsParameters>(
      functions,
      'reserveMultipleReels'
    );

  const [note, setNote] = useState('');
  const [showNote, setShowNote] = useState(false);
  const [reserving, setReserving] = useState(false);
  const [withQuote, setWithQuote] = useState(false);
  const theme = useTheme();
  const firestore = useFirestore();
  const { showProgress } = useProgress();

  const canCreateQuote = useMemo(
    () =>
      hasOneOfPermissions([
        UserPermission.RequestQuotes,
        UserPermission.ManageQuotes,
      ]) &&
      (!isAdmin || (isAdmin && impersonatedUser)),
    [hasOneOfPermissions, impersonatedUser, isAdmin]
  );

  useEffect(() => {
    showProgress(reserving);
    return () => {
      showProgress(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reserving]);

  const reserveMultipleReels = async () => {
    if (
      !impersonatedUser &&
      !impersonatedCustomer &&
      user.reservationCount + selectedReels.length >
        user.maxReservationsPerPeriod
    ) {
      addNotification(
        t('inventory.multipleReservations.results.reservationsExceeded', {
          number:
            user.reservationCount +
            selectedReels.length -
            user.maxReservationsPerPeriod,
        }),
        NotificationLevel.Error,
        true
      );

      return;
    }
    setReserving(true);
    addNotification(
      t('inventory.reserveInfo'),
      NotificationLevel.Information,
      true
    );

    let failedReservations: MultipleReservationsResponse[] = [];

    await multipleReservationsFunction({
      impersonatedUserId: impersonatedUser?.id,
      madeForCompanyId: impersonatedCustomer?.company.companyId,
      madeForCustomerName: impersonatedCustomer?.personName,
      note,
      reservations: selectedReels.map(({ id, inventoryType }) => {
        return {
          reelId: id,
          inventoryType,
          note: '',
        };
      }),
    })
      .then((res) => {
        const results = res.data as MultipleReservationsResponse[];
        failedReservations = results.filter(
          (reservation) => reservation.succeeded === false
        );

        if (failedReservations.length) {
          addNotification(
            t('inventory.multipleReservations.results.partial', {
              failed: failedReservations.length,
              total: selectedReels.length,
            }),
            NotificationLevel.Error,
            true
          );
        } else {
          addNotification(
            t('inventory.multipleReservations.results.success'),
            NotificationLevel.Success,
            true
          );
        }
      })
      .catch(() => {
        addNotification(
          t('inventory.multipleReservations.results.globalFail'),
          NotificationLevel.Error,
          true
        );
      })
      .finally(() => {
        setReserving(false);
        resetShoppingTruck(
          failedReservations.map((reservation) => reservation.reelId ?? '')
        );
      });

    if (withQuote) {
      const isAdminOrAgent = isAdmin || isAgent;
      const customerCompanyId =
        impersonatedUser?.companyRef.id ||
        impersonatedCustomer?.company.companyId;

      const customerCompany =
        isAdminOrAgent && customerCompanyId
          ? (
              await getDoc(doc(firestore, 'Companies', customerCompanyId))
            ).data()
          : null;
      const currentCompany = (await getDoc(user.companyRef)).data();

      const newQuoteModel = new Quote(
        impersonatedUser ?? user,
        isAgent ? (currentCompany as Company) : null,
        (isAdminOrAgent ? customerCompany : currentCompany) as Company
      );
      newQuoteModel.status = isAdmin
        ? QuoteStatus.InProgress
        : QuoteStatus.Draft;
      newQuoteModel.typeOfBuy = QuoteTypeOfBuy.InventoryBuy;
      newQuoteModel.measurementUnits = measureUnits;

      if (isAdmin) {
        newQuoteModel.employeeId = user.id;
        newQuoteModel.employeeName = user.name || '';

        if (impersonatedUser) {
          newQuoteModel.createdById = user.id;
          newQuoteModel.createdByName = user.name || '';
          newQuoteModel.createdByNCEmployee = true;
        } else {
          newQuoteModel.customerId = '';
          newQuoteModel.customerName = '';
          newQuoteModel.customerEmail = '';
        }
      }

      for (const [index, [, reels]] of Object.entries(
        groupBy(selectedReels, 'cableRef.id')
      ).entries()) {
        const product = await getDoc(reels[0].cableRef);
        const productData = product.data() as Cable;
        newQuoteModel.products.push({
          ...productData,
          partNumber: productData?.id,
          minimumOrderQuantity: 0,
          discount: 0,
          quantity: Number(
            convertReelQuantity(
              newQuoteModel.measurementUnits,
              sum(reels.map((reel) => reel.quantity))
            )
          ),
          lineItem: index + 1,
        } as QuoteProduct);
      }

      const docRef = doc(collection(firestore, 'Quotes'));
      await setDoc(docRef, { ...newQuoteModel, id: docRef.id });
      window.open(
        `/${isAdmin ? 'admin/' : isAgent ? 'agent/' : ''}quotes/${docRef.id}`,
        '_blank'
      );

      setWithQuote(false);
    }
  };

  return (
    <Drawer open anchor="right" PaperProps={{ sx: Sx.drawerPaper(reserving) }}>
      {/* Header */}
      <Sx.StyledHeaderContainer
        display="flex"
        justifyContent="space-between"
        onClick={toggleShoppingTruck}
      >
        <Grid
          container
          alignItems="center"
          display="flex"
          gap={2}
          wrap="nowrap"
          mr={3}
        >
          <TruckIcon color={theme.palette.common.white} />
          <Typography variant="h3" color={theme.palette.common.white}>
            {t('inventory.shoppingTruck.yourTruck', {
              count: selectedReels.length,
            })}
          </Typography>
        </Grid>
        <IconButton onClick={toggleShoppingTruck}>
          <CloseIcon htmlColor={theme.palette.common.white} />
        </IconButton>
      </Sx.StyledHeaderContainer>

      {/* Cards Section */}
      <Sx.StyledShoppingTruckContainer>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="flex-start"
          rowGap={2}
          sx={Sx.cardSection}
        >
          <Show if={selectedReels.length === 0}>
            <Typography variant="h4" mt={2}>
              {t('inventory.shoppingTruck.emptyTruck')}
            </Typography>
          </Show>

          {selectedReels.map((reel) => (
            <Grid item key={reel.id} sx={{ height: '110px', width: '100%' }}>
              <ShoppingTruckCard
                measureUnits={measureUnits}
                reel={reel}
                toggleItemSelection={toggleItemSelection}
              />
            </Grid>
          ))}
        </Grid>
      </Sx.StyledShoppingTruckContainer>

      {/* Footer */}
      <Box
        display="flex"
        flexDirection="column"
        rowGap={2}
        sx={Sx.buttonsSection(showNote)}
      >
        {canCreateQuote && (
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setWithQuote(e.target.checked);
                }}
                checked={withQuote}
              />
            }
            disableTypography
            sx={CheckboxSx.formLabel('medium')}
            label={t('inventory.shoppingTruck.withQuote')}
          />
        )}
        <Show if={showNote}>
          <TextField
            inputProps={{ maxLength: 255 }}
            label={t('inventory.shoppingTruck.reservationNote')}
            multiline
            fullWidth
            autoFocus
            minRows={5}
            onChange={(e) => setNote(e.target.value)}
            value={note}
            variant="filled"
            sx={{ overflow: 'auto' }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setShowNote(false)}
                >
                  <KeyboardArrowDownIcon />
                </InputAdornment>
              ),
            }}
          />
        </Show>
        <Show if={!showNote}>
          <Button variant="outlined" onClick={() => setShowNote(!showNote)}>
            {t(
              `inventory.shoppingTruck.${note !== '' ? 'editNote' : 'addNote'}`
            )}
          </Button>
        </Show>
        <ContrastButtonMain
          disabled={selectedReels.length === 0 || reserving}
          onClick={reserveMultipleReels}
        >
          {t('inventory.shoppingTruck.reserve', {
            count: selectedReels.length,
          })}
        </ContrastButtonMain>
      </Box>
    </Drawer>
  );
};
