import { Routes as ReactRouterRoutes, Route } from 'react-router-dom';
import {
  AdminLayout,
  AgentLayout,
  ClientLayout,
  PublicLayout,
} from '../layouts';

import {
  ForgotPassword,
  ForgotPasswordConfirmation,
  Home,
  HttpError,
  Inventory,
  Login,
  Logout,
  NotVerified,
  Unsubscribe,
  Orders,
  ResetPassword,
  Reservations,
  Settings,
  SignUp,
  SignUpConfirmation,
  User,
  Users,
  UnsubscribeConfirmation,
  VerifyEmail,
  Order,
  Quotes,
  Quote,
  ReelReturns,
  ReelReturn,
  QuoteConversionRate,
  ConversionRate,
} from '../pages';
import {
  Company,
  Companies,
  ReservationConversionRate,
  Dashboard as DashboardAdmin,
  DeleteCompany,
  DeleteUser,
  ReelReturns as AdminReelReturns,
  ReelReturnsReport,
  RejectUserRequest,
  ReservationLogs,
  ReservationsSettings,
  Reservations as AdminReservations,
  Order as AdminOrAgentOrder,
  Orders as AdminOrders,
  PermissionsSettings,
  Specifications,
  Contacts,
  User as AdminUser,
  Users as AdminUsers,
  AddUser,
  DeleteSpecification,
  Specification,
  ImpersonateCustomer,
  UpdatePassword,
  UserActivity,
  DeleteUnmappedOrderFile,
  MarkReservationAsPurchased,
  Agencies,
  Agency,
  AgentBulletinBoardSettings,
  ErrorLogs,
  Quotes as AdminQuotes,
  LeadTimesSettings,
  Quote as AdminQuote,
  InventorySearches,
  TwoFactorConfiguration,
} from '../pages/admin';
import {
  MyAgency,
  ReservationConversionRate as AgentReservationConversionRate,
  Dashboard as DashboardAgent,
  User as AgentUser,
  ImpersonateCustomer as AgentImpersonateCustomer,
  Users as AgentUsers,
  AddUser as AgentAddUser,
  Reservations as AgentReservations,
  ReportFiles,
  Orders as AgentOrders,
  Quote as AgentQuote,
} from '../pages/agent';
import {
  CompanyPageView,
  ConsultationMode,
  ErrorCategory,
  OrdersPageView,
  ReportFileType,
  UserPageView,
} from '../enums';
import { CancelQuote } from '../pages/modals';
import { RedirectLayout } from '../layouts/RedirectLayout/RedirectLayout.component';
import { CommonRedirect } from '../layouts/RedirectLayout/CommonRedirect.component';

const Routes: React.FC = () => (
  <ReactRouterRoutes>
    <Route
      path="*"
      element={<HttpError errorCategory={ErrorCategory.NotFound} />}
    />
    <Route
      path="access-denied"
      element={<HttpError errorCategory={ErrorCategory.AccessDenied} />}
    />
    <Route
      path="expired-link"
      element={<HttpError errorCategory={ErrorCategory.ExpiredLink} />}
    />

    <Route element={<PublicLayout strictlyPublic={true} hideAppName={true} />}>
      <Route path="login" element={<Login />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route
        path="set-password"
        element={<ResetPassword context="setPassword" />}
      />
    </Route>

    <Route element={<PublicLayout strictlyPublic={true} />}>
      <Route path="forgot-password" element={<ForgotPassword />}>
        <Route path="confirmation" element={<ForgotPasswordConfirmation />} />
      </Route>
      <Route path="sign-up" element={<SignUp />}>
        <Route path="confirmation" element={<SignUpConfirmation />} />
      </Route>
    </Route>

    <Route element={<PublicLayout />}>
      <Route path="validate-email" element={<VerifyEmail />} />
      <Route path="unsubscribe" element={<Unsubscribe />}>
        <Route path="confirmation" element={<UnsubscribeConfirmation />} />
      </Route>
      <Route path="logout" element={<Logout />} />
    </Route>

    <Route element={<RedirectLayout />}>
      <Route path="common/quotes/:quoteId" element={<CommonRedirect />} />
    </Route>

    <Route element={<ClientLayout />}>
      <Route path="/" element={<Home />} />
      <Route path="not-verified" element={<NotVerified />} />
      <Route path="inventory/:type" element={<Inventory />} />
      <Route
        path="reservations"
        element={<Reservations mode={ConsultationMode.Current} />}
      />
      <Route
        path="reservations/history"
        element={<Reservations mode={ConsultationMode.History} />}
      />
      <Route path="orders" element={<Orders />}>
        <Route path=":orderId" element={<Order />} />
      </Route>
      <Route path="users" element={<Users view={UserPageView.ListUsers} />}>
        <Route path=":userId" element={<User />} />
        <Route
          path="order-file-permissions"
          element={<Users view={UserPageView.OrderFilePermissions} />}
        />
      </Route>
      <Route path="quotes" element={<Quotes mode={ConsultationMode.Current} />}>
        <Route path=":quoteId" element={<Quote />} />
        <Route path=":quoteId/delete" element={<CancelQuote modal />} />
      </Route>
      <Route
        path="quotes/history"
        element={<Quotes mode={ConsultationMode.History} />}
      />
      <Route path="settings" element={<Settings />}>
        <Route path="password" element={<UpdatePassword />} />
      </Route>
      <Route path="reel-returns" element={<ReelReturns />}>
        <Route path=":reelReturnId" element={<ReelReturn isAdmin={false} />} />
      </Route>
    </Route>

    <Route element={<AdminLayout />}>
      <Route path="admin/2fa-setup" element={<TwoFactorConfiguration />} />
      <Route path="admin/dashboard" element={<DashboardAdmin />} />
      <Route path="admin/inventory/:type" element={<Inventory />}>
        <Route path="reserve" element={<ImpersonateCustomer />} />
      </Route>
      <Route path="admin/portal/contacts" element={<Contacts />} />
      <Route
        path="admin/portal/permissions"
        element={<PermissionsSettings />}
      />
      <Route
        path="admin/portal/agent-bulletin-board"
        element={<AgentBulletinBoardSettings />}
      />
      <Route path="admin/portal/lead-times" element={<LeadTimesSettings />} />
      <Route
        path="admin/portal/reservations"
        element={<ReservationsSettings />}
      />
      <Route path="admin/portal/specifications" element={<Specifications />}>
        <Route path=":specId" element={<Specification />} />
        <Route path=":specId/delete" element={<DeleteSpecification modal />} />
      </Route>
      <Route path="admin/reservations" element={<AdminReservations />}>
        <Route
          path=":reservationId/purchase"
          element={<MarkReservationAsPurchased modal />}
        />
      </Route>
      <Route
        path="admin/reservations/history"
        element={<AdminReservations mode={ConsultationMode.History} />}
      />
      <Route path="admin/reports/user-activity" element={<UserActivity />} />
      <Route
        path="admin/reports/inventory-searches"
        element={<InventorySearches />}
      />
      <Route path="admin/reports/conversion-rate" element={<ConversionRate />}>
        <Route path="reservation" element={<ReservationConversionRate />} />
        <Route path="quote" element={<QuoteConversionRate />} />
      </Route>
      <Route
        path="admin/reports/reservation-logs"
        element={<ReservationLogs />}
      />
      <Route
        path="admin/reports/reel-returns"
        element={<ReelReturnsReport />}
      />

      <Route path="admin/reports/error-logs" element={<ErrorLogs />} />

      <Route path="admin/settings" element={<Settings />}>
        <Route path="password" element={<UpdatePassword />} />
      </Route>
      <Route
        path="admin/users/awaitingApproval"
        element={<AdminUsers mode="approvals" />}
      >
        <Route path=":userId/reject" element={<RejectUserRequest modal />} />
      </Route>
      <Route path="admin/users" element={<AdminUsers />}>
        <Route path=":userId" element={<AdminUser />}>
          <Route
            path="reject"
            element={<RejectUserRequest modal inProfile />}
          />
        </Route>
        <Route path=":userId/delete" element={<DeleteUser modal />} />
        <Route path="new" element={<AddUser />} />
      </Route>
      <Route
        path="admin/companies/:companyId"
        element={<Company view={CompanyPageView.AssignedUsers} />}
      >
        <Route
          path="order-file-permissions"
          element={<Company view={CompanyPageView.OrderFilePermissions} />}
        />
      </Route>
      <Route path="admin/companies" element={<Companies />}>
        <Route path=":companyId/users/:userId" element={<AdminUser />} />
        <Route path=":companyId/users/new" element={<AddUser />} />
        <Route path=":companyId/delete" element={<DeleteCompany modal />} />
      </Route>
      <Route path="admin/agencies/:agencyId" element={<Agency />} />
      <Route path="admin/agencies" element={<Agencies />}>
        <Route path=":agencyId/delete" element={<DeleteCompany modal />} />
        <Route path=":agencyId/users/new" element={<AddUser />} />
        <Route path=":agencyId/users/:userId" element={<AdminUser />} />
      </Route>
      <Route path="admin/orders/:orderId" element={<AdminOrAgentOrder />} />
      <Route path="admin/orders" element={<AdminOrders />}>
        <Route
          path="unassigned"
          element={<AdminOrders view={OrdersPageView.UnassignedDocuments} />}
        >
          <Route
            path=":fileId/delete"
            element={<DeleteUnmappedOrderFile modal />}
          />
        </Route>
      </Route>
      <Route path="admin/reel-returns" element={<AdminReelReturns />}>
        <Route path="company" element={<ImpersonateCustomer />} />
        <Route path=":reelReturnId" element={<ReelReturn isAdmin={true} />} />
      </Route>

      <Route
        path="admin/quotes"
        element={<AdminQuotes mode={ConsultationMode.Current} />}
      >
        <Route path=":quoteId" element={<AdminQuote />} />
        <Route path=":quoteId/cancel" element={<CancelQuote modal />} />
      </Route>
      <Route
        path="admin/quotes/history"
        element={<AdminQuotes mode={ConsultationMode.History} />}
      />
    </Route>

    <Route element={<AgentLayout />}>
      <Route path="agent/dashboard" element={<DashboardAgent />} />
      <Route path="agent/users" element={<AgentUsers />}>
        <Route path="new" element={<AgentAddUser />} />
        <Route path=":userId" element={<AgentUser />} />
      </Route>
      <Route path="agent/settings" element={<Settings />}>
        <Route path="password" element={<UpdatePassword />} />
      </Route>
      <Route path="agent/inventory/:type" element={<Inventory />}>
        <Route path="reserve" element={<AgentImpersonateCustomer />} />
      </Route>
      <Route
        path="agent/reservations"
        element={<AgentReservations mode={ConsultationMode.Current} />}
      />
      <Route
        path="agent/reservations/history"
        element={<AgentReservations mode={ConsultationMode.History} />}
      />
      <Route
        path="agent/quotes"
        element={<Quotes mode={ConsultationMode.Current} />}
      >
        <Route path=":quoteId" element={<AgentQuote />} />
        <Route path=":quoteId/delete" element={<CancelQuote modal />} />
      </Route>
      <Route
        path="agent/quotes/history"
        element={<Quotes mode={ConsultationMode.History} />}
      />
      <Route
        path="agent/reports/sales"
        element={<ReportFiles fileType={ReportFileType.AgencySalesReport} />}
      />
      <Route
        path="agent/reports/commissions"
        element={
          <ReportFiles fileType={ReportFileType.AgencyCommissionsReport} />
        }
      />
      <Route path="agent/reports/conversion-rate" element={<ConversionRate />}>
        <Route
          path="reservation"
          element={<AgentReservationConversionRate />}
        />
        <Route path="quote" element={<QuoteConversionRate />} />
      </Route>
      <Route path="agent/orders" element={<AgentOrders />} />
      <Route path="agent/orders/:orderId" element={<AdminOrAgentOrder />} />
      <Route path="agent/agencies/:agencyId" element={<MyAgency />} />
    </Route>
  </ReactRouterRoutes>
);

export default Routes;
