import { Box, Button, Grid, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTranslation } from 'react-i18next';
import { useMemo, useRef, useState } from 'react';
import * as Sx from '../../QuoteNotesDrawer.styles';
import { Show } from '../../../Show/Show.component';
import { QuoteInternalNoteEditField } from '../QuoteInternalNoteEditField/QuoteInternalNoteEditField.component';
import QuoteInternalNotesTabProps from './QuoteInternalNotesTab.props';
import { InternalNoteTab } from './components/InternalNoteTab.component';
import { InternalNoteDelete } from './components/InternalNoteDelete.component';

export const QuoteInternalNotesTab = ({
  quoteId,
  active,
  internalNotes,
  draftInternalNotes,
  setDraftInternalNotes,
}: QuoteInternalNotesTabProps) => {
  const { t } = useTranslation();

  const [addingNote, setAddingNote] = useState(false);
  const [editingNoteIndex, setEditingNoteIndex] = useState<number | null>(null);
  const [deletingNoteIndex, setDeletingNoteIndex] = useState<number | null>(
    null
  );
  const noteEditFieldRef = useRef<{ saveNote: () => Promise<void> } | null>(
    null
  );

  const [height, setHeight] = useState(0);

  const handleCancelClick = () => {
    setAddingNote(false);
    setEditingNoteIndex(null);
  };

  const handleSaveClick = async () => {
    if (noteEditFieldRef.current) {
      await noteEditFieldRef.current.saveNote();
    }
    setAddingNote(false);
    setEditingNoteIndex(null);
  };

  const handleEditClick = (index: number) => {
    setAddingNote(false);
    setEditingNoteIndex(index);
  };

  const handleDeleteClick = (index: number, height: number) => {
    setHeight(height);
    setDeletingNoteIndex(index);
  };

  const handleDeleteCancelClick = () => {
    setHeight(0);
    setDeletingNoteIndex(null);
  };

  const handleOnDeleteResult = (success = false) => {
    if (success) {
      setDeletingNoteIndex(null);
    }
  };

  const showAddNote = useMemo(
    () =>
      !addingNote && editingNoteIndex === null && deletingNoteIndex === null,
    [addingNote, deletingNoteIndex, editingNoteIndex]
  );

  const showSaveCancel = useMemo(
    () =>
      (addingNote || editingNoteIndex !== null) && deletingNoteIndex === null,
    [addingNote, deletingNoteIndex, editingNoteIndex]
  );

  const showDeleteConfirmation = useMemo(
    () => deletingNoteIndex !== null,
    [deletingNoteIndex]
  );

  return (
    <Show if={active}>
      <Grid
        container
        sx={{ height: 'calc(100% - 50px)' }}
        flexDirection="column"
        justifyContent="space-between"
      >
        <Grid
          item
          sx={{
            height: 'calc(100% - 70px)',
            overflowY: 'auto',
          }}
        >
          <Show if={addingNote}>
            <Sx.StyledNoteContainer>
              <QuoteInternalNoteEditField
                ref={noteEditFieldRef}
                quoteId={quoteId}
                noteId="new"
                currentNoteIndex={-1}
                draftInternalNotes={draftInternalNotes}
                setDraftInternalNotes={setDraftInternalNotes}
              />
            </Sx.StyledNoteContainer>
          </Show>
          {(quoteId === 'new' ? draftInternalNotes : internalNotes).map(
            (note, index) => (
              <Sx.StyledNoteContainer key={index}>
                {showDeleteConfirmation && deletingNoteIndex === index ? (
                  <InternalNoteDelete
                    height={height}
                    noteId={note.id}
                    deletingNoteIndex={deletingNoteIndex}
                    onCancelClick={handleDeleteCancelClick}
                    onDelete={handleOnDeleteResult}
                    quoteId={quoteId}
                    draftInternalNotes={draftInternalNotes}
                    setDraftInternalNotes={setDraftInternalNotes}
                  />
                ) : (
                  <InternalNoteTab
                    index={index}
                    isEditing={editingNoteIndex === index}
                    note={note}
                    onDeleteClick={handleDeleteClick}
                    onEditClick={handleEditClick}
                  >
                    <Box mt={1}>
                      <QuoteInternalNoteEditField
                        ref={noteEditFieldRef}
                        quoteId={quoteId}
                        noteId={note.id}
                        currentNoteIndex={index}
                        draftInternalNotes={draftInternalNotes}
                        setDraftInternalNotes={setDraftInternalNotes}
                      />
                    </Box>
                  </InternalNoteTab>
                )}
              </Sx.StyledNoteContainer>
            )
          )}
        </Grid>
        <Grid item container sx={Sx.buttonsSection}>
          <Show if={showSaveCancel}>
            <Grid
              item
              container
              columnSpacing={2}
              justifyContent="space-between"
              sx={{ height: '100%' }}
            >
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={handleCancelClick}
                >
                  <Typography lineHeight="1.75">
                    {t('quotes.edit.notes.cancel')}
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={handleSaveClick}
                >
                  <Typography color="white" lineHeight="1.75">
                    {t(
                      `quotes.edit.notes.internal.${
                        addingNote ? 'saveNew' : 'saveEdit'
                      }`
                    )}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Show>
          <Show if={showAddNote}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => setAddingNote(true)}
              startIcon={<AddCircleIcon sx={{ mr: 0.5 }} />}
            >
              {t('quotes.edit.notes.internal.add')}
            </Button>
          </Show>
        </Grid>
      </Grid>
    </Show>
  );
};
