import { where } from 'firebase/firestore';
import { Cable } from '../models/Cable';
import { CollectionQueryOptions } from '../types';
import { useLocalCollection } from './useLocalCollection';

export function useQuotableCables(options?: CollectionQueryOptions) {
  return useLocalCollection<Cable>(
    'Cables',
    [where('isQuotable', '==', true)],
    options?.sorting || { sortField: 'id', sortDirection: 'asc' },
    options?.pagination,
    options?.filters
  );
}
