import { Timestamp } from 'firebase/firestore';
import { Model, Nullable } from '../types';
import {
  Language,
  MeasurementUnits,
  ProvinceOrState,
  QuoteExpirationTimeOption,
  QuoteStatus,
  QuoteTypeOfBuy,
} from '../enums';
import { MAX_DATE } from '../hooks/useQuotesFilters';
import { QuoteProduct } from './QuoteProduct';
import { User } from './User';
import { Company } from './Company';
import { QuoteCustomerNote } from './QuoteCustomerNote';
import { FirebaseFile } from './FirebaseFile';

export class Quote implements Model {
  id!: string;
  agencyId: Nullable<string> = null;
  agencyName: Nullable<string> = null;
  companyId: Nullable<string> = null;
  companyName: Nullable<string> = null;
  companyAccountNumber: Nullable<string> = null;
  contractorName: Nullable<string> = null;
  customerEmail!: string;
  customerName!: string;
  customerId!: string;
  customerNote: Nullable<QuoteCustomerNote> = null;
  createdById!: string;
  createdByName!: string;
  createdByNCEmployee!: boolean;
  dateCreated!: Timestamp;
  dateSubmitted?: Nullable<Timestamp> = null;
  expirationTime: Nullable<Timestamp> = null;
  expirationTimeNormalized: Timestamp = Timestamp.fromMillis(
    MAX_DATE.startOf('day').toMillis()
  );
  expirationTimeOption: Nullable<QuoteExpirationTimeOption> = null;
  isCustomUser: Nullable<boolean> = false;
  lastModified!: Timestamp;
  measurementUnits!: MeasurementUnits;
  projectName: Nullable<string> = null;
  quoteNumber: Nullable<string> = null;
  shippingYearMonth: Nullable<number> = null;
  shippingLocation: Nullable<ProvinceOrState> = null;
  status!: QuoteStatus;
  totalCost: Nullable<number> = null;
  typeOfBuy: Nullable<QuoteTypeOfBuy> = null;
  employeeId: Nullable<string> = null;
  employeeName: Nullable<string> = null;
  projectNameNormalized: { en: string; fr: string } = { en: '', fr: '' };
  quoteNumberNormalized = '';
  nciProjectId: Nullable<string> = null;
  nciProjectIdNormalized = '';
  filters: string[] = [];
  quotedTime: Nullable<Timestamp> = null;
  sourceQuoteNumber: Nullable<string> = null;
  reportFile?: Nullable<{
    [Language.English]: Nullable<FirebaseFile>;
    [Language.French]: Nullable<FirebaseFile>;
  }>;

  products: Array<QuoteProduct> = [];

  constructor(user: User, agency: Company | null, company: Company | null) {
    Object.assign(this, {
      id: 'new',
      agencyId: agency?.id ?? null,
      agencyName: agency?.name ?? null,
      companyId: company?.id ?? null,
      companyName: company?.name ?? null,
      companyAccountNumber: company?.accountNumber ?? null,
      createdById: user.id,
      createdByName: user.name,
      createdByNCEmployee: user.isNCEmployee,
      customerEmail: user.email,
      customerId: user.id,
      customerName: user.name,
      employeeId: null,
      employeeName: null,
      dateCreated: Timestamp.now(),
      lastModified: Timestamp.now(),
      products: [],
      quotedTime: null,
      expirationTimeOption: null,
      reportFile: null,
      nciProjectId: null,
    });
  }
}
