import { useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  ContrastButtonMain,
  RecaptchaVerifier,
  TwoFactorCodeVerification,
} from '../../components';
import {
  formatPhoneNumber,
  CLEAN_PHONE_NUMBER_INPUT_REGEX,
} from '../../utils/phoneNumber';
import {
  useAuthentication,
  useHasSecondAuthFactors,
  useNotification,
  usePageTitle,
} from '../../hooks';
import { getPageTitle } from '../../utils/pages';
import { TwoFactorLayout } from '../../layouts';
import { NotificationLevel } from '../../enums';

const NORTH_AMERICAN_PHONE_NUMBER_PATTERN =
  /^(?:\+1\s)?\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})$/;

const TwoFactorConfiguration: React.FC = () => {
  usePageTitle(getPageTitle('twoFactorConfiguration'));
  const { t } = useTranslation();
  const { sendSmsVerificationCode, enrollSmsSecondFactor } =
    useAuthentication();
  const userHasSecondFactors = useHasSecondAuthFactors();
  const navigate = useNavigate();
  const { addNotification } = useNotification();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberSubmitted, setPhoneNumberSubmitted] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [showCodeField, setShowCodeField] = useState(false);

  useEffect(() => {
    if (userHasSecondFactors) {
      navigate('/admin/dashboard');
    }
  }, [navigate, userHasSecondFactors]);

  const onBackClick = () => {
    setShowCodeField(false);
  };

  const handlePhoneNumberSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    if (!phoneNumber) {
      return;
    }
    setPhoneNumberSubmitted(true);
    if (!NORTH_AMERICAN_PHONE_NUMBER_PATTERN.test(phoneNumber)) {
      setPhoneNumberError(t('twoFactorAuth.setup.phoneNumber.invalid'));
      return;
    }
    try {
      await sendSmsVerificationCode(phoneNumber);
      setShowCodeField(true);
    } catch (error) {
      addNotification(
        t('twoFactorAuth.setup.phoneNumber.errorSavingPhoneNumber'),
        NotificationLevel.Error
      );
    }
  };

  const handleResendCode = async () => {
    try {
      await sendSmsVerificationCode(phoneNumber);
      addNotification(
        t('twoFactorAuth.setup.phoneNumber.codeResent'),
        NotificationLevel.Success
      );
    } catch (error) {
      addNotification(
        t('twoFactorAuth.setup.phoneNumber.codeResentError'),
        NotificationLevel.Error
      );
    }
  };

  const handleVerifyCode = async (code: string) => {
    await enrollSmsSecondFactor(code, phoneNumber);
  };

  return (
    <TwoFactorLayout title={t('twoFactorAuth.setup.header')}>
      <RecaptchaVerifier />
      {!showCodeField ? (
        <Box
          component="form"
          onSubmit={handlePhoneNumberSubmit}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body1" sx={{ my: 1 }}>
            <strong>{t('twoFactorAuth.setup.phoneNumber.description')}</strong>
          </Typography>
          <TextField
            placeholder="(555) 555-5555"
            variant="outlined"
            value={phoneNumber}
            inputProps={{ maxLength: 20 }}
            onChange={(e) => {
              setPhoneNumberError('');
              const value = e.target.value.replace(
                CLEAN_PHONE_NUMBER_INPUT_REGEX,
                ''
              );
              const formattedValue = formatPhoneNumber(value);
              setPhoneNumber(formattedValue ?? value);
              if (phoneNumberSubmitted && formattedValue) {
                if (!NORTH_AMERICAN_PHONE_NUMBER_PATTERN.test(formattedValue)) {
                  setPhoneNumberError(
                    t('twoFactorAuth.setup.phoneNumber.invalid')
                  );
                } else {
                  setPhoneNumberError('');
                }
              }
            }}
            helperText={phoneNumberError || ''}
          />
          <Typography variant="body1" sx={{ my: 2 }} textAlign="center">
            {t('twoFactorAuth.setup.phoneNumber.note1')}
            <br /> {t('twoFactorAuth.setup.phoneNumber.note2')}
          </Typography>
          <ContrastButtonMain
            variant="contained"
            disabled={!phoneNumber}
            fullWidth
            type="submit"
            sx={{ width: '50%' }}
          >
            {t('twoFactorAuth.setup.phoneNumber.submit')}
          </ContrastButtonMain>
        </Box>
      ) : (
        <TwoFactorCodeVerification
          phoneNumber={phoneNumber}
          onBackClick={onBackClick}
          onSendCode={handleResendCode}
          onVerifyCode={handleVerifyCode}
        />
      )}
    </TwoFactorLayout>
  );
};

export default TwoFactorConfiguration;
