import { useMemo } from 'react';
import { Button, Drawer, Grid, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import TruckIcon from '../../assets/TruckIcon';
import { Show } from '../Show/Show.component';
import { MultipleReservationsDrawerProps } from './MultipleReservationsDrawer.props';
import * as Sx from './MultipleReservationsDrawer.styles';
import {
  MultipleReservationsDrawerAdmin,
  MultipleReservationsDrawerAgent,
  MultipleReservationsDrawerUser,
} from './components';

export const MultipleReservationsDrawer = ({
  impersonatedUser,
  impersonatedCustomer,
  isAdmin,
  isAgent,
  selectedReels,
  shoppingTruck,
  toggleShoppingTruck,
  user,
}: MultipleReservationsDrawerProps) => {
  const { t } = useTranslation();

  const effectiveUser = impersonatedUser ?? user;

  const isAdminOrAgent = isAdmin || isAgent;

  const reservationsCount = useMemo(() => {
    let customer = effectiveUser.reservationCount ?? 0;
    let northernCables = effectiveUser.reservationByNCCount ?? 0;

    if (isAdmin) {
      northernCables += selectedReels.length;
    } else {
      customer += selectedReels.length;
    }

    const total = customer + northernCables;
    return { customer, northernCables, total };
  }, [effectiveUser, isAdmin, selectedReels]);

  return (
    <Drawer
      open={true}
      variant="permanent"
      anchor="bottom"
      PaperProps={{
        sx: Sx.drawerPaper(isAdminOrAgent),
      }}
    >
      <Grid container alignItems="center" sx={{ height: '100%' }}>
        <Show if={isAdmin}>
          <MultipleReservationsDrawerAdmin
            effectiveUser={!impersonatedCustomer ? effectiveUser : null}
            reservationsCount={!impersonatedCustomer ? reservationsCount : null}
            impersonatedCustomer={impersonatedCustomer}
          />
        </Show>
        <Show if={isAgent}>
          <MultipleReservationsDrawerAgent
            impersonatedCustomer={impersonatedCustomer}
          />
        </Show>
        <Show if={!isAdminOrAgent}>
          <MultipleReservationsDrawerUser
            effectiveUser={effectiveUser}
            reservationsCount={reservationsCount}
            selectedReels={selectedReels}
          />
        </Show>
        {/* Button Section */}
        <Grid
          container
          item
          md={3}
          alignItems="center"
          justifyContent="center"
          sx={Sx.buttonSection}
        >
          <Grid item>
            <Show if={!shoppingTruck}>
              <Button
                variant="contained"
                color={isAdminOrAgent ? 'secondary' : undefined}
                onClick={toggleShoppingTruck}
                sx={Sx.buttonStyles}
              >
                <Typography sx={{ paddingRight: '10px' }}>
                  {t('inventory.multipleReservations.yourTruck', {
                    count: selectedReels.length,
                  })}
                </Typography>
                <TruckIcon />
              </Button>
            </Show>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};
