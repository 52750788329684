import { TFunction } from 'i18next';
import { User } from '../../models';
import { formatPhoneNumber } from '../../utils/phoneNumber';
import report from './report';

const PREFIX = 'NC_Customers_Contacts';
const NAMESPACE = 'users.fields';
const EXPORTED_KEYS = [
  'name',
  'title',
  'language',
  'companyClass',
  'companyName',
  'email',
  'country',
  'provinceOrState',
  'city',
  'phoneNumber',
  'extension',
  'role',
  'status',
  'reservation',
  'receiveCommunications',
];

class userMailingList extends report<User> {
  private readonly data: User[];

  constructor(data: User[], t: TFunction<'translation', undefined>) {
    super(PREFIX, NAMESPACE, EXPORTED_KEYS, t);

    this.data = data;
  }

  protected getSanitizedData = () =>
    this.data.map(
      (user) =>
        ({
          ...user,
          country: this.t(`countries.${user.country}`),
          language: this.t(`languages.${user.language}`),
          phoneNumber: formatPhoneNumber(user.phoneNumber || ''),
          provinceOrState: this.t(`provincesOrStates.${user.provinceOrState}`),
          status: this.t(`users.statuses.${user.status}`),
          receiveCommunications: user.receiveCommunications || 'N/A',
          role: user.role ? this.t(`users.roles.${user.role}`) : 'N/A',
          reservation:
            user.canReserve === true
              ? this.t('common.boolean.true')
              : this.t('common.boolean.false'),
        } as User)
    );

  static generate(data: User[], t: TFunction<'translation', undefined>) {
    const mailingList = new userMailingList(data, t);

    mailingList.generateCSV();
  }
}

export default userMailingList;
