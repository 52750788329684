import { Box, styled } from '@mui/material';
import theme from '../../theme';

export const StyledVerificationInput = styled(Box)(() => {
  return {
    '& .verification-container': {
      width: '100%',
      height: '80px',
    },
    '& .verification-character': {
      border: `1px solid ${theme.palette.primary.main}80`,
      borderRadius: '6px',
      width: '60px',
      height: '80px',
      paddingTop: '16px',
    },
  };
});
