import {
  Box,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ProductType, UserPermission } from '../../enums';
import RequirePermission from '../../security/RequirePermission';
import { useForm, usePageTitle, usePortalSettings } from '../../hooks';
import { getPageTitle } from '../../utils/pages';
import {
  Container,
  FormControlHeader,
  ProductTypeLeadTimeRow,
  ProgressIndicator,
} from '../../components';
import { StyledTable, StyledTableCell, StyledTableRow } from '../../tables';
import PortalSettings from './partials/PortalSettings';

const LeadTimesSettings = () => {
  const { t } = useTranslation();
  usePageTitle(getPageTitle('portalSettings.leadTimes'));
  const portalSettings = usePortalSettings();
  const form = useForm(portalSettings);

  return (
    <RequirePermission oneOf={[UserPermission.ManagePortalSettings]}>
      <PortalSettings>
        <FormControlHeader
          form={form}
          title={t('portalSettings.leadTimes.title')}
        />
        <Container>
          {portalSettings.loading || !portalSettings.item ? (
            <ProgressIndicator />
          ) : (
            <Box sx={{ mt: 3 }}>
              <Typography variant="h3" sx={{ mt: 3, mb: 4 }}>
                {t('portalSettings.leadTimes.subtitle')}
              </Typography>
              <Typography variant="h4" sx={{ mt: 3, mb: 3 }}>
                {t('portalSettings.leadTimes.description')}
              </Typography>
              <TableContainer>
                <StyledTable>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell width="20%">
                        {t('portalSettings.leadTimes.fields.productType')}
                      </StyledTableCell>
                      <StyledTableCell width="40%">
                        {t('portalSettings.leadTimes.fields.leadTime', {
                          lang: t('languages.EN'),
                        })}
                      </StyledTableCell>
                      <StyledTableCell width="40%">
                        {t('portalSettings.leadTimes.fields.leadTime', {
                          lang: t('languages.FR'),
                        })}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {Object.values(ProductType)
                      .filter((value) => !isNaN(Number(value)))
                      .map((productType) => (
                        <ProductTypeLeadTimeRow
                          key={productType}
                          form={form}
                          field="productTypeLeadTimes"
                          productType={productType as ProductType}
                        />
                      ))}
                  </TableBody>
                </StyledTable>
              </TableContainer>
            </Box>
          )}
        </Container>
      </PortalSettings>
    </RequirePermission>
  );
};

export default LeadTimesSettings;
