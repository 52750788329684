import { DateTime } from 'luxon';
import { i18n, TFunction } from 'i18next';

import cable_icon from '../../assets/CableIcon.png';
import { SanitizedProductSearchStats } from '../../models';
import { InventorySearchesFilters, Nullable } from '../../types';
import { DefaultValue } from '../../enums';
import report, { MARGIN, PDF_CONFIG } from './report';

const NAMESPACE = 'reports.inventorySearches';
const PREFIX = 'NC_InventorySearches';

class inventorySearchesPdf extends report {
  private readonly stats: SanitizedProductSearchStats[];
  private readonly filters: InventorySearchesFilters;
  private startDate: Nullable<DateTime>;
  private endDate: Nullable<DateTime>;
  private readonly DEFAULT_ROW_HEIGHT = 13;

  constructor(
    stats: SanitizedProductSearchStats[],
    filters: InventorySearchesFilters,
    t: TFunction<'translation', undefined>,
    i18n: i18n
  ) {
    super(NAMESPACE, t, i18n);

    this.stats = stats;
    this.filters = filters;
    this.startDate = this.filters.startDate?.setLocale('en') ?? null;
    this.endDate = this.filters.endDate?.setLocale('en') ?? null;
  }

  protected getFilename(): string {
    const from = this.startDate?.toFormat('LLddyyyy');
    const to = this.endDate?.toFormat('LLddyyyy');
    return `${PREFIX}_${from}_${to}.pdf`;
  }

  protected buildHeader(): void {
    this.outputDocumentDate();

    this.curHeight += PDF_CONFIG.LINE_SIZE + 5;

    this.outputImage(cable_icon, MARGIN.X, this.curHeight, 10, 10);

    this.outputH1(
      this.t(`${NAMESPACE}.title`),
      this.curWidth + 15,
      this.curHeight + 7
    );

    this.curHeight += PDF_CONFIG.LINE_SIZE / 2 + 2;

    let formattedFilters = this.t(`${NAMESPACE}.dateFilter`, {
      from: this.startDate?.toLocaleString(),
      to: this.endDate?.toLocaleString(),
    });

    if (this.filters.productType) {
      formattedFilters += `\n(${this.t(
        `productTypes.${this.filters.productType}`
      )}`;
      formattedFilters += this.filters.productSubType
        ? ` - ${this.t(`productSubTypes.${this.filters.productSubType}`)}`
        : '';
      formattedFilters += this.filters.material
        ? ` - ${this.t(`materials.${this.filters.material}`)}`
        : '';
      formattedFilters += this.filters.voltage
        ? ` - ${this.filters.voltage} V`
        : '';
      formattedFilters += ')';
    }

    const addedLines = this.outputH3(
      formattedFilters,
      this.curWidth,
      this.curHeight + 7
    );

    this.curHeight += PDF_CONFIG.LINE_SIZE / 2 + addedLines * 5;
  }

  protected buildBody(): void {
    this.drawLine(0.5);

    this.curHeight += 5;

    this.stats.forEach((stat, index) => this.buildRow(stat, index));
  }

  private buildRow(stat: SanitizedProductSearchStats, index: number): void {
    const {
      standardType,
      productType,
      productSubType,
      material,
      voltage,
      numberOfConductors,
      gauge,
      nbOfSearches,
      emptyResults,
      lowInventory,
      reservationWasMade,
    } = stat;

    this.addDataColumn(
      'inventory',
      this.t(`inventoryType.${standardType}`),
      MARGIN.X,
      35
    );

    this.addDataColumn(
      'productType',
      this.t(`productTypes.${productType}`),
      MARGIN.X + 30,
      35
    );
    this.addDataColumn(
      'productSubType',
      productSubType
        ? this.t(`productSubTypes.${productSubType}`)
        : DefaultValue.Dash,
      MARGIN.X + 60,
      35
    );
    this.addDataColumn(
      'material',
      this.t(`materials.${material}`),
      MARGIN.X + 90,
      35
    );
    this.addDataColumn(
      'voltage',
      voltage ?? this.t('common.notApplicable'),
      MARGIN.X + 120,
      35
    );
    this.addDataColumn(
      'numberOfConductors',
      numberOfConductors,
      MARGIN.X + 149,
      35
    );

    this.curHeight += 12;
    this.addDataColumn('gauge', gauge, MARGIN.X, 35);
    this.addDataColumn('nbOfSearches', String(nbOfSearches), MARGIN.X + 30, 35);
    this.addDataColumn('emptyResults', String(emptyResults), MARGIN.X + 60, 35);
    this.addDataColumn('lowInventory', String(lowInventory), MARGIN.X + 90, 35);
    this.addDataColumn(
      'reservationWasMade',
      String(reservationWasMade),
      MARGIN.X + 120,
      35
    );

    this.curHeight += 8;

    this.drawLine(0.2);

    this.curHeight += 6;

    const count = this.stats.length;
    this.nextRow(this.DEFAULT_ROW_HEIGHT, index, count);
  }

  static generate(
    stats: SanitizedProductSearchStats[],
    filters: InventorySearchesFilters,
    t: TFunction<'translation', undefined>,
    i18n: i18n
  ) {
    const report = new inventorySearchesPdf(stats, filters, t, i18n);

    report.generate();
  }
}

export default inventorySearchesPdf;
