import { Timestamp } from 'firebase/firestore';
import { DocumentReference } from '@firebase/firestore';

import {
  Country,
  Language,
  MeasurementUnits,
  ProvinceOrState,
  TimeZone,
  UserRole,
  UserStatus,
} from '../enums/';
import { Model, Nullable } from '../types';
import { OrderFilesPermissions } from './OrderFilesPermissions';

export class UserOrderFilePermission extends OrderFilesPermissions {
  useDefault = true;
}
export class User implements Model {
  id!: string;
  canReserve = false;
  canSeeCSA = false;
  canSeeUL = false;
  city?: string;
  comments?: string;
  companyClass?: string;
  companyName?: string;
  companyRef!: DocumentReference;
  country?: Country;
  creationDate?: Timestamp;
  email?: string;
  extension?: string;
  extensionNotificationDelay?: number;
  holdDelay?: number;
  isDeleted = false;
  isNCEmployee = false;
  language?: Language;
  maxReservationsPerPeriod!: number;
  measurementUnits!: MeasurementUnits;
  name?: string;
  orderFilesPermissions!: UserOrderFilePermission;
  phoneNumber?: string;
  phoneSecondFactorLastDigits: Nullable<string> = null;
  provinceOrState?: ProvinceOrState;
  receiveCommunications = false;
  reservationByNCCount = 0;
  reservationCount = 0;
  role?: UserRole;
  status?: UserStatus;
  suggestedCompanyName?: string;
  timeZone?: TimeZone;
  title?: string;

  constructor(
    extensionNotificationDelay: number,
    holdDelay: number,
    maxReservationsPerPeriod: number,
    language: Nullable<Language> = Language.English,
    timezone: Nullable<TimeZone> = TimeZone.EasternTime,
    orderFilesPermissions?: UserOrderFilePermission
  ) {
    this.extensionNotificationDelay = extensionNotificationDelay;
    this.holdDelay = holdDelay;
    this.maxReservationsPerPeriod = maxReservationsPerPeriod;
    this.language = language ?? undefined;
    this.timeZone = timezone ?? undefined;
    this.orderFilesPermissions =
      orderFilesPermissions ??
      ({ useDefault: true } as UserOrderFilePermission);
  }
}
