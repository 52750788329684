import { Link } from 'react-router-dom';
import {
  MenuItem as MuiMenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { Show } from '../../components';

import { useMatchLocation } from '../../hooks';
import * as Sx from './MenuItem.styles';
import { MenuItemProps } from './MenuItem.props';

export const MenuItem = (props: MenuItemProps) => {
  const {
    icon,
    match,
    title,
    titleAccess,
    to,
    href,
    onClick,
    target,
    children,
  } = props;
  const isMatch = useMatchLocation(to, match);

  return (
    <MuiMenuItem
      tabIndex={0}
      aria-label={titleAccess ?? title}
      component={to ? Link : 'a'}
      to={to}
      href={href}
      onClick={onClick}
      target={target}
      sx={Sx.menuItem(isMatch, !!to)}
    >
      <Show if={!!icon}>
        <ListItemIcon sx={Sx.listItemIcon(isMatch)}>{icon}</ListItemIcon>
      </Show>
      <Show if={!children}>
        <ListItemText sx={Sx.listItemText(!!icon)}>{title}</ListItemText>
      </Show>
      {children}
    </MuiMenuItem>
  );
};
