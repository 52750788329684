import { styled, SxProps, Tab, TabProps } from '@mui/material';

interface StyledTabProps extends TabProps {
  label: string;
}

export const mainContainer: SxProps = { my: { xs: 2, md: 4 } };

export const fieldContainer: SxProps = { flexBasis: { md: '16.666667%' } };

export const pleaseApply: SxProps = { mt: 1 };

export const StyledTab = styled(Tab)<StyledTabProps>(() => {
  return {
    fontSize: '1rem',
    letterSpacing: '-1px',
    '&.MuiButtonBase-root.MuiTab-root:first-of-type': {
      paddingLeft: 0,
    },
    '&.MuiButtonBase-root.MuiTab-root:last-of-type': {
      paddingRight: 0,
    },
    '&.Mui-selected': {
      fontWeight: 'bold',
    },
  };
});
