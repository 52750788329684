import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { ErrorLayout } from '../../layouts';
import logo from '../../assets/403_logo.png';
import { ErrorCategory } from '../../enums';
import { useAuthentication, usePageTitle } from '../../hooks';

interface Props {
  errorCategory: ErrorCategory;
}

const HttpError: React.FC<Props> = ({ errorCategory }: Props) => {
  const { t } = useTranslation();
  const { checkTokenRevocation } = useAuthentication();
  usePageTitle(t(`errors.${errorCategory}.title`));

  useEffect(() => {
    (async () => {
      if (errorCategory === ErrorCategory.AccessDenied) {
        await checkTokenRevocation();
      }
    })();
  }, [checkTokenRevocation, errorCategory]);

  return (
    <ErrorLayout>
      <Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ '& img': { maxWidth: '100vw', height: 'auto' } }}
        >
          <img alt={t('errors.imageAltText')} src={logo} />
          <Typography variant="h1" color="#ffffff" sx={{ mt: 2 }}>
            {t(`errors.${errorCategory}.title`)}
          </Typography>
          <Typography color="#ffffff" sx={{ mt: 2 }}>
            {t(`errors.${errorCategory}.message`)}
          </Typography>
        </Box>
      </Box>
    </ErrorLayout>
  );
};

export default HttpError;
