import { Grid } from '@mui/material';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QuoteTypeOfBuyOptions } from '../../../../mappings';
import { RadioGroup } from '../../../RadioGroup/RadioGroup.component';
import { TextField } from '../../../TextField/TextField.component';
import { DefaultValue, QuoteTypeOfBuy } from '../../../../enums';
import {
  useAuthentication,
  useFormFieldValue,
  useShipToValues,
} from '../../../../hooks';
import { Select } from '../../../Select/Select.component';
import { ReadyOnlyTypography } from '../../QuoteSummaryDetails.styles';
import { QuoteDatePicker } from '../QuoteDatePicker/QuoteDatePicker.component';
import { FormattedYearMonth } from '../../..';
import { Quote as QuoteModel } from '../../../../models';
import { Nullable } from '../../../../types';
import { QuoteDetailsProps } from './QuoteDetails.props';
import {
  GridStyles,
  RadioGroupStyles,
  ReadonlyStyledStack,
  StyledStack,
} from './QuoteDetails.styles';

export const QuoteDetails = memo(
  ({ form, isMobile = false, isReadonly = false }: QuoteDetailsProps) => {
    const { isAdmin } = useAuthentication();
    const { t } = useTranslation();

    const shipToValues = useShipToValues();

    const { value: typeOfBuy } = useFormFieldValue<
      QuoteModel,
      Nullable<QuoteTypeOfBuy>
    >(form, 'typeOfBuy');

    const showProjectInfoFields = useMemo(
      () => !!typeOfBuy && typeOfBuy !== QuoteTypeOfBuy.InventoryBuy,
      [typeOfBuy]
    );

    return (
      <>
        {isReadonly ? (
          <ReadonlyStyledStack>
            <ReadyOnlyTypography>
              <b>
                {`${t('quotes.edit.summary.quoteDetails.typeOfBuy.label')}: `}
              </b>
              {`${t(
                `quotes.edit.summary.quoteDetails.typeOfBuy.${
                  form.item?.typeOfBuy || ''
                }`,
                { defaultValue: DefaultValue.Dash }
              )}`}
            </ReadyOnlyTypography>
            {showProjectInfoFields && (
              <>
                <ReadyOnlyTypography>
                  <b>
                    {`${t('quotes.edit.summary.quoteDetails.projectName')}: `}
                  </b>
                  {`${form.item?.projectName || ''}`}
                </ReadyOnlyTypography>
                {isAdmin && (
                  <ReadyOnlyTypography>
                    <b>{`${t(
                      'quotes.edit.summary.quoteDetails.nciProjectId'
                    )}: `}</b>
                    {`${form.item?.nciProjectId || ''}`}
                  </ReadyOnlyTypography>
                )}
                <ReadyOnlyTypography>
                  <b>
                    {`${t(
                      'quotes.edit.summary.quoteDetails.contractorName'
                    )}: `}
                  </b>
                  {`${form.item?.contractorName || ''}`}
                </ReadyOnlyTypography>
                {form.item?.shippingYearMonth && (
                  <ReadyOnlyTypography>
                    <b>
                      {`${t(
                        'quotes.edit.summary.quoteDetails.shippingYearMonth.label'
                      )}: `}
                    </b>
                    <FormattedYearMonth
                      unitLength="short"
                      yearMonth={form.item?.shippingYearMonth}
                    />
                  </ReadyOnlyTypography>
                )}
              </>
            )}
            {form.item?.shippingLocation && (
              <ReadyOnlyTypography>
                <b>
                  {`${t('quotes.edit.summary.quoteDetails.shipTo.readonly')}: `}
                </b>
                {t(`provincesOrStates.${form.item?.shippingLocation}`)}
              </ReadyOnlyTypography>
            )}
          </ReadonlyStyledStack>
        ) : (
          <StyledStack>
            <RadioGroup
              fullWidth={isMobile}
              disabled={isReadonly}
              form={form}
              field="typeOfBuy"
              options={QuoteTypeOfBuyOptions.map((o) => {
                return { ...o, label: t(o.label) };
              })}
              sx={RadioGroupStyles}
            />
            <Grid
              container
              justifyContent="space-between"
              rowGap={{ xs: 2, sm: 1 }}
              sx={GridStyles}
            >
              {showProjectInfoFields && (
                <>
                  <Grid item xs={12} sm={6} pr={{ sm: 1 }}>
                    <TextField
                      disabled={isReadonly}
                      form={form}
                      label={t('quotes.edit.summary.quoteDetails.projectName')}
                      field="projectName"
                      required={showProjectInfoFields}
                      maxLength={60}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} pl={{ sm: 1 }}>
                    <TextField
                      disabled={isReadonly}
                      form={form}
                      label={t(
                        'quotes.edit.summary.quoteDetails.contractorName'
                      )}
                      field="contractorName"
                      maxLength={60}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} pr={{ sm: 1 }}>
                    <QuoteDatePicker
                      form={form}
                      field={'shippingYearMonth'}
                      isMobile={isMobile}
                    />
                  </Grid>
                </>
              )}
              <Grid
                item
                xs={12}
                sm={showProjectInfoFields ? 6 : 10}
                pl={{ sm: showProjectInfoFields ? 1 : 0 }}
              >
                <Select
                  disabled={isReadonly}
                  form={form}
                  field="shippingLocation"
                  label={t(
                    `quotes.edit.summary.quoteDetails.shipTo.${
                      isMobile ? 'short' : 'label'
                    }`
                  )}
                  options={shipToValues}
                  getOptionLabel={(region) => t(`provincesOrStates.${region}`)}
                  required
                  autocomplete="off"
                  disableAutoComplete
                />
              </Grid>
            </Grid>
          </StyledStack>
        )}
      </>
    );
  }
);
