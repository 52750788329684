import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuthentication, usePermission, useRouterOutlet } from '../hooks';
import { UserPermission } from '../enums';
import { RequirePermission } from '../security';

import AdminReports from './admin/partials/Reports';
import AgentReports from './agent/partial/Reports';
import ConversionRatePartial from './partials/ConversionRatePartial';

const ConversionRate: React.FC = () => {
  const { outlet } = useRouterOutlet();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAdmin } = useAuthentication();
  const { hasPermission } = usePermission();

  const hasQuotePermission = hasPermission(UserPermission.ViewQuoteReports);
  const hasReservationPermission = hasPermission(
    isAdmin
      ? UserPermission.ViewReservationReports
      : UserPermission.ViewReservationConversionRatesReports
  );

  const routePrefix = isAdmin ? 'admin' : 'agent';

  useEffect(() => {
    if (
      (location.pathname.endsWith('quote') && !hasQuotePermission) ||
      (!outlet && hasReservationPermission)
    ) {
      navigate(`/${routePrefix}/reports/conversion-rate/reservation`);
    } else if (
      (location.pathname.endsWith('reservation') &&
        !hasReservationPermission) ||
      (!outlet && hasQuotePermission)
    ) {
      navigate(`/${routePrefix}/reports/conversion-rate/quote`);
    }
  }, [
    hasPermission,
    hasQuotePermission,
    hasReservationPermission,
    location.pathname,
    navigate,
    outlet,
    routePrefix,
  ]);

  const renderConversionRatePartial = () => (
    <ConversionRatePartial
      showTabs={hasQuotePermission && hasReservationPermission}
    >
      {outlet}
    </ConversionRatePartial>
  );

  return (
    <RequirePermission
      oneOf={[
        isAdmin
          ? UserPermission.ViewReservationReports
          : UserPermission.ViewReservationConversionRatesReports,
        UserPermission.ViewQuoteReports,
      ]}
    >
      {isAdmin ? (
        <AdminReports>{renderConversionRatePartial()}</AdminReports>
      ) : (
        <AgentReports>{renderConversionRatePartial()}</AgentReports>
      )}
    </RequirePermission>
  );
};

export default ConversionRate;
