import { useMemo } from 'react';
import { matchPath, To, useLocation } from 'react-router-dom';

export function useMatchLocation(
  to: To | undefined,
  match?: string | string[]
) {
  const location = useLocation();

  const isMatch = useMemo(() => {
    if (Array.isArray(match)) {
      return match.some(
        (path) => !!matchPath({ path, end: true }, location.pathname)
      );
    } else if (typeof match === 'string') {
      return !!matchPath({ path: match, end: true }, location.pathname);
    }

    return !!matchPath(
      { path: to?.toString() || '', end: true },
      location.pathname
    );
  }, [location.pathname, match, to]);

  return isMatch;
}
