import { memo, useMemo } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Quote, Quote as QuoteModel, User } from '../../../../models';
import { Select } from '../../../Select/Select.component';
import { QuoteInputState, QuoteStatus } from '../../../../enums';
import {
  useAuthentication,
  useFormFieldValue,
  useQuoteDetails,
} from '../../../../hooks';
import { TextField } from '../../../TextField/TextField.component';
import { Nullable } from '../../../../types';
import { UserSelectContainer } from '../../../UserSelect/UserSelectContainer.component';
import { ReadyOnlyTypography } from '../../QuoteSummaryDetails.styles';
import { UnquotedQuoteStatuses } from '../../../../mappings';
import { CustomerDetailsProps } from './CustomerDetails.props';
import { StyledStack } from './CustomerDetails.styles';

export const CustomerDetails = memo(
  ({
    form,
    isMobile = false,
    agencies,
    selectableCompanies,
  }: CustomerDetailsProps) => {
    const { t } = useTranslation();
    const { isAdmin, isAgent, isCustomer } = useAuthentication();
    const { isReadonly } = useQuoteDetails(form);

    const { value: agencyId } = useFormFieldValue<Quote, string>(
      form,
      'agencyId'
    );

    const { value: status } = useFormFieldValue<Quote, QuoteStatus>(
      form,
      'status'
    );

    const customer = useMemo(() => {
      if (form.item?.customerId || form.item?.customerName) {
        return {
          id: form.item.customerId,
          name: form.item.customerName,
          email: form.item.customerEmail,
        } as User;
      }
      return null;
    }, [form.item]);

    const setCustomer = (user: Nullable<User | string>) => {
      if (typeof user == 'object') {
        form.setItem({
          ...form.item,
          customerId: user?.id,
          customerName: user?.name,
          customerEmail: user?.email,
        } as QuoteModel);
      } else {
        form.setItem({
          ...form.item,
          customerId: '',
          customerName: user ?? '',
          customerEmail: '',
          isCustomUser: true,
        } as QuoteModel);
      }
    };

    const agencySelection: Record<QuoteInputState, JSX.Element | null> = {
      [QuoteInputState.Editable]: (
        <Select
          form={form}
          fullWidth={isMobile}
          field="agencyId"
          label={t('quotes.edit.summary.personalDetails.agencyName')}
          options={agencies.map((c) => c.id)}
          getOptionLabel={(val) =>
            agencies.find((c) => c.id === val)?.name ?? val
          }
        />
      ),
      [QuoteInputState.Disabled]: (
        <TextField
          form={form}
          label={t('quotes.edit.summary.personalDetails.agencyName')}
          field="agencyName"
          disabled
        />
      ),
      [QuoteInputState.Readonly]: (
        <ReadyOnlyTypography>
          <b>{`${t('quotes.edit.summary.personalDetails.agencyName')}: `}</b>
          {`${form.item?.agencyName}`}
        </ReadyOnlyTypography>
      ),
    };

    const companySelection: Record<QuoteInputState, JSX.Element | null> = {
      [QuoteInputState.Editable]: (
        <Select
          form={form}
          fullWidth={isMobile}
          field="companyId"
          label={t('quotes.edit.summary.personalDetails.companyName')}
          options={selectableCompanies.map((c) => c.id)}
          getOptionLabel={(val) =>
            selectableCompanies.find((c) => c.id === val)?.name ?? val
          }
          required
        />
      ),
      [QuoteInputState.Disabled]: (
        <TextField
          form={form}
          label={t('quotes.edit.summary.personalDetails.companyName')}
          field="companyName"
          required
          disabled
        />
      ),
      [QuoteInputState.Readonly]: (
        <ReadyOnlyTypography>
          <b>{`${t('quotes.edit.summary.personalDetails.companyName')}: `}</b>
          {`${form.item?.companyName || ''}`}
        </ReadyOnlyTypography>
      ),
    };

    const userSelection: Record<QuoteInputState, JSX.Element | null> = {
      [QuoteInputState.Editable]: (
        <UserSelectContainer
          allowFreeSolo={true}
          companyId={form.item?.agencyId || form.item?.companyId || undefined}
          user={customer}
          onSelect={(user) => setCustomer(user)}
          textFieldProps={{
            variant: 'outlined',
            required: true,
            label: t('quotes.edit.summary.personalDetails.user'),
            error: !!form.errors['customerId'],
            helperText: form.errors['customerId'],
          }}
        />
      ),
      [QuoteInputState.Disabled]: (
        <TextField
          form={form}
          label={t('quotes.edit.summary.personalDetails.customerName')}
          field="customerName"
          required
          disabled
        />
      ),
      [QuoteInputState.Readonly]: (
        <ReadyOnlyTypography>
          <b>{`${t('quotes.edit.summary.personalDetails.customerName')}: `}</b>
          {`${form.item?.customerName || ''}`}
        </ReadyOnlyTypography>
      ),
    };

    const userEmail: Record<QuoteInputState, JSX.Element | null> = {
      [QuoteInputState.Editable]: null,
      [QuoteInputState.Disabled]: (
        <TextField
          form={form}
          label={t('quotes.edit.summary.personalDetails.customerEmail')}
          field="customerEmail"
          required
          disabled
        />
      ),
      [QuoteInputState.Readonly]: (
        <ReadyOnlyTypography>
          {form.item?.customerEmail && (
            <>
              <b>{`${t(
                'quotes.edit.summary.personalDetails.customerEmail'
              )}: `}</b>
              {`${form.item?.customerEmail || ''}`}
            </>
          )}
        </ReadyOnlyTypography>
      ),
    };

    const selections = useMemo(() => {
      let company = QuoteInputState.Readonly;
      let agency: Nullable<QuoteInputState> = agencyId
        ? QuoteInputState.Readonly
        : null;
      let user = QuoteInputState.Readonly;
      let email: Nullable<QuoteInputState> = null;

      if (isAdmin) {
        if (!status || UnquotedQuoteStatuses.includes(status)) {
          if (form.item?.createdByNCEmployee && !form.item?.sourceQuoteNumber) {
            company = QuoteInputState.Editable;
            agency = QuoteInputState.Editable;
            user = QuoteInputState.Editable;
          } else {
            company = QuoteInputState.Disabled;
            agency = agencyId ? QuoteInputState.Disabled : null;
            user = QuoteInputState.Disabled;
          }
        } else {
          email = QuoteInputState.Readonly;
        }
      } else if (isAgent && agencyId) {
        if (!isReadonly) {
          company = QuoteInputState.Editable;
          agency = QuoteInputState.Disabled;
          user = QuoteInputState.Disabled;
        }
      } else if (isCustomer) {
        company = QuoteInputState.Readonly;
        user = QuoteInputState.Readonly;
        agency = agencyId ? QuoteInputState.Readonly : null;
        if (form.item?.customerEmail) {
          email = QuoteInputState.Readonly;
        }
      }

      return { company, agency, user, email };
    }, [
      agencyId,
      form.item?.createdByNCEmployee,
      form.item?.customerEmail,
      form.item?.sourceQuoteNumber,
      isAdmin,
      isAgent,
      isCustomer,
      isReadonly,
      status,
    ]);

    return (
      <StyledStack>
        {isAgent && <Box>{companySelection[selections.company]}</Box>}

        {selections.agency && <Box>{agencySelection[selections.agency]}</Box>}

        {!isAgent && <Box>{companySelection[selections.company]}</Box>}

        <Box>{userSelection[selections.user]}</Box>

        {selections.email && <Box>{userEmail[selections.email]}</Box>}
      </StyledStack>
    );
  }
);
