import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { usePagination, useSorting } from '../../hooks';
import { useQuoteUserConversionRates } from '../../hooks/useQuoteUserConversionRates';
import { DataTable, DataTableColumn } from '../../tables';
import { QuoteUserConversionRateTableProps } from './QuoteUserConversionRateTableProps';

export const QuoteUserConversionRateTable = ({
  companyId,
  parent,
  quotes,
  productTypes,
}: QuoteUserConversionRateTableProps) => {
  const { t } = useTranslation();
  const pagination = usePagination();
  const sorting = useSorting('name', { sortDirection: 'asc' });

  const {
    list: quoteConversionRate,
    unpaginatedResults,
    loading,
  } = useQuoteUserConversionRates(companyId, quotes, productTypes, {
    pagination,
    sorting,
  });

  // Used for export data only. No need to set state to refresh UI.
  useEffect(() => {
    if (parent) {
      parent.subQuoteConversionRates = unpaginatedResults;
    }
    return () => {
      if (parent) {
        parent.subQuoteConversionRates = [];
      }
    };
  }, [parent, unpaginatedResults]);

  return (
    <DataTable
      loading={loading}
      data={quoteConversionRate}
      pagination={pagination}
      sorting={sorting}
    >
      <DataTableColumn
        property="name"
        title={t('reports.quoteConversionRate.user')}
        output={(user) =>
          user.id ? user.name : t('reports.quoteConversionRate.misc')
        }
        width="20%"
      />
      <DataTableColumn
        property="quoted"
        title={t('reports.quoteConversionRate.quoted')}
        width="8%"
      />
      <DataTableColumn
        property="expired"
        title={t('reports.quoteConversionRate.expired')}
        width="8%"
      />
      <DataTableColumn
        property="won"
        title={t('reports.quoteConversionRate.won')}
        width="8%"
      />
      <DataTableColumn
        property="lost"
        title={t('reports.quoteConversionRate.lost')}
        width="8%"
      />
      <DataTableColumn
        property="rate"
        title={t('reports.quoteConversionRate.rate')}
        output={(value) => `${value}%`}
        width="10%"
      />
      <DataTableColumn
        property="totalQuotedFormatted"
        title={t('reports.quoteConversionRate.totalQuoted')}
        width="10%"
      />
      <DataTableColumn
        property="totalWonFormatted"
        title={t('reports.quoteConversionRate.totalWon')}
        width="10%"
      />
      <DataTableColumn
        property="totalWonRate"
        title={t('reports.quoteConversionRate.totalWonRate')}
        output={(value) => `${value}%`}
        width="17%"
      />
    </DataTable>
  );
};
