export const groupBy = <T, K extends keyof T>(
  list: T[],
  getKey: (item: T) => K
) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) previous[group] = [];
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);

// Map is useful when we need to keep the original ordering of the groups.
// When using dictionary or object, Javascript will sort the keys automatically for optimization.
export const mapGroupBy = <T, K>(data: T[], getKey: (item: T) => K) => {
  const map = new Map<K, T[]>();
  for (const item of data) {
    const key = getKey(item);
    if (!map.has(key)) {
      map.set(key, []);
    }
    map.get(key)?.push(item);
  }
  return map;
};
