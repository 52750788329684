import PieChartIcon from '@mui/icons-material/PieChart';
import { useTranslation } from 'react-i18next';

import { Container, DecoratedHeader } from '../../../components';
import { Tab } from '../../../navigation';
import NeedsPermission from '../../../security/NeedsPermission';
import { UserPermission } from '../../../enums/';

interface Props {
  children: React.ReactNode;
}

const Reports = ({ children }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <DecoratedHeader
          icon={<PieChartIcon />}
          title={t('reports.title')}
          variant="colored"
        >
          <NeedsPermission oneOf={[UserPermission.ViewLogs]}>
            <Tab
              title={t('reports.userActivity.title')}
              to={'/admin/reports/user-activity'}
            />
          </NeedsPermission>

          <NeedsPermission oneOf={[UserPermission.ViewInventorySearchReports]}>
            <Tab
              title={t('reports.inventorySearches.title')}
              to={'/admin/reports/inventory-searches'}
            />
          </NeedsPermission>

          <NeedsPermission oneOf={[UserPermission.ViewLogs]}>
            <Tab
              title={t('reports.reservationLogs.title')}
              to={'/admin/reports/reservation-logs'}
            />
          </NeedsPermission>

          <NeedsPermission
            oneOf={[
              UserPermission.ViewReservationReports,
              UserPermission.ViewQuoteReports,
            ]}
          >
            <Tab
              title={t('reports.reservationConversionRate.title')}
              to={'/admin/reports/conversion-rate'}
              match={'/admin/reports/conversion-rate/*'}
            />
          </NeedsPermission>

          <NeedsPermission oneOf={[UserPermission.ViewReelReturns]}>
            <Tab
              title={t('reports.reelReturns.title')}
              to={'/admin/reports/reel-returns'}
            />
          </NeedsPermission>

          <NeedsPermission oneOf={[UserPermission.ViewLogs]}>
            <Tab
              title={t('reports.errorLogs.title')}
              to={'/admin/reports/error-logs'}
            />
          </NeedsPermission>
        </DecoratedHeader>
      </Container>
      {children}
    </>
  );
};

export default Reports;
