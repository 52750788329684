import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuote } from '../../../../hooks';
import { FormattedTimestamp, MultilineTypography } from '../../..';
import { QuoteNotesProps } from './QuoteNotes.props';
import { StyledCustomerNote } from './QuoteNotes.styles';

export const QuoteNotes = ({
  internalNotesCount = 0,
  quoteId,
  isAdmin,
  setShowNotesDrawer,
}: QuoteNotesProps) => {
  const { t } = useTranslation();
  const { item: quote } = useQuote(quoteId);

  return isAdmin ? (
    <Button
      variant="text"
      onClick={() => setShowNotesDrawer && setShowNotesDrawer(true)}
    >
      <Typography variant="body2">
        {t('quotes.edit.summary.quoteDetails.viewNotes', {
          count:
            internalNotesCount + (quote?.customerNote?.content.trim() ? 1 : 0),
        })}
      </Typography>
    </Button>
  ) : (
    quote?.customerNote?.content && (
      <StyledCustomerNote>
        <MultilineTypography variant="body1" sx={{ fontSize: '1rem' }}>
          <>
            <b>{`${t('quotes.edit.summary.quoteDetails.note')}: `}</b>
            {quote.customerNote.content}
          </>
        </MultilineTypography>
        <Typography variant="caption">
          {t('quotes.edit.summary.quoteDetails.noteBy', {
            name: quote?.customerNote?.lastModifiedByName,
          })}
          <FormattedTimestamp timestamp={quote?.customerNote?.lastModified} />
        </Typography>
      </StyledCustomerNote>
    )
  );
};
