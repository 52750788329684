import { DateTime } from 'luxon';
import { i18n, TFunction } from 'i18next';

import quote_icon from '../../assets/QuoteIcon.png';
import { Quote, SanitizedQuoteConversionRate } from '../../models';

import { Nullable, QuoteConversionRateFilters } from '../../types';
import { ProductType } from '../../enums';
import report, { MARGIN, PDF_CONFIG } from './report';

const PREFIX = 'NC_QuoteConversionRates';
const NAMESPACE = 'reports.quoteConversionRate';

class quotesConversionRatePdf extends report {
  private data: SanitizedQuoteConversionRate[];
  private readonly DEFAULT_ROW_HEIGHT = 13;
  private originalQuotes: Quote[];
  private companyIds: Nullable<string[]>;
  private productTypes: Nullable<ProductType[]>;
  private startDate: Nullable<DateTime>;
  private endDate: Nullable<DateTime>;
  private byCompany: boolean;
  private rowIndex = 0;

  constructor(
    data: SanitizedQuoteConversionRate[],
    originalQuotes: Quote[],
    filters: QuoteConversionRateFilters,
    t: TFunction,
    i18n: i18n
  ) {
    super(NAMESPACE, t, i18n);

    this.data = data;
    this.originalQuotes = originalQuotes;
    this.companyIds = filters.companyIds;
    this.productTypes = filters.productTypes;
    this.byCompany = filters.searchByCompany;
    this.startDate = filters.startDate?.setLocale('en') ?? null;
    this.endDate = filters.endDate?.setLocale('en') ?? null;
  }

  protected buildHeader(): void {
    this.outputDocumentDate();

    this.curHeight += PDF_CONFIG.LINE_SIZE + 5;

    this.outputImage(quote_icon, MARGIN.X, this.curHeight, 10, 10);

    this.outputH1(
      this.t(`${NAMESPACE}.title`),
      this.curWidth + 15,
      this.curHeight + 7
    );

    this.curHeight += PDF_CONFIG.LINE_SIZE / 2 + 2;

    let filters = this.t(`${NAMESPACE}.dateFilter`, {
      from: this.startDate?.toLocaleString(),
      to: this.endDate?.toLocaleString(),
    });

    const companyNames = new Set();
    const productTypeNames = new Set();
    this.originalQuotes.forEach(({ companyId, companyName, products }) => {
      const filteredProducts = products.filter(
        (p) =>
          !this.productTypes ||
          (p.productType && this.productTypes.includes(p.productType))
      );

      if (
        filteredProducts.length &&
        (!this.companyIds || this.companyIds.includes(companyId || ''))
      ) {
        companyNames.add(companyName);
        filteredProducts.forEach((p) => {
          productTypeNames.add(this.t(`productTypes.${p.productType}`));
        });
      }
    });
    filters +=
      this.companyIds && companyNames.size
        ? `\n(${[...companyNames].sort().join(', ')})`
        : '';
    filters +=
      this.productTypes && productTypeNames.size
        ? `\n(${[...productTypeNames].sort().join(', ')})`
        : '';

    const addedLines = this.outputH3(
      filters,
      this.curWidth,
      this.curHeight + 7
    );

    this.curHeight += PDF_CONFIG.LINE_SIZE / 2 + addedLines * 5;
  }

  protected buildBody(): void {
    this.drawLine(0.5);

    this.curHeight += 5;
    const totalRows = this.data.reduce((acc, item) => {
      acc += item.subQuoteConversionRates.length + 1;
      return acc;
    }, 0);

    this.data.forEach((conversion) => {
      this.buildRow(conversion, totalRows);
    });
  }

  private buildRow(
    conversion: SanitizedQuoteConversionRate,
    totalRows: number
  ): void {
    const {
      name,
      quoted,
      expired,
      won,
      lost,
      rate,
      totalQuotedFormatted,
      totalWonFormatted,
      totalWonRate,
    } = conversion;

    this.addDataColumn(
      this.byCompany ? 'company' : 'productType',
      name,
      MARGIN.X,
      35
    );
    this.addDataColumn('quoted', String(quoted), MARGIN.X + 45);
    this.addDataColumn('expired', String(expired), MARGIN.X + 75);
    this.addDataColumn('won', String(won), MARGIN.X + 98);
    this.addDataColumn('lost', String(lost), MARGIN.X + 120);
    this.addDataColumn('rateDecimal', String(rate / 100), MARGIN.X + 145);

    this.curHeight += 12;

    this.addDataColumn('totalQuoted', totalQuotedFormatted, MARGIN.X, 35);
    this.addDataColumn('totalWon', totalWonFormatted, MARGIN.X + 45);
    this.addDataColumn(
      'totalWonRate',
      String(totalWonRate / 100),
      MARGIN.X + 75
    );

    this.curHeight += 9;

    this.drawLine(0.3);

    this.curHeight += 6;

    this.nextRow(this.DEFAULT_ROW_HEIGHT, this.rowIndex, totalRows);
    this.rowIndex++;

    conversion.subQuoteConversionRates.forEach(
      (subQuotesConversion, subIndex) => {
        this.buildProductTypeSubQuoteRow(
          subQuotesConversion,
          conversion,
          subIndex,
          totalRows
        );
      }
    );
  }

  private buildProductTypeSubQuoteRow(
    subConversion: SanitizedQuoteConversionRate,
    companyStats: SanitizedQuoteConversionRate,
    subIndex: number,
    totalRows: number
  ) {
    const {
      name,
      quoted,
      expired,
      won,
      lost,
      rate,
      totalQuotedFormatted,
      totalWonFormatted,
      totalWonRate,
    } = subConversion;

    this.addDataColumn('user', name, MARGIN.X + 5, 30);
    this.addDataColumn('quoted', String(quoted), MARGIN.X + 45);
    this.addDataColumn('expired', String(expired), MARGIN.X + 75);
    this.addDataColumn('won', String(won), MARGIN.X + 98);
    this.addDataColumn('lost', String(lost), MARGIN.X + 120);
    this.addDataColumn('rateDecimal', String(rate / 100), MARGIN.X + 145);

    this.curHeight += 12;

    this.addDataColumn('totalQuoted', totalQuotedFormatted, MARGIN.X + 5, 30);
    this.addDataColumn('totalWon', totalWonFormatted, MARGIN.X + 45);
    this.addDataColumn(
      'totalWonRate',
      String(totalWonRate / 100),
      MARGIN.X + 75
    );

    this.curHeight += 9;

    this.drawLine(
      subIndex === companyStats.subQuoteConversionRates.length - 1 ? 0.3 : 0.1
    );

    this.curHeight += 6;

    this.nextRow(this.DEFAULT_ROW_HEIGHT, this.rowIndex, totalRows);
    this.rowIndex++;
  }

  protected getFilename(): string {
    const fromDate = this.startDate?.toFormat('LLddyyyy');
    const toDate = this.endDate?.toFormat('LLddyyyy');
    return `${PREFIX}_${this.t(
      `${NAMESPACE}.${this.byCompany ? 'byCompany' : 'byProductType'}`
    )}_${fromDate}-${toDate}.pdf`.replace(/ /g, '');
  }

  static generate = (
    data: SanitizedQuoteConversionRate[],
    originalQuotes: Quote[],
    filters: QuoteConversionRateFilters,
    t: TFunction,
    i18n: i18n
  ) => {
    const report = new quotesConversionRatePdf(
      data,
      originalQuotes,
      filters,
      t,
      i18n
    );
    report.generate();
  };
}

export default quotesConversionRatePdf;
