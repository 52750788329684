import { useTranslation } from 'react-i18next';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { MeasurementUnits } from '../../enums';
import { MeasurementUnitsToggleProps } from './MeasurmentUnitsToggle.props';

export const MeasurementUnitsToggle = ({
  units,
  setUnits,
}: MeasurementUnitsToggleProps) => {
  const { t } = useTranslation();

  return (
    <ToggleButtonGroup
      size="small"
      exclusive
      value={units}
      onChange={(_, value: MeasurementUnits) => setUnits(value)}
    >
      <ToggleButton value={MeasurementUnits.Metric}>
        {t('users.fields.units.metric')}
      </ToggleButton>
      <ToggleButton value={MeasurementUnits.Imperial}>
        {t('users.fields.units.imperial')}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
