import { useTranslation } from 'react-i18next';
import { Avatar, Box, Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { useMemo, useState } from 'react';
import { FormattedTimestamp, QuoteStatusBadge, Show } from '..';
import { QuoteStatus, QuoteTypeOfBuy } from '../../enums';
import { useAuthentication, useCurrentUser } from '../../hooks';
import { Quote } from '../../models';
import * as Sx from '../DecoratedHeader/DecoratedHeader.styles';
import { QuoteHeaderProps } from './QuoteHeader.props';
import { StyledContentStack, StyledStack } from './QuoteHeader.styles';

export const QuoteHeader = ({
  form,
  isNewQuote,
  onSave,
  navigateBack,
  saveButtonText,
  showSaveButton = false,
  units,
  setUnits,
}: QuoteHeaderProps) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const { isAdmin } = useAuthentication();
  const [isEditMode, setIsEditMode] = useState(false);

  const onEdit = () => {
    form.setItem({
      ...form.item,
      status: QuoteStatus.InProgress,
      quotedTime: null,
      expirationTime:
        form.item?.status === QuoteStatus.Expired
          ? null
          : form.item?.expirationTime,
      products: form.item?.products.map((product) => {
        return {
          ...product,
          purchaseStatus: undefined,
        };
      }),
    } as Quote);
    setIsEditMode(true);
  };

  const createdDateValue = useMemo(() => {
    if (form.item) {
      return form.item.createdByNCEmployee
        ? form.item.dateCreated
        : form.item.dateSubmitted;
    } else {
      return null;
    }
  }, [form.item]);

  const onCancel = () => {
    form.revert();
    setUnits(form.originalItem?.measurementUnits || units);
    setIsEditMode(false);
  };

  const showEditButton = useMemo(
    () =>
      Boolean(
        !isEditMode &&
          isAdmin &&
          (form.originalItem?.status === QuoteStatus.Quoted ||
            form.originalItem?.status === QuoteStatus.Expired)
      ),
    [form.originalItem, isAdmin, isEditMode]
  );

  const showDates = useMemo(
    () =>
      Boolean(
        isAdmin && (createdDateValue || form.item?.lastModified) && !isNewQuote
      ),
    [createdDateValue, form.item?.lastModified, isNewQuote, isAdmin]
  );

  return (
    <StyledStack showDates={showDates}>
      <Stack flexDirection="row" alignItems="center" boxSizing="border-box">
        <Avatar sx={Sx.avatar('monochrome')}>{<RequestQuoteIcon />}</Avatar>
        <Typography component="h1" variant="h1" color="primary" sx={Sx.title}>
          {isNewQuote
            ? t('quotes.edit.title.new')
            : form.item?.projectName &&
              form.item.typeOfBuy !== QuoteTypeOfBuy.InventoryBuy
            ? form.item.projectName
            : form.item?.quoteNumber
            ? form.item.quoteNumber
            : t('quotes.edit.title.edit')}
        </Typography>
        {!isNewQuote && form.originalItem?.status && (
          <QuoteStatusBadge
            status={form.originalItem?.status}
            text={t(`quotes.status.${form.originalItem?.status}`).toUpperCase()}
          />
        )}
      </Stack>

      <Box sx={{ flex: 1 }}>
        <StyledContentStack showDates={showDates}>
          <Show if={isAdmin}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              columnGap={1}
              justifyContent="space-around"
              sx={{ flex: 1, flexWrap: 'wrap' }}
            >
              <Show if={!!createdDateValue}>
                <Stack direction="row" alignItems="center" columnGap={0.5}>
                  <Typography variant="body2">
                    {t('quotes.edit.summary.quoteDetails.dateCreated')}
                  </Typography>
                  <Typography variant="body2">
                    <FormattedTimestamp
                      timestamp={createdDateValue || undefined}
                      timeZone={currentUser.timeZone}
                      showTime={false}
                    />
                  </Typography>
                </Stack>
              </Show>
              <Show if={!!form.item?.lastModified}>
                <Stack direction="row" alignItems="center" columnGap={0.5}>
                  <Typography variant="body2">
                    {t('quotes.edit.summary.quoteDetails.dateUpdated')}
                  </Typography>
                  <Typography variant="body2">
                    <FormattedTimestamp
                      timestamp={form.item?.lastModified}
                      timeZone={currentUser.timeZone}
                      showTime={false}
                    />
                  </Typography>
                </Stack>
              </Show>
            </Stack>
          </Show>

          <Stack
            direction={{ xs: 'column', md: 'row' }}
            columnGap={4}
            rowGap={{ xs: 1, md: 0 }}
            justifyContent="flex-end"
          >
            <Show if={showEditButton}>
              <Button
                sx={{
                  minWidth: '160px',
                  ...(isAdmin ? { maxWidth: { md: '160px' } } : {}),
                }}
                variant="outlined"
                onClick={onEdit}
              >
                {t('quotes.editQuote')}
              </Button>
            </Show>
            <Show if={showSaveButton && !isEditMode}>
              <Button
                sx={{
                  minWidth: '160px',
                  ...(isAdmin ? { maxWidth: { md: '160px' } } : {}),
                }}
                variant="outlined"
                onClick={onSave}
              >
                {saveButtonText}
              </Button>
            </Show>
            <Show if={form.dirty}>
              <Button
                onClick={onCancel}
                variant="contained"
                disabled={form.saving}
              >
                {t('forms.cancel')}
              </Button>
            </Show>
            <Show if={!form.dirty}>
              <Button
                fullWidth
                sx={{ ...(isAdmin ? { maxWidth: { md: '180px' } } : {}) }}
                variant="contained"
                color="primary"
                onClick={navigateBack}
              >
                {t('quotes.edit.back')}
              </Button>
            </Show>
          </Stack>
        </StyledContentStack>
      </Box>
    </StyledStack>
  );
};
