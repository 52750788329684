import { Stack, styled, StackProps } from '@mui/material';

interface StyleStackProps extends StackProps {
  showDates: boolean;
}

export const StyledStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'showDates',
})<StyleStackProps>(({ theme, showDates }) => {
  return {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    borderBottom: '1px solid',
    borderColor: theme.palette.grey[300],
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),

    [theme.breakpoints.down('lg')]: {
      ...(showDates && {
        '& > .MuiStack-root': {
          width: '100%',
        },
      }),
    },

    [theme.breakpoints.down('md')]: {
      rowGap: theme.spacing(2),
      paddingBottom: theme.spacing(2),

      '& > .MuiStack-root': {
        width: '100%',
      },
    },
  };
});

export const StyledContentStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'showDates',
})<StyleStackProps>(({ theme, showDates }) => {
  return {
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: showDates ? 'space-between' : 'flex-end',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      rowGap: theme.spacing(1),
    },

    [theme.breakpoints.up('md')]: {
      columnGap: theme.spacing(2),

      '& .MuiStack-root:last-of-type': {
        alignItems: 'center',
      },
    },
  };
});
