import { useMemo } from 'react';
import { useCompany, useCurrentUser } from '../../hooks';
import { getSelectableCompanies } from '../../utils';
import { QuoteConversionRateFiltersProps } from './QuoteConversionRateFilters.props';
import { QuoteConversionRateFilters } from './QuoteConversionRateFilters.component';

export const AgentQuoteConversionRateFilters = (
  props: Omit<QuoteConversionRateFiltersProps, 'companies' | 'loadingCompanies'>
) => {
  const currentUser = useCurrentUser();
  const { item: currentCompany } = useCompany(currentUser.companyRef.id);

  const selectableCompanies = useMemo(
    () =>
      currentCompany?.associatedCompanies
        ? getSelectableCompanies(
            Object.values(currentCompany.associatedCompanies)
          )
        : [],
    [currentCompany]
  );

  return (
    <QuoteConversionRateFilters
      {...props}
      companies={selectableCompanies}
      loadingCompanies={false}
    />
  );
};
