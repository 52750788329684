import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

import { Outlet } from 'react-router-dom';
import {
  Container,
  DecoratedHeader,
  OrderFilters,
  OrderTable,
  UnassignedDocuments,
} from '../../components';
import { getPageTitle } from '../../utils/pages';
import {
  useCompanies,
  usePageTitle,
  useRouterOutlet,
  useUnmappedFiles,
} from '../../hooks/';
import { Filter, OrderFilters as OrderFiltersType } from '../../types';
import { Tab } from '../../navigation';
import { RequirePermission } from '../../security';
import { CompanyType, OrdersPageView, UserPermission } from '../../enums';

interface Props {
  view?: OrdersPageView;
}

const Orders: React.FC<Props> = ({ view = OrdersPageView.OrdersList }) => {
  const { t } = useTranslation();
  usePageTitle(
    view === OrdersPageView.OrdersList
      ? getPageTitle('orders')
      : getPageTitle('unassignedDocuments')
  );
  const { showOutletOnly } = useRouterOutlet();

  const [filters, setFilters] = useState<OrderFiltersType>({
    companyId: null,
    searchField: null,
  });

  const { list: companies } = useCompanies({
    filters: [['type', '==', CompanyType.Customer] as Filter],
  });

  const { list: unmappedFiles } = useUnmappedFiles();

  return (
    <RequirePermission oneOf={[UserPermission.ViewOrders]}>
      {showOutletOnly || (
        <>
          <Container>
            <DecoratedHeader
              icon={<FolderOpenIcon />}
              title={t('pages.orders.pageTitle')}
              variant="colored"
            >
              <Tab title={t('orders.ordersList')} to={'/admin/orders'} />
              <Tab
                title={t('unassignedDocuments.title', {
                  count: unmappedFiles.length,
                })}
                to={'/admin/orders/unassigned'}
                withBadge={unmappedFiles.length > 0}
              />
            </DecoratedHeader>
          </Container>

          {view === OrdersPageView.OrdersList ? (
            <>
              <Container>
                <OrderFilters
                  companies={companies}
                  filters={filters}
                  setFilters={setFilters}
                />
              </Container>
              <OrderTable filters={filters} />
            </>
          ) : (
            <UnassignedDocuments />
          )}
        </>
      )}
      <Outlet />
    </RequirePermission>
  );
};

export default Orders;
