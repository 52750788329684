import { TextField, Grid, Typography, Button, Card, Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomLeadTimeRowCollapseProps } from './CustomLeadTimeRowCollapse.props';

export const CustomLeadTimeRowCollapse = ({
  mode,
  product,
  products,
  setMode,
  setProducts,
}: CustomLeadTimeRowCollapseProps) => {
  const { t } = useTranslation();
  const [customLeadTime, setCustomLeadTime] = useState(
    product.customLeadTime ?? ''
  );

  return mode === 'edit' ? (
    <Card
      variant="outlined"
      sx={{ width: '485px', m: 1, p: 2, borderRadius: '6px' }}
    >
      <TextField
        fullWidth
        value={customLeadTime}
        onChange={(e) => setCustomLeadTime(e.target.value)}
        label={t('quotes.edit.products.table.labels.customLeadTime')}
        sx={{
          mt: 1.5,
        }}
        InputLabelProps={{ shrink: true }}
        inputProps={{ maxLength: 255 }}
      />
      <Grid
        container
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
        margin={1}
      >
        <Button
          variant="contained"
          sx={{ mr: 1 }}
          onClick={(e) => {
            e.preventDefault();
            if (setProducts && products && setMode) {
              setProducts(
                products.map((p) => {
                  if (p.id === product.id && p.lineItem === product.lineItem) {
                    return {
                      ...p,
                      customLeadTime: customLeadTime ?? null,
                    };
                  }
                  return p;
                })
              );
              setMode('view');
            }
          }}
        >
          {t('quotes.edit.products.table.actions.customLeadTime.save')}
        </Button>
        <Button
          variant="outlined"
          sx={{ mr: 1 }}
          onClick={(e) => {
            e.preventDefault();
            if (setMode) {
              setMode('view');
            }
          }}
        >
          {t('quotes.edit.products.table.actions.customLeadTime.cancel')}
        </Button>
      </Grid>
    </Card>
  ) : (
    <Box mx={2} mt={1} mb={1}>
      <Typography variant="body1">{product.customLeadTime}</Typography>
    </Box>
  );
};
