import { useTranslation } from 'react-i18next';

import { Container, Show } from '../../components';
import { Tab } from '../../navigation';
import { NeedsPermission } from '../../security';
import { UserPermission } from '../../enums';
import { useAuthentication } from '../../hooks';

interface Props {
  children: React.ReactNode;
  showTabs?: boolean;
}

const ConversionRatePartial = ({ children, showTabs }: Props) => {
  const { t } = useTranslation();
  const { isAdmin } = useAuthentication();
  const routePrefix = isAdmin ? 'admin' : 'agent';

  return (
    <>
      <Container>
        <Show if={!!showTabs}>
          <NeedsPermission
            oneOf={[
              isAdmin
                ? UserPermission.ViewReservationReports
                : UserPermission.ViewReservationConversionRatesReports,
            ]}
          >
            <Tab
              title={t('reports.reservationConversionRate.reservationTitle')}
              to={`/${routePrefix}/reports/conversion-rate/reservation`}
            />
          </NeedsPermission>
          <NeedsPermission oneOf={[UserPermission.ViewQuoteReports]}>
            <Tab
              title={t('reports.quoteConversionRate.title')}
              to={`/${routePrefix}/reports/conversion-rate/quote`}
            />
          </NeedsPermission>
        </Show>
      </Container>
      {children}
    </>
  );
};

export default ConversionRatePartial;
