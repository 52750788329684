import { useTranslation } from 'react-i18next';
import { TextField } from '../..';
import { useMapSubform } from '../../../hooks';
import { Formable } from '../../../types';
import { Quote, QuoteProduct } from '../../../models';

interface props {
  form: Formable<Quote>;
  index: number;
}

export const CustomDescription = ({ form, index }: props) => {
  const { t } = useTranslation();
  const subform = useMapSubform(form, 'products', index, {} as QuoteProduct);

  return (
    <TextField
      form={subform}
      forceDelay
      field="description"
      required
      fullWidth
      maxLength={60}
      label=""
      displayCustomError={
        (form.errors as Record<string, string>)[
          `products[${index}].description`
        ] !== undefined
      }
      placeholder={t('quotes.edit.products.table.labels.customDescription')}
      customStyling={{
        my: 0,
        minWidth: { md: '160px', lg: '180px' },
        maxWidth: { xs: '70%', md: '100%' },
      }}
      customInputId={`description-${index}`}
    />
  );
};
