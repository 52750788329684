import { Autocomplete, Grid, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useTranslation } from 'react-i18next';
import { useAuthentication, useCurrentUser } from '../../hooks';
import { QuotesFiltersSubProps } from './QuotesFilters.props';
import { QuotesFiltersRequestorsByCompany } from './QuotesFiltersRequestorsByCompany.component';

export const QuotesFiltersRequestors = ({
  filters,
  setFilters,
}: QuotesFiltersSubProps) => {
  const { t } = useTranslation();
  const { isAdmin } = useAuthentication();
  const currentUser = useCurrentUser();

  const companyId = isAdmin ? filters.companyId : currentUser.companyRef.id;

  return (
    <Grid item xs={12} sx={{ flexBasis: { md: '15%' } }}>
      {!companyId ? (
        <Autocomplete
          disabled
          popupIcon={<KeyboardArrowDownIcon />}
          options={[]}
          sx={{ flex: '1 1 0' }}
          renderInput={(params) => (
            <TextField {...params} label={t('quotes.filters.requestor')} />
          )}
        />
      ) : (
        <QuotesFiltersRequestorsByCompany
          filters={filters}
          setFilters={setFilters}
          companyId={companyId}
        />
      )}
    </Grid>
  );
};
