import { Stack, Typography, useTheme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { sumBy } from 'lodash';
import { useMemo } from 'react';
import { StyledTableCell } from '../../../../tables';
import { Reel } from '../../../../models';
import { ReelQuantity } from '../../../ReelQuantity/ReelQuantity.component';
import { InventoryTableGroupHeaderProps } from './InventoryTableGroupHeader.props';
import * as Sx from './InventoryTableGroupHeader.styles';

export const InventoryTableGroupHeader = ({
  groupedData,
  isOpen,
  measureUnits,
  isMultipleReservations,
}: InventoryTableGroupHeaderProps) => {
  const { palette } = useTheme();

  const renderGroupKey = () => (
    <Stack direction="row" spacing={1}>
      <Typography fontWeight="700">{`${groupedData[0].description} - ${groupedData[0].cableRef.id}`}</Typography>
      <Typography fontWeight="700">{`(${groupedData.length})`}</Typography>
    </Stack>
  );

  const sumItem = useMemo(() => {
    return {
      numberOfPieces: sumBy(groupedData, 'numberOfPieces'),
      quantity: sumBy(groupedData, 'quantity'),
      minimumLength: sumBy(groupedData, 'minimumLength'),
      maximumLength: sumBy(groupedData, 'maximumLength'),
      packageLength: groupedData[0].packageLength, // should be the same for each item in the same group
    };
  }, [groupedData]);

  return (
    <>
      <StyledTableCell
        key="groupKey"
        colSpan={isMultipleReservations ? 2 : 1}
        sx={Sx.headerCell(isOpen)}
      >
        {renderGroupKey()}
      </StyledTableCell>
      <StyledTableCell
        key="quantity"
        colSpan={5}
        sx={{
          ...Sx.headerCell(isOpen),
          borderBottom: `1px solid ${
            isOpen ? palette.secondary.light : '#BBBBBBBB'
          } !important`,
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography fontWeight="700">
            <ReelQuantity item={sumItem as Reel} unitsType={measureUnits} />
          </Typography>
          <KeyboardArrowDownIcon
            key="expand"
            sx={{
              transform: `rotate(${isOpen ? '-180deg' : '0'})`,
              transition: 'transform 0.1s',
            }}
          />
        </Stack>
      </StyledTableCell>
    </>
  );
};
