import Box from '@mui/material/Box';
import { LayoutProps } from './Layout.props';

export const Layout = ({ children }: LayoutProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      maxHeight: '100vh',
    }}
  >
    {children}
  </Box>
);
