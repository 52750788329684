import { Autocomplete, TextField } from '@mui/material';
import { doc } from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { InventoryType } from '../../enums';
import { useUsers } from '../../hooks';
import { Filter } from '../../types';
import { input } from '../../pages/admin/modals/ImpersonateCustomer/ImpersonateCustomer.styles';
import { User } from '../../models';
import { UserSelectProps } from './UserSelect.props';

export const UserSelect = ({
  companyId,
  fromCollapse,
  inventoryType,
  onSelect,
  user,
  allowFreeSolo,
  textFieldProps,
}: UserSelectProps) => {
  const firestore = useFirestore();

  const userFilters = useMemo(() => {
    const queryFilters = [] as Filter[];
    const companyRef = doc(firestore, 'Companies', companyId ?? '');

    queryFilters.push(['status', '==', 'active']);
    queryFilters.push(['companyRef', '==', companyRef]);
    queryFilters.push(['isNCEmployee', '==', false]);

    if (fromCollapse && inventoryType === InventoryType.CSA) {
      queryFilters.push(['canSeeCSA', '==', true]);
    }

    if (fromCollapse && inventoryType === InventoryType.UL) {
      queryFilters.push(['canSeeUL', '==', true]);
    }

    return queryFilters;
  }, [companyId, firestore, fromCollapse, inventoryType]);

  const { list: users } = useUsers({
    includeDeleted: false,
    filters: userFilters,
  });

  const onUserSelectChange = (value: string | User | null) => {
    if (typeof value === 'string' && allowFreeSolo) {
      const existingUser = users.find(
        (u) => u.name?.trim()?.toLowerCase() === value.trim().toLowerCase()
      );
      onSelect(existingUser ?? value);
    } else if (typeof value !== 'string') {
      onSelect(value);
    }
  };

  return (
    <Autocomplete
      fullWidth
      freeSolo={allowFreeSolo}
      disabled={(!companyId || users.length === 0) && !allowFreeSolo}
      popupIcon={<KeyboardArrowDownIcon />}
      options={users ?? []}
      value={user}
      onChange={(e, value) => onUserSelectChange(value)}
      getOptionLabel={(user) =>
        typeof user === 'string' ? user : user.name || ''
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          sx={input}
          onChange={
            allowFreeSolo
              ? (e) => onUserSelectChange(e.target.value)
              : undefined
          }
          {...textFieldProps}
        />
      )}
    />
  );
};
