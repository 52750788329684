import { DatePicker } from '@mui/x-date-pickers';
import { Timestamp } from 'firebase/firestore';
import { t } from 'i18next';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Quote as QuoteModel } from '../../../../models';
import { useFormFieldValue } from '../../../../hooks';

import { dateToYearMonth, yearMonthToDate } from '../../../../utils';
import { QuoteDatePickerProps } from './QuoteDatePicker.props';

type QuoteDate = Timestamp | number | null;

export const QuoteDatePicker = ({
  field,
  form,
  minDate = DateTime.now().startOf('day'),
  isMobile = false,
}: QuoteDatePickerProps) => {
  const { value, setValue } = useFormFieldValue<QuoteModel, QuoteDate>(
    form,
    field
  );

  const isYearMonth = field === 'shippingYearMonth';

  const formattedValue = useMemo(() => {
    if (!value) {
      return null;
    } else {
      return typeof value === 'number'
        ? yearMonthToDate(value)
        : DateTime.fromJSDate(value.toDate());
    }
  }, [value]);

  const updateFieldFunction = (
    value: DateTime | null,
    setValue: (value: QuoteDate) => void
  ) => {
    setValue(
      value === null
        ? null
        : isYearMonth
        ? dateToYearMonth(value)
        : Timestamp.fromDate(value.endOf('day').toJSDate())
    );
  };

  const onDateChange = (
    newDate: DateTime | null,
    setValue: (value: QuoteDate) => void
  ) => {
    if (!newDate || newDate.invalidReason || newDate.invalidExplanation) {
      const today = DateTime.now();
      updateFieldFunction(isYearMonth ? null : today, setValue);
    } else {
      const formDate = form.item?.[field];
      const dateIsSame =
        !!formDate && isYearMonth
          ? formDate === dateToYearMonth(newDate)
          : newDate?.hasSame(
              DateTime.fromJSDate((formDate as Timestamp)?.toDate()),
              'day'
            );

      if (!formDate || !dateIsSame) {
        updateFieldFunction(newDate, setValue);
      }
    }
  };

  return (
    <DatePicker
      maxDate={DateTime.now().plus({ years: 19 })}
      openTo={isYearMonth ? 'month' : 'day'}
      views={isYearMonth ? ['year', 'month'] : undefined}
      value={formattedValue}
      label={t(
        `quotes.edit.summary.quoteDetails.${field}.${
          isMobile ? 'short' : 'label'
        }`
      )}
      minDate={minDate}
      onChange={(value) => onDateChange(value, setValue)}
      showDaysOutsideCurrentMonth
      closeOnSelect
      slotProps={{
        toolbar: {
          hidden: true,
        },
        actionBar: {
          actions: ['accept'],
        },
        textField: {
          required: true,
          fullWidth: true,
          sx: { flex: '1 1 0' },
          error: !!form.errors[field],
          helperText: t(form.errors[field] || ''),
        },
      }}
    />
  );
};
