import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UnderlinedLink } from '../UnderlinedLink/UnderlinedLink.component';
import { ContrastButtonMain } from '../ContrastButton/ContrastButton.component';
import { SecondFactorVerificationInput } from '..';
import { TwoFactorCodeVerificationProps } from './TwoFactorCodeVerification.props';

export const TwoFactorCodeVerification = ({
  phoneNumber,
  onBackClick,
  onSendCode,
  onVerifyCode,
  shouldRedirectOnSuccess = true,
}: TwoFactorCodeVerificationProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [verificationCode, setVerificationCode] = useState('');
  const [verificationError, setVerificationError] = useState('');

  const handleCodeSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!verificationCode || verificationCode.length !== 6) {
      return;
    }
    try {
      await onVerifyCode(verificationCode);
      if (shouldRedirectOnSuccess) {
        navigate('/admin/dashboard');
      }
    } catch (error) {
      setVerificationError(t('twoFactorAuth.verification.error'));
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleCodeSubmit}
      noValidate
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="body1" sx={{ mb: 2 }}>
        {t('twoFactorAuth.verification.description', {
          phoneNumber: phoneNumber.replace(/.(?=.{4})/g, '*'),
        })}
      </Typography>
      <SecondFactorVerificationInput
        code={verificationCode}
        setCode={setVerificationCode}
      />
      <Typography variant="caption" sx={{ my: 1 }}>
        {t('twoFactorAuth.verification.didntGetCode')}
        <UnderlinedLink onClick={onSendCode}>
          {t('twoFactorAuth.verification.resendCode')}
        </UnderlinedLink>
      </Typography>
      {verificationError && (
        <Typography variant="body1" color="error" sx={{ my: 1 }}>
          {verificationError}
        </Typography>
      )}
      <Grid
        container
        flexDirection="row"
        justifyContent={onBackClick ? 'space-between' : 'center'}
        spacing={1}
        mt={2}
      >
        {onBackClick && (
          <Grid item xs={6}>
            <Button variant="outlined" fullWidth onClick={onBackClick}>
              {t('twoFactorAuth.verification.back')}
            </Button>
          </Grid>
        )}
        <Grid item xs={6} display="flex" justifyContent="center">
          <ContrastButtonMain
            variant="contained"
            type="submit"
            fullWidth
            disabled={!verificationCode || verificationCode.length !== 6}
          >
            {t('twoFactorAuth.verification.submit')}
          </ContrastButtonMain>
        </Grid>
      </Grid>
    </Box>
  );
};
