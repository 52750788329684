import { useMemo } from 'react';
import { sortBy } from 'lodash';
import { useCompany, useCurrentUser } from '../../hooks';
import { Company } from '../../models';
import { ReservationConversionRateFiltersProps } from './ReservationConversionRateFilters.props';
import { ReservationConversionRateFilters } from './ReservationConversionRateFilters.component';

export const AgentReservationConversionRateFilters = (
  props: Omit<
    ReservationConversionRateFiltersProps,
    'companies' | 'loadingCompanies'
  >
) => {
  const currentUser = useCurrentUser();
  const { item: currentCompany } = useCompany(currentUser.companyRef.id);

  const companies = useMemo(
    (): Partial<Company>[] =>
      sortBy(
        Object.values(currentCompany?.associatedCompanies ?? [])
          .filter((c) => !c.isDeleted)
          .map((c) => {
            return {
              id: c.companyId,
              name: c.companyName,
              accountNumber: c.companyAccountNumber,
              isDeleted: c.isDeleted,
              customerAddresses: c.customerAddresses,
            };
          }),
        'name'
      ),
    [currentCompany]
  );

  return (
    <ReservationConversionRateFilters
      {...props}
      companies={companies as Company[]}
    />
  );
};
