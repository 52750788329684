import { useState } from 'react';
import { Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { FilterContainer } from '..';
import { useLocalStorage } from '../../hooks';
import { ReservationConversionRateFilters as Filters } from '../../types';
import { MultiSelect } from '../MultiSelect/MultiSelect.component';
import { ReservationConversionRateFiltersProps } from './ReservationConversionRateFilters.props';

const emptyFilters = {
  companyIds: null,
  startDate: DateTime.now().plus({ month: -1 }),
  endDate: DateTime.now(),
};

export const ReservationConversionRateFilters = ({
  onSearch,
  loadedFilters,
  companies,
  loadingCompanies,
}: ReservationConversionRateFiltersProps) => {
  const { setItem: setExpandedCompanies } = useLocalStorage<string[]>(
    'reservationConversionRates',
    'expandedCompanies'
  );
  const { t } = useTranslation();
  const [filters, setFilters] = useState<Filters>(
    loadedFilters || emptyFilters
  );

  const clearFilters = () => {
    setFilters(emptyFilters);
    setExpandedCompanies([]);
    onSearch(null);
  };

  return (
    <FilterContainer
      title={t('reports.reservationConversionRate.reservationTitle')}
      buttonsDisabled={!filters.startDate || !filters.endDate}
      onSearch={() => onSearch(filters)}
      onClear={() => clearFilters()}
    >
      <Grid
        sx={{
          my: { xs: 2, md: 4 },
        }}
      >
        <Grid display="flex" justifyContent="space-evenly" gap="1em">
          <DatePicker
            label={t('reports.reservationConversionRate.startDate')}
            value={filters.startDate}
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
              textField: {
                fullWidth: true,
                required: true,
                sx: { flex: '1 1 0' },
              },
            }}
            onChange={(value) =>
              setFilters((prev) => {
                return { ...prev, startDate: value };
              })
            }
            format={t('formats.date')}
            maxDate={filters.endDate || DateTime.now()}
          />

          <DatePicker
            label={t('reports.reservationConversionRate.endDate')}
            value={filters.endDate}
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
              textField: {
                fullWidth: true,
                required: true,
                sx: { flex: '1 1 0' },
              },
            }}
            onChange={(value) =>
              setFilters((prev) => {
                return { ...prev, endDate: value };
              })
            }
            format={t('formats.date')}
            minDate={filters.startDate || undefined}
            maxDate={DateTime.now()}
          />

          <MultiSelect
            selectedOptionIds={filters.companyIds}
            options={companies}
            displayKey="name"
            idKey="id"
            label={t('reports.reservationConversionRate.company')}
            selectedLabel={t(
              'reports.reservationConversionRate.companySelected',
              {
                count: filters.companyIds?.length || 0,
              }
            )}
            loadingOptions={loadingCompanies}
            onChange={(companyIds) => {
              setFilters((prev) => {
                return { ...prev, companyIds };
              });
            }}
          />
        </Grid>
      </Grid>
    </FilterContainer>
  );
};
