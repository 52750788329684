/* eslint-disable max-len */

const TwoFactorIcon: React.FC = () => (
  <svg
    width="148"
    height="144"
    viewBox="0 0 148 144"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="74"
      cy="69.5"
      rx="61"
      ry="59.5"
      fill="#F6992B"
      fillOpacity="0.12"
    />
    <path
      d="M74.0472 32C61.681 32 53.6643 40.076 44.1841 43.02C40.3279 44.22 38.3998 44.816 37.6187 45.66C36.8375 46.5 36.6114 47.736 36.1551 50.2C31.2629 76.584 41.9518 100.976 67.4407 110.472C70.1745 111.492 71.5435 112 74.0595 112C76.5755 112 77.9487 111.488 80.6867 110.468C106.171 100.976 116.848 76.584 111.956 50.2C111.499 47.736 111.269 46.5 110.488 45.656C109.707 44.812 107.783 44.216 103.927 43.02C94.4424 40.076 86.4134 32 74.0472 32Z"
      stroke="#E68008"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.8591 63.8359C66.8591 63.5959 66.892 62.2119 66.9002 60.4759C66.9002 58.8839 66.7604 57.3559 67.5415 55.9559C70.4604 50.2999 78.8471 50.8759 80.9191 56.6359C81.2767 57.5839 81.3014 59.0839 81.2891 60.4759C81.2767 62.2479 81.3137 63.8359 81.3137 63.8359M67.2167 65.5199C62.7767 65.5199 60.5567 68.6399 60.0634 70.5599C59.5701 72.4799 59.5701 79.4399 59.8661 82.3199C60.8527 85.9199 63.3194 87.4079 65.7367 87.8879C67.9567 88.0799 77.3301 88.0079 80.0434 88.0079C83.9901 88.0799 86.9501 86.6399 88.1834 82.3199C88.4301 80.8799 88.6767 72.9599 88.0601 70.5599C86.7527 66.7199 83.4967 65.5199 81.0301 65.5199H67.2167Z"
      stroke="#E68008"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TwoFactorIcon;
