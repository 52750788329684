import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AuthError,
  AuthErrorCodes,
  MultiFactorError,
  MultiFactorResolver,
  ParsedToken,
  PhoneAuthProvider,
  PhoneInfoOptions,
  PhoneMultiFactorGenerator,
  User as AuthUser,
  MultiFactorSession,
  PhoneMultiFactorInfo,
} from 'firebase/auth';

import { doc, getDoc, setDoc, Timestamp } from 'firebase/firestore';
import { FirebaseError } from 'firebase/app';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import hooks from '../firebase/hooks';
import authFunctions from '../firebase/auth';
import clearFirestoreCache from '../firebase/cache';
import { UserRole, UserRoleCategory } from '../enums/';
import { roles } from '../security';
import {
  CurrentUserContext,
  CurrentUserContextValue,
} from '../providers/CurrentUserProvider';
import { DIDOMI_STORAGE_KEYS } from '../components/ConsentNotice/constants';

export interface LoginResult {
  type:
    | 'success'
    | 'failure'
    | 'disabled'
    | 'mfa-required'
    | 'too-many-attempts';
  mfaPhoneNumber?: string;
}

export const usePublicAuthentication = () => {
  const auth = hooks.useAuth();
  const functions = useFunctions();
  const logUserActivity = httpsCallable(functions, 'logUserActivity');
  const { user, logout, loading } = useAuthentication();

  const [mfaResolver, setMfaResolver] = useState<MultiFactorResolver | null>(
    null
  );
  const [verificationId, setVerificationId] = useState('');

  const sendSmsMfaCode = useCallback(async () => {
    const phoneInfoOptions = {
      multiFactorHint: mfaResolver?.hints[0],
      session: mfaResolver?.session,
    } as PhoneInfoOptions;
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    const verifId = await phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      window.recaptchaVerifier
    );
    setVerificationId(verifId);
  }, [auth, mfaResolver?.hints, mfaResolver?.session]);

  const verifyMfaCode = useCallback(
    async (verificationCode: string) => {
      if (!mfaResolver || !verificationId) {
        throw new Error();
      }
      const cred = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      const result = await mfaResolver.resolveSignIn(multiFactorAssertion);

      logUserActivity({
        email: result.user.email,
        success: 'success',
      });
    },
    [logUserActivity, mfaResolver, verificationId]
  );

  const login = useCallback(
    async (username: string, password: string): Promise<LoginResult> => {
      try {
        clearFirestoreCache();
        await authFunctions.signInWithEmailAndPassword(
          auth,
          username,
          password
        );
      } catch (error) {
        const authError = error as AuthError;
        if (authError.code === AuthErrorCodes.USER_DISABLED) {
          return { type: 'disabled' };
        }
        if (authError.code === AuthErrorCodes.MFA_REQUIRED) {
          const resolver = authFunctions.getMultiFactorResolver(
            auth,
            authError as MultiFactorError
          );
          setMfaResolver(resolver);
          const phoneNumber = (resolver?.hints[0] as PhoneMultiFactorInfo)
            .phoneNumber;
          const phoneInfoOptions = {
            multiFactorHint: resolver?.hints[0],
            session: resolver?.session,
          } as PhoneInfoOptions;
          const phoneAuthProvider = new PhoneAuthProvider(auth);
          try {
            const verifId = await phoneAuthProvider.verifyPhoneNumber(
              phoneInfoOptions,
              window.recaptchaVerifier
            );
            setVerificationId(verifId);
          } catch (e) {
            const fbError = e as FirebaseError;
            if (fbError.code === AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER) {
              return { type: 'too-many-attempts' };
            }
            throw e;
          }
          return { type: 'mfa-required', mfaPhoneNumber: phoneNumber };
        }
        return { type: 'failure' };
      }
      return { type: 'success' };
    },
    [auth]
  );

  const verifyPasswordResetCode = useCallback(
    (oobCode: string) => authFunctions.verifyPasswordResetCode(auth, oobCode),
    [auth]
  );

  const verifyEmail = useCallback(
    (oobCode: string) => authFunctions.applyActionCode(auth, oobCode),
    [auth]
  );

  return {
    user,
    login,
    sendSmsMfaCode,
    verifyEmail,
    verifyMfaCode,
    verifyPasswordResetCode,
    logout,
    loading,
  };
};

export const useAuthentication = () => {
  const auth = hooks.useAuth();
  const firestore = hooks.useFirestore();
  const multiFactor = authFunctions.multiFactor;

  const { status, data } = hooks.useSigninCheck();

  const [claims, setClaims] = useState<ParsedToken | null>(null);
  const [multiFactorSession, setMultiFactorSession] = useState<
    MultiFactorSession | undefined
  >(undefined);
  const [verificationId, setVerificationId] = useState('');

  useEffect(() => {
    if (auth.currentUser && !multiFactorSession) {
      multiFactor(auth.currentUser)
        .getSession()
        .then((session) => {
          setMultiFactorSession(session);
        });
    }
  }, [auth.currentUser, multiFactor, multiFactorSession]);

  const phoneAuthProvider = useMemo(() => new PhoneAuthProvider(auth), [auth]);
  const user = useMemo(() => data?.user, [data]);
  const loading = useMemo(() => status === 'loading', [status]);
  const role = useMemo(() => claims?.role as UserRole, [claims]);
  const userRoleCategory = useMemo(() => {
    if (roles.AdminRoles.includes(role)) {
      return UserRoleCategory.Admin;
    }
    if (roles.AgentRoles.includes(role)) {
      return UserRoleCategory.Agent;
    }

    return UserRoleCategory.Customer;
  }, [role]);
  const multiFactorUser = useMemo(
    () => (user ? multiFactor(user) : null),
    [user, multiFactor]
  );

  const enrollSmsSecondFactor = useCallback(
    async (verificationCode: string, phoneNumber: string) => {
      const cred = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      await multiFactor(auth.currentUser as AuthUser).enroll(
        multiFactorAssertion,
        'SMS'
      );
      await setDoc(
        doc(firestore, 'Users', user?.uid || ''),
        {
          phoneSecondFactorLastDigits: phoneNumber.slice(-4),
        },
        { merge: true }
      );
    },
    [auth.currentUser, firestore, multiFactor, user?.uid, verificationId]
  );

  const sendSmsVerificationCode = useCallback(
    async (phoneNumber: string) => {
      const phoneInfoOptions = {
        phoneNumber:
          (phoneNumber.startsWith('+1') ? '+' : '+1') +
          phoneNumber.replace(/\D/g, ''),
        session: multiFactorSession,
      } as PhoneInfoOptions;
      const verifId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        window.recaptchaVerifier
      );
      setVerificationId(verifId);
    },
    [multiFactorSession, phoneAuthProvider]
  );

  useEffect(() => {
    let isMounted = true;
    user?.getIdTokenResult().then((tokenResult) => {
      if (isMounted && tokenResult.claims) {
        setClaims(tokenResult.claims);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [user]);

  const logout = useCallback(async () => {
    clearFirestoreCache();
    clearStorage();
    await authFunctions.signOut(auth);
  }, [auth]);

  const clearStorage = () => {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && !DIDOMI_STORAGE_KEYS.includes(key)) {
        localStorage.removeItem(key);
      }
    }
  };

  const checkTokenRevocation = useCallback(async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const token = await user.getIdTokenResult(false);
        const revokeTimeRef = doc(
          firestore,
          `/PortalMetadata/portal_metadata/userRevokeTimestamps/${user.uid}`
        );
        const revokeTimeDoc = (await getDoc(revokeTimeRef)).data() as
          | { revokeTimestamp: Timestamp }
          | undefined;

        const authTime = token.claims.auth_time as number | undefined;
        if (
          revokeTimeDoc &&
          authTime &&
          revokeTimeDoc.revokeTimestamp.toMillis() > authTime * 1000
        ) {
          await logout();
        }
      } catch (error) {
        if (
          error instanceof FirebaseError &&
          error.code === 'auth/id-token-revoked'
        ) {
          await logout();
        }
      }
    }
  }, [auth.currentUser, firestore, logout]);

  const refresh = useCallback(() => {
    user?.getIdTokenResult(true).then((tokenResult) => {
      if (tokenResult.claims) {
        setClaims(tokenResult.claims);
      }
    });
  }, [user]);

  return {
    checkTokenRevocation,
    enrollSmsSecondFactor,
    loading,
    logout,
    refresh,
    sendSmsVerificationCode,
    role,
    isAdmin: roles.AdminRoles.includes(role),
    isAgent: roles.AgentRoles.includes(role),
    isCustomer: roles.CustomerRoles.includes(role),
    multiFactorUser,
    user,
    userRoleCategory,
  };
};

export default function AuthConsumer(): CurrentUserContextValue {
  return React.useContext(CurrentUserContext) as CurrentUserContextValue;
}
