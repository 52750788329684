export enum Region {
  // New Brunswick, Nova Scotia, Prince Edward Island, Newfoundland and Labrador
  Atlantic = 'atlantic',
  // Quebec
  Quebec = 'quebec',
  // Ontario
  Ontario = 'ontario',
  // Manitoba, Saskatchewan, Alberta, British Columbia, NWT, Yukon, Nunavut
  Western = 'western',
  // All US states
  UnitedStates = 'us',
}
