import { Box, Container } from '@mui/material';
import { styled } from '@mui/system';

export const TwoFactorIconContainer = styled(Box)(() => {
  return {
    marginBottom: 40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  };
});

export const TwoFactorLayoutContainer = styled(Container)(() => {
  return {
    width: '555px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };
});
