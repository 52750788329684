import { useCallback, useEffect, useState } from 'react';
import { Nullable } from '../types';

export type storageType =
  | 'inventory'
  | 'inventorySearches'
  | 'reservation'
  | 'reservationConversionRates'
  | 'reelReturns'
  | 'searchLog'
  | 'quoteConversionRates'
  | 'quoteFilters';

export function useLocalStorage<T>(storageKey: storageType, subKey?: string) {
  const key = `${storageKey}:${subKey}`;
  const [data, setData] = useState<Nullable<T> | undefined>(undefined);
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    getItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  useEffect(() => {
    setLoaded(data !== undefined);
  }, [data]);

  const getItem = (): Nullable<T> => {
    const data = JSON.parse(localStorage.getItem(key) as string);

    setData(data);
    return data;
  };

  const removeItem = useCallback((): void => {
    setData(null);
    localStorage.removeItem(key);
  }, [key]);

  const setItem = useCallback(
    (data: Nullable<T>): void => {
      if (data === null) {
        return removeItem();
      }

      setData(data);
      localStorage.setItem(key, JSON.stringify(data));
    },
    [key, removeItem]
  );

  return {
    getItem,
    item: data ?? null,
    key,
    isLoaded,
    removeItem,
    setItem,
  };
}
