import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { UnderlinedLinksProps } from './UnderlinedLink.props';

export const UnderlinedLink = ({
  to,
  onClick,
  children,
  openInNewTab,
}: UnderlinedLinksProps) => (
  <Link
    {...(to ? { to } : {})}
    {...(to ? { component: RouterLink } : {})}
    onClick={onClick}
    {...(openInNewTab ? { target: '_blank', rel: 'noreferrer' } : {})}
    sx={{
      cursor: 'pointer',
      fontWeight: '700',
      '&:hover': {
        color: 'secondary.main',
      },
    }}
  >
    {children}
  </Link>
);
