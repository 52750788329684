import { Typography } from '@mui/material';
import { DefaultValue } from '../../enums';

export function NullableOutput<T>(
  item: T,
  field: keyof T,
  output: (item: T) => JSX.Element | string,
  rejectZero = false,
  forceDefault = false
): JSX.Element | string {
  return item[field] === null ||
    item[field] === undefined ||
    (rejectZero && item[field] === 0) ||
    forceDefault ? (
    <Typography
      variant="body2"
      sx={{ '&.MuiTypography-root': { textAlign: 'left' } }}
    >
      {DefaultValue.Dash}
    </Typography>
  ) : (
    output(item as T)
  );
}
