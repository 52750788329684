import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { useQuoteMarkets, useSorting } from '../../hooks';
import { InventoryFilters, MeasurementUnitsToggle, Show } from '..';
import { CatalogTable } from '../CatalogTable/CatalogTable.component';
import { InventoryType } from '../../enums';
import { Inventory } from '../../types/Inventory';
import { CatalogSearchDialogProps } from './CatalogSearchDialog.props';
import { dialogPaperStyles } from './CatalogSearchDialog.styles';
import { CatalogFiltersType } from './CatalogFiltersType';

export const CatalogSearchDialog = ({
  catalogSearchDialogOpen,
  form,
  onAddProduct,
  setCatalogSearchDialogOpen,
  units,
  setUnits,
  catalogFilters,
  onCatalogFiltersChange,
}: CatalogSearchDialogProps) => {
  const { t } = useTranslation();
  const { canSeeCSA, canSeeUL } = useQuoteMarkets(form);

  const [filters, setFilters] = useState<CatalogFiltersType>(null);
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (!isEqual(catalogFilters.filters, filters)) {
      setFilters(catalogFilters.filters);
      setShow(
        !!catalogFilters.filters?.productType ||
          !!catalogFilters.filters?.partNumber
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogFilters]);

  const [inventoryType, setInventoryType] = useState<Inventory>(
    canSeeCSA ? 'csa' : 'ul'
  );

  const onInventoryTypeChange = (type: Inventory) => {
    onCatalogFiltersChange(null, type);
    setInventoryType(type);
  };

  const sorting = useSorting('description');

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const handleDialogClose = () => {
    setCatalogSearchDialogOpen(false);
  };

  const handleSearch = (newFilters: CatalogFiltersType) => {
    if (!isEqual(newFilters, filters)) {
      setFilters(newFilters);
      onCatalogFiltersChange(newFilters, inventoryType);
    }
    setShow(!!newFilters?.productType || !!newFilters?.partNumber);
  };

  const showTable = show && !!filters && (canSeeCSA || canSeeUL);

  useEffect(() => {
    if (canSeeCSA || canSeeUL) {
      if (!canSeeCSA && inventoryType === 'csa') {
        onInventoryTypeChange('ul');
      } else if (!canSeeUL && inventoryType === 'ul') {
        onInventoryTypeChange('csa');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canSeeCSA, canSeeUL, inventoryType]);

  return (
    <Dialog
      open={catalogSearchDialogOpen}
      maxWidth={'lg'}
      fullWidth
      fullScreen={!isDesktop}
      onClose={handleDialogClose}
      scroll={'paper'}
      PaperProps={{ sx: dialogPaperStyles }}
    >
      <DialogTitle />
      <IconButton
        aria-label="close"
        onClick={handleDialogClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ height: 800 }}>
        <Grid container flexDirection="row" mb={2}>
          <Grid item alignSelf={'center'} xs={6}>
            <Typography variant="h1">
              {t('catalog.searchDialog.title')}
            </Typography>
          </Grid>
          <Grid container item xs={6} justifyContent={'flex-end'}>
            <RadioGroup
              row
              name="inventoryType"
              value={inventoryType}
              onChange={(_, value) => onInventoryTypeChange(value as Inventory)}
            >
              {canSeeCSA && (
                <FormControlLabel
                  value="csa"
                  control={<Radio />}
                  label="CSA"
                  disabled={!canSeeUL}
                />
              )}
              {canSeeUL && (
                <FormControlLabel
                  value="ul"
                  control={<Radio />}
                  label="UL"
                  disabled={!canSeeCSA}
                />
              )}
            </RadioGroup>
          </Grid>
        </Grid>
        <InventoryFilters
          forCatalog
          inventoryType={inventoryType}
          onSearch={handleSearch}
          filters={filters ?? undefined}
          footerContents={
            <Typography variant="body2" mt={{ xs: 1, md: -2.5 }}>
              {t('catalog.searchDialog.help')}
            </Typography>
          }
        />
        <Show if={showTable}>
          <Box display="flex" justifyContent="flex-end" mt={2} mb={1}>
            <MeasurementUnitsToggle setUnits={setUnits} units={units} />
          </Box>
          <CatalogTable
            units={units}
            form={form}
            type={
              inventoryType === 'csa' ? InventoryType.CSA : InventoryType.UL
            }
            filters={filters}
            onAddProduct={onAddProduct}
            setFilters={setFilters}
            sorting={sorting}
          />
        </Show>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleDialogClose}>
          {t('catalog.searchDialog.backButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
