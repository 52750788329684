import { Theme, useMediaQuery } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import {
  useAuthentication,
  useCompany,
  useCurrentUser,
  useFormFieldValue,
} from '../../hooks';
import { Quote as QuoteModel } from '../../models';
import { Nullable } from '../../types';
import { CompanyType } from '../../enums';
import { getSelectableCompanies } from '../../utils';
import { QuoteCustomerNote } from '../../models/QuoteCustomerNote';
import { QuoteSummaryDetailsProps } from './QuoteSummaryDetails.props';
import {
  StyledCustomerDetails,
  StyledOtherDetails,
  StyledQuoteDetails,
  StyledStack,
} from './QuoteSummaryDetails.styles';
import { CustomerDetails, OtherDetails, QuoteDetails } from '.';

export const QuoteSummaryDetails = React.memo(
  ({ form, isReadonly }: QuoteSummaryDetailsProps) => {
    const isMobile = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down('sm')
    );

    const { isAgent } = useAuthentication();

    const currentUser = useCurrentUser();
    const { item: currentCompany } = useCompany(currentUser.companyRef.id);

    const { value: companyId } = useFormFieldValue<QuoteModel, string>(
      form,
      'companyId'
    );
    const { setValue: setCompanyName } = useFormFieldValue<
      QuoteModel,
      Nullable<string>
    >(form, 'companyName');

    const { value: customerNote } = useFormFieldValue<
      QuoteModel,
      Nullable<QuoteCustomerNote>
    >(form, 'customerNote');

    useEffect(() => {
      if (
        !isReadonly &&
        form.dirty &&
        currentCompany?.type === CompanyType.Agency &&
        currentCompany.associatedCompanies
      ) {
        setCompanyName(
          companyId
            ? currentCompany.associatedCompanies[companyId]?.companyName ?? null
            : null
        );
      }
    }, [companyId, currentCompany, form.dirty, setCompanyName, isReadonly]);

    const selectableCompanies = useMemo(() => {
      if (
        currentCompany?.type === CompanyType.Agency &&
        currentCompany.associatedCompanies
      ) {
        return getSelectableCompanies(
          Object.values(currentCompany.associatedCompanies)
        );
      }

      return [];
    }, [currentCompany]);

    return (
      <StyledStack>
        <StyledCustomerDetails
          hasCustomerNote={!!customerNote}
          isReadonly={isReadonly}
          isAgent={isAgent}
        >
          <CustomerDetails
            form={form}
            isMobile={isMobile}
            agencies={
              currentCompany?.type === CompanyType.Agency
                ? [currentCompany]
                : []
            }
            selectableCompanies={selectableCompanies}
          />
        </StyledCustomerDetails>

        <StyledQuoteDetails
          hasCustomerNote={!!customerNote}
          isReadonly={isReadonly}
          isAgent={isAgent}
        >
          <QuoteDetails
            form={form}
            isMobile={isMobile}
            isReadonly={isReadonly}
          />
        </StyledQuoteDetails>

        <StyledOtherDetails
          hasCustomerNote={!!customerNote}
          isReadonly={isReadonly}
          isAgent={isAgent}
        >
          <OtherDetails form={form} isReadonly={isReadonly} />
        </StyledOtherDetails>
      </StyledStack>
    );
  }
);
