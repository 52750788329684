import { InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { forwardRef, useMemo } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { QUOTE_PRODUCT_MAX_QUANTITY } from '../../../../mappings';
import theme from '../../../../theme';
import { QuoteProductQuantityProps } from './QuoteProductQuantity.props';
import * as Sx from './QuoteProductQuantity.styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NumericFormatCustom = forwardRef<NumericFormatProps, any>(
  function NumericFormatCustom(props, ref) {
    const { onChange, currencysymbol, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({ target: { value: values.value } });
        }}
        fixedDecimalScale
        thousandSeparator
        decimalScale={2}
        allowNegative={false}
        valueIsNumericString
        prefix={currencysymbol}
      />
    );
  }
);

const numericValueIsInvalid = (min: number, max: number, value?: number) =>
  value === undefined || value < min || value > max;

export const QuoteProductQuantity = ({
  onChange,
  quantity,
  disabled,
  showError = true,
  inputAdornment,
  width = '130px',
  maxValue = QUOTE_PRODUCT_MAX_QUANTITY,
  minValue = 1,
  currencyFormatter,
  numberValidation,
}: QuoteProductQuantityProps) => {
  const numberValidationMessage = useMemo(
    () => (numberValidation ? numberValidation(quantity) : null),
    [numberValidation, quantity]
  );

  return (
    <TextField
      required
      variant="outlined"
      type={currencyFormatter ? 'text' : 'Number'}
      disabled={disabled}
      value={quantity ?? 0}
      error={
        (showError && numericValueIsInvalid(minValue, maxValue, quantity)) ||
        !!numberValidationMessage
      }
      sx={{ width, ...Sx.textFieldNumericStyles }}
      onFocus={(event) => event.target.select()}
      onChange={(e) => {
        if (e.target.value === '') {
          onChange(0);
          return;
        }
        const val = Number(e.target.value);
        onChange(val);
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        inputMode: 'numeric',
        pattern: '[0-9]*',
        currencysymbol: currencyFormatter?.getSymbol(),
      }}
      InputProps={{
        inputComponent: currencyFormatter ? NumericFormatCustom : undefined,
        ...(inputAdornment
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <Typography sx={{ opacity: 0.6 }}>
                    {inputAdornment}
                  </Typography>
                  {!!numberValidationMessage && (
                    <Tooltip
                      sx={Sx.toolTipError}
                      title={numberValidationMessage}
                    >
                      <ErrorOutlineIcon
                        htmlColor={theme.palette.error.main}
                        fontSize="small"
                      />
                    </Tooltip>
                  )}
                </InputAdornment>
              ),
            }
          : undefined),
      }}
    />
  );
};
