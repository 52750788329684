import VerificationInput from 'react-verification-input';
import { SecondFactorVerificationInputProps } from './SecondFactorVerificationInput.props';
import { StyledVerificationInput } from './SecondFactorVerificationInput.styles';

export const SecondFactorVerificationInput = ({
  code,
  setCode,
}: SecondFactorVerificationInputProps) => (
  <StyledVerificationInput>
    <VerificationInput
      value={code}
      validChars="0-9"
      placeholder=""
      autoFocus
      onChange={(value) => setCode(value)}
      classNames={{
        container: 'verification-container',
        character: 'verification-character',
        characterInactive: 'verification-character-inactive',
        characterSelected: 'verification-character-selected',
      }}
    />
  </StyledVerificationInput>
);
