import { useAuth } from 'reactfire';
import { RecaptchaVerifier as RecaptchaVerifierAuth } from 'firebase/auth';
import { useEffect } from 'react';

export const RecaptchaVerifier = () => {
  const auth = useAuth();

  useEffect(() => {
    let verifier: RecaptchaVerifierAuth | null = null;
    if (auth) {
      verifier = new RecaptchaVerifierAuth(auth, 'recaptcha-container', {
        size: 'invisible',
      });
      verifier.render().then(() => {
        window.recaptchaVerifier = verifier as RecaptchaVerifierAuth;
      });
    }

    return () => {
      verifier?.clear();
    };
  }, [auth]);

  return <div id="recaptcha-container"></div>;
};
