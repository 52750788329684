import { useTranslation } from 'react-i18next';
import CableIcon from '@mui/icons-material/Cable';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { Outlet } from 'react-router-dom';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { CountrySpecific, NeedsPermission } from '../../security';
import { Footer, MenuItem, Navigation } from '../../navigation/';
import { Layout } from '../../components';
import { Country, UserPermission } from '../../enums/';
import { AuthenticatedLayout, MainContentLayout } from '..';

export const ClientLayout = () => {
  const { t } = useTranslation();

  return (
    <AuthenticatedLayout>
      <Layout>
        <Navigation>
          <NeedsPermission oneOf={[UserPermission.ViewInventory]}>
            <MenuItem
              title={t('navigation.inventory')}
              icon={<CableIcon />}
              to="/inventory/csa"
              match="/inventory/*"
            />
          </NeedsPermission>

          <NeedsPermission
            oneOf={[
              UserPermission.ManageCompanyReservations,
              UserPermission.ManageOwnReservations,
            ]}
          >
            <MenuItem
              title={t('navigation.reservations')}
              icon={<HourglassTopIcon />}
              to="/reservations"
              match="/reservations/*"
            />
          </NeedsPermission>

          <NeedsPermission
            oneOf={[
              UserPermission.RequestQuotes,
              UserPermission.ViewCompanyQuotes,
            ]}
          >
            <MenuItem
              title={t('navigation.quotes')}
              icon={<RequestQuoteIcon />}
              to="/quotes"
              match="quotes/*"
            />
          </NeedsPermission>

          <NeedsPermission
            oneOf={[
              UserPermission.ManageOrderFilesAccess,
              UserPermission.ViewOrdersConfirmation,
              UserPermission.ViewOrdersInvoice,
              UserPermission.ViewOrdersShipping,
            ]}
          >
            <MenuItem
              title={t('navigation.orders')}
              icon={<FolderOpenIcon />}
              to="/orders"
              match="/orders/*"
            />
          </NeedsPermission>

          <NeedsPermission oneOf={[UserPermission.ManageReelReturns]}>
            <CountrySpecific country={Country.Canada} hide>
              <MenuItem
                title={t('navigation.reelReturns')}
                icon={<GroupWorkIcon />}
                to="/reel-returns"
                match="/reel-returns/*"
              />
            </CountrySpecific>
          </NeedsPermission>

          <NeedsPermission oneOf={[UserPermission.ViewCompanyUsers]}>
            <MenuItem
              title={t('navigation.users')}
              icon={<SupervisedUserCircleIcon />}
              to="/users"
              match="/users/*"
            />
          </NeedsPermission>
        </Navigation>
        <MainContentLayout>
          <Outlet />
        </MainContentLayout>
        <Footer />
      </Layout>
    </AuthenticatedLayout>
  );
};
