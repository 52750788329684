import { useEffect, useRef, useState } from 'react';
import { Box, Stack } from '@mui/system';

import {
  FOOTER_HEIGHT,
  HEADER_PADDING,
} from '../../navigation/Navigation/constants';
import { Container } from '../../components';
import { getQuoteSubmitHeight } from '../../components/QuoteSubmit/QuoteSubmit.styles';
import { QuoteLayoutProps } from './QuoteLayout.props';

export const QuoteLayout = ({
  headerElement,
  bodyElement,
  footerElement,
  withTotalCost = false,
}: QuoteLayoutProps) => {
  const [scrollHeight, setScrollHeight] = useState(0);

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const updateHeight = () => {
      if (ref.current) {
        setScrollHeight(ref.current.offsetHeight);
      }
    };

    updateHeight();

    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  const containerHeight = {
    xs: `calc(100vh - ${HEADER_PADDING})`,
    md: `calc(100vh - ${HEADER_PADDING} - ${FOOTER_HEIGHT})`,
  };

  return (
    <Container
      sx={{
        height: containerHeight,
        minHeight: containerHeight,
        maxHeight: containerHeight,
      }}
      subSx={{
        minHeight: '100%',
        height: '100%',
        maxHeight: '100%',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Stack
        display="flex"
        flexDirection="column"
        sx={{
          height: {
            xs: `calc(100% - ${getQuoteSubmitHeight(withTotalCost, true)})`,
            md: `calc(100% - ${getQuoteSubmitHeight(withTotalCost)} - 2px)`,
          },
        }}
        justifyContent="flex-start"
        maxWidth="xl"
      >
        <Box
          ref={ref}
          sx={{
            minHeight: { md: '100px' },
          }}
        >
          {headerElement}
        </Box>
        <Box
          sx={{
            height: { md: `calc(100% - ${scrollHeight}px)` },
            overflow: 'auto',
            position: 'relative',
          }}
        >
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              pt: 3,
              pb: { md: 4 },
              pr: { md: 1 },
            }}
          >
            {bodyElement}
          </Stack>
        </Box>
      </Stack>
      {footerElement}
    </Container>
  );
};
