import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Timestamp } from 'firebase/firestore';
import {
  useSorting,
  usePagination,
  useOrders,
  useCurrentUser,
  useOrderFilePermissions,
  useAuthentication,
} from '../../hooks';
import { DataTable, DataTableColumn } from '../../tables';
import { Filter } from '../../types';
import { Container, FormattedTimestamp, UnderlinedLink } from '..';
import { Order } from '../../models';
import { UserRoleCategoryPath } from '../../enums';
import { OrderTableProps } from './OrderTable.props';
import { tableBodyStyles } from './OrderTable.styles';

export const OrderTable = React.memo(({ filters }: OrderTableProps) => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const { getOrderFilePermissions } = useOrderFilePermissions();
  const { isAdmin, isAgent, userRoleCategory } = useAuthentication();

  const pagination = usePagination();
  const sorting = useSorting('dateModified', { sortDirection: 'desc' });
  const { companyId, searchField } = filters;

  const isAdminOrAgent = isAdmin || isAgent;

  const orderFilters = useMemo(() => {
    if (!filters) return [];

    const result = [];

    if (companyId && isAdminOrAgent) {
      result.push(['companyId', '==', companyId]);
    }

    if (isAgent) {
      result.push(['agencyId', '==', user.companyRef.id]);
    }

    if (!isAdminOrAgent) {
      if (user.companyRef.id)
        result.push(['companyId', '==', user.companyRef.id]);
    }

    if (searchField) {
      const normalizedValue = searchField
        .toLowerCase()
        .replace(/[^a-z0-9]/g, '');

      result.push(['searchNormalized', 'array-contains', normalizedValue]);
    }

    return result;
  }, [
    filters,
    companyId,
    isAdminOrAgent,
    isAgent,
    searchField,
    user.companyRef.id,
  ]) as Filter[];

  const { list: orderList, loading } = useOrders({
    sorting,
    pagination,
    filters: orderFilters,
  });

  const orderFilesPermissions = getOrderFilePermissions();

  const orderNumberOutput = (orderNumber: string, order: Order) => {
    const pathPrefix = UserRoleCategoryPath[userRoleCategory];

    return (
      <UnderlinedLink to={`${pathPrefix}/orders/${order.id}`}>
        {orderNumber}
      </UnderlinedLink>
    );
  };

  const dateOutput = (timestamp: Timestamp) => (
    <FormattedTimestamp timestamp={timestamp} timeZone={user.timeZone} />
  );

  return (
    <Container>
      <DataTable
        data={!loading ? orderList : undefined}
        sorting={sorting}
        pagination={pagination}
        mobileBorder="none"
        tableBodyStyles={tableBodyStyles}
      >
        <DataTableColumn
          mobileHeight="2.5em"
          property="orderNumber"
          title={t('orders.table.orderNumber')}
          width={'12%'}
          output={orderNumberOutput}
        />

        <DataTableColumn
          mobileHeight="4em"
          property="poNumber"
          width={'12%'}
          title={t('orders.table.poNumber')}
        />
        {orderFilesPermissions.shipping && (
          <DataTableColumn
            mobileHeight="4em"
            property="shippingFilesAmount"
            title={t('orders.table.shippingDoc')}
            textAlign="center"
          />
        )}
        {orderFilesPermissions.invoiceCredit && (
          <DataTableColumn
            mobileHeight="4em"
            property="invoiceFilesAmount"
            title={t('orders.table.invoicesDoc')}
            textAlign="center"
          />
        )}
        {orderFilesPermissions.confirmation && (
          <DataTableColumn
            mobileHeight="4em"
            property="confirmationFilesAmount"
            title={t('orders.table.orderConfirm')}
            textAlign="center"
          />
        )}
        <DataTableColumn
          mobileHeight="4em"
          property="dateModified"
          title={t('orders.table.lastModifiedDate')}
          output={dateOutput}
        />
        {isAdminOrAgent && (
          <DataTableColumn
            mobileHidden
            mobileHeight="4em"
            property="companyName"
            title={t('orders.table.company')}
          />
        )}
      </DataTable>
    </Container>
  );
});
