export class SanitizedQuoteConversionRate {
  id!: string;
  name!: string;
  quoted!: number;
  expired!: number;
  won!: number;
  lost!: number;
  rate!: number;
  totalQuoted!: number;
  totalWon!: number;
  totalWonRate!: number;
  totalQuotedFormatted!: string;
  totalWonFormatted!: string;
  subQuoteConversionRates!: SanitizedQuoteConversionRate[];

  constructor() {
    this.name = '';
    this.quoted = 0;
    this.expired = 0;
    this.won = 0;
    this.lost = 0;
    this.rate = 0;
    this.totalQuoted = 0;
    this.totalWon = 0;
    this.totalWonRate = 0;
    this.totalQuotedFormatted = '';
    this.totalWonFormatted = '';
    this.subQuoteConversionRates = [];
  }
}
