import { useTranslation } from 'react-i18next';

import { Grid, Switch, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { FilterContainer } from '..';
import { QuoteConversionRateFilters as Filters, Nullable } from '../../types';
import { MultiSelect } from '../MultiSelect/MultiSelect.component';
import { QuoteConversionRateFiltersProps } from './QuoteConversionRateFilters.props';
import * as Sx from './QuoteConversionRateFilters.styles';

const emptyFilters: Filters = {
  companyIds: null,
  productTypes: null,
  searchByCompany: true,
  startDate: DateTime.now().plus({ month: -1 }),
  endDate: DateTime.now(),
};

export const QuoteConversionRateFilters = ({
  onSearch,
  loadedFilters,
  companies,
  productTypes,
  loadingCompanies,
  loadingProductTypes,
}: QuoteConversionRateFiltersProps) => {
  const { t, i18n } = useTranslation();

  const [filters, setFilters] = useState<Filters>(
    loadedFilters || emptyFilters
  );

  const productTypeOptions = useMemo(
    () =>
      Object.entries(productTypes?.types ?? {})
        .map(([key, value]) => {
          value.id = parseInt(key);
          return value;
        })
        .sort((a, b) => a.name.localeCompare(b.name)),

    [productTypes]
  );

  const clearFilters = () => {
    setFilters((prev) => {
      return {
        ...emptyFilters,
        searchByCompany: prev.searchByCompany,
      };
    });
    onSearch(null);
  };

  const onByCompanyChange = (searchByCompany: Nullable<boolean> = null) => {
    setFilters((prev) => {
      return {
        ...prev,
        searchByCompany:
          searchByCompany !== null ? !searchByCompany : filters.searchByCompany,
      };
    });
    onSearch(null);
  };

  const switchLabel = (text: string, enabled: boolean) => (
    <Typography variant="h4" sx={Sx.switchLabel(i18n, enabled)}>
      <label htmlFor="searchByCompany">{text}</label>
    </Typography>
  );

  const switches = (
    <>
      {switchLabel(
        t('reports.quoteConversionRate.byCompany'),
        filters.searchByCompany
      )}
      <Switch
        id="searchByCompany"
        checked={!filters.searchByCompany}
        onChange={(e) => onByCompanyChange(e.currentTarget.checked)}
      />
      {switchLabel(
        t('reports.quoteConversionRate.byProductType'),
        !filters.searchByCompany
      )}
    </>
  );

  return (
    <FilterContainer
      headerContents={switches}
      title={t('reports.quoteConversionRate.title')}
      buttonsDisabled={!filters.startDate?.isValid || !filters.endDate?.isValid}
      onClear={() => clearFilters()}
      onSearch={() => onSearch(filters)}
    >
      <Grid sx={{ my: { xs: 2, md: 4 } }}>
        <Grid display="flex" justifyContent="space-evenly" gap="1em">
          <DatePicker
            label={t('reports.quoteConversionRate.startDate')}
            value={filters.startDate}
            slotProps={{
              actionBar: { actions: ['clear'] },
              textField: {
                fullWidth: true,
                required: true,
                sx: { flex: '1 1 0' },
              },
            }}
            onChange={(value) =>
              setFilters((prev) => {
                return { ...prev, startDate: value || emptyFilters.startDate };
              })
            }
            format={t('formats.date')}
            maxDate={filters.endDate || DateTime.now()}
          />

          <DatePicker
            label={t('reports.quoteConversionRate.endDate')}
            value={filters.endDate}
            slotProps={{
              actionBar: { actions: ['clear'] },
              textField: {
                fullWidth: true,
                required: true,
                sx: { flex: '1 1 0' },
              },
            }}
            onChange={(value) =>
              setFilters((prev) => {
                return { ...prev, endDate: value || emptyFilters.endDate };
              })
            }
            format={t('formats.date')}
            minDate={filters.startDate || undefined}
            maxDate={DateTime.now()}
          />

          <MultiSelect
            selectedOptionIds={filters.companyIds}
            options={companies}
            loadingOptions={loadingCompanies}
            displayKey="name"
            idKey="id"
            label={t('reports.quoteConversionRate.company')}
            selectedLabel={t('reports.quoteConversionRate.companySelected', {
              count: filters.companyIds?.length || 0,
            })}
            onChange={(companyIds) => {
              setFilters((prev) => {
                return { ...prev, companyIds: companyIds };
              });
            }}
          />

          <MultiSelect
            selectedOptionIds={filters.productTypes}
            options={productTypeOptions}
            loadingOptions={loadingProductTypes}
            displayKey="name"
            idKey="id"
            label={t('reports.quoteConversionRate.productType')}
            selectedLabel={t(
              'reports.quoteConversionRate.productTypeSelected',
              { count: filters.productTypes?.length || 0 }
            )}
            onChange={(productTypes) => {
              setFilters((prev) => {
                return { ...prev, productTypes };
              });
            }}
            getOptionLabel={(option) => t(`productTypes.${option.id}`)}
          />
        </Grid>
      </Grid>
    </FilterContainer>
  );
};
