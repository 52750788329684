import { Avatar, Button, Drawer, Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LockIcon from '@mui/icons-material/Lock';

import { useState } from 'react';
import { AuthErrorCodes } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import {
  useForm,
  useNotification,
  usePublicAuthentication,
  useUpdatePassword,
} from '../../../hooks';
import {
  Container,
  RecaptchaVerifier,
  TextField,
  TwoFactorCodeVerification,
} from '../../../components';
import { UpdatePasswordValidations } from '../../../validation';
import { ModificationMode, NotificationLevel } from '../../../enums';
import { TwoFactorLayout } from '../../../layouts';

const UpdatePassword = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { login, sendSmsMfaCode, verifyMfaCode, user } =
    usePublicAuthentication();
  const updatePassword = useUpdatePassword();
  const [mfaRequired, setMfaRequired] = useState(false);
  const [mfaPhoneNumber, setMfaPhoneNumber] = useState('');
  const { addNotification, clearNotification } = useNotification();
  const navigateBack = () => {
    navigate(location.pathname.replace('/password', ''));
  };

  const form = useForm(updatePassword, {
    mode: ModificationMode.Add,
    validations: UpdatePasswordValidations,
    defaultNavigationPrompt: false,
    onSaveSuccess: () => {
      navigateBack();
    },
  });

  const verifyCode = async (verificationCode: string) => {
    await verifyMfaCode(verificationCode);
    if (form.item) {
      await updatePassword.tryUpdatePassword(form.item, false);
      setMfaRequired(false);
      setMfaPhoneNumber('');
      form.save();
    }
  };

  const onSave = async () => {
    const errors = await form.validate(form.item, true);
    if (!form.item || Object.keys(errors).length !== 0) {
      return;
    }

    try {
      addNotification(t('forms.saving'), NotificationLevel.Information);
      await updatePassword.tryUpdatePassword(form.item, true);
      form.save();
    } catch (e) {
      clearNotification();
      const error = e as FirebaseError;
      // Should only happen for MFA users
      if (error?.code === AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN) {
        const result = await login(
          user?.email as string,
          form.item.oldPassword
        );
        if (result.type === 'mfa-required') {
          setMfaRequired(true);
          setMfaPhoneNumber(result.mfaPhoneNumber ?? '');
          return;
        }
      }
      addNotification(
        t(
          error.name === 'CustomError'
            ? error.message
            : 'forms.savedUnsuccessfully'
        ),
        NotificationLevel.Error
      );
    }
  };

  return (
    <>
      <RecaptchaVerifier />
      {mfaRequired ? (
        <TwoFactorLayout title={t('twoFactorAuth.verification.title')}>
          <TwoFactorCodeVerification
            shouldRedirectOnSuccess={false}
            phoneNumber={mfaPhoneNumber}
            onSendCode={sendSmsMfaCode}
            onVerifyCode={verifyCode}
          />
        </TwoFactorLayout>
      ) : (
        <Drawer
          anchor="bottom"
          open={true}
          PaperProps={{
            sx: {
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
              height: { xs: '450px', md: '250px' },
              background: '#2C2C2C',
              color: '#ffffff',
              overflow: 'hidden',
            },
          }}
        >
          <Container>
            <Grid display="flex" alignItems="center" mb={2} mt={5}>
              <Avatar
                sx={{
                  backgroundColor: 'secondary.contrastText',
                  border: 1,
                  borderColor: 'secondary.main',
                }}
              >
                <LockIcon sx={{ color: 'secondary.main' }} />
              </Avatar>
              <Typography variant="h2" marginLeft="20px">
                {t('settings.password.title')}
              </Typography>
            </Grid>

            <Grid
              mt={2}
              display="flex"
              flexDirection="row"
              alignItems="end"
              sx={{
                flexDirection: { xs: 'column', md: 'row' },
                '& .MuiTextField-root': { ml: 1 },
              }}
            >
              <TextField
                form={form}
                field="oldPassword"
                label={t('settings.password.fields.oldPassword')}
                contrast
                type="password"
              />

              <TextField
                form={form}
                field="newPassword"
                label={t('settings.password.fields.newPassword')}
                contrast
                type="password"
              />

              <TextField
                form={form}
                field="confirmPassword"
                label={t('settings.password.fields.confirmPassword')}
                contrast
                type="password"
              />

              <Grid
                display="flex"
                mb={1.5}
                sx={{
                  ml: { xs: 0, md: 2 },
                  mt: { xs: 3, md: 0 },
                  width: { xs: '100%', md: 'auto' },
                  justifyContent: { xs: 'space-between', sm: 'right', md: '' },
                  flexDirection: { xs: 'row-reverse', md: 'row' },
                }}
              >
                <Button
                  disabled={
                    !form.dirty ||
                    form.saving ||
                    Object.keys(form.errors).length > 0
                  }
                  onClick={() => onSave()}
                  sx={{
                    minWidth: { xs: '150px', sm: '250px', md: '80px' },
                    backgroundColor: 'secondary.main',
                    color: 'primary.contrastText',
                    marginLeft: { xs: 0, sm: 2, md: 0 },
                    marginRight: { xs: 0, md: 2 },
                    '&:hover': {
                      backgroundColor: 'secondary.main',
                    },
                  }}
                >
                  {t('forms.save')}
                </Button>
                <Button
                  onClick={() => navigateBack()}
                  sx={{
                    minWidth: { xs: '150px', sm: '250px', md: '80px' },
                    color: 'primary.contrastText',
                    border: 1,
                    borderColor: 'primary.contrastText',
                    '&:hover': {
                      borderColor: 'primary.contrastText',
                    },
                  }}
                >
                  {t('forms.cancel')}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Drawer>
      )}
    </>
  );
};

export default UpdatePassword;
