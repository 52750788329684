import { useState } from 'react';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { BottomDrawer } from '../../../../components';
import { useCompanies } from '../../../../hooks';
import { Company, User } from '../../../../models';
import { CompanyType } from '../../../../enums';
import { Nullable } from '../../../../types';
import { UserSelectContainer } from '../../../../components/UserSelect/UserSelectContainer.component';
import { OutletContext } from './ImpersonateCustomer.interface';
import {
  cancelButton,
  confirmButton,
  input,
} from './ImpersonateCustomer.styles';

const ImpersonateCustomer = () => {
  const [company, setCompany] = useState<Company | null>(null);
  const [user, setUser] = useState<Nullable<User | string>>(null);

  const { fromCollapse, inventoryType, onCancel, onAdminImpersonate, title } =
    useOutletContext<OutletContext>();

  const { t } = useTranslation();

  const { list: companies } = useCompanies({
    includeDeleted: false,
    filters: [['type', '==', CompanyType.Customer]],
  });

  const impersonate = async () => {
    if (!user || !onAdminImpersonate || !company) {
      return;
    }

    if (typeof user === 'string') {
      onAdminImpersonate({
        company: {
          companyId: company.id,
          companyAccountNumber: company.accountNumber || '',
          companyName: company.name,
          customerAddresses: [],
          isDeleted: company.isDeleted,
          shippingDocEmailRecipient: company.shippingDocEmailRecipient,
        },
        personName: user,
        canSeeCSA: company.canSeeCSA,
        canSeeUL: company.canSeeUL,
      });
      return;
    } else {
      onAdminImpersonate(user);
    }
  };

  const cancel = () => {
    onCancel && onCancel();
  };

  const formButtons = () => (
    <Grid marginBottom={1.5}>
      <Button disabled={!user} onClick={() => impersonate()} sx={confirmButton}>
        {t('forms.confirm')}
      </Button>

      <Button onClick={() => cancel()} sx={cancelButton}>
        {t('companies.actions.cancel')}
      </Button>
    </Grid>
  );

  return (
    <BottomDrawer
      title={t(title)}
      icon={<HowToRegIcon sx={{ color: 'secondary.main' }} />}
      open={true}
      rightContent={formButtons()}
    >
      <Grid display="flex">
        <Grid display="flex" width="50%" minWidth="500px">
          <Autocomplete
            fullWidth={true}
            popupIcon={<KeyboardArrowDownIcon />}
            options={companies ?? []}
            value={company}
            onChange={(e, value) => {
              setCompany(value);
              setUser(null);
            }}
            getOptionLabel={(company) => company.name || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Company"
                variant="filled"
                sx={input}
              />
            )}
            sx={{ mr: 5 }}
          />

          <UserSelectContainer
            companyId={company?.id}
            inventoryType={inventoryType}
            onSelect={setUser}
            user={user}
            fromCollapse={fromCollapse}
            allowFreeSolo={Boolean(
              inventoryType && (company?.canSeeUL || company?.canSeeCSA)
            )}
            textFieldProps={{
              variant: 'filled',
              label: t('impersonateCustomer.user'),
            }}
          />
        </Grid>
      </Grid>
    </BottomDrawer>
  );
};

export default ImpersonateCustomer;
