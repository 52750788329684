import { useCallback, useMemo, useState } from 'react';
import {
  Autocomplete,
  Checkbox,
  createFilterOptions,
  FilterOptionsState,
  TextField,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { MultiSelectProps } from './MultiSelect.props';
import { PAGE_SIZE } from './constants';

export const MultiSelect = <T, K extends keyof T>({
  onChange,
  options,
  loadingOptions,
  selectedOptionIds,
  label,
  selectedLabel,
  idKey,
  displayKey,
  getOptionLabel,
}: MultiSelectProps<T, K>) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [page, setPage] = useState(1);

  const _filterOptions = createFilterOptions<T>();

  const selectedOptions = useMemo(
    () => options.filter((c) => selectedOptionIds?.includes(c[idKey])),
    [idKey, options, selectedOptionIds]
  );

  const filterOptions = useCallback(
    (options: T[], state: FilterOptionsState<T>) => {
      const results = _filterOptions(options, state);
      return [...selectedOptions, ...results.slice(0, page * PAGE_SIZE)];
    },
    [_filterOptions, selectedOptions, page]
  );

  const renderOptionLabel = (option: T) =>
    getOptionLabel ? getOptionLabel(option) : String(option[displayKey]);

  return (
    <Autocomplete
      popupIcon={<KeyboardArrowDownIcon />}
      multiple
      disableCloseOnSelect
      filterSelectedOptions
      options={options ?? []}
      loading={loadingOptions}
      value={selectedOptions}
      onClose={() => setPage(1)}
      ListboxProps={{
        onScrollCapture: (event) => {
          const listboxNode = event.currentTarget;
          if (
            listboxNode.scrollTop + listboxNode.clientHeight ===
            listboxNode.scrollHeight
          ) {
            setPage((page) => page + 1);
          }
        },
        /** Fix for scrolling to top when loading new page of options */
        role: 'list-box',
      }}
      getOptionDisabled={(option) =>
        selectedOptions.length === PAGE_SIZE &&
        !selectedOptions.includes(option)
          ? true
          : false
      }
      getOptionLabel={(option) => renderOptionLabel(option)}
      filterOptions={filterOptions}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={String(option[idKey])}>
          <Typography sx={{ width: 'calc(100% - 10px)' }}>
            {renderOptionLabel(option)}
          </Typography>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            checked={selected}
          />
        </li>
      )}
      onChange={(_, item) => {
        onChange(item.length > 0 ? item.map((val) => val[idKey]) : null);
      }}
      renderTags={(value) => (
        <Typography pl={'5px'}>
          {value.length === 1 ? renderOptionLabel(value[0]) : selectedLabel}
        </Typography>
      )}
      sx={{ flex: '1.5 1 0' }}
      inputValue={inputValue}
      onInputChange={(
        event: React.SyntheticEvent,
        value: string,
        reason: string
      ) => {
        if (event && event.type === 'blur') {
          setInputValue('');
        } else if (reason !== 'reset') {
          setInputValue(value);
        }
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
};
