import { DateTime } from 'luxon';
import { TFunction } from 'i18next';
import { TimeZone } from '../../enums';
import { ReservationLog } from '../../models';
import { Nullable } from '../../types';
import formatReservationNumber from '../../utils/formatReservationNumber';
import report from './report';

const PREFIX = 'NC_ReservationActivityLog';
const NAMESPACE = 'reports.reservationLogs';
const EXPORTED_KEYS = [
  'reservationNumber',
  'date',
  'madeByName',
  'madeForName',
  'reelNumber',
  'initialStatus',
  'endStatus',
  'companyName',
  'initialComment',
  'endComment',
];

class reservationLogsCsv extends report<ReservationLog> {
  private readonly data: ReservationLog[];
  private readonly fromDate: Nullable<DateTime>;
  private readonly toDate: Nullable<DateTime>;

  constructor(
    data: ReservationLog[],
    fromDate: Nullable<DateTime>,
    toDate: Nullable<DateTime>,
    t: TFunction<'translation', undefined>
  ) {
    super(PREFIX, NAMESPACE, EXPORTED_KEYS, t);

    this.data = data;
    this.fromDate = fromDate;
    this.toDate = toDate;
  }

  protected override getFilename(): string {
    const fromDate = this.fromDate?.toFormat('LLddyyyy');
    const toDate = this.toDate?.toFormat('LLddyyyy');

    return `${PREFIX}_${fromDate}_${toDate}.csv`;
  }

  protected getSanitizedData(): ReservationLog[] {
    return this.data.map(
      (value) =>
        ({
          ...value,
          reservationNumber: formatReservationNumber(value.reservationNumber),
          initialComment: value.initialComment ?? 'N/A',
          initialStatus: value.initialStatus
            ? this.t(`reservations.statuses.${value.initialStatus}`)
            : 'N/A',
          endStatus: value.endStatus
            ? this.t(`reservations.statuses.${value.endStatus}`)
            : 'N/A',
          date: DateTime.fromMillis(value.date.toMillis())
            .setLocale('en')
            .setZone(TimeZone.EasternTime)
            .toLocaleString(DateTime.DATETIME_MED),
        } as unknown as ReservationLog)
    );
  }

  static generate(
    data: ReservationLog[],
    fromDate: Nullable<DateTime>,
    toDate: Nullable<DateTime>,
    t: TFunction<'translation', undefined>
  ) {
    const report = new reservationLogsCsv(data, fromDate, toDate, t);

    report.generateCSV();
  }
}

export default reservationLogsCsv;
