import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { Drawer, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';

import theme from '../../theme';
import { useQuote, useQuoteInternalNotes } from '../../hooks';
import * as Sx from './QuoteNotesDrawer.styles';
import { QuoteInternalNotesTab } from './components/QuoteInternalNotesTab/QuoteInternalNotesTab.component';
import QuoteNotesDrawerProps from './QuoteNotesDrawer.props';
import { QuoteCustomerNoteTab } from './components/QuoteCustomerNoteTab/QuoteCustomerNoteTab.component';

enum NotesTab {
  Internal,
  Customer,
}

export const QuoteNotesDrawer = ({
  draftInternalNotes,
  setDraftInternalNotes,
  quoteId,
  open,
  setOpen,
}: QuoteNotesDrawerProps) => {
  const { t } = useTranslation();

  const { list: internalNotes } = useQuoteInternalNotes(quoteId);
  const { item: quote, save: saveQuote } = useQuote(quoteId);
  const [tab, setTab] = useState(NotesTab.Internal);

  const isNewQuote = quoteId === 'new';

  return (
    <Drawer anchor="right" open={open} PaperProps={{ sx: Sx.drawerPaper }}>
      <Sx.StyledHeaderContainer display="flex" justifyContent="space-between">
        <Grid
          container
          alignItems="center"
          display="flex"
          gap={2}
          wrap="nowrap"
          mr={3}
        >
          <Typography variant="h3" color={theme.palette.common.white}>
            {t('quotes.edit.notes.title')}
          </Typography>
        </Grid>
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
        >
          <CloseIcon htmlColor={theme.palette.common.white} />
        </IconButton>
      </Sx.StyledHeaderContainer>
      <Sx.StyledQuoteNotesContainer>
        <Tabs
          value={tab}
          onChange={(_, tab) => setTab(tab)}
          sx={Sx.borderBottom}
        >
          <Tab
            label={t('quotes.edit.notes.internal.tab', {
              count: internalNotes.length || draftInternalNotes.length,
            })}
            sx={{ width: isNewQuote ? '100%' : '50% ' }}
          />
          {!isNewQuote && (
            <Tab
              label={t('quotes.edit.notes.customer.tab').concat(
                quote?.customerNote?.content.trim() ? ' (1)' : ''
              )}
              sx={{ width: '50% ' }}
            />
          )}
        </Tabs>
        <QuoteInternalNotesTab
          quoteId={quoteId || 'new'}
          active={tab === NotesTab.Internal}
          internalNotes={internalNotes}
          draftInternalNotes={draftInternalNotes}
          setDraftInternalNotes={setDraftInternalNotes}
        />
        {!!quote && (
          <QuoteCustomerNoteTab
            active={tab === NotesTab.Customer}
            quote={quote}
            saveQuote={saveQuote}
          />
        )}
      </Sx.StyledQuoteNotesContainer>
    </Drawer>
  );
};
