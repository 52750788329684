import { Typography } from '@mui/material';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Outlet } from 'react-router-dom';
import { Stack } from '@mui/system';
import {
  Container,
  DecoratedHeader,
  CurrentReservationTable,
  ReservationFilters,
  ReservationHistoryTable,
} from '../../components';
import { RequirePermission } from '../../security';
import { ConsultationMode, UserPermission } from '../../enums/';
import {
  useCompany,
  useCurrentUser,
  usePageTitle,
  usePermission,
} from '../../hooks/';
import { Tab } from '../../navigation';
import {
  AssociatedCompany,
  ReservationFilters as ReservationFiltersType,
} from '../../types';
import { getPageTitle } from '../../utils/pages';
import { getDefaultReservationFilters } from '../../utils/reservationFilters';

interface Props {
  mode?: ConsultationMode;
}

const Reservations: React.FC<Props> = ({
  mode = ConsultationMode.Current,
}: Props) => {
  usePageTitle(
    mode === ConsultationMode.Current
      ? getPageTitle('reservations')
      : getPageTitle('reservations.history')
  );
  const { t } = useTranslation();
  const [filters, setFilters] = useState<ReservationFiltersType>(
    getDefaultReservationFilters()
  );

  const currentUser = useCurrentUser();
  const { item: company } = useCompany(currentUser.companyRef.id);

  const associatedCompanies: AssociatedCompany[] = useMemo(() => {
    if (!company?.associatedCompanies) return [];

    return Object.values(company.associatedCompanies) as AssociatedCompany[];
  }, [company?.associatedCompanies]);

  const { hasPermission } = usePermission();
  const canViewAgencyReservations = hasPermission(
    UserPermission.ManageAgencyCustomerReservations
  );

  return (
    <RequirePermission
      oneOf={[
        UserPermission.ManageAgencyCustomerReservations,
        UserPermission.ManageOwnReservations,
      ]}
    >
      <Container>
        <DecoratedHeader
          icon={<HourglassTopIcon />}
          title={t('navigation.reservations')}
          variant="colored"
        >
          <Tab title={t('reservations.current')} to={'/agent/reservations'} />
          <Tab
            title={t('reservations.history')}
            to={'/agent/reservations/history'}
          />
        </DecoratedHeader>
      </Container>
      <Container
        backgroundColor="secondary.light"
        roundedTopCorners={true}
        sx={{ pt: { xs: mode === ConsultationMode.Current ? 2 : 0, md: 0 } }}
      >
        <Stack>
          <Typography
            variant="h2"
            sx={{ mt: 4, mb: 3, display: { xs: 'none', md: 'block' } }}
          >
            {mode === ConsultationMode.Current
              ? t('reservations.current')
              : t('reservations.history')}
          </Typography>
        </Stack>
      </Container>
      <ReservationFilters
        companies={associatedCompanies}
        filters={filters}
        setFilters={setFilters}
        mode={mode}
      />
      {mode === ConsultationMode.Current ? (
        <CurrentReservationTable
          // Key is used to force the data to be reset when switching between
          // permission modes, as useLocalCollection attempts to filter
          // locally
          key={filters.showOnlyMyReservations.toString()}
          isAgent
          canViewAgencyReservations={canViewAgencyReservations}
          filters={filters}
          mode={mode}
        />
      ) : (
        <ReservationHistoryTable
          isAgent
          canViewAgencyReservations={canViewAgencyReservations}
          filters={filters}
          mode={mode}
        />
      )}
      <Outlet />
    </RequirePermission>
  );
};

export default Reservations;
