import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ClickAwayListener,
  Grow,
  IconButton,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { MultilineTypography } from '../..';
import { popperStyles } from './ReasonForLost.styles';
import { ReasonForLostPopperProps } from './ReasonForLost.props';

export const ReasonForLostPopper = ({
  onEditClick,
  onClose,
  onOpen,
  reason,
}: ReasonForLostPopperProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    onOpen();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClickAway = (event: Event) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    handlePopperClose();
  };

  const handlePopperClose = () => {
    onClose();
    setOpen(false);
  };

  const handleEditClick = () => {
    setOpen(false);
    onEditClick();
  };

  return !reason ? null : (
    <>
      <IconButton ref={anchorRef} onClick={handleToggle}>
        <AnnouncementOutlinedIcon style={{ transform: 'scaleX(-1)' }} />
      </IconButton>
      <Popper
        sx={popperStyles}
        open={open}
        anchorEl={anchorRef.current}
        transition
        placement="top-end"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper elevation={2} sx={{ borderRadius: '0.3rem' }}>
              <ClickAwayListener
                onClickAway={handleClickAway}
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
              >
                <Stack
                  direction="column"
                  spacing={1}
                  px={2}
                  py={1}
                  minWidth={250}
                  maxWidth={350}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography fontSize="1rem" fontWeight="bold">
                      {t('quotes.edit.products.table.reasonForLost.title')}
                    </Typography>
                    <IconButton onClick={handlePopperClose}>
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                  <MultilineTypography>{reason}</MultilineTypography>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Button
                      variant="outlined"
                      sx={{ paddingX: 2 }}
                      endIcon={<EditOutlinedIcon fontSize="small" />}
                      onClick={handleEditClick}
                    >
                      {t('forms.edit')}
                    </Button>
                  </Stack>
                </Stack>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
