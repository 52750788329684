import React, {
  Dispatch,
  Fragment,
  ReactElement,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import {
  TableBody,
  TableContainer,
  TableSortLabel,
  TableHead,
  Typography,
  TableRow,
  TableCell,
  Collapse,
  Checkbox,
  useMediaQuery,
  Theme,
  SxProps,
  useTheme,
  Stack,
  Tooltip,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import { difference, get, union } from 'lodash';

import { Model } from '../types';
import MutableSortable from '../types/MutableSortable';
import Pageable from '../types/Pageable';
import { MoreMenu, Pagination } from '../components';
import { mapGroupBy } from '../utils/array';
import { DataTableColumnProps } from './DataTableColumnProps';
import { StyledTable, StyledTableRow, StyledTableCell } from '.';

type GroupItem = {
  groupKey: string;
  isGroup: boolean;
};

interface Props<T extends Model> {
  children: Array<ReactElement<DataTableColumnProps<T>> | boolean | undefined>;
  compactHeader?: boolean;
  data?: Array<T>;
  isDisabledFunction?: (item: T) => boolean;
  isSelectedFunction?: (items: Array<T>, item: T) => boolean;
  loading?: boolean;
  menuItems?: (item: T) => ReactNode;
  pagination?: Pageable;
  sorting?: MutableSortable;
  rowCollapseContent?: (item: T) => ReactNode;
  rowCollapseOpened?: (item: T) => boolean;
  rowOnClick?: (item: T) => void;
  selected?: T[];
  setSelectAll?: Dispatch<SetStateAction<T[]>>;
  mobileBorder?: string;
  mobileHideMenuItems?: boolean;
  noResultElement?: ReactNode;
  tableBodyStyles?: SxProps;
  tableHeaderStyles?: SxProps;
  tableStyles?: SxProps;
  specialFirstBodyRow?: JSX.Element;
  disableActiveItem?: boolean;
  isRowOnError?: (item: T) => boolean;
  groupKey?: (item: T) => string;
  renderGroupHeader?: (groupItems: T[], isOpen: boolean) => JSX.Element;
  openedGroup?: string[];
  setOpenedGroup?: Dispatch<SetStateAction<string[]>>;
  customRowKey?: (item: T) => string;
}

export const DataTable = <T extends Model>({
  compactHeader = false,
  children,
  data,
  isDisabledFunction,
  isSelectedFunction,
  loading,
  menuItems,
  pagination,
  sorting,
  rowCollapseContent,
  rowCollapseOpened,
  rowOnClick,
  selected,
  setSelectAll,
  mobileBorder,
  mobileHideMenuItems = false,
  noResultElement,
  tableBodyStyles,
  tableHeaderStyles,
  tableStyles,
  specialFirstBodyRow,
  disableActiveItem = false,
  isRowOnError,
  groupKey,
  renderGroupHeader,
  openedGroup,
  setOpenedGroup,
  customRowKey,
}: Props<T>) => {
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState<T | null>(null);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  const { palette } = useTheme();

  const extractedChildren = useMemo(() => {
    const result = [];
    for (const child of children) {
      if ((child as ReactElement)?.type === Fragment) {
        const fragmentChildren = (child as ReactElement).props[
          'children'
        ] as Array<DataTableColumnProps<T>>;
        result.push(...fragmentChildren);
      } else {
        result.push(child);
      }
    }

    return result;
  }, [children]);

  const groupedData = useMemo(
    () => (groupKey && data ? mapGroupBy(data, groupKey) : null),
    [data, groupKey]
  );

  const flatGroupedData = useMemo(() => {
    if (!groupedData) {
      return null;
    }
    const result: (GroupItem | T)[] = [];
    groupedData.forEach((value, key) => {
      result.push(...[{ groupKey: key, isGroup: true }, ...value]);
    });
    return result;
  }, [groupedData]);

  const renderData = useMemo(
    () => flatGroupedData ?? data,
    [flatGroupedData, data]
  );

  const isGroupOpened = (key: string) => openedGroup?.includes(key);

  const toggleOpenGroup = (key: string) => {
    if (!setOpenedGroup || !openedGroup) {
      return;
    }
    if (isGroupOpened(key)) {
      setOpenedGroup(openedGroup.filter((item) => item !== key));
    } else {
      setOpenedGroup([...openedGroup, key]);
    }
  };

  const columns = extractedChildren.filter((x) => x) as Array<
    ReactElement<DataTableColumnProps<T>>
  >;

  const hasMenuItems = useMemo(
    () => menuItems && data?.some((item) => menuItems(item)),
    [data, menuItems]
  );

  const onRowClick = (dataItem: T) => {
    if (mobileBorder !== 'none') {
      if (activeItem === dataItem) {
        setActiveItem(null);
      } else {
        setActiveItem(dataItem);
      }
    }
    if (rowOnClick) {
      rowOnClick(dataItem);
    }
  };

  const selectAllChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectAll && data && setSelectAll((prev) => union(prev, data));
    } else {
      setSelectAll && data && setSelectAll((prev) => difference(prev, data));
    }
  };

  const getHeaderChildren = (child: ReactElement<DataTableColumnProps<T>>) => {
    let title: string | ReactNode = child.props.title;
    if (child.props.tooltip && child.props.title) {
      title = (
        <Tooltip title={child.props.tooltip}>
          <span>{child.props.title}</span>
        </Tooltip>
      );
    } else if (child.props.property.toString() === 'selectable') {
      title = (
        <Checkbox
          checked={
            selected &&
            data &&
            selected.length > 0 &&
            data.every((i) => selected.includes(i))
          }
          onChange={selectAllChecked}
        />
      );
    }
    return (
      <>
        {title}
        {sorting && !child.props.disableSort && data && data.length > 0 && (
          <TableSortLabel
            active={sorting?.sortField === child.props.property}
            direction={
              sorting?.sortField === child.props.property
                ? sorting?.sortDirection
                : undefined
            }
            IconComponent={ArrowDropDownIcon}
            onClick={() =>
              sorting?.sortByField(child.props.property.toString())
            }
          />
        )}
      </>
    );
  };

  const renderGroupHeaderBase = (dataItem: GroupItem) => {
    const isOpen = isGroupOpened(dataItem.groupKey);
    return (
      <StyledTableRow
        key={dataItem.groupKey}
        onClick={() => toggleOpenGroup(dataItem.groupKey)}
        sx={{
          border: 'none !important',
          cursor: 'pointer',
          backgroundColor: palette.secondary.light,
        }}
      >
        {renderGroupHeader && groupedData ? (
          renderGroupHeader(groupedData.get(dataItem.groupKey) ?? [], !!isOpen)
        ) : (
          <StyledTableCell key="groupKey" colSpan={columns.length}>
            <Stack direction="row" justifyContent="space-between">
              <Typography>{dataItem.groupKey}</Typography>
              <KeyboardArrowDownIcon
                key="expand"
                sx={{
                  transform: `rotate(${isOpen ? '-180deg' : '0'})`,
                  transition: 'transform 0.1s',
                }}
              />
            </Stack>
          </StyledTableCell>
        )}
      </StyledTableRow>
    );
  };

  return (
    <TableContainer sx={{ ...tableStyles }}>
      <StyledTable>
        <TableHead sx={{ ...tableHeaderStyles }}>
          <StyledTableRow>
            {columns &&
              columns.map((child) => (
                <StyledTableCell
                  key={child.props.property.toString()}
                  onClick={() =>
                    sorting &&
                    !child.props.disableSort &&
                    sorting.sortByField(child.props.property.toString())
                  }
                  width={
                    child.props.property.toString() !== 'selectable'
                      ? child.props.width
                      : '42px'
                  }
                  sx={{
                    cursor:
                      sorting && !child.props.disableSort
                        ? 'pointer'
                        : undefined,
                    display: {
                      md: child.props.hidden ? 'none' : 'table-cell',
                    },
                    textAlign: {
                      md: child.props.textAlign ?? 'left',
                      sm: 'left',
                    },
                    borderBottom: flatGroupedData?.length
                      ? '1px solid #BBBBBBBB !important'
                      : undefined,
                  }}
                  sortDirection={
                    child.props.disableSort ? sorting?.sortDirection : undefined
                  }
                >
                  {compactHeader ? (
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      textAlign={child.props.textAlign ?? 'left'}
                    >
                      {getHeaderChildren(child)}
                    </Stack>
                  ) : (
                    getHeaderChildren(child)
                  )}
                </StyledTableCell>
              ))}
            {hasMenuItems && <StyledTableCell className="menu-items" />}
          </StyledTableRow>
        </TableHead>
        <TableBody sx={{ ...tableBodyStyles }}>
          {specialFirstBodyRow}
          {renderData &&
            renderData.map((dataItem, index) => {
              const isGroupItem =
                'groupKey' in dataItem && 'isGroup' in dataItem;
              const isOpen =
                groupKey &&
                isGroupOpened(
                  isGroupItem ? dataItem.groupKey : groupKey(dataItem)
                );
              return isGroupItem ? (
                renderGroupHeaderBase(dataItem)
              ) : (
                <React.Fragment
                  key={customRowKey ? customRowKey(dataItem) : dataItem.id}
                >
                  <StyledTableRow
                    onClick={() => {
                      if (
                        !(isDisabledFunction && isDisabledFunction(dataItem))
                      ) {
                        onRowClick(dataItem);
                      }
                    }}
                    className={`${index % 2 === 1 ? 'alternate-color' : ''} ${
                      activeItem === dataItem && mobileBorder !== 'none'
                        ? 'active-item'
                        : ''
                    } ${
                      (!groupKey || isOpen) &&
                      selected &&
                      isSelectedFunction &&
                      selected?.length > 0 &&
                      isSelectedFunction(selected, dataItem)
                        ? 'selected-item'
                        : ''
                    } ${
                      isDisabledFunction && isDisabledFunction(dataItem)
                        ? 'disabled-item'
                        : ''
                    }`}
                    sx={{
                      ...(isRowOnError &&
                        isRowOnError(dataItem) && {
                          backgroundColor: `${palette.error.light} !important`,
                        }),
                      ...(rowOnClick && {
                        cursor: 'pointer',
                      }),
                      paddingTop: {
                        xs: '1em',
                      },
                      paddingBottom: {
                        xs: '0.5em',
                      },
                      border: {
                        md: 'inherit',
                        xs: mobileBorder,
                      },
                      borderBottom: {
                        md: isOpen
                          ? `5px solid ${palette.common.white} !important`
                          : 'none  !important',
                      },
                      '&active': {
                        border: {
                          md: 'inherit',
                          xs: mobileBorder,
                        },
                      },
                      '&.alternate-color': {
                        backgroundColor: groupKey
                          ? palette.common.white
                          : undefined,
                      },
                      visibility: !groupKey || isOpen ? 'visible' : 'collapse',
                      display: {
                        md: 'table-row',
                        xs: !groupKey || isOpen ? 'flex' : 'none',
                      },
                    }}
                  >
                    {columns &&
                      columns.map((child, index) => (
                        <StyledTableCell
                          sx={{
                            display: {
                              xs:
                                child.props.mobileHidden &&
                                (activeItem !== dataItem ||
                                  !child.props.mobileVisibleWhenActive)
                                  ? 'none'
                                  : child.props.mobileInlineHeaderDirection
                                  ? 'flex'
                                  : 'table-cell',
                              md: child.props.hidden ? 'none' : 'table-cell',
                            },
                            color: {
                              xs:
                                index === 0 &&
                                activeItem === dataItem &&
                                !disableActiveItem
                                  ? 'secondary.main'
                                  : 'inherit',
                              md: 'inherit',
                            },
                            flexBasis: {
                              xs:
                                child.props.mobileWidth ||
                                (index === 0 && hasMenuItems ? '75%' : '100%'),
                              md: 'unset',
                            },
                            fontWeight: {
                              xs: index === 0 ? 'bold' : 'unset',
                              md: 'unset',
                            },
                            height: {
                              xs: child.props.mobileHeight,
                              md: 'unset',
                            },
                            order: {
                              xs: child.props.mobileOrder ?? index - 1,
                              md: 'unset',
                            },
                            textAlign: {
                              md: child.props.textAlign ?? 'left',
                              xs: child.props.mobileTextAlign ?? 'left',
                            },
                            border:
                              groupKey && !isOpen
                                ? 'none !important'
                                : undefined,

                            ...child.props.otherStyling,
                          }}
                          key={child.props.property.toString()}
                        >
                          <>
                            {index !== 0 &&
                              child.props.hasMobileLabel !== false && (
                                <Typography
                                  variant="caption"
                                  sx={{
                                    display: { xs: 'block', md: 'none' },
                                    alignSelf: {
                                      xs: child.props
                                        .mobileInlineHeaderDirection
                                        ? 'center'
                                        : 'inherit',
                                      md: 'inherit',
                                    },
                                    order:
                                      child.props
                                        .mobileInlineHeaderDirection === 'rtl'
                                        ? 1
                                        : 0,
                                    flexGrow:
                                      child.props
                                        .mobileInlineHeaderDirection === 'ltr'
                                        ? 10
                                        : 0,
                                  }}
                                >
                                  {child.props.title}
                                </Typography>
                              )}
                            {child.props.activeOutput && activeItem === dataItem
                              ? child.props.activeOutput(
                                  get(dataItem, child.props.property),
                                  dataItem
                                )
                              : child.props.output
                              ? child.props.output(
                                  get(dataItem, child.props.property),
                                  dataItem
                                )
                              : get(dataItem, child.props.property)}
                          </>
                        </StyledTableCell>
                      ))}
                    {hasMenuItems &&
                      menuItems &&
                      !(mobileHideMenuItems && isMobile) && (
                        <StyledTableCell
                          className="menu-items"
                          sx={{
                            flexBasis: {
                              xs: '25%',
                              md: 'unset',
                            },
                            height: 0,
                            order: -1,
                            textAlign: 'right',
                            '& button': {
                              visibility: menuItems(dataItem)
                                ? 'visible'
                                : 'hidden',
                            },
                          }}
                        >
                          <MoreMenu>{menuItems(dataItem)}</MoreMenu>
                        </StyledTableCell>
                      )}
                  </StyledTableRow>

                  {rowCollapseContent && rowCollapseOpened && (
                    <TableRow className="row-collapse-content">
                      <TableCell
                        style={{ padding: 0, borderBottom: 'none' }}
                        colSpan={
                          extractedChildren.length + (hasMenuItems ? 1 : 0)
                        }
                      >
                        <Collapse
                          sx={{
                            position: { xs: 'fixed', md: 'static' },
                            left: 0,
                            bottom: -1,
                            width: '100%',
                            zIndex: 5000,
                            ...(isRowOnError &&
                              isRowOnError(dataItem) && {
                                backgroundColor: `${palette.error.light} !important`,
                              }),
                          }}
                          in={rowCollapseOpened(dataItem)}
                        >
                          {rowCollapseOpened(dataItem) &&
                            rowCollapseContent(dataItem)}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              );
            })}
        </TableBody>
      </StyledTable>
      {data &&
        data.length === 0 &&
        (loading === undefined || !loading) &&
        !specialFirstBodyRow &&
        (noResultElement ? (
          noResultElement
        ) : (
          <Typography variant="h3" sx={{ mt: 4, mb: 4 }}>
            {t('common.noItemsFound')}
          </Typography>
        ))}

      {pagination && (
        <Pagination
          pagination={pagination}
          disabled={!data || data.length === 0}
        />
      )}
    </TableContainer>
  );
};
