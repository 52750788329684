import { useMemo } from 'react';
import {
  and,
  or,
  QueryCompositeFilterConstraint,
  Timestamp,
  where,
} from 'firebase/firestore';
import { DateTime } from 'luxon';
import { Filter, QuotesFilters } from '../types';
import {
  QuoteExpirationTimeFilters,
  QuoteFilterOption,
  UserPermission,
} from '../enums';
import { useCurrentUser } from './useCurrentUser';
import { TEXT_INCLUDE_END } from './useCollection';
import { useAuthentication, usePermission } from '.';

// Every quotes should have a 'filters' property that is an array that contains the string 'any'.
// This is the default array-contains value when no search field in the table is applied.
// This is in order to decrease the number of index needed in Firebase.
const ANY_FILTERS = 'any';

export const MAX_DATE = DateTime.fromJSDate(new Date(9999, 1, 1));
const MIN_DATE = DateTime.fromJSDate(new Date(1, 1, 1));

export function useQuotesFilters(filters: QuotesFilters) {
  const { isAgent, isAdmin } = useAuthentication();
  const { hasPermission } = usePermission();
  const user = useCurrentUser();

  const expirationTimeFilter = useMemo(() => {
    const now = DateTime.now();
    let startDateRange = MIN_DATE;
    let endDateRange = MAX_DATE;

    switch (filters.expirationTime) {
      case QuoteExpirationTimeFilters.ONE_WEEK:
        startDateRange = now;
        endDateRange = now.plus({ weeks: 1 });
        break;
      case QuoteExpirationTimeFilters.ONE_TWO_WEEK:
        startDateRange = now.plus({ weeks: 1 });
        endDateRange = now.plus({ weeks: 2 });
        break;
      case QuoteExpirationTimeFilters.TWO_FOUR_WEEK:
        startDateRange = now.plus({ weeks: 2 });
        endDateRange = now.plus({ weeks: 4 });
        break;
      case QuoteExpirationTimeFilters.FOUR_EIGHT_WEEK:
        startDateRange = now.plus({ weeks: 4 });
        endDateRange = now.plus({ weeks: 8 });
        break;
      case QuoteExpirationTimeFilters.EIGHT_WEEK:
        startDateRange = now.plus({ weeks: 8 });
        endDateRange = MAX_DATE.minus({ year: 1 }); // To avoid returning null expirationTime
        break;
      default:
        break;
    }

    const startFilter = [
      'expirationTimeNormalized',
      '>=',
      Timestamp.fromMillis(startDateRange.setZone(user.timeZone).toMillis()),
    ];

    const endFilter = [
      'expirationTimeNormalized',
      '<=',
      Timestamp.fromMillis(endDateRange.setZone(user.timeZone).toMillis()),
    ];

    return [startFilter, endFilter] as Filter[];
  }, [filters.expirationTime, user.timeZone]);

  const queryFilters = useMemo(() => {
    const {
      companyId,
      endDate,
      searchField,
      startDate,
      requestorId,
      typeOfBuy,
      employeeId,
      status,
      region,
    } = filters;

    const filterList: Filter[] = [];

    let composite: QueryCompositeFilterConstraint | undefined = undefined;
    const normalizedValue = searchField
      ?.toLowerCase()
      .replace(/[^a-z0-9]/g, '');
    composite = or(
      and(
        where('projectNameNormalized.fr', '>=', normalizedValue || ''),
        where(
          'projectNameNormalized.fr',
          '<=',
          (normalizedValue || '') + TEXT_INCLUDE_END
        )
      ),
      and(
        where('projectNameNormalized.en', '>=', normalizedValue || ''),
        where(
          'projectNameNormalized.en',
          '<=',
          (normalizedValue || '') + TEXT_INCLUDE_END
        )
      ),
      and(
        where('quoteNumberNormalized', '>=', normalizedValue || ''),
        where(
          'quoteNumberNormalized',
          '<=',
          (normalizedValue || '') + TEXT_INCLUDE_END
        )
      ),
      and(
        where('nciProjectIdNormalized', '>=', normalizedValue || ''),
        where(
          'nciProjectIdNormalized',
          '<=',
          (normalizedValue || '') + TEXT_INCLUDE_END
        )
      )
    );

    const subFilters: string[] = [];
    if (requestorId) {
      subFilters.push(`${QuoteFilterOption.Requestor}=${requestorId}`);
    }
    if (typeOfBuy) {
      subFilters.push(`${QuoteFilterOption.TypeOfBuy}=${typeOfBuy}`);
    }
    if (employeeId) {
      subFilters.push(`${QuoteFilterOption.Employee}=${employeeId}`);
    }
    if (status) {
      subFilters.push(`${QuoteFilterOption.Status}=${status}`);
    }
    if (region) {
      subFilters.push(`${QuoteFilterOption.Region}=${region}`);
    }

    if (subFilters.length) {
      filterList.push(['filters', 'array-contains', subFilters.join(';')]);
    } else {
      filterList.push(['filters', 'array-contains', ANY_FILTERS]);
    }

    filterList.push(...expirationTimeFilter);

    const startDateRange = startDate?.isValid ? startDate : MIN_DATE;
    filterList.push([
      'lastModified',
      '>=',
      Timestamp.fromMillis(
        startDateRange.setZone(user.timeZone).startOf('day').toMillis()
      ),
    ]);

    const endDateRange = endDate?.isValid ? endDate : MAX_DATE;
    filterList.push([
      'lastModified',
      '<=',
      Timestamp.fromMillis(
        endDateRange.setZone(user.timeZone).endOf('day').toMillis()
      ),
    ]);

    if (companyId) {
      filterList.push(['companyId', '==', companyId]);
    }

    if (isAgent) {
      if (!hasPermission(UserPermission.ViewAgencyQuotes)) {
        filterList.push(['customerId', '==', user.id]);
      } else {
        filterList.push(['agencyId', '==', user.companyRef.id]);
      }
    } else if (!isAdmin) {
      if (!hasPermission(UserPermission.ViewCompanyQuotes)) {
        filterList.push(['customerId', '==', user.id]);
      } else {
        filterList.push(['agencyId', '==', null]);
        filterList.push(['companyId', '==', user.companyRef.id]);
      }
    }

    if (!filterList.length) {
      return { filter: undefined, composite: undefined };
    }

    return { filter: filterList, composite: [composite] };
  }, [
    filters,
    hasPermission,
    isAdmin,
    isAgent,
    user.companyRef.id,
    user.id,
    user.timeZone,
    expirationTimeFilter,
  ]);

  return queryFilters;
}
