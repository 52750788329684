import { DateTime } from 'luxon';
import { TFunction } from 'i18next';
import { SanitizedQuoteConversionRate } from '../../models';
import { Nullable, QuoteConversionRateFilters } from '../../types';
import report from './report';

const PREFIX = 'NC_QuoteConversionRates';
const NAMESPACE = 'reports.quoteConversionRate';
const EXPORTED_KEYS = [
  'quoted',
  'expired',
  'won',
  'lost',
  'rateDecimal',
  'totalQuoted',
  'totalWon',
  'totalWonRate',
];

class quotesConversionRateCsv extends report<SanitizedQuoteConversionRate> {
  private readonly data: SanitizedQuoteConversionRate[];
  private readonly fromDate: Nullable<DateTime>;
  private readonly toDate: Nullable<DateTime>;
  private readonly byCompany: boolean;

  constructor(
    data: SanitizedQuoteConversionRate[],
    filters: QuoteConversionRateFilters,
    t: TFunction
  ) {
    super(
      PREFIX,
      NAMESPACE,
      [
        ...(filters.searchByCompany ? ['company', 'user'] : ['productType']),
        ...EXPORTED_KEYS,
      ],
      t
    );

    this.data = data;
    this.fromDate = filters.startDate;
    this.toDate = filters.endDate;
    this.byCompany = filters.searchByCompany;
  }

  protected override getFilename(): string {
    const fromDate = this.fromDate?.toFormat('LLddyyyy');
    const toDate = this.toDate?.toFormat('LLddyyyy');

    return `${PREFIX}_${this.t(
      `${NAMESPACE}.${this.byCompany ? 'byCompany' : 'byProductType'}`
    )}_${fromDate}-${toDate}.csv`.replace(/ /g, '');
  }

  protected getSanitizedData(): SanitizedQuoteConversionRate[] {
    return this.data
      .map(
        (conversion) =>
          ({
            ...conversion,
            company: conversion.name,
            productType: this.byCompany
              ? this.t('reports.quoteConversionRate.byCompanyTotal')
              : conversion.name,
            rateDecimal: conversion.rate / 100,
            totalWonRate: conversion.totalWonRate / 100,
          } as SanitizedQuoteConversionRate)
      )
      .flatMap((conversion) => [
        conversion,
        ...conversion.subQuoteConversionRates.map(
          (stat) =>
            ({
              ...stat,
              company: '',
              user: stat.name,
              productType: stat.name,
              rateDecimal: stat.rate / 100,
              totalWonRate: stat.totalWonRate / 100,
            } as SanitizedQuoteConversionRate)
        ),
      ]);
  }

  static generate(
    data: SanitizedQuoteConversionRate[],
    filters: QuoteConversionRateFilters,
    t: TFunction
  ) {
    const report = new quotesConversionRateCsv(data, filters, t);
    report.generateCSV();
  }
}

export default quotesConversionRateCsv;
