import { Stack, styled, SxProps } from '@mui/material';

export const GridStyles: SxProps = {
  '&.MuiGrid-root>.MuiGrid-root.MuiGrid-item': {
    pt: 0,

    '& .MuiFormControl-root': {
      m: 0,
    },
  },
};

export const RadioGroupStyles: SxProps = {
  mt: 0,
  mr: 1.5,
};

export const ReadonlyStyledStack = styled(Stack)(() => {
  return {
    flexDirection: 'column',
    '& .MuiTypography-root': {
      width: '100%',
      textWrap: 'pretty',
      wordBreak: 'break-word',
    },
  };
});

export const StyledStack = styled(Stack)(({ theme }) => {
  return {
    flexDirection: 'row',
    width: 'inherit',

    '& .MuiFormControl-root': {
      minWidth: '30%',
    },

    [theme.breakpoints.down('lg')]: {
      '& .MuiFormControl-root': {
        '&:has(+ .MuiGrid-root>.MuiGrid-root:only-child)': {
          minWidth: '40%',
        },
      },
    },

    [theme.breakpoints.up('lg')]: {
      '& .MuiGrid-root>.MuiGrid-root:only-child': {
        maxWidth: '60%',
      },
    },

    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
    },
  };
});
