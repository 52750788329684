import { useTranslation } from 'react-i18next';
import { Timestamp } from 'firebase/firestore';
import { useMemo } from 'react';
import { Grid } from '@mui/material';
import { DataTable, DataTableColumn } from '../../tables';
import { usePagination, useSorting } from '../../hooks';
import { useInventorySearchStats } from '../../hooks/useInventorySearchStats';
import { Filter } from '../../types';
import { DefaultValue } from '../../enums';
import { CONDUCTOR_GAUGES } from '../InventoryFilters/InventoryFilters.constants';
import { SanitizedProductSearchStats } from '../../models';
import { ExportMenu, Show } from '..';
import { inventorySearchesCsv, inventorySearchesPdf } from '../../reports';
import { InventorySearchesTableProps } from './InventorySearchesTable.props';
import * as Sx from './InventorySearchesTable.styles';

export const InventorySearchesTable = ({
  filters,
}: InventorySearchesTableProps) => {
  const { t, i18n } = useTranslation();

  const pagination = usePagination();
  const sorting = useSorting('nbOfSearches', {
    sortDirection: 'desc',
    sortFieldOverrides: {
      gauge: (e) =>
        CONDUCTOR_GAUGES.indexOf((e as SanitizedProductSearchStats).gauge),
      productType: (e) =>
        t(`productTypes.${(e as SanitizedProductSearchStats).productType}`),
      productSubType: (e) =>
        t(
          `productSubTypes.${(e as SanitizedProductSearchStats).productSubType}`
        ),
      material: (e) =>
        t(`materials.${(e as SanitizedProductSearchStats).material}`),
    },
  });

  const queryFilters = useMemo(() => {
    if (!filters) return [];

    const result = [];

    const {
      startDate,
      endDate,
      productType,
      productSubType,
      material,
      voltage,
    } = filters;

    if (startDate)
      result.push([
        'date',
        '>=',
        Timestamp.fromMillis(startDate.startOf('day').toMillis()),
      ]);

    if (endDate)
      result.push([
        'date',
        '<=',
        Timestamp.fromMillis(endDate.endOf('day').toMillis()),
      ]);

    if (productType) result.push(['productType', '==', productType]);
    if (productSubType) result.push(['productSubType', '==', productSubType]);
    if (material) result.push(['material', '==', material]);
    if (voltage) result.push(['voltage', '==', voltage]);

    return result;
  }, [filters]) as Filter[];

  const {
    loading,
    list: searchStats,
    unpaginatedResults,
  } = useInventorySearchStats({
    sorting,
    pagination,
    filters: queryFilters,
  });

  const exportCSV = async () => {
    if (!filters) return;

    inventorySearchesCsv.generate(
      unpaginatedResults,
      filters.startDate,
      filters.endDate,
      t
    );
  };

  const exportPDF = async () => {
    if (!filters) return;

    inventorySearchesPdf.generate(unpaginatedResults, filters, t, i18n);
  };

  return (
    <>
      <Show if={searchStats.length > 0}>
        <Grid display="flex" justifyContent="flex-end" mt={1} mb={0}>
          <ExportMenu
            onCSVClicked={() => exportCSV()}
            onPDFClicked={() => exportPDF()}
          />
        </Grid>
      </Show>
      <DataTable
        data={searchStats}
        pagination={pagination}
        sorting={sorting}
        loading={loading}
        tableStyles={Sx.tableStyles}
        tableHeaderStyles={Sx.tableHeaderStyles}
        compactHeader
      >
        <DataTableColumn
          property="standardType"
          title={t('reports.inventorySearches.inventory')}
          width="5%"
          output={(value) => t(`inventoryType.${value}`)}
        />
        <DataTableColumn
          property="productType"
          title={t('reports.inventorySearches.productType')}
          width="10%"
          output={(value) => t(`productTypes.${value}`)}
        />
        <DataTableColumn
          property="productSubType"
          title={t('reports.inventorySearches.productSubType')}
          width="10%"
          output={(value) =>
            value ? t(`productSubTypes.${value}`) : DefaultValue.Dash
          }
        />
        <DataTableColumn
          property="material"
          title={t('reports.inventorySearches.material')}
          width="10%"
          output={(value) => t(`materials.${value}`)}
        />
        <DataTableColumn
          property="voltage"
          title={t('reports.inventorySearches.voltage')}
          width="10%"
          output={(value) => (value ? value : DefaultValue.NotAvailable)}
        />
        <DataTableColumn
          property="numberOfConductors"
          title={t('reports.inventorySearches.numberOfConductors')}
          width="10%"
        />
        <DataTableColumn
          property="gauge"
          title={t('reports.inventorySearches.gauge')}
          width="10%"
        />
        <DataTableColumn
          property="nbOfSearches"
          title={t('reports.inventorySearches.nbOfSearches')}
          width="10%"
        />
        <DataTableColumn
          property="emptyResults"
          title={t('reports.inventorySearches.emptyResults')}
          width="10%"
        />
        <DataTableColumn
          property="lowInventory"
          title={t('reports.inventorySearches.lowInventory')}
          width="10%"
        />
        <DataTableColumn
          property="reservationWasMade"
          title={t('reports.inventorySearches.reservationWasMade')}
          width="10%"
        />
      </DataTable>
    </>
  );
};
