import { DateTime } from 'luxon';
import { TFunction } from 'i18next';
import { TimeZone } from '../../enums';
import { UserActivity } from '../../models';
import { Nullable } from '../../types';
import report from './report';

const PREFIX = 'NC_UserActivityLog';
const NAMESPACE = 'reports.userActivity';
const EXPORTED_KEYS = [
  'email',
  'name',
  'role',
  'companyName',
  'date',
  'success',
];

class userActivitiesCsv extends report<UserActivity> {
  private readonly data: UserActivity[];
  private readonly fromDate: Nullable<DateTime>;
  private readonly toDate: Nullable<DateTime>;

  constructor(
    data: UserActivity[],
    fromDate: Nullable<DateTime>,
    toDate: Nullable<DateTime>,
    t: TFunction<'translation', undefined>
  ) {
    super(PREFIX, NAMESPACE, EXPORTED_KEYS, t);

    this.data = data;
    this.fromDate = fromDate;
    this.toDate = toDate;
  }

  protected override getFilename(): string {
    const fromDate = this.fromDate?.toFormat('LLddyyyy');
    const toDate = this.toDate?.toFormat('LLddyyyy');

    return `${PREFIX}_${fromDate}_${toDate}.csv`;
  }

  protected getSanitizedData(): UserActivity[] {
    return this.data.map(
      (activity) =>
        ({
          ...activity,
          date: DateTime.fromMillis(activity.date.toMillis())
            .setLocale('en')
            .setZone(TimeZone.EasternTime)
            .toLocaleString(DateTime.DATETIME_MED),
        } as unknown as UserActivity)
    );
  }

  static generate(
    data: UserActivity[],
    fromDate: Nullable<DateTime>,
    toDate: Nullable<DateTime>,
    t: TFunction<'translation', undefined>
  ) {
    const report = new userActivitiesCsv(data, fromDate, toDate, t);

    report.generateCSV();
  }
}

export default userActivitiesCsv;
