import {
  applyActionCode,
  confirmPasswordReset,
  getMultiFactorResolver,
  multiFactor,
  signInWithEmailAndPassword,
  signOut,
  verifyPasswordResetCode,
} from 'firebase/auth';

const auth = {
  applyActionCode,
  confirmPasswordReset,
  getMultiFactorResolver,
  multiFactor,
  signInWithEmailAndPassword,
  signOut,
  verifyPasswordResetCode,
};

export default auth;
