import { SxProps } from '@mui/material';
import { QuoteStatus } from '../../enums';
import { QuoteStatusColors } from '../../mappings';

export const statusDot = (status: QuoteStatus | null): SxProps => {
  return {
    borderRadius: '50%',
    display: 'inline-block',
    height: '10px',
    width: '10px',
    backgroundColor: (!!status && QuoteStatusColors[status]) || '#ffffff',
  };
};

export const statusText = {
  wordBreak: 'break-word',
  fontWeight: 'bold',
  textAlign: 'center',
  display: 'inline',
};
