import { Chip, SxProps, alpha, styled } from '@mui/material';
import Switch, { switchClasses } from '@mui/material/Switch';
import { i18n } from 'i18next';
import theme from '../../theme';

export const tableBodyStyles: SxProps = {
  '&.MuiTableBody-root': {
    '& .MuiTableRow-root': {
      borderLeft: 'solid',
      borderLeftWidth: 5,
      borderLeftColor: 'transparent',

      '&.selected-item': {
        backgroundColor: alpha(theme.palette.secondary.main, 0.15),
        borderLeftColor: 'secondary.main',
        borderBottomLeftRadius: '0.75rem',
        borderTopLeftRadius: '0.75rem',
      },

      '&.disabled-item': {
        backgroundColor: alpha('#979797', 0.5),
        opacity: 0.6,
        cursor: 'default',
      },
    },
  },
};

export const tableStyles: SxProps = {
  '&.MuiTableContainer-root': {
    '& .MuiTable-root': {
      borderCollapse: 'collapse',
    },
  },
};

export const multipleReservationsToggleStyles = (
  i18n: i18n,
  enabled: boolean
): SxProps => {
  return {
    ...(enabled ? { fontWeight: 700, color: theme.palette.primary.dark } : {}),
    ...(i18n.language === 'fr' ? { letterSpacing: '-1px' } : {}),
  };
};

export const BadgeStyles = (i18n: i18n): SxProps => {
  return {
    '& .MuiBadge-badge': {
      ...(i18n.language === 'fr'
        ? { right: -30, top: -8 }
        : { right: -15, top: -8 }),
    },
  };
};

export const StyledChip = styled(Chip)(({ theme }) => {
  return {
    textTransform: 'uppercase',
    borderRadius: '16px',
    borderWidth: '2px',
    borderColor: theme.palette.secondary.main,
    height: '20px',
    fontWeight: 900,
    fontStyle: 'italic',
    fontSize: '0.75rem',
    paddingLeft: 0,
    paddingRight: 0,
    letterSpacing: '-1px',
  };
});

const pxToRem = (px: number, oneRemPx = 17) => `${px / oneRemPx}rem`;

export const StyledSwitch = styled(Switch)(({ theme }) => {
  const width = pxToRem(44);
  const height = pxToRem(24);
  const size = pxToRem(20);
  const gap = (24 - 20) / 2;
  return {
    width,
    height,
    padding: 0,
    margin: theme.spacing(1),
    overflow: 'unset',
    [`& .${switchClasses.switchBase}`]: {
      padding: pxToRem(gap),
      [`&.${switchClasses.checked}`]: {
        color: theme.palette.secondary.light,
        transform: `translateX(calc(${width} - ${size} - ${pxToRem(2 * gap)}))`,
        [`& + .${switchClasses.track}`]: {
          backgroundColor: theme.palette.secondary.main,
          opacity: 1,
          border: 'none',
        },
        [`& .${switchClasses.thumb}`]: {
          backgroundColor: theme.palette.secondary.light,
        },
      },
    },
    [`& .${switchClasses.thumb}`]: {
      boxShadow: 'none',
      backgroundColor: theme.palette.secondary.light,
      width: size,
      height: size,
    },
    [`& .${switchClasses.track}`]: {
      borderRadius: 30,
      backgroundColor: theme.palette.grey[600],
      opacity: 1,
      transition: theme.transitions.create(['background-color']),
      boxSizing: 'border-box',
    },
  };
});
