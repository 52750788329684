import { SxProps } from '@mui/material';

export const totalConversionRateBox: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexWrap: 'nowrap',
  justifyContent: 'space-around',
  width: '11em',
  height: '7em',
  marginTop: '1em',
  borderRadius: '4px',
  backgroundColor: '#f9f9f9',
  border: '1px solid #d7d7d7',
};

export const breakdownCollapseContainer: SxProps = {
  alignItems: { xs: 'top', md: 'center' },
  backgroundColor: 'secondary.light',
  borderBottomLeftRadius: { xs: 0, md: 10 },
  borderBottomRightRadius: { xs: 0, md: 10 },
  borderTopColor: 'secondary.main',
  borderTopLeftRadius: { xs: '1rem', md: 0 },
  borderTopRightRadius: { xs: '1rem', md: 0 },
  borderTopStyle: 'solid',
  borderTopWidth: { xs: 0, md: 5 },
  display: 'flex',
  flexDirection: { xs: 'column', md: 'row' },
  height: { xs: 'auto' },
  justifyContent: 'space-between',
  marginBottom: { xs: 0, md: 2 },
  paddingX: { xs: 3, md: 0 },
  width: '100%',
};
