import { SxProps } from '@mui/material';
import theme from '../../theme';

export const formControlRoot = (hasError: boolean): SxProps => {
  return {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    mb: '5px',
    ...(hasError && {
      mt: 1,
      mb: -1,
    }),
  };
};

export const formLabel = {
  color: '#000',
};

export const formRadioGroup = (hasError: boolean): SxProps => {
  return {
    padding: '5px 14px 10px 14px',
    ...(hasError
      ? {
          border: `1px solid ${theme.palette.error.main}`,
          borderRadius: '4px',
        }
      : {
          border: '1px solid transparent', // prevent small shift when error is shown
        }),
  };
};

export const formErrorText = {
  mb: 3,
};
