import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import {
  Button,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';

import {
  Timestamp,
  collection,
  getDocs,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { CompletedReelReturn, FirebaseFile, ReelReturn } from '../../models';
import {
  useSorting,
  useCurrentUser,
  usePagination,
  useDownloadFiles,
  useReelSizes,
} from '../../hooks';
import { ReelReturnStatus } from '../../enums';
import { DataTable, DataTableColumn } from '../../tables';
import { Filter } from '../../types';
import { Container, ExportMenu, Show } from '..';
import { ReelReturnStatusBadge } from '../ReelReturnStatusBadge/ReelReturnStatusBadge.component';
import { reelReturnsReportCsv, reelReturnsReportPdf } from '../../reports';
import { formatDateSpan } from '../ReelReturnDatePicker/utils';
import { ReelReturnsTableProps } from './ReelReturnsTable.props';
import { tableBodyStyles } from './ReelReturnsTable.styles';

export const ReelReturnsTable = React.memo(
  ({
    filters,
    isAdmin = false,
    isReport = false,
    reelReturnsHook: useReelReturns,
    editReelReturn,
    cancelReelReturn,
    receiveReelReturn,
  }: ReelReturnsTableProps) => {
    const { t, i18n } = useTranslation();
    const firestore = useFirestore();
    const user = useCurrentUser();
    const pagination = usePagination();
    const { downloadFromFireStorage } = useDownloadFiles();
    const navigate = useNavigate();

    const sorting = useSorting('returnByDate', { sortDirection: 'desc' });

    const queryFilters = useMemo(() => {
      if (!filters) return [];

      const result = [];

      const {
        startDate,
        endDate,
        reelSize,
        companyIds,
        depotLocation,
        status,
      } = filters;

      if (startDate) {
        result.push([
          'returnByDate',
          '>=',
          Timestamp.fromMillis(startDate.startOf('day').toMillis()),
        ]);
      }

      if (endDate) {
        result.push([
          'returnByDate',
          '<=',
          Timestamp.fromMillis(endDate.endOf('day').toMillis()),
        ]);
      }

      // push reelSize filter
      if (reelSize)
        result.push(['reelsReturned', 'array-contains', reelSize.id]);
      if (companyIds) result.push(['companyId', 'in', companyIds]);
      if (depotLocation) result.push(['depotId', '==', depotLocation.id]);
      if (status) result.push(['status', '==', status]);

      return result;
    }, [filters]) as Filter[];

    const { list: reelReturnList, loading } = useReelReturns({
      sorting,
      filters: queryFilters,
      pagination,
    });

    const { list: reelSizes } = useReelSizes({
      filters: [['isReturnable', '==', true]],
    });

    const statusOutput = (status: ReelReturnStatus) => (
      <ReelReturnStatusBadge
        status={status}
        text={t(
          `reelReturns.status.${
            status === ReelReturnStatus.Unsubmitted
              ? 'unsubmittedShort'
              : status
          }`
        ).toUpperCase()}
      />
    );

    const dateOutput = (timestamp: Timestamp) => {
      const formattedValue = DateTime.fromJSDate(timestamp.toDate());
      const dateSpan = formatDateSpan(user.language, formattedValue, true);
      return <Typography>{dateSpan}</Typography>;
    };

    const downloadOutput = (reportFile: FirebaseFile, item: ReelReturn) => (
      <Button
        fullWidth
        disabled={!item.nciRmaNumber}
        variant="contained"
        onClick={() => {
          downloadFromFireStorage(
            `${reportFile.storageBucket}/${reportFile.storageId}`,
            reportFile.fileName
          );
        }}
      >
        {t('reelReturns.table.downloadButton')}
      </Button>
    );

    const editOutput = (reelReturn: ReelReturn) => (
      <Button
        fullWidth
        variant="outlined"
        onClick={() =>
          (editReelReturn && editReelReturn(reelReturn)) ||
          navigate(reelReturn.id)
        }
        disabled={
          reelReturn.status === ReelReturnStatus.Cancelled ||
          reelReturn.status === ReelReturnStatus.Received
        }
      >
        {t('reelReturns.table.editButton')}
      </Button>
    );

    const customerMenuItems = (item: ReelReturn) => [
      <MenuItem
        key="download-menu-item"
        disabled={!item.nciRmaNumber}
        onClick={() => {
          if (item.reportFile) {
            downloadFromFireStorage(
              `${item.reportFile.storageBucket}/${item.reportFile.storageId}`,
              item.reportFile.fileName
            );
          }
        }}
      >
        <ListItemIcon>
          <DownloadIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('reelReturns.table.downloadButton')}</ListItemText>
      </MenuItem>,
      <MenuItem
        key="cancel-menu-item"
        disabled={
          item.status === ReelReturnStatus.Cancelled ||
          item.status === ReelReturnStatus.Received
        }
        onClick={() => onCancelClick(item)}
      >
        <ListItemIcon>
          <DeleteIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('reelReturns.table.cancelButton')}</ListItemText>
      </MenuItem>,
    ];

    const adminMenuItems = (item: ReelReturn) => (
      <MenuItem
        disabled={item.status !== ReelReturnStatus.Generated}
        onClick={() => onReceivedClick(item)}
      >
        <ListItemIcon>
          <CheckIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('reelReturns.table.receivedButton')}</ListItemText>
      </MenuItem>
    );

    const onCancelClick = (item: ReelReturn) => {
      if (cancelReelReturn) {
        cancelReelReturn(item);
      }
    };

    const onReceivedClick = (item: ReelReturn) => {
      if (receiveReelReturn) {
        receiveReelReturn(item);
      }
    };

    const exportCSV = async () => {
      if (!filters) return;
      const data = await getData();
      reelReturnsReportCsv.generate(data, filters, reelSizes, t);
    };

    const exportPDF = async () => {
      if (!filters) return;
      const data = await getData();
      reelReturnsReportPdf.generate(data, filters, t, i18n);
    };

    const getData = async () => {
      const q = query(
        collection(firestore, 'CompletedReelReturns'),
        ...queryFilters.map((queryFilter) =>
          where(queryFilter[0], queryFilter[1], queryFilter[2])
        ),
        orderBy(sorting?.sortField, sorting.sortDirection)
      );

      const result = await getDocs(q);

      return result.docs.map((doc) => doc.data() as CompletedReelReturn);
    };

    const columns: { [key: string]: JSX.Element | undefined } = {
      status: (
        <DataTableColumn
          disableSort={isReport && isAdmin}
          mobileHeight="3em"
          property="status"
          key="status"
          title={t('reelReturns.table.status')}
          output={statusOutput}
          width="8%"
        />
      ),
      createdBy: (
        <DataTableColumn
          disableSort={isReport && isAdmin}
          property="creatorName"
          key="creatorName"
          title={t('reelReturns.table.createdBy')}
          width="10%"
        />
      ),
      companyName: (
        <DataTableColumn
          disableSort={isReport && isAdmin}
          mobileHeight="4em"
          property="companyName"
          key="companyName"
          title={t('reelReturns.table.company')}
        />
      ),
      nciRmaNumber: (
        <DataTableColumn
          disableSort={isReport && isAdmin}
          mobileHeight="3.5em"
          property="nciRmaNumber"
          key="nciRmaNumber"
          title={t('reelReturns.table.nciRma')}
          width="13%"
          output={(number) => number || t('reelReturns.status.noNciRma')}
        />
      ),
      clientRmaNumber: (
        <DataTableColumn
          disableSort={isReport && isAdmin}
          property="clientRmaNumber"
          key="clientRmaNumber"
          title={t('reelReturns.table.clientRma')}
          width="15%"
        />
      ),
      returnByDate: (
        <DataTableColumn
          mobileHeight="3.5em"
          property="returnByDate"
          key="returnByDate"
          title={t('reelReturns.table.returnDate')}
          width="12%"
          output={dateOutput}
        />
      ),
      depotName: (
        <DataTableColumn
          disableSort={isReport && isAdmin}
          mobileHeight="4em"
          property="depotName"
          key="depotName"
          title={t('reelReturns.table.depotLocation')}
        />
      ),
      reelQuantity: filters?.reelSize ? (
        <DataTableColumn
          textAlign="center"
          disableSort
          mobileHeight="4em"
          property={`reels.${filters.reelSize.id}`}
          key="reelQuantity"
          title={t('reelReturns.table.amountOfReels', {
            reel: filters.reelSize.reelType,
          })}
          width="10%"
        />
      ) : undefined,
      reportFile: (
        <DataTableColumn
          disableSort
          mobileHeight="4em"
          property="reportFile"
          key="reportFile"
          title=""
          width="12%"
          output={downloadOutput}
        />
      ),
      editAction: (
        <DataTableColumn
          property="editAction"
          key="editAction"
          title=""
          disableSort
          textAlign="center"
          width="8%"
          output={(_, reelReturn) => editOutput(reelReturn)}
        />
      ),
    };

    const adminColumns = [
      columns.status,
      columns.companyName,
      columns.createdBy,
      columns.returnByDate,
      columns.nciRmaNumber,
      columns.depotName,
      columns.editAction,
    ];
    const adminReportColumns = [
      columns.status,
      columns.companyName,
      columns.returnByDate,
      columns.nciRmaNumber,
      columns.depotName,
      columns.reelQuantity,
      columns.reportFile,
    ];
    const userColumns = [
      columns.status,
      columns.createdBy,
      columns.nciRmaNumber,
      columns.clientRmaNumber,
      columns.returnByDate,
      columns.depotName,
      columns.editAction,
    ];

    return (
      <Container>
        <Show if={isAdmin && isReport && reelReturnList.length > 0}>
          <Grid display="flex" justifyContent="flex-end" mt={1}>
            <ExportMenu
              onCSVClicked={() => exportCSV()}
              onPDFClicked={() => exportPDF()}
            />
          </Grid>
        </Show>
        <DataTable
          data={reelReturnList}
          loading={loading}
          sorting={sorting}
          mobileBorder="none"
          pagination={pagination}
          menuItems={(item) =>
            isAdmin && isReport ? adminMenuItems(item) : customerMenuItems(item)
          }
          tableBodyStyles={tableBodyStyles}
        >
          {isReport ? adminReportColumns : isAdmin ? adminColumns : userColumns}
        </DataTable>
      </Container>
    );
  }
);
