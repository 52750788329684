import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestore } from 'reactfire';
import {
  collection,
  getDocs,
  orderBy,
  query,
  Timestamp,
  where,
} from 'firebase/firestore';
import { Grid } from '@mui/material';

import { usePagination, useReservationLogs, useSorting } from '../../hooks';
import { DataTable, DataTableColumn } from '../../tables';
import { TimeZone } from '../../enums';
import { Filter, Nullable } from '../../types';
import { ReservationLog } from '../../models';
import formatReservationNumber from '../../utils/formatReservationNumber';
import { reservationLogsCsv, reservationLogsPdf } from '../../reports';
import { ExportMenu, FormattedTimestamp, Show, TableRowDialog } from '..';
import { ReservationLogsTableProps } from './ReservationLogsTable.props';

export const ReservationLogsTable = ({
  filters,
}: ReservationLogsTableProps) => {
  const { t, i18n } = useTranslation();
  const pagination = usePagination();
  const firestore = useFirestore();
  const sorting = useSorting('date', { sortDirection: 'desc' });

  const [openedRow, setOpenedRow] = useState<Nullable<string>>(null);

  const queryFilters = useMemo(() => {
    if (!filters) return [];

    const result = [];

    const { startDate, endDate, companyId, userId, reservationNumber } =
      filters;

    if (startDate)
      result.push([
        'date',
        '>=',
        Timestamp.fromMillis(startDate.startOf('day').toMillis()),
      ]);

    if (endDate)
      result.push([
        'date',
        '<=',
        Timestamp.fromMillis(endDate.endOf('day').toMillis()),
      ]);

    if (companyId && !userId) result.push(['companyId', '==', companyId]);
    if (userId) result.push(['madeForId', '==', userId]);
    if (reservationNumber)
      result.push(['reservationNumber', '==', reservationNumber]);

    return result;
  }, [filters]) as Filter[];

  const { list } = useReservationLogs({
    pagination,
    sorting,
    filters: queryFilters,
  });

  const exportCSV = async () => {
    if (!filters) return;

    const data = await getData();

    reservationLogsCsv.generate(data, filters.startDate, filters.endDate, t);
  };
  const exportPDF = async () => {
    if (!filters) return;

    const data = await getData();

    reservationLogsPdf.generate(
      data,
      filters.startDate,
      filters.endDate,
      t,
      i18n
    );
  };

  const getData = async () => {
    const q = query(
      collection(firestore, 'ReservationLogs'),
      ...queryFilters.map((queryFilter) =>
        where(queryFilter[0], queryFilter[1], queryFilter[2])
      ),
      orderBy(sorting?.sortField, sorting.sortDirection)
    );

    const result = await getDocs(q);

    return result.docs.map((doc) => doc.data() as ReservationLog);
  };

  const renderRowContent = (log: ReservationLog) => (
    <TableRowDialog
      title={t('reports.reservationLogs.rowMessage', {
        reelId: log.reelNumber,
        initialComment: log.initialComment || 'N/A',
        endComment: log.endComment || 'N/A',
      })}
    />
  );

  return (
    <>
      <Show if={list.length > 0}>
        <Grid display="flex" justifyContent="flex-end" mt={1}>
          <ExportMenu
            onCSVClicked={() => exportCSV()}
            onPDFClicked={() => exportPDF()}
          />
        </Grid>
      </Show>

      <DataTable
        data={list}
        pagination={pagination}
        sorting={sorting}
        rowCollapseContent={(log) => renderRowContent(log)}
        rowCollapseOpened={(log) => log.id === openedRow}
        rowOnClick={(log) => setOpenedRow(openedRow !== log.id ? log.id : null)}
      >
        <DataTableColumn
          disableSort
          property="reservationNumber"
          title={t('reports.reservationLogs.reservationNumber')}
          output={(value) => formatReservationNumber(value)}
        />

        <DataTableColumn
          property="date"
          title={t('reports.reservationLogs.eventDate')}
          output={(value) => (
            <FormattedTimestamp
              timestamp={value}
              timeZone={TimeZone.EasternTime}
            />
          )}
        />

        <DataTableColumn
          disableSort
          property="companyName"
          title={t('reports.reservationLogs.companyName')}
        />

        <DataTableColumn
          disableSort
          property="madeByName"
          title={t('reports.reservationLogs.byName')}
          output={(value) => value || t('reports.reservationLogs.system')}
        />

        <DataTableColumn
          disableSort
          property="madeForName"
          title={t('reports.reservationLogs.forName')}
          output={(value) => value || t('reports.reservationLogs.system')}
        />

        <DataTableColumn
          disableSort
          property="initialStatus"
          title={t('reports.reservationLogs.initialStatus')}
          output={(value) =>
            value ? t(`reservations.statuses.${value}`) : 'N/A'
          }
        />

        <DataTableColumn
          disableSort
          property="endStatus"
          title={t('reports.reservationLogs.endStatus')}
          output={(value) =>
            value ? t(`reservations.statuses.${value}`) : 'N/A'
          }
        />
      </DataTable>
    </>
  );
};
