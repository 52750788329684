import { TFunction } from 'i18next';

import { SanitizedReservationConversionRate } from '../../models';
import ConversionRateFilters from '../../types/ReservationConversionRateFilters';
import report from './report';

const PREFIX = 'NC_ReservationConversionRates_';
const NAMESPACE = 'reports.reservationConversionRate.csv';
const EXPORTED_KEYS = [
  'companyName',
  'userName',
  'reserved',
  'cancelled',
  'expired',
  'purchased',
  'rate',
];

class reservationConversionRateReport extends report<SanitizedReservationConversionRate> {
  private conversions: SanitizedReservationConversionRate[];
  private filters: ConversionRateFilters;

  constructor(
    conversions: SanitizedReservationConversionRate[],
    filters: ConversionRateFilters,
    t: TFunction<'translation', undefined>
  ) {
    let keys = EXPORTED_KEYS;
    if (!conversions.some((item) => item.userConversionStatistics.length > 0)) {
      keys = EXPORTED_KEYS.filter((key) => key !== 'userName');
    }

    super(PREFIX, NAMESPACE, keys, t);

    this.conversions = conversions;
    this.filters = filters;
  }

  protected override getFilename(): string {
    const fromDate = this.filters.startDate?.toFormat('LLddyyyy');
    const toDate = this.filters.endDate?.toFormat('LLddyyyy');

    return `${PREFIX}_${fromDate}_${toDate}.csv`;
  }

  protected getSanitizedData(): SanitizedReservationConversionRate[] {
    return this.conversions
      .map(
        (conversion) =>
          ({
            ...conversion,
            userName: this.t(
              'reports.reservationConversionRate.csv.companyTotal'
            ),
            rate: conversion.rate / 100,
          } as SanitizedReservationConversionRate)
      )
      .flatMap((conversion) => [
        conversion,
        ...conversion.userConversionStatistics.map(
          (stat) =>
            ({
              ...stat,
              rate: stat.rate / 100,
            } as SanitizedReservationConversionRate)
        ),
      ]);
  }

  static generate(
    conversions: SanitizedReservationConversionRate[],
    filters: ConversionRateFilters,
    t: TFunction<'translation', undefined>
  ) {
    const report = new reservationConversionRateReport(conversions, filters, t);

    report.generateCSV();
  }
}

export default reservationConversionRateReport;
