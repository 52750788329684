import { Skeleton, styled, SxProps, Typography } from '@mui/material';
import {
  paper as cardPaper,
  grid as cardGrid,
} from '../DashboardCard/DashboardCard.styles';

export const grid: SxProps = {
  ...cardGrid,
  width: '100%',
};

export const paper: SxProps = {
  ...cardPaper,
  maxWidth: '100%',
  height: '100%',
};

export const titleStyles: SxProps = {
  height: '50px',
};

export const StyledCount = styled(Typography)(() => {
  return {
    fontWeight: 600,
  };
});

export const StyledSkeleton = styled(Skeleton)(({ theme }) => {
  return {
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: '0.5em',
  };
});
