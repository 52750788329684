import { Info } from 'luxon';
import { useTranslation } from 'react-i18next';
import { FormattedYearMonthProps } from './FormattedYearMonth.props';

export const FormattedYearMonth = ({
  unitLength,
  yearMonth,
}: FormattedYearMonthProps) => {
  const { i18n } = useTranslation();
  const year = yearMonth.toString().substring(0, 4);
  const month = parseInt(yearMonth.toString().substring(4, 6));

  return (
    <>{`${
      Info.months(unitLength, { locale: i18n.language })[month - 1]
    } ${year}`}</>
  );
};
