import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { Timestamp } from 'firebase/firestore';
import { NotificationLevel, QuoteStatus, UserRole } from '../../../enums';
import { QuestionDrawer } from '../../../components/QuestionDrawer/QuestionDrawer.component';
import {
  useAuthentication,
  useNotification,
  useProgress,
  useQuote,
} from '../../../hooks';
import { Modal } from '../../../types';
import { Quote } from '../../../models';

const CancelQuote: Modal = () => {
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const { showProgress } = useProgress();
  const params = useParams();
  const { role } = useAuthentication();

  const { deleteDoc, save } = useQuote(params.quoteId);
  const navigate = useNavigate();

  useEffect(() => {
    showProgress(disabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  const cancelQuote = async () => {
    setDisabled(true);

    try {
      if (role === UserRole.NciAdmin) {
        await save({
          status: QuoteStatus.Cancelled,
          lastModified: Timestamp.now(),
        } as Quote);
      } else {
        await deleteDoc();
      }
      addNotification(t('quotes.cancel.success'), NotificationLevel.Success);
    } catch {
      addNotification(t('quotes.cancel.error'), NotificationLevel.Error);
    }

    setDisabled(false);
    navigateBack();
  };

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <QuestionDrawer
      disabled={disabled}
      open={true}
      icon={<DeleteIcon sx={{ color: 'secondary.main' }} />}
      message={t('quotes.cancel.message')}
      onConfirm={() => cancelQuote()}
      onCancel={() => navigateBack()}
      title={t('quotes.cancel.title')}
    />
  );
};

export default CancelQuote;
