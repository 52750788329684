export enum QuoteStatus {
  Cancelled = 'cancelled',
  Draft = 'draft',
  Expired = 'expired',
  InProgress = 'inProgress',
  Lost = 'lost',
  Won = 'won',
  PartiallyWonPending = 'partiallyWonPending',
  PartiallyWon = 'partiallyWon',
  Quoted = 'quoted',
  Submitted = 'submitted',
}
