import {
  Box,
  Grid,
  Paper,
  Stack,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';
import {
  arrowIcon,
  link as linkStyles,
} from '../DashboardCard/DashboardCard.styles';
import { useProductTypes } from '../../hooks';
import { DefaultValue, FilterOption } from '../../enums';
import { InventorySearchReportTranslationMap } from '../../mappings';
import { StyledTable, StyledTableCell, StyledTableRow } from '../../tables';
import { Props, RowData } from './interface';
import {
  grid,
  paper,
  StyledCount,
  StyledSkeleton,
  titleStyles,
} from './DashboardInventorySearchReport.styles';

export const DashboardInventorySearchReport = ({
  link,
  linkText,
  products,
  title,
  loading: productsLoading,
}: Props) => {
  const { t } = useTranslation();
  const { item: productTypes, loading } = useProductTypes();

  const data: RowData[] = useMemo(() => {
    if (!productTypes || loading || !products) {
      return [];
    }

    const formattedProducts = Object.entries(products).map((product) => {
      const [properties, count] = product;
      return {
        key: properties,
        count,
        properties: properties.split(';').reduce((acc, property) => {
          const [key, value] = property.split('=');
          switch (key as string) {
            case FilterOption.StandardType:
              acc[key] = t(`inventoryType.${value}`);
              break;
            case FilterOption.ProductType:
              acc[key] = productTypes.types[value]?.name || value;
              break;
            case FilterOption.ProductSubType:
              acc[key] = t(`productSubTypes.${value}`);
              break;
            case FilterOption.MaterialType:
              acc[key] = t(`materials.${value}`);
              break;
            default:
              acc[key] = value;
          }
          return acc;
        }, {} as Record<string, string>),
      };
    });

    return orderBy(
      formattedProducts,
      [
        'count',
        (row) => row.properties[FilterOption.ProductType] || '',
        (row) => row.properties[FilterOption.NumberOfConductors] || '',
        (row) => row.properties[FilterOption.Gauge] || '',
      ],
      ['desc', 'asc', 'asc', 'asc']
    );
  }, [productTypes, loading, products, t]);

  const buildHeader = () => (
    <StyledTableRow>
      {Object.values(InventorySearchReportTranslationMap).map((option) => (
        <StyledTableCell key={option}>
          <Typography variant="body1">
            {t(`inventory.filters.${option}`)}
          </Typography>
        </StyledTableCell>
      ))}
      {
        <StyledTableCell key="count">
          <StyledCount>{t('dashboard.table.count')}</StyledCount>
        </StyledTableCell>
      }
    </StyledTableRow>
  );

  const buildRow = (searchLog: RowData, index: number) => (
    <StyledTableRow
      key={index}
      className={`${index % 2 === 1 ? 'alternate-color' : ''}`}
    >
      {Object.keys(InventorySearchReportTranslationMap).map((key, subIndex) => (
        <StyledTableCell key={`${index}-${subIndex}`}>
          <Typography variant="body1">
            {searchLog.properties[key] ?? DefaultValue.Dash}
          </Typography>
        </StyledTableCell>
      ))}
      {
        <StyledTableCell>
          <StyledCount>{searchLog.count}</StyledCount>
        </StyledTableCell>
      }
    </StyledTableRow>
  );

  return (
    <Paper elevation={1} sx={paper}>
      <Grid sx={grid}>
        {!!productTypes && !loading && !productsLoading ? (
          <>
            <Typography variant="h4" sx={titleStyles}>
              {title}
            </Typography>

            {data.length === 0 ? (
              <Box sx={{ mb: 3 }}>
                <Typography variant="h2">{t('common.noItemsFound')}</Typography>
              </Box>
            ) : (
              <TableContainer sx={{ mb: 3 }}>
                <StyledTable sx={{ width: '100%' }}>
                  <TableHead>{buildHeader()}</TableHead>
                  <TableBody>
                    {data.map((product, index) => buildRow(product, index))}
                  </TableBody>
                </StyledTable>
              </TableContainer>
            )}

            <Link to={link} style={linkStyles}>
              <Grid display="flex" alignItems="center">
                <Typography variant="caption" mr={1}>
                  {linkText}
                </Typography>
                <ArrowForwardIcon sx={arrowIcon} fontSize="small" />
              </Grid>
            </Link>
          </>
        ) : (
          <Stack spacing={1} direction="column">
            <StyledSkeleton
              animation="wave"
              variant="rectangular"
              height={50}
            />
            <StyledSkeleton
              animation="wave"
              variant="rectangular"
              height={400}
            />
          </Stack>
        )}
      </Grid>
    </Paper>
  );
};
