import { SxProps } from '@mui/system';
import { Stack, styled } from '@mui/material';
import theme from '../../theme';

export const QUOTE_SUBMIT_HEIGHT = '70px';
export const QUOTE_SUBMIT_HEIGHT_MOBILE = '95px';

export function getQuoteSubmitHeight(
  withTotalCost = false,
  isMobile = false
): string {
  return `calc(${
    isMobile ? QUOTE_SUBMIT_HEIGHT_MOBILE : QUOTE_SUBMIT_HEIGHT
  } + ${withTotalCost ? '8px' : '0px'})`;
}

export const BoxStyles = (
  withButtons = true,
  withTotalCost = false,
  isCustomer = false
): SxProps => {
  return {
    display: withButtons ? 'flex' : 'none',
    flexDirection: 'row',
    columnGap: 4,
    minHeight: getQuoteSubmitHeight(withTotalCost),
    maxHeight: getQuoteSubmitHeight(withTotalCost),
    height: getQuoteSubmitHeight(withTotalCost),
    width: isCustomer ? '100%' : 'calc(100% - 4rem)',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'absolute',
    borderTop: `2px solid ${theme.palette.primary.contrastText}`,
    borderTopColor: theme.palette.primary.contrastText,
    backgroundColor: 'white',
    bottom: 0,
    zIndex: 2,

    '& button': {
      m: 0,
      width: 'auto',
    },

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      minHeight: QUOTE_SUBMIT_HEIGHT_MOBILE,
      maxHeight: QUOTE_SUBMIT_HEIGHT_MOBILE,
      height: QUOTE_SUBMIT_HEIGHT_MOBILE,
      columnGap: 0,
      rowGap: theme.spacing(1),
      position: 'sticky',
      justifyContent: 'center',
      alignItems: 'center',
      paddingY: theme.spacing(1),
      marginTop: 0,
      borderTop: `1px solid ${theme.palette.primary.contrastText}`,
      width: '100%',

      '& button': {
        width: '100%',
      },
    },
  };
};

export const ButtonStyles: SxProps = {
  textTransform: 'uppercase',
};

export const TotalCostStack = styled(Stack)(({ theme }) => {
  return {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    flexDirection: 'column',
    minWidth: '200px',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: theme.spacing(2),
      paddingTop: 0,
      paddingBottom: 0,
    },

    [theme.breakpoints.only('xs')]: {
      columnGap: theme.spacing(1),

      '& .MuiTypography-root': {
        fontSize: '1.1rem',
      },
    },
  };
});
