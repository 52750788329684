import { Button, Grid, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import { useCallback } from 'react';
import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import { ProfileValidations } from '../validation';
import { getPageTitle } from '../utils/pages';
import {
  useAuthentication,
  useCurrentUser,
  useForm,
  useNotification,
  usePageTitle,
  usePermission,
  useProgress,
  useProvincesOrStates,
  useUser,
} from '../hooks';
import {
  Checkbox,
  Container,
  DecoratedHeader,
  FormControlHeader,
  PhoneNumberField,
  RadioGroup,
  Select,
  Show,
  TextField,
  TwoFactorSettingCard,
} from '../components';
import {
  Country,
  Language,
  MeasurementUnits,
  NotificationLevel,
  TimeZone,
  UserPermission,
} from '../enums';

const Settings: React.FC = () => {
  const { t } = useTranslation();
  const functions = useFunctions();
  const navigate = useNavigate();
  const { hasPermission } = usePermission();
  const { addNotification } = useNotification();
  const { showProgress } = useProgress();
  usePageTitle(getPageTitle('settings'));

  const resetSecondAuthFactor = httpsCallable(
    functions,
    'resetSecondAuthFactor'
  );

  const currentUser = useCurrentUser();
  const user = useUser(currentUser.id);
  const form = useForm(user, {
    validations: ProfileValidations,
  });

  const provinceOrStates = useProvincesOrStates(form.item?.country);

  const { isAdmin, isAgent, multiFactorUser } = useAuthentication();

  const resetPhoneSecondFactor = useCallback(async () => {
    if (multiFactorUser) {
      try {
        showProgress(true);
        await resetSecondAuthFactor({ factor: 'phone' });
        addNotification(
          t('users.confirmations.smsSecondFactorReset'),
          NotificationLevel.Success
        );
        navigate(0); // Redirect user to 2FA setup
      } catch (error) {
        addNotification(
          t('users.errors.smsSecondFactorResetError'),
          NotificationLevel.Error
        );
      } finally {
        showProgress(false);
      }
    }
  }, [
    multiFactorUser,
    showProgress,
    resetSecondAuthFactor,
    addNotification,
    t,
    navigate,
  ]);

  return (
    <>
      <Container>
        <DecoratedHeader
          icon={<SettingsIcon />}
          title={t('navigation.settings')}
          variant="colored"
        />
      </Container>
      <FormControlHeader
        form={form}
        title={t('companies.form.title', { name: form.item?.name })}
      />

      <Grid sx={{ backgroundColor: 'secondary.light' }}>
        <Container sx={{ mt: 5, mb: 2 }}>
          <Grid container>
            <Grid item xs={12} md={6} sx={{ pr: { md: '10%' } }}>
              <Typography variant="h3" mb={3}>
                {t('users.form.sections.profileInformation')}
              </Typography>

              {!(isAdmin || isAgent) && (
                <>
                  <Typography>
                    {t('reservations.currentReservations')}
                  </Typography>
                  <Grid display="flex" mb={3}>
                    <Typography variant="h3" color="secondary">
                      {user.item?.reservationCount}
                    </Typography>
                    <Typography variant="h3">/</Typography>
                    <Typography variant="h3">
                      {user.item?.maxReservationsPerPeriod}
                    </Typography>
                  </Grid>
                </>
              )}
              <TextField
                form={form}
                required
                field="name"
                label={t('users.fields.name')}
                maxLength={255}
              />

              <TextField
                form={form}
                field="title"
                label={t('users.fields.title')}
                maxLength={255}
              />

              <Select
                form={form}
                disabled={isAdmin || isAgent}
                field="language"
                required
                label={t('users.fields.language')}
                options={Object.values(Language)}
                getOptionLabel={(value) => t(`languages.${value}`)}
              />

              <Grid mt={2}>
                <Button
                  variant="contained"
                  onClick={() => navigate('password')}
                >
                  {t('users.fields.changePassword')}
                </Button>
              </Grid>

              <Grid mt={5}>
                <Checkbox
                  variant="medium"
                  field="receiveCommunications"
                  form={form}
                  label={t('users.fields.receiveCommunications')}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} sx={{ pr: { md: '10%' } }}>
              <Typography variant="h3" mb={2}>
                {t('users.form.sections.location')}
              </Typography>

              <Select
                form={form}
                field="country"
                required
                label={t('users.fields.country')}
                options={Object.values(Country)}
                getOptionLabel={(value) => t(`countries.${value}`)}
              />

              <Select
                form={form}
                field="provinceOrState"
                required
                label={t('users.fields.provinceOrState')}
                options={Object.values(provinceOrStates)}
                getOptionLabel={(value) => t(`provincesOrStates.${value}`)}
              />

              <TextField
                form={form}
                required
                field="city"
                label={t('users.fields.city')}
                maxLength={255}
              />

              <Select
                form={form}
                field="timeZone"
                required
                label={t('users.fields.timeZone')}
                options={Object.values(TimeZone)}
                getOptionLabel={(value) => t(`timeZones.${value}`)}
              />

              <Typography variant="h3" mt={5} mb={2}>
                {t('users.form.sections.generalInformation')}
              </Typography>

              <TextField
                form={form}
                disabled={true}
                field="email"
                label={t('users.fields.email')}
                maxLength={255}
              />

              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <PhoneNumberField
                    form={form}
                    field="phoneNumber"
                    required
                    label={t('users.fields.phoneNumber')}
                  />
                </Grid>
                <Grid item xs={5}>
                  <PhoneNumberField
                    form={form}
                    field="extension"
                    label={t('users.fields.extension')}
                    variant="extension"
                  />
                </Grid>
              </Grid>
              <RadioGroup
                row
                required
                form={form}
                field="measurementUnits"
                options={[
                  {
                    key: MeasurementUnits.Metric,
                    label: t('users.fields.units.metric'),
                  },
                  {
                    key: MeasurementUnits.Imperial,
                    label: t('users.fields.units.imperial'),
                  },
                ]}
                label={t('users.fields.units.label')}
              />

              {isAdmin && (
                <>
                  <Typography variant="h3" mt={2} mb={2}>
                    {t('users.form.sections.twoFactorAuthentication')}
                  </Typography>
                  <Typography variant="body1">
                    {t(
                      'users.form.sections.twoFactorAuthenticationDescription'
                    )}
                  </Typography>
                  <TwoFactorSettingCard
                    phoneNumberLastDigits={
                      user.item?.phoneSecondFactorLastDigits
                    }
                    onReset={resetPhoneSecondFactor}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </Grid>

      <Container sx={{ my: 2 }}>
        {user.item?.canReserve &&
          hasPermission(UserPermission.ManageOwnReservations) && (
            <Grid container>
              <Grid item xs={12} md={6} sx={{ pr: { md: '10%' } }}>
                <Typography variant="h3" mt={5} mb={2}>
                  {t('users.form.sections.reservationPermission')}
                </Typography>

                <Show if={!isAgent}>
                  <Typography variant="h4" sx={{ mt: 3 }}>
                    {t('users.fields.maxReservationsPerPeriod.title')}
                  </Typography>

                  <TextField
                    form={form}
                    disabled={true}
                    field="maxReservationsPerPeriod"
                    label={t('users.fields.maxReservationsPerPeriod.label')}
                  />
                </Show>

                <Typography variant="h4" sx={{ mt: 3 }}>
                  {t('users.fields.holdDelay.title')}
                </Typography>

                <TextField
                  form={form}
                  disabled={true}
                  field="holdDelay"
                  label={t('users.fields.holdDelay.label')}
                />
              </Grid>
            </Grid>
          )}
      </Container>

      <Outlet />
    </>
  );
};

export default Settings;
