import { useFirestore } from 'reactfire';
import {
  addDoc,
  collection,
  doc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { InventorySearchLog } from '../models';
import { InventoryFilters } from '../types';
import { InventorySearchReportFilterOptionsMap } from '../mappings';
import { Filter } from '../types/InventoryFilters';
import { DefaultValue } from '../enums';

const mandatorySearchByProductFilters: Filter[] = [
  'standardType',
  'productType',
  'material',
  'voltage',
  'numberOfConductors',
  'gauge',
] as const;

export const InventoryFiltersConverter = {
  toFirestore: (filters: InventoryFilters) =>
    Object.entries(InventorySearchReportFilterOptionsMap).reduce(
      (acc, [key, value]) => {
        const filterKey = key as keyof InventoryFilters;
        if (
          filters[filterKey] &&
          filters[filterKey] !== DefaultValue.NotAvailable
        ) {
          return (acc += `${value}=${filters[filterKey]};`);
        }

        return acc;
      },
      ''
    ),
};

export function useInventorySearchLogs() {
  const firestore = useFirestore();
  const collectionRef = collection(firestore, 'InventorySearchLogs');

  const createLog = async (filters: InventoryFilters) => {
    const data: Partial<InventorySearchLog> = {
      date: Timestamp.now(),
      searchString: InventoryFiltersConverter.toFirestore(filters),
      yieldedResults: false,
      reservationWasMade: false,
      lowInventory: false,
    };

    return await addDoc(collectionRef, data);
  };

  const updateLog = async (id: string, data: Partial<InventorySearchLog>) =>
    await updateDoc(doc(collectionRef, id), data);

  // Log is only valid if either:
  // - search by part number
  // - search by cable with 5 of 6 fields present (subtype is optional)
  const logIsValid = (filters: InventoryFilters) =>
    (!filters.searchByCable && !!filters.partNumber) ||
    (filters.searchByCable &&
      mandatorySearchByProductFilters.every((field) => !!filters[field]));

  return {
    createLog,
    logIsValid,
    updateLog,
  };
}
