import { TextField } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Timestamp } from 'firebase/firestore';
import { orderBy } from 'lodash';
import { useCurrentUser } from '../../../../hooks';
import { useQuoteInternalNote } from '../../../../hooks/useQuoteInternalNote';
import { QuoteInternalNote } from '../../../../models';
import QuoteInternalNoteEditFieldProps from './QuoteInternalNoteEditField.props';

const NOTE_MAX_LENGTH = 300;

export const QuoteInternalNoteEditField = forwardRef(
  (
    {
      quoteId,
      noteId,
      currentNoteIndex,
      draftInternalNotes,
      setDraftInternalNotes,
    }: QuoteInternalNoteEditFieldProps,
    ref
  ) => {
    const currentUser = useCurrentUser();

    const { item, save, loading } = useQuoteInternalNote(quoteId, noteId);
    const [noteFieldContent, setNoteFieldContent] = useState(
      draftInternalNotes.length && currentNoteIndex !== -1
        ? draftInternalNotes[currentNoteIndex].content
        : item?.content || ''
    );

    useEffect(() => {
      if (!loading && !draftInternalNotes.length && currentNoteIndex !== -1) {
        setNoteFieldContent(item?.content || '');
      }
    }, [currentNoteIndex, draftInternalNotes.length, item, loading]);

    useImperativeHandle(ref, () => {
      return {
        saveNote: async () => {
          if (quoteId === 'new') {
            if (currentNoteIndex === -1 && noteFieldContent) {
              setDraftInternalNotes([
                {
                  content: noteFieldContent,
                  createdById: currentUser.id,
                  createdByName: currentUser.name || '',
                  dateCreated: Timestamp.now(),
                  lastModified: Timestamp.now(),
                  id: 'new',
                },
                ...draftInternalNotes,
              ]);
            } else {
              setDraftInternalNotes(
                orderBy(
                  draftInternalNotes.map((note, index) => {
                    if (index === currentNoteIndex) {
                      return {
                        ...note,
                        content: noteFieldContent,
                        lastModified: Timestamp.now(),
                      };
                    }
                    return note;
                  }),
                  ['lastModified'],
                  'desc'
                )
              );
            }
          } else if (!item && noteFieldContent) {
            await save({
              content: noteFieldContent,
              createdById: currentUser.id,
              createdByName: currentUser.name || '',
              dateCreated: Timestamp.now(),
              lastModified: Timestamp.now(),
            } as QuoteInternalNote);
          } else if (item) {
            await save({
              ...item,
              content: noteFieldContent,
              lastModified: Timestamp.now(),
            });
          }
        },
      };
    });

    return (
      <TextField
        fullWidth
        autoFocus
        multiline
        value={noteFieldContent}
        rows={3}
        inputProps={{ maxLength: NOTE_MAX_LENGTH }}
        onChange={(e) => setNoteFieldContent(e.target.value)}
      />
    );
  }
);
