import { useTranslation } from 'react-i18next';

import { useMapSubform } from '../../hooks';
import { TextField } from '..';
import { StyledTableRow, StyledTableCell } from '../../tables';
import { Language } from '../../enums';
import { ProductTypeLeadTimeRowProps } from './ProductTypeLeadTimeRow.props';

export const ProductTypeLeadTimeRow = ({
  form,
  field,
  productType,
}: ProductTypeLeadTimeRowProps) => {
  const { t } = useTranslation();
  const subform = useMapSubform(form, field, productType, {
    [Language.English]: '',
    [Language.French]: '',
  });

  return (
    <StyledTableRow>
      <StyledTableCell>{t(`productTypes.${productType}`)}</StyledTableCell>
      <StyledTableCell>
        <TextField
          form={subform}
          forceDelay
          field={Language.English}
          label=""
        />
      </StyledTableCell>
      <StyledTableCell>
        <TextField form={subform} forceDelay field={Language.French} label="" />
      </StyledTableCell>
    </StyledTableRow>
  );
};
