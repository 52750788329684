import { Box, BoxProps, Stack, styled } from '@mui/material';

interface CustomBoxProps extends BoxProps {
  isReadonly?: boolean;
}

export const StyledStack = styled(Stack)(({ theme }) => {
  return {
    flexDirection: 'row',
    width: '100%',
    useFlexGap: true,
    flexWrap: 'wrap',
    marginBottom: theme.spacing(1),

    [theme.breakpoints.down('md')]: {
      height: 'unset',
      flexDirection: 'column',
    },

    [theme.breakpoints.down('lg')]: {
      rowGap: theme.spacing(2.5),
    },

    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(2),
    },
  };
});

export const StyledCustomerDetails = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isReadonly',
})<CustomBoxProps>(({ theme, isReadonly }) => {
  return {
    width: 'inherit',
    flexDirection: 'column',

    '& .MuiStack-root': {
      '& .MuiBox-root': {
        '&:first-of-type': {
          '& .MuiFormControl-root': {
            marginTop: 0,
          },
          '& .MuiAutocomplete-root': {
            '& .MuiFormControl-root': {
              marginTop: 0,
            },
          },
        },
      },
      '& .MuiAutocomplete-root': {
        '& .MuiFormControl-root': {
          marginTop: theme.spacing(1),
        },
      },
    },

    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
    },

    [theme.breakpoints.only('md')]: {
      maxWidth: '40%',
      paddingRight: theme.spacing(1.5),
    },

    [theme.breakpoints.up('lg')]: {
      maxWidth: isReadonly ? '30%' : '25%',
      paddingRight: theme.spacing(1.5),
    },
  };
});

export const StyledQuoteDetails = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isReadonly',
})<CustomBoxProps>(({ theme, isReadonly }) => {
  return {
    width: 'inherit',
    flexDirection: 'column',

    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
    },

    [theme.breakpoints.only('md')]: {
      maxWidth: '60%',
    },

    [theme.breakpoints.up('lg')]: {
      maxWidth: isReadonly ? '30%' : '55%',
      paddingRight: theme.spacing(1.5),
    },
  };
});

export const StyledOtherDetails = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isReadonly',
})<CustomBoxProps>(({ theme, isReadonly }) => {
  return {
    width: 'inherit',
    flexDirection: 'column',

    [theme.breakpoints.down('lg')]: {
      width: '100%',
      maxWidth: '100%',
    },

    [theme.breakpoints.up('lg')]: {
      maxWidth: isReadonly ? '40%' : '20%',
    },
  };
});
