import { MeasurementUnits } from '../enums';
import { Reel } from '../models';
import { convertReelQuantity } from './units';

export default function formatPackagedReelQuantity(
  unitsType: MeasurementUnits,
  reel: Reel
): string {
  if (reel.packageLength) {
    return `${Math.round(
      (reel.quantity / reel.packageLength + Number.EPSILON) as number
    )}x${convertReelQuantity(
      unitsType,
      reel.packageLength as number,
      reel.productType
    )}`;
  } else {
    return `${convertReelQuantity(
      unitsType,
      reel.quantity as number,
      reel.productType
    )}`;
  }
}
