import { useTheme } from '@mui/material/styles';
import {
  Collapse,
  Drawer,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import isMobile from 'is-mobile';

import { Container, ContrastButtonMain } from '..';

import { InventorySpecificationContainerProps } from './InventorySpecificationContainer.props';

const MobileContainer = ({
  children,
  open,
  selectionCount,
  setOpen,
  specificationCount,
  onEmailClicked,
  onSMSClicked,
}: InventorySpecificationContainerProps) => {
  const { t } = useTranslation();
  return (
    <Drawer
      anchor="bottom"
      open={true}
      variant="persistent"
      PaperProps={{
        sx: {
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
          maxHeight: open ? '80%' : '20%',
          background: '#2C2C2C',
          color: '#ffffff',
        },
      }}
    >
      <Grid
        onClick={() => setOpen(!open)}
        sx={{
          backgroundColor: 'primary.main',
          paddingTop: open ? 4 : 2,
          paddingBottom: open ? 6 : 2,
          ':hover': { cursor: 'pointer' },
        }}
      >
        <Grid
          sx={{ paddingX: 2, height: '100%' }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid display="flex" gap="1em">
            <PictureAsPdfIcon sx={{ color: 'secondary.main' }} />

            <Typography variant="h3">
              {t('inventory.specifications.count', {
                count: specificationCount,
              })}
            </Typography>
          </Grid>
          <KeyboardArrowDownIcon
            sx={{
              transform: `rotate(${!open ? '180deg' : '0'})`,
              transition: 'transform 0.1s',
            }}
          />
        </Grid>
      </Grid>

      {open && (
        <>
          <Grid
            sx={{
              paddingX: 2,
              overflowY: 'auto',
              backgroundColor: 'primary.light',
            }}
          >
            {children}
          </Grid>
          <Grid
            display="flex"
            justifyContent="space-between"
            gap="1em"
            sx={{
              paddingX: 2,
              paddingY: 4,
              height: '100%',
              backgroundColor: 'primary.main',
            }}
          >
            <ContrastButtonMain
              onClick={onEmailClicked}
              disabled={selectionCount === 0 || selectionCount > 8}
              sx={{
                color: 'secondary.light',
                width: '150px',
              }}
            >
              {t('inventory.specifications.email')}
            </ContrastButtonMain>

            {isMobile() && (
              <ContrastButtonMain
                onClick={onSMSClicked}
                disabled={selectionCount !== 1}
                sx={{
                  color: 'secondary.light',
                  width: '150px',
                }}
              >
                {t('inventory.specifications.SMS')}
              </ContrastButtonMain>
            )}
          </Grid>
        </>
      )}
    </Drawer>
  );
};

const DesktopContainer = ({
  children,
  onEmailClicked,
  onDownloadClicked,
  open,
  selectionCount,
  setOpen,
  specificationCount,
}: InventorySpecificationContainerProps) => {
  const { t } = useTranslation();

  return (
    <Grid
      sx={{
        color: open ? 'secondary.light' : 'primary.main',
        backgroundColor: open ? 'primary.main' : 'secondary.light',
        borderBottom: '3px solid',
        borderBottomColor: 'primary.main',
        borderBottomLeftRadius: open ? '14px' : '3px',
        borderBottomRightRadius: open ? '14px' : '3px',
        marginTop: 0.5,
        marginBottom: 5,
        transition: '0.1s',
      }}
    >
      <Container>
        <Grid
          display="flex"
          alignItems="center"
          gap="1em"
          onClick={() => setOpen(!open)}
          sx={{ paddingY: 2, ':hover': { cursor: 'pointer' } }}
        >
          <PictureAsPdfIcon sx={{ color: 'secondary.main' }} />
          <Typography variant="h3">
            {t('inventory.specifications.count', {
              count: specificationCount,
            })}
          </Typography>
          <KeyboardArrowDownIcon
            sx={{
              transform: `rotate(${!open ? '-90deg' : '0'})`,
              transition: 'transform 0.1s',
            }}
          />
        </Grid>

        <Collapse in={open}>{children}</Collapse>

        {open && (
          <Grid sx={{ paddingY: 5 }} display="flex" gap="1em">
            <ContrastButtonMain
              onClick={onEmailClicked}
              disabled={selectionCount === 0 || selectionCount > 8}
              sx={{ color: 'primary.contrastText', minWidth: '100px' }}
            >
              {t('inventory.specifications.email')}
            </ContrastButtonMain>
            <ContrastButtonMain
              onClick={onDownloadClicked}
              disabled={selectionCount === 0}
              sx={{ color: 'primary.contrastText', minWidth: '100px' }}
            >
              {t('inventory.specifications.download')}
            </ContrastButtonMain>
          </Grid>
        )}
      </Container>
    </Grid>
  );
};

export const InventorySpecificationContainer = ({
  children,
  open,
  setOpen,
  selectionCount,
  specificationCount,
  ...rest
}: InventorySpecificationContainerProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const SpecContainer = isDesktop ? DesktopContainer : MobileContainer;

  return (
    <SpecContainer
      {...rest}
      selectionCount={selectionCount}
      specificationCount={specificationCount}
      open={open}
      setOpen={setOpen}
    >
      {children}
    </SpecContainer>
  );
};
