import { useCallback, useEffect, useMemo, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { Company, Quote, QuoteProduct, User } from '../models';
import { Formable } from '../types';
import { Inventory } from '../types/Inventory';
import { isQuoteProductMarketValid } from '../utils/quote';

export const useQuoteMarkets = (form: Formable<Quote>) => {
  const firestore = useFirestore();
  const [customer, setCustomer] = useState<User | null>(null);
  const [company, setCompany] = useState<Company | null>(null);

  useEffect(() => {
    (async () => {
      if (!form.item?.customerId) {
        setCustomer(null);
      } else if (customer?.id !== form.item.customerId) {
        const userRef = doc(firestore, 'Users', form.item.customerId);
        const user = (await getDoc(userRef)).data();
        setCustomer(user as User);
      }
    })();
  }, [customer?.id, firestore, form.item?.customerId]);

  useEffect(() => {
    (async () => {
      if (!form.item?.companyId) {
        setCompany(null);
      } else if (company?.id !== form.item.companyId) {
        const companyRef = doc(firestore, 'Companies', form.item.companyId);
        const company = (await getDoc(companyRef)).data();
        setCompany(company as Company);
      }
    })();
  }, [company?.id, firestore, form.item?.companyId]);

  const markets = useMemo((): Inventory[] => {
    const isCustomUser = !!form.item?.isCustomUser;
    const isForAgent = !!form.item?.agencyId;
    const markets: Inventory[] = [];

    const canSeeCSA =
      isCustomUser ||
      (company?.canSeeCSA && (isForAgent || customer?.canSeeCSA));

    const canSeeUL =
      isCustomUser || (company?.canSeeUL && (isForAgent || customer?.canSeeUL));

    if (canSeeCSA) {
      markets.push('csa');
    }

    if (canSeeUL) {
      markets.push('ul');
    }

    return markets;
  }, [company, customer, form.item?.agencyId, form.item?.isCustomUser]);

  const isValid = useCallback(
    (product: QuoteProduct) =>
      !customer || !company || isQuoteProductMarketValid(product, markets),
    [company, customer, markets]
  );

  return {
    markets,
    canSeeCSA: markets.includes('csa'),
    canSeeUL: markets.includes('ul'),
    isValid,
  };
};
