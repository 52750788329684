import { Autocomplete, Grid, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useTranslation } from 'react-i18next';
import { useNcEmployeeUsers } from '../../hooks';
import { QuotesFiltersSubProps } from './QuotesFilters.props';

export const QuotesFiltersEmployee = ({
  filters,
  setFilters,
}: QuotesFiltersSubProps) => {
  const { t } = useTranslation();
  const { list: employees } = useNcEmployeeUsers();

  return (
    <Grid item xs={12} sx={{ flexBasis: { md: '15%' } }}>
      <Autocomplete
        popupIcon={<KeyboardArrowDownIcon />}
        getOptionLabel={(user) => user.name || ''}
        options={employees ?? []}
        value={employees?.find((u) => u.id === filters.employeeId) ?? null}
        onChange={(_, user) =>
          setFilters((prev) => {
            return { ...prev, employeeId: user?.id || null };
          })
        }
        sx={{ flex: '1 1 0' }}
        renderInput={(params) => (
          <TextField {...params} label={t('quotes.filters.employeeName')} />
        )}
      />
    </Grid>
  );
};
