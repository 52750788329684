import { httpsCallable } from 'firebase/functions';
import { createContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth, useFunctions } from 'reactfire';
import { onAuthStateChanged, MultiFactorUser, User } from 'firebase/auth';

import { UserRole, UserRoleCategory, UserStatus } from '../enums';
import { useAppLanguage, useHasSecondAuthFactors, useUser } from '../hooks';
import { useAuthentication } from '../hooks/useAuthentication';
import { User as UserModel } from '../models';

export interface CurrentUserContextValue {
  authUser: User | null;
  multiFactorUser: MultiFactorUser | null;
  user: UserModel;
  role: UserRole;
  loading: boolean;
  checkTokenRevocation: () => Promise<void>;
  enrollSmsSecondFactor: (
    verificationCode: string,
    phoneNumber: string
  ) => Promise<void>;
  logout: () => Promise<void>;
  refresh: () => void;
  sendSmsVerificationCode: (phoneNumber: string) => Promise<void>;
  isAdmin: boolean;
  isAgent: boolean;
  isCustomer: boolean;
  userRoleCategory: UserRoleCategory;
}

export const CurrentUserContext = createContext({} as CurrentUserContextValue);

interface Props {
  children: React.ReactNode;
}

export const CurrentUserProvider = ({ children }: Props) => {
  const auth = useAuth();
  const {
    user: authUser,
    multiFactorUser,
    isAdmin,
    checkTokenRevocation,
    refresh,
    logout,
    role,
    ...propAuth
  } = useAuthentication();
  // This should never be null when used inside of an authentication check, therefore:
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const persistentAuthUser = useRef(authUser!);
  const location = useLocation();
  const { item: user } = useUser(persistentAuthUser.current.uid);
  const [storedUser, setStoredUser] = useState(user);

  const navigate = useNavigate();
  const functions = useFunctions();
  const activateUser = httpsCallable(functions, 'activateUser');
  const userHasSecondFactors = useHasSecondAuthFactors();

  useAppLanguage(user?.language);

  useEffect(() => {
    if (storedUser?.status === UserStatus.Inactive) {
      logout();
    }
  }, [storedUser?.status, logout]);

  useEffect(() => {
    if (
      !!authUser &&
      !authUser?.emailVerified &&
      location.pathname !== '/validate-email'
    ) {
      navigate('/not-verified');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, storedUser, location.pathname]);

  useEffect(() => {
    if (
      authUser?.emailVerified &&
      (storedUser?.status === UserStatus.Pending ||
        storedUser?.status === UserStatus.PendingEmailValidation)
    ) {
      activateUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, storedUser]);

  useEffect(() => {
    if (
      storedUser &&
      user &&
      role &&
      (storedUser.status !== user.status ||
        storedUser.role !== user.role ||
        storedUser.role !== role)
    ) {
      refresh();
    }

    setStoredUser(user);
  }, [refresh, storedUser, user, role]);

  useEffect(() => {
    if (
      authUser &&
      isAdmin &&
      !userHasSecondFactors &&
      location.pathname !== '/logout'
    ) {
      navigate('/admin/2fa-setup');
    }
  }, [authUser, userHasSecondFactors, isAdmin, location.pathname, navigate]);

  useEffect(() => {
    const intervalId = setInterval(checkTokenRevocation, 60000);

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        clearInterval(intervalId);
      }
    });

    checkTokenRevocation();

    return () => {
      clearInterval(intervalId);
      unsubscribe();
    };
  }, [auth, checkTokenRevocation]);

  if (!user || !role) {
    return null;
  }

  return (
    <CurrentUserContext.Provider
      value={{
        authUser,
        multiFactorUser,
        user,
        checkTokenRevocation,
        refresh,
        logout,
        role,
        isAdmin,
        ...propAuth,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};
