import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GroupWorkIcon from '@mui/icons-material/GroupWork';

import {
  query,
  collectionGroup,
  where,
  getDocs,
  doc,
  getDoc,
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import {
  usePageTitle,
  useReelReturnsOfAdmin,
  useRouterOutlet,
} from '../../hooks/';
import { getPageTitle } from '../../utils/pages';
import {
  Container,
  ContrastButtonMain,
  DecoratedHeader,
  ReelReturnsTable,
} from '../../components';
import { Country, UserPermission } from '../../enums';
import { ReelReturn, User } from '../../models';
import { CountrySpecific, RequirePermission } from '../../security';
import { CancelReelReturn } from '../modals';
import { Nullable } from '../../types';

const ReelReturns: React.FC = () => {
  const { t } = useTranslation();
  const { showOutletOnly } = useRouterOutlet();
  const navigate = useNavigate();
  usePageTitle(getPageTitle('reelReturns'));
  const location = useLocation();
  const { reelReturnId } = useParams();
  const firestore = useFirestore();

  const [impersonatedUser, setImpersonatedUser] =
    useState<Nullable<User>>(null);

  useEffect(() => {
    let ignore = false;

    async function getData() {
      const q = query(
        collectionGroup(firestore, 'ReelReturns'),
        where('id', '==', reelReturnId)
      );

      return await getDocs(q);
    }

    async function getUser(id: string) {
      const docRef = doc(firestore, 'Users', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setImpersonatedUser(docSnap.data() as User);
      } else {
        setImpersonatedUser(null);
        navigate('/admin/reel-returns');
      }
    }

    if (reelReturnId && reelReturnId !== 'new') {
      getData().then(async (documents) => {
        if (!ignore) {
          const reelReturn = (documents.docs[0]?.data() as ReelReturn) || null;
          if (reelReturn) {
            await getUser(reelReturn.creatorId);
          } else {
            setImpersonatedUser(null);
            navigate('/admin/reel-returns');
          }
        }
      });
    }

    if (reelReturnId === 'new' && !impersonatedUser) {
      navigate('/admin/reel-returns/company');
    }

    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reelReturnId]);

  useEffect(() => {
    if (location.pathname !== '/reel-returns') {
      setShowCancelDrawer(false);
      setReelReturnToCancel(null);
    }
  }, [location.pathname]);

  const [showCancelDrawer, setShowCancelDrawer] = useState(false);
  const [reelReturnToCancel, setReelReturnToCancel] =
    useState<ReelReturn | null>(null);

  const onCancelReelReturn = (item: ReelReturn) => {
    setReelReturnToCancel(item);
    setShowCancelDrawer(true);
  };

  const onEditReelReturn = async (item: ReelReturn) => {
    const docRef = doc(firestore, 'Users', item.creatorId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setImpersonatedUser(docSnap.data() as User);
      navigate(item.id);
    } else {
      setImpersonatedUser(null);
    }
  };

  const onAdminImpersonate = (user: User) => {
    setImpersonatedUser(user);
    navigate('/admin/reel-returns/new');
  };

  const onCancel = () => {
    navigate(location.pathname.replace('/company', ''));
  };

  return (
    <RequirePermission oneOf={[UserPermission.ManageReelReturns]}>
      <CountrySpecific country={Country.Canada}>
        {showOutletOnly || (
          <React.Fragment>
            <Container>
              <DecoratedHeader
                icon={<GroupWorkIcon />}
                title={t('pages.reelReturns.pageTitle')}
                variant="colored"
              >
                <ContrastButtonMain
                  fullWidth
                  sx={{ mt: 3 }}
                  onClick={() => navigate('company')}
                >
                  {t('reelReturns.page.createNewReturnButton')}
                </ContrastButtonMain>
              </DecoratedHeader>
            </Container>
            <ReelReturnsTable
              isAdmin={true}
              reelReturnsHook={useReelReturnsOfAdmin}
              cancelReelReturn={onCancelReelReturn}
              editReelReturn={onEditReelReturn}
              filters={null}
            />
          </React.Fragment>
        )}
        <CancelReelReturn
          reelReturn={reelReturnToCancel}
          visible={showCancelDrawer}
          visibilitySet={setShowCancelDrawer}
        />
        <Outlet
          context={{
            impersonatedUser,
            onCancel,
            onAdminImpersonate,
            title: 'impersonateCustomer.reelReturn',
          }}
        />
      </CountrySpecific>
    </RequirePermission>
  );
};

export default ReelReturns;
