import { useTranslation } from 'react-i18next';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import CableIcon from '@mui/icons-material/Cable';
import PieChartIcon from '@mui/icons-material/PieChart';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ShieldIcon from '@mui/icons-material/Shield';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

import { UserPermission } from '../../enums';
import { MenuItem } from '..';
import { usePermission } from '../../hooks';
import { NeedsPermission } from '../../security';

export const AdminMenu = () => {
  const { t } = useTranslation();

  const { hasPermission, hasOneOfPermissions } = usePermission();

  return (
    <>
      <MenuItem
        title={t('navigation.dashboard')}
        icon={<DashboardIcon />}
        to="/admin/dashboard"
      />

      <NeedsPermission oneOf={[UserPermission.ViewAllReservations]}>
        <MenuItem
          title={t('navigation.reservations')}
          icon={<HourglassTopIcon />}
          to="/admin/reservations"
          match="admin/reservations/*"
        />
      </NeedsPermission>

      <NeedsPermission oneOf={[UserPermission.ManageQuotes]}>
        <MenuItem
          title={t('navigation.quotes')}
          icon={<RequestQuoteIcon />}
          to="/admin/quotes"
          match="admin/quotes/*"
        />
      </NeedsPermission>

      <NeedsPermission oneOf={[UserPermission.ViewOrders]}>
        <MenuItem
          title={t('navigation.orders')}
          icon={<FolderOpenIcon />}
          to="/admin/orders"
          match="admin/orders/*"
        />
      </NeedsPermission>

      <NeedsPermission oneOf={[UserPermission.ViewInventory]}>
        <MenuItem
          title={t('navigation.inventory')}
          icon={<CableIcon />}
          to="/admin/inventory/csa"
          match="/admin/inventory/*"
        />
      </NeedsPermission>

      <NeedsPermission oneOf={[UserPermission.ManageReelReturns]}>
        <MenuItem
          title={t('navigation.reelReturns')}
          icon={<GroupWorkIcon />}
          to="/admin/reel-returns"
          match="/admin/reel-returns/*"
        />
      </NeedsPermission>

      <NeedsPermission
        oneOf={[
          UserPermission.ViewReservationReports,
          UserPermission.ViewLogs,
          UserPermission.ViewQuoteReports,
        ]}
      >
        <MenuItem
          title={t('navigation.reports')}
          icon={<PieChartIcon />}
          to={
            hasPermission(UserPermission.ViewLogs)
              ? '/admin/reports/user-activity'
              : '/admin/reports/conversion-rate'
          }
          match="/admin/reports/*"
        />
      </NeedsPermission>

      <NeedsPermission
        oneOf={[
          UserPermission.ManageNCUsers,
          UserPermission.ManageCustomerUsers,
          UserPermission.ManageAgencyUsers,
        ]}
      >
        <MenuItem
          title={t('navigation.users')}
          icon={<SupervisedUserCircleIcon />}
          to="/admin/users"
          match="admin/users/*"
        />
      </NeedsPermission>

      <NeedsPermission
        oneOf={[UserPermission.ManageCompanies, UserPermission.ManageAgencies]}
      >
        <MenuItem
          title={t('navigation.companies')}
          icon={<BusinessCenterIcon />}
          to={
            hasPermission(UserPermission.ManageCompanies)
              ? '/admin/companies'
              : '/admin/agencies'
          }
          match={['admin/companies/*', 'admin/agencies/*']}
        />
      </NeedsPermission>

      <NeedsPermission
        oneOf={[
          UserPermission.ManageInventoryClasses,
          UserPermission.ManagePortalSettings,
          UserPermission.ManageRolesAndPermissions,
          UserPermission.ManageAgentBulletinBoard,
        ]}
      >
        <MenuItem
          title={t('navigation.portal')}
          icon={<ShieldIcon />}
          to={
            hasOneOfPermissions([
              UserPermission.ManagePortalSettings,
              UserPermission.ManageInventoryClasses,
            ])
              ? '/admin/portal/reservations'
              : hasPermission(UserPermission.ManageRolesAndPermissions)
              ? '/admin/portal/permissions'
              : '/admin/portal/agent-bulletin-board'
          }
          match="admin/portal/*"
        />
      </NeedsPermission>
    </>
  );
};
