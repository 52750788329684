import { Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TwoFactorSettingCardProps } from './TwoFactorSettingCard.props';
import { StyledFactorCard } from './TwoFactorSettingCard.styles';

export const TwoFactorSettingCard = ({
  phoneNumberLastDigits,
  onReset,
}: TwoFactorSettingCardProps) => {
  const { t } = useTranslation();
  return (
    <StyledFactorCard>
      <Typography variant="body1">
        <strong>{t('users.fields.smsSecondFactor')}</strong>
        {phoneNumberLastDigits && ` (XXX-XXX-${phoneNumberLastDigits})`}
      </Typography>
      <Button
        variant="outlined"
        onClick={onReset}
        disabled={!phoneNumberLastDigits}
      >
        {t('users.actions.resetSmsSecondFactor')}
      </Button>
    </StyledFactorCard>
  );
};
