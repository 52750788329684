import { SxProps } from '@mui/system';

export const tableStyles: SxProps = {
  '&.MuiTableContainer-root': {
    width: { md: '100%' },
    maxWidth: { md: '100%' },
  },
};

export const tableHeaderStyles: SxProps = {
  '&.MuiTableHead-root': {
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        maxWidth: '120px',
        minWidth: '85px',
        padding: '8px',
      },
    },
  },
};
