import { Box, styled, SxProps } from '@mui/material';
import theme from '../../theme';

export const tableStyles: SxProps = {
  '&.MuiTableContainer-root': {
    overflowX: 'auto',
    overflowY: 'auto',

    '&:not(:has(+ p)):not(:has(+ .MuiBox-root))': {
      '& .MuiTable-root': {
        '& .MuiTableBody-root': {
          '& .MuiTableRow-root:last-of-type': {
            [theme.breakpoints.down('md')]: {
              borderBottomColor: 'transparent',
            },
          },
        },
      },
    },
  },

  '& .MuiTable-root': {
    borderCollapse: 'collapse',
  },
};

export const tableHeaderStyles: SxProps = {
  '&.MuiTableHead-root': {
    borderBottom: 'inherit',
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium': {
        paddingLeft: '16px',
      },
    },
  },
};

export const tableBodyStyles = (customFirstRow = false): SxProps => {
  return {
    '&.MuiTableBody-root': {
      '& .MuiTableRow-root': {
        backgroundColor: 'inherit',
        py: { xs: '0.2em' },
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.primary.contrastText,

        [`&:nth-of-type(${customFirstRow ? 'even' : 'odd'})`]: {
          borderBottom: 'none',
        },

        '&#custom-first-row': {
          borderBottom: '1px solid',
          borderBottomColor: theme.palette.primary.contrastText,
        },
      },

      'tr > td': {
        border: 'none',
        '&.menu-items': {
          p: { xs: 0, md: 1 },
        },

        '& .MuiInputBase-root': {
          height: {
            xs: '40px',
          },
        },
      },
    },
  };
};

export const tableCellStyles: SxProps = {
  backgroundColor: theme.palette.secondary.light,
  '&.MuiTableBody-root &:last-child': {
    backgroundColor: theme.palette.secondary.light,
  },
};

export const StyledBox = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1.5),
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(1),

    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
  };
});
