import { ProductIdSet, ProductIdTuple } from './types';

export const hasProductIdTuple = (
  set: ProductIdSet,
  tuple: ProductIdTuple
): boolean => {
  for (const item of set) {
    if (item[0] === tuple[0] && item[1] === tuple[1]) {
      return true;
    }
  }
  return false;
};

export const addProductIdTuple = (
  set: ProductIdSet,
  tuple: ProductIdTuple
): ProductIdSet => {
  const newSet = new Set(set);
  if (!hasProductIdTuple(newSet, tuple)) {
    newSet.add(tuple);
  }
  return newSet;
};

export const deleteProductIdTuple = (
  set: ProductIdSet,
  tuple: ProductIdTuple
): ProductIdSet => {
  const newSet = new Set(set);
  for (const item of newSet) {
    if (item[0] === tuple[0] && item[1] === tuple[1]) {
      newSet.delete(item);
      break;
    }
  }
  return newSet;
};
