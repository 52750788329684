import { useState } from 'react';
import { Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';

import { DateTime } from 'luxon';
import { ReportFilesFilters as Filters } from '../../types';
import { FilterContainer } from '..';
import { ReportFilesFiltersProp } from './ReportFilesFilters.props';

const emptyFilters = {
  startDate: DateTime.now().set({ month: 1 }),
  endDate: DateTime.now(),
};

export const ReportFilesFilters = ({
  onSearch,
  fileType,
}: ReportFilesFiltersProp) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<Filters>(emptyFilters);

  const clearFilters = () => {
    setFilters(emptyFilters);
    onSearch(null);
  };

  return (
    <FilterContainer
      buttonsDisabled={!filters.startDate || !filters.endDate}
      title={t(`reports.fileTypes.${fileType}Title`)}
      onSearch={() => onSearch(filters)}
      onClear={() => clearFilters()}
    >
      <Stack sx={{ my: 4 }} direction="row" spacing={2}>
        <DatePicker
          views={['year', 'month']}
          label={t('reports.files.startDate')}
          value={filters.startDate}
          slotProps={{
            actionBar: { actions: ['clear'] },
            textField: { required: true },
          }}
          onChange={(value) =>
            setFilters((prev) => {
              return { ...prev, startDate: value };
            })
          }
          maxDate={filters.endDate || DateTime.now()}
        />

        <DatePicker
          views={['year', 'month']}
          label={t('reports.files.endDate')}
          value={filters.endDate}
          slotProps={{
            actionBar: { actions: ['clear'] },
            textField: { required: true },
          }}
          onChange={(value) =>
            setFilters((prev) => {
              return { ...prev, endDate: value };
            })
          }
          minDate={filters.startDate || undefined}
          maxDate={DateTime.now()}
        />
      </Stack>
    </FilterContainer>
  );
};
