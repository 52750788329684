import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { ReelReturnsFilters as Filters } from '../../types';
import { useCompanies, useReelDepots, useReelSizes } from '../../hooks';
import { FilterContainer } from '../FilterContainer/FilterContainer.component';
import { formatReelDepotLabel } from '../../models/ReelDepot';
import { CompanyType, ReelReturnStatus } from '../../enums';
import { MultiSelect } from '../MultiSelect/MultiSelect.component';
import { ReelReturnsReportFiltersProps } from './ReelReturnsReportFilters.props';

const emptyFilters: Filters = {
  companyIds: null,
  depotLocation: null,
  startDate: DateTime.now().plus({ month: -1 }),
  endDate: DateTime.now().plus({ month: 1 }),
  reelSize: null,
  status: null,
};

export const ReelReturnsReportFilters = ({
  onSearch,
  loadedFilters,
}: ReelReturnsReportFiltersProps) => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState<Filters>(
    loadedFilters || emptyFilters
  );
  const { list: companies, loading: loadingCompanies } = useCompanies({
    filters: [['type', '==', CompanyType.Customer]],
  });
  const { list: reelSizes } = useReelSizes({
    filters: [['isReturnable', '==', true]],
  });
  const { list: reelDepots } = useReelDepots();

  const reelReturnStatuses = [
    ReelReturnStatus.Cancelled,
    ReelReturnStatus.Generated,
    ReelReturnStatus.Received,
  ];

  const clearFilters = () => {
    setFilters(emptyFilters);
    onSearch(null);
  };

  useEffect(() => {
    if (loadedFilters) {
      setFilters(loadedFilters);
    }
  }, [companies, loadedFilters]);

  return (
    <FilterContainer
      buttonsDisabled={!filters.startDate || !filters.endDate}
      title={t('reports.reelReturns.title')}
      onSearch={() => onSearch(filters)}
      onClear={() => clearFilters()}
    >
      <Grid
        sx={{
          my: { xs: 2, md: 4 },
        }}
      >
        <Grid display="flex" justifyContent="space-evenly" gap="1em">
          <DatePicker
            label={t('reports.userActivity.startDate')}
            value={filters.startDate}
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
              textField: {
                fullWidth: true,
                required: true,
                sx: { flex: '1 1 0' },
              },
            }}
            onChange={(value) =>
              setFilters((prev) => {
                return { ...prev, startDate: value };
              })
            }
            format={t('formats.date')}
            maxDate={filters.endDate || undefined}
          />

          <DatePicker
            label={t('reports.userActivity.endDate')}
            value={filters.endDate}
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
              textField: {
                fullWidth: true,
                required: true,
                sx: { flex: '1 1 0' },
              },
            }}
            onChange={(value) =>
              setFilters((prev) => {
                return { ...prev, endDate: value };
              })
            }
            format={t('formats.date')}
            minDate={filters.startDate || undefined}
          />

          <Autocomplete
            popupIcon={<KeyboardArrowDownIcon />}
            getOptionLabel={(reelSize) => reelSize.reelType}
            options={reelSizes}
            value={filters.reelSize}
            onChange={(_, reelSize) =>
              setFilters((prev) => {
                return { ...prev, reelSize };
              })
            }
            sx={{ flex: '1 1 0' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('reports.reelReturns.reelSize')}
              />
            )}
          />

          <MultiSelect
            displayKey="name"
            idKey="id"
            selectedOptionIds={filters.companyIds}
            options={companies}
            loadingOptions={loadingCompanies}
            label={t('reports.reservationConversionRate.company')}
            selectedLabel={t(
              'reports.reservationConversionRate.companySelected',
              {
                count: filters.companyIds?.length || 0,
              }
            )}
            onChange={(companyIds) => {
              setFilters((prev) => {
                return { ...prev, companyIds };
              });
            }}
          />

          <Autocomplete
            popupIcon={<KeyboardArrowDownIcon />}
            getOptionLabel={(reelDepot) => formatReelDepotLabel(reelDepot)}
            options={reelDepots}
            value={filters.depotLocation}
            onChange={(_, reelDepot) =>
              setFilters((prev) => {
                return { ...prev, depotLocation: reelDepot };
              })
            }
            sx={{ flex: '1 1 0' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('reports.reelReturns.reelDepot')}
              />
            )}
          />

          <Autocomplete
            popupIcon={<KeyboardArrowDownIcon />}
            getOptionLabel={(status) =>
              t(`reports.reelReturns.statusValue.${status}`)
            }
            options={reelReturnStatuses}
            value={filters.status}
            onChange={(_, status) =>
              setFilters((prev) => {
                return { ...prev, status: status };
              })
            }
            sx={{ flex: '1 1 0' }}
            renderInput={(params) => (
              <TextField {...params} label={t('reports.reelReturns.status')} />
            )}
          />
        </Grid>
      </Grid>
    </FilterContainer>
  );
};
