import { Box, styled } from '@mui/material';

export const StyledCustomerNote = styled(Box)(({ theme }) => {
  return {
    [theme.breakpoints.up('sm')]: {
      overflowY: 'auto',
      maxHeight: theme.spacing(18),
    },

    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  };
});
