import { SxProps } from '@mui/material';
import theme from '../../../../theme';

export const headerCell = (isOpen: boolean): SxProps => {
  return {
    borderTop: '0 !important',
    borderBottom: {
      md: `1px solid ${
        isOpen ? theme.palette.secondary.light : '#BBBBBBBB'
      } !important`,
      xs: 'unset',
    },
    borderRadius: '0 !important',
    py: {
      md: '20px !important',
      xs: '10px !important',
    },
  };
};
