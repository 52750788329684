import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { doc } from 'firebase/firestore';
import { compact, includes, union } from 'lodash';
import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';

import firebaseHooks from '../../firebase/hooks';
import {
  Checkbox,
  Container,
  DropDownCheckboxList,
  NumericTextField,
  PhoneNumberField,
  RadioGroup,
  ReservationCounter,
  Select,
  Show,
  TextField,
  TwoFactorSettingCard,
} from '../../components';
import {
  useAuthentication,
  useNotification,
  usePermission,
  usePermissionRoles,
  usePortalSettings,
  useProgress,
  useProvincesOrStates,
} from '../../hooks';
import { roles, statuses } from '../../security';
import {
  CompanyType,
  Country,
  Language,
  MeasurementUnits,
  ModificationMode,
  NotificationLevel,
  TimeZone,
  UserPermission,
  UserRole,
  UserStatus,
} from '../../enums/';
import { User } from '../../models';

import { UserFormProps } from './UserForm.props';

export const UserForm = ({
  companies,
  form,
  isAdmin,
  isAgent,
  mode,
  userIsAwaitingApproval = false,
}: UserFormProps) => {
  const firestore = firebaseHooks.useFirestore();
  const { companyId, userId, agencyId } = useParams();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const functions = useFunctions();
  const forgotPassword = httpsCallable(functions, 'sendResetPasswordEmail');
  const emailVerified = httpsCallable(functions, 'resendEmailVerification');
  const resetSecondAuthFactor = httpsCallable(
    functions,
    'resetSecondAuthFactor'
  );
  const { showProgress } = useProgress();
  const { addNotification } = useNotification();
  const roleList = usePermissionRoles();
  const { isAdmin: isCurrentUserAdmin, user: currentUser } =
    useAuthentication();

  const { item: data, setItem } = form;

  const previousRole = useRef<UserRole | undefined>(form.originalItem?.role);

  const { item: portalSettingsData } = usePortalSettings();
  const { hasPermission, roleHasPermission } = usePermission();

  const northernCablesCompany = useMemo(
    () =>
      companies.find((company) => company.type === CompanyType.NorthernCables),
    [companies]
  );

  const northernCablesUser = useMemo(() => {
    if (!data?.role) return false;
    return roles.AdminRoles.includes(data.role);
  }, [data]);

  const agentUser = useMemo(
    () =>
      !!agencyId ||
      isAgent ||
      (data?.role && roles.AgentRoles.includes(data.role)),
    [agencyId, isAgent, data?.role]
  );

  const roleAllowsReservations = useMemo(() => {
    if (!data?.role) {
      return true;
    } else if (!agentUser) {
      return roleHasPermission(data.role, UserPermission.ManageOwnReservations);
    } else {
      return (
        roleHasPermission(
          data.role,
          UserPermission.ManageAgencyCustomerReservations
        ) || roleHasPermission(data.role, UserPermission.ManageOwnReservations)
      );
    }
  }, [data?.role, roleHasPermission, agentUser]);

  // Role options available to this particular user that is being edited, based
  // on their current role.
  // Cannot switch between Admin to : Customer roles or Agent
  // Can switch between Agent and Customer roles when current user is admin and not under a company
  const filteredRoleList = useMemo(() => {
    if (mode === ModificationMode.Edit) {
      if (
        form.originalItem?.role &&
        roles.AdminRoles.includes(form.originalItem.role)
      ) {
        return roles.AdminRoles;
      } else if (isCurrentUserAdmin && !agencyId && !companyId) {
        return [
          ...(hasPermission(UserPermission.ManageAgencyUsers)
            ? roles.AgentRoles
            : []),
          ...(hasPermission(UserPermission.ManageCustomerUsers)
            ? roles.CustomerRoles
            : []),
        ];
      } else if (
        form.originalItem?.role &&
        roles.AgentRoles.includes(form.originalItem.role)
      ) {
        return roles.AgentRoles;
      } else {
        return roles.CustomerRoles;
      }
    } else if (agencyId) {
      return roles.AgentRoles;
    } else if (companyId || userIsAwaitingApproval) {
      return roles.CustomerRoles;
    }
    return roleList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, form.originalItem, roleList, companyId, agencyId]);

  const filteredCompanyList = useMemo(
    () =>
      companies.filter((company) => {
        const isNorthernCables = company.type === CompanyType.NorthernCables;
        return agentUser
          ? company.type === CompanyType.Agency
          : isNorthernCables === northernCablesUser &&
              company.type !== CompanyType.Agency;
      }),
    [companies, northernCablesUser, agentUser]
  );

  const filteredStatuses = useMemo(() => {
    const status = form.originalItem?.status;

    if (!isAdmin) {
      return compact(
        union(statuses.CustomerStatuses, [form.originalItem?.status])
      );
    }

    switch (status) {
      case UserStatus.WaitingForApproval:
        return [
          UserStatus.WaitingForApproval,
          UserStatus.Pending,
          UserStatus.Inactive,
        ];
      case UserStatus.Pending:
      case UserStatus.Inactive:
        return [UserStatus.Pending, UserStatus.Inactive];
      case UserStatus.Active:
        return [UserStatus.Active, UserStatus.Inactive];
      default:
        return statuses.Statuses;
    }
  }, [form.originalItem?.status, isAdmin]);

  // Update certain user fields under certain conditions
  useEffect(() => {
    if (data) {
      let newData: Partial<User> = {};

      // Fill in the company id for new users, if available
      const targetCompanyId =
        companyId ?? agencyId ?? (isAgent ? currentUser.companyRef.id : null);
      if (
        targetCompanyId &&
        !userId &&
        data.companyRef?.id !== targetCompanyId
      ) {
        newData = {
          ...newData,
          companyRef: doc(firestore, 'Companies', targetCompanyId),
        };
      }

      // Get the current company
      const company = companies.find(
        (company) =>
          company.id === data.companyRef?.id ||
          company.id === newData.companyRef?.id
      );
      // Update company-related information on user, if different than selected company
      if (
        data.companyRef &&
        company &&
        (data.companyName !== company.name ||
          data.companyClass !== company.class)
      ) {
        newData = {
          ...newData,
          canSeeCSA: !!company.canSeeCSA,
          canSeeUL: !!company.canSeeUL,
          companyName: company.name,
          companyClass: company.class,
        };
      }

      // Uncheck canReserve if current role does not allow reservation
      if (
        isAdmin &&
        !roleAllowsReservations &&
        !northernCablesUser &&
        data.canReserve
      ) {
        newData = {
          ...newData,
          canReserve: false,
        };
      }

      // Apply default reservation values from portal settings if empty
      const resetCustomerReservation =
        previousRole.current &&
        data?.role &&
        roles.AgentRoles.includes(previousRole.current) &&
        !roles.AgentRoles.includes(data.role);
      if (
        portalSettingsData &&
        (resetCustomerReservation ||
          !data.maxReservationsPerPeriod ||
          !data.holdDelay ||
          !data.extensionNotificationDelay)
      ) {
        newData = {
          ...newData,
          maxReservationsPerPeriod: portalSettingsData.maxReservationsPerPeriod,
          holdDelay: portalSettingsData.holdDelay,
          extensionNotificationDelay:
            portalSettingsData.extensionNotificationDelay,
        };
      }

      // Update default values for Northern Cables user
      if (
        mode !== ModificationMode.Edit &&
        data &&
        northernCablesUser &&
        northernCablesCompany &&
        (data.language !== Language.English ||
          !data.canReserve ||
          !data.canSeeUL ||
          !data.canSeeCSA ||
          !data.companyRef ||
          data.companyRef.id !== northernCablesCompany.id ||
          !data.isNCEmployee)
      ) {
        newData = {
          ...newData,
          language: Language.English,
          canReserve: true,
          canSeeUL: true,
          canSeeCSA: true,
          companyRef: doc(firestore, 'Companies', northernCablesCompany.id),
          isNCEmployee: true,
        };
      }

      // Remove Northern Cables company if user is not in a Northern Cables role
      // Remove Company if user is or is not in an Agency role
      if (
        !northernCablesUser &&
        ((mode !== ModificationMode.Edit &&
          ((data.companyRef &&
            data.companyRef.id === northernCablesCompany?.id) ||
            data.isNCEmployee)) ||
          (agentUser ? company?.type !== 'agency' : company?.type === 'agency'))
      ) {
        newData = {
          ...newData,
          companyRef: undefined,
          isNCEmployee: false,
        };
      }

      if (agentUser && data.language !== Language.English) {
        newData = {
          ...newData,
          language: Language.English,
        };
      }

      previousRole.current = data?.role;

      if (Object.keys(newData).length > 0) {
        setItem({ ...data, ...newData });
      }
    }
  }, [
    companies,
    companyId,
    agencyId,
    currentUser,
    data,
    firestore,
    setItem,
    isAdmin,
    mode,
    northernCablesCompany,
    northernCablesUser,
    agentUser,
    portalSettingsData,
    roleAllowsReservations,
    userId,
    isAgent,
  ]);

  const provincesOrStates = useProvincesOrStates(data?.country);

  useEffect(() => {
    showProgress(loading);
  }, [loading, showProgress]);

  const showResetPasswordButton =
    isAdmin &&
    form.mode === ModificationMode.Edit &&
    [
      UserStatus.Active,
      UserStatus.Pending,
      UserStatus.PendingEmailValidation,
    ].includes(data?.status as UserStatus);

  const showEmailVerificationButton =
    isAdmin &&
    form?.item?.status === UserStatus.PendingEmailValidation &&
    form.mode === ModificationMode.Edit;

  const sendPasswordResetLink = async () => {
    if (data) {
      setLoading(true);
      try {
        await forgotPassword(data.email);
        addNotification(
          t('users.confirmations.passwordResetEmailSent'),
          NotificationLevel.Success
        );
      } catch {
        addNotification(
          t('users.errors.adminUnableToSendResetPasswordEmail'),
          NotificationLevel.Error
        );
      }
      setLoading(false);
    }
  };

  const sendEmailConfirmationLink = async () => {
    if (!data) return;

    setLoading(true);

    try {
      await emailVerified(data?.id);
      addNotification(
        t('users.confirmations.emailVerificationSent'),
        NotificationLevel.Success
      );
    } catch (error) {
      const message =
        (error as Partial<{ code: string }>).code ===
        'functions/failed-precondition'
          ? t('emailValidation.alreadyVerified')
          : t('users.errors.unableToSendVerificationEmail');

      addNotification(message, NotificationLevel.Error);
    }

    setLoading(false);
  };

  const resetPhoneSecondFactor = useCallback(async () => {
    try {
      showProgress(true);
      await resetSecondAuthFactor({ userId: data?.id, factor: 'phone' });
      addNotification(
        t('users.confirmations.smsSecondFactorReset'),
        NotificationLevel.Success
      );
    } catch (error) {
      addNotification(
        t('users.errors.smsSecondFactorResetError'),
        NotificationLevel.Error
      );
    } finally {
      showProgress(false);
    }
  }, [showProgress, resetSecondAuthFactor, data?.id, addNotification, t]);

  return (
    <>
      <Container backgroundColor="#f9f9f9" sx={{ pb: 6 }}>
        <Grid container>
          <Grid item xs={12} md={6} sx={{ pr: { md: '20%' } }}>
            <Typography component="h3" variant="h3" sx={{ mt: 8, mb: 4 }}>
              {t('users.form.sections.profileInformation')}
            </Typography>
            <Show if={mode === ModificationMode.Edit}>
              <Grid container spacing={6}>
                <Grid item xs={7}>
                  <Select
                    form={form}
                    field="status"
                    required
                    label={t('users.fields.status')}
                    options={filteredStatuses}
                    disabled={
                      currentUser.id === data?.id ||
                      (isAgent &&
                        data?.status &&
                        !includes(statuses.CustomerStatuses, data?.status)) ||
                      (!isAdmin &&
                        (!hasPermission(UserPermission.DisableCompanyUsers) ||
                          (data?.status &&
                            !includes(
                              statuses.CustomerStatuses,
                              data?.status
                            ))))
                    }
                    getOptionLabel={(role) => t(`users.statuses.${role}`)}
                  />
                </Grid>
                <Grid item xs={5}>
                  <ReservationCounter
                    currentReservations={data?.reservationCount}
                    maxReservations={data?.maxReservationsPerPeriod}
                  />
                </Grid>
              </Grid>
            </Show>
            <TextField
              disabled={!isAdmin}
              form={form}
              required
              field="name"
              label={t('users.fields.name')}
              maxLength={255}
            />
            <TextField
              disabled={!isAdmin}
              form={form}
              field="title"
              label={t('users.fields.title')}
              maxLength={255}
            />
            <Select
              disabled={!isAdmin}
              form={form}
              required
              field="role"
              label={t('users.fields.role')}
              options={filteredRoleList}
              getOptionLabel={(role) => t(`users.roles.${role}`)}
            />
            <Typography component="h3" variant="h3" sx={{ mt: 8, mb: 4 }}>
              {t('users.form.sections.generalInformation')}
            </Typography>
            <TextField
              disabled={
                !isAdmin ||
                mode === ModificationMode.Edit ||
                userIsAwaitingApproval
              }
              form={form}
              field="email"
              required
              label={t('users.fields.email')}
              maxLength={255}
            />
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <PhoneNumberField
                  disabled={!isAdmin}
                  form={form}
                  field="phoneNumber"
                  required
                  label={t('users.fields.phoneNumber')}
                />
              </Grid>
              <Grid item xs={5}>
                <PhoneNumberField
                  disabled={!isAdmin}
                  form={form}
                  field="extension"
                  label={t('users.fields.extension')}
                  variant="extension"
                />
              </Grid>
            </Grid>
            <Grid item md={6}>
              <Select
                form={form}
                field="language"
                required
                disabled={!isAdmin || agentUser || northernCablesUser}
                label={t('users.fields.language')}
                options={Object.values(Language)}
                getOptionLabel={(role) => t(`languages.${role}`)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} sx={{ pr: { md: '20%' } }}>
            <Typography component="h3" variant="h3" sx={{ mt: 8, mb: 4 }}>
              {t('users.form.sections.location')}
            </Typography>
            <Select
              disabled={!isAdmin}
              form={form}
              field="country"
              label={t('users.fields.country')}
              options={Object.values(Country)}
              getOptionLabel={(role) => t(`countries.${role}`)}
              required
              autocomplete="ignore-address"
            />
            <Select
              disabled={!isAdmin}
              form={form}
              field="provinceOrState"
              label={t('users.fields.provinceOrState')}
              options={provincesOrStates}
              getOptionLabel={(role) => t(`provincesOrStates.${role}`)}
              required
              autocomplete="ignore-address"
            />
            <TextField
              disabled={!isAdmin}
              form={form}
              field="city"
              label={t('users.fields.city')}
              maxLength={255}
              required
              autocomplete="ignore-address"
            />
            <Select
              disabled={!isAdmin}
              form={form}
              field="timeZone"
              required
              label={t('users.fields.timeZone')}
              options={Object.values(TimeZone)}
              getOptionLabel={(role) => t(`timeZones.${role}`)}
            />

            <Show if={isAdmin}>
              <Typography component="h3" variant="h3" sx={{ mt: 8, mb: 4 }}>
                {t('users.form.sections.comments')}
              </Typography>
              <TextField
                disabled={!isAdmin}
                form={form}
                field="comments"
                label={t('users.fields.comments')}
                minRows={5}
                multiline
              />
            </Show>

            <Show if={showResetPasswordButton}>
              <Button
                disabled={loading}
                variant="contained"
                onClick={sendPasswordResetLink}
              >
                {t('users.actions.sendPasswordResetLink')}
              </Button>
            </Show>

            <Show if={showEmailVerificationButton}>
              <Grid mt={2}>
                <Button
                  disabled={loading}
                  variant="contained"
                  onClick={sendEmailConfirmationLink}
                >
                  {t('users.actions.sendEmailValidationLink')}
                </Button>
              </Grid>
            </Show>
          </Grid>
        </Grid>
        <Grid mt={5}>
          <Checkbox
            disabled={!isAdmin}
            variant="medium"
            field="receiveCommunications"
            form={form}
            label={t('users.fields.receiveCommunications')}
          />
        </Grid>
      </Container>
      <Container sx={{ pb: 6 }}>
        <Grid container>
          <Grid item xs={12} md={6} sx={{ pr: { md: '10%' } }}>
            <Typography component="h3" variant="h3" sx={{ mt: 8, mb: 4 }}>
              {t(`users.form.sections.${agentUser ? 'agency' : 'company'}`)}
            </Typography>
            <Grid container spacing={2}>
              <Show if={userIsAwaitingApproval}>
                <Grid item xs={12}>
                  <span>
                    {`${t('users.form.suggestedByCustomer')} ${
                      form.item?.suggestedCompanyName ?? ''
                    }`}
                  </span>
                </Grid>
              </Show>
              <Grid item xs={8} md={6}>
                <Select
                  autocomplete="new-company"
                  form={form}
                  required
                  disabled={
                    !isAdmin ||
                    northernCablesUser ||
                    !!companyId ||
                    !!agencyId ||
                    isAgent
                  }
                  field="companyRef"
                  label={t(
                    `users.fields.${agentUser ? 'agencyName' : 'companyName'}`
                  )}
                  options={filteredCompanyList}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  getOptionLabel={(companyRef) =>
                    companies.find((company) => company.id === companyRef.id)
                      ?.name || ''
                  }
                  getOptionValue={(company) =>
                    company ? doc(firestore, 'Companies', company.id) : null
                  }
                />
              </Grid>
              <Grid item xs={4} md={4}>
                {!agentUser && (
                  <Grid>
                    <Select
                      form={form}
                      field="companyRef"
                      disabled={true}
                      label={t('users.fields.companyCode')}
                      options={filteredCompanyList}
                      isOptionEqualToValue={(option, value) =>
                        option?.id === value?.id
                      }
                      getOptionLabel={(companyRef) =>
                        companies.find(
                          (company) => company.id === companyRef.id
                        )?.code || ''
                      }
                      getOptionValue={(company) =>
                        company ? doc(firestore, 'Companies', company.id) : null
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item md={10}>
              <Typography component="h3" variant="h3" sx={{ mt: 8, mb: 4 }}>
                {t('users.form.sections.inventory')}
              </Typography>
              <RadioGroup
                row
                required
                form={form}
                field="measurementUnits"
                defaultValue={
                  mode !== ModificationMode.Edit
                    ? data?.country === Country.UnitedStates
                      ? MeasurementUnits.Imperial
                      : MeasurementUnits.Metric
                    : undefined
                }
                options={[
                  {
                    key: MeasurementUnits.Metric,
                    label: t('users.fields.units.metric'),
                  },
                  {
                    key: MeasurementUnits.Imperial,
                    label: t('users.fields.units.imperial'),
                  },
                ]}
                label={t('users.fields.units.label')}
              />
              <DropDownCheckboxList
                form={form}
                required
                label={t('users.fields.canSeeCSAOrUL')}
                disabled={!isAdmin || northernCablesUser}
                fields={['canSeeCSA', 'canSeeUL']}
                fieldLabels={[
                  t('users.fields.canSeeCSA'),
                  t('users.fields.canSeeUL'),
                ]}
              />
              <Show if={isAdmin}>
                <Select
                  form={form}
                  field="companyRef"
                  disabled={true}
                  label={t('users.fields.inventoryClass')}
                  options={filteredCompanyList}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  getOptionLabel={(companyRef) => {
                    if (northernCablesUser) {
                      return t('common.notApplicable');
                    }
                    const company = companies.find(
                      (company) => company.id === companyRef.id
                    );
                    return company
                      ? t(`inventoryClasses.${company.class}`)
                      : '';
                  }}
                  getOptionValue={(company) =>
                    company ? doc(firestore, 'Companies', company.id) : null
                  }
                />
              </Show>
            </Grid>
          </Grid>
          <Show
            if={Boolean(
              northernCablesUser &&
                isAdmin &&
                hasPermission(UserPermission.ManageTwoFactorAuthSettings)
            )}
          >
            <Grid item md={6} sx={{ pr: { md: '20%' } }}>
              <Typography variant="h3" sx={{ mt: 8, mb: 4 }}>
                {t('users.form.sections.twoFactorAuthentication')}
              </Typography>
              <TwoFactorSettingCard
                phoneNumberLastDigits={data?.phoneSecondFactorLastDigits}
                onReset={resetPhoneSecondFactor}
              />
            </Grid>
          </Show>
          <Show if={!northernCablesUser && !!roleAllowsReservations}>
            <Grid item md={6} sx={{ pr: { md: '20%' } }}>
              <Typography component="h3" variant="h3" sx={{ mt: 8, mb: 4 }}>
                {t('users.form.sections.reservationPermission')}
              </Typography>
              <Checkbox
                form={form}
                disabled={
                  !isAdmin || northernCablesUser || !roleAllowsReservations
                }
                field="canReserve"
                label={t('users.fields.canReserve.label')}
                variant="medium"
              />
              <Typography variant="h4" sx={{ mt: 3, mb: 3 }}>
                {t('users.fields.maxReservationsPerPeriod.title')}
              </Typography>
              <NumericTextField
                form={form}
                field="maxReservationsPerPeriod"
                label={t('users.fields.maxReservationsPerPeriod.label')}
                defaultValue={portalSettingsData?.maxReservationsPerPeriod}
                minimum={agentUser ? 99 : 1}
                maximum={99}
                disabled={agentUser || !isAdmin || !data?.canReserve}
                required={!!data?.canReserve}
              />
              <Typography variant="h4" sx={{ mt: 3, mb: 3 }}>
                {t('users.fields.holdDelay.title')}
              </Typography>
              <NumericTextField
                form={form}
                field="holdDelay"
                label={t('users.fields.holdDelay.label')}
                unitLabel={t('common.units.hours')}
                defaultValue={portalSettingsData?.holdDelay}
                minimum={2}
                maximum={99}
                disabled={!isAdmin || !data?.canReserve}
                required={!!data?.canReserve}
              />
              <Typography variant="h4" sx={{ mt: 3, mb: 3 }}>
                {t('users.fields.extensionNotificationDelay.title')}
              </Typography>
              <NumericTextField
                form={form}
                field="extensionNotificationDelay"
                label={t('users.fields.extensionNotificationDelay.label')}
                unitLabel={t('common.units.hours')}
                defaultValue={portalSettingsData?.extensionNotificationDelay}
                minimum={1}
                maximum={Math.min(
                  9,
                  data?.holdDelay ? Math.max(data.holdDelay - 1, 1) : 9
                )}
                disabled={!isAdmin || !data?.canReserve}
                required={!!data?.canReserve}
              />
            </Grid>
          </Show>
        </Grid>
      </Container>
    </>
  );
};
