import { memo, useEffect, useMemo } from 'react';
import { useCompanies, useQuoteInternalNotes } from '../../hooks';
import { Quote as QuoteModel } from '../../models';
import { CompanyType } from '../../enums';
import {
  CustomerDetails,
  OtherDetails,
  QuoteDetails,
} from '../QuoteSummaryDetails';
import { AdminQuoteSummaryDetailsProps } from './AdminQuoteSummaryDetails.props';
import {
  StyledCustomerDetails,
  StyledOtherDetails,
  StyledQuoteDetails,
  StyledStack,
} from './AdminQuoteSummaryDetails.styles';

export const AdminQuoteSummaryDetails = memo(
  ({
    form,
    isReadonly,
    draftInternalNotes,
    setShowNotesDrawer,
  }: AdminQuoteSummaryDetailsProps) => {
    const { list: companies } = useCompanies();
    const { list: internalNotes } = useQuoteInternalNotes(form.item?.id ?? '');

    const agencies = useMemo(
      () => companies.filter((c) => c.type === CompanyType.Agency),
      [companies]
    );

    const customerCompanies = useMemo(
      () => companies.filter((c) => c.type === CompanyType.Customer),
      [companies]
    );

    const selectableCompanies = useMemo(() => {
      const selectedAgency = agencies.find((a) => a.id === form.item?.agencyId);
      const associatedCompanies = Object.values(
        selectedAgency?.associatedCompanies ?? {}
      ).map((a) => a.companyId);

      return customerCompanies
        .filter((c) => !selectedAgency || associatedCompanies.includes(c.id))
        .sort((a, b) => (a.name >= b.name ? 1 : -1));
    }, [agencies, customerCompanies, form.item?.agencyId]);

    useEffect(() => {
      if (!isReadonly && form.dirty) {
        const agency = agencies.find((a) => a.id === form.item?.agencyId);
        const company = agency
          ? selectableCompanies.find((c) => c.id === form.item?.companyId)
          : customerCompanies.find((c) => c.id === form.item?.companyId);

        const resetCustomer =
          form.item?.agencyName !== (agency?.name ?? null) ||
          form.item?.companyName !== (company?.name ?? null);

        form.setItem({
          ...form.item,
          agencyId: agency?.id ?? null,
          agencyName: agency?.name ?? null,
          companyId: company?.id ?? null,
          companyName: company?.name ?? null,
          companyAccountNumber: company?.accountNumber ?? null,
          ...(resetCustomer
            ? { customerId: '', customerName: '', customerEmail: '' }
            : {}),
        } as QuoteModel);
      }
    }, [agencies, customerCompanies, form, selectableCompanies, isReadonly]);

    return (
      <StyledStack>
        <StyledCustomerDetails isReadonly={isReadonly}>
          <CustomerDetails
            form={form}
            agencies={agencies}
            selectableCompanies={selectableCompanies}
          />
        </StyledCustomerDetails>

        <StyledQuoteDetails isReadonly={isReadonly}>
          <QuoteDetails form={form} isReadonly={isReadonly} />
        </StyledQuoteDetails>

        <StyledOtherDetails isReadonly={isReadonly}>
          <OtherDetails
            form={form}
            isAdmin
            isReadonly={isReadonly}
            internalNotesCount={
              internalNotes.length || draftInternalNotes.length
            }
            setShowNotesDrawer={setShowNotesDrawer}
          />
        </StyledOtherDetails>
      </StyledStack>
    );
  }
);
