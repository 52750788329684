import { useEffect } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
  FormHelperText,
  SxProps,
} from '@mui/material';
import { useFormFieldValue } from '../../hooks';
import { RadioGroupProps } from './RadioGroup.props';
import * as Sx from './RadioGroup.style';

export const RadioGroup = <T, K>({
  defaultValue,
  options,
  disabled,
  required,
  label,
  form,
  field,
  fullWidth,
  row,
  sx,
}: RadioGroupProps<T, K>) => {
  const { value, setValue } = useFormFieldValue<T, K | null>(form, field);

  useEffect(() => {
    if (value === undefined && defaultValue && defaultValue !== undefined) {
      setValue(defaultValue);
    }
  }, [value, setValue, defaultValue]);

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(defaultValue || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const onChangeUnits = (
    _: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setValue(value as unknown as K);
  };

  return (
    <FormControl
      component="fieldset"
      fullWidth={fullWidth}
      disabled={disabled}
      required={required}
      sx={
        {
          ...Sx.formControlRoot(!!form.errors[field]),
          ...sx,
        } as SxProps
      }
      error={!!form.errors[field]}
    >
      {label && (
        <FormLabel component="legend" sx={Sx.formLabel}>
          {label}
        </FormLabel>
      )}
      <MuiRadioGroup
        row={row}
        defaultValue={defaultValue}
        value={value || null}
        onChange={onChangeUnits}
        sx={Sx.formRadioGroup(!!form.errors[field])}
      >
        {options.map((item) => (
          <FormControlLabel
            key={item.key}
            value={item.key}
            control={<Radio />}
            label={item.label}
          />
        ))}
      </MuiRadioGroup>
      {!!form.errors[field] && (
        <FormHelperText sx={Sx.formErrorText}>
          {form.errors[field]}
        </FormHelperText>
      )}
    </FormControl>
  );
};
