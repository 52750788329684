import { Avatar, Button, Grid, Typography } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import StarIcon from '@mui/icons-material/Star';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { usePageTitle, usePermission, usePortalMetadata } from '../../../hooks';
import {
  Container,
  DashboardCard,
  DashboardInventorySearchReport,
} from '../../../components';
import { NeedsPermission } from '../../../security';
import { DefaultValue, UserPermission } from '../../../enums';

import * as Sx from './Dashboard.styles';

export const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const { hasAllPermissions } = usePermission();
  const navigate = useNavigate();
  usePageTitle();

  const { item, loading } = usePortalMetadata();

  const reservationConversionRate =
    item && item.rollingCompletedReservationsCount > 0
      ? `${Math.round(
          (item?.rollingPurchasedReservationsCount /
            item?.rollingCompletedReservationsCount) *
            100
        ).toString()}%`
      : DefaultValue.NotAvailable;

  const spaceBetween = !!hasAllPermissions([
    UserPermission.ViewReservationReports,
    UserPermission.ViewAllReservations,
    UserPermission.ManageCustomerUsers,
    UserPermission.ManageQuotes,
  ]);

  return (
    <Grid sx={Sx.background}>
      <Container sx={Sx.container} subSx={Sx.innerContainer}>
        <Grid>
          <Grid display="flex" alignItems="center">
            <Avatar sx={Sx.avatar}>
              <DashboardIcon fontSize="large" />
            </Avatar>
            <Typography variant="h1" color="white" ml={2}>
              {t('dashboard.title')}
            </Typography>
          </Grid>

          <Grid display="flex" justifyContent="right" mt={5}>
            <NeedsPermission
              oneOf={[
                UserPermission.ManageNCUsers,
                UserPermission.ManageCustomerUsers,
                UserPermission.ManageAgencyUsers,
              ]}
            >
              <Button
                onClick={() => navigate('/admin/users/new')}
                startIcon={<HowToRegIcon />}
                variant="contained"
                sx={Sx.buttonMR}
              >
                {t('dashboard.addUser')}
              </Button>
            </NeedsPermission>
          </Grid>
        </Grid>

        <Grid container sx={Sx.gridContainer}>
          <Grid item sx={Sx.grid(spaceBetween)}>
            <NeedsPermission oneOf={[UserPermission.ViewReservationReports]}>
              <DashboardCard
                icon={<StarIcon sx={Sx.icon} />}
                innerMessage={`${item?.rollingPurchasedReservationsCount}/${item?.rollingCompletedReservationsCount}`}
                link="/admin/reports/conversion-rate"
                linkText={t('dashboard.reports')}
                message={reservationConversionRate}
                title={t('dashboard.reservationConversionRate')}
              />
            </NeedsPermission>

            <NeedsPermission oneOf={[UserPermission.ViewAllReservations]}>
              <DashboardCard
                icon={<HourglassTopIcon sx={Sx.icon} />}
                link="/admin/reservations"
                linkText={t('dashboard.reservations')}
                message={item?.activeReservationsCount?.toString()}
                title={t('dashboard.totalCurrentReservations')}
              />
            </NeedsPermission>

            <NeedsPermission oneOf={[UserPermission.ManageQuotes]}>
              <DashboardCard
                icon={<RequestQuoteIcon sx={Sx.icon} />}
                link="/admin/quotes"
                linkText={t('dashboard.quotes')}
                message={item?.outstandingQuoteRequests?.toString()}
                title={t('dashboard.quoteRequests')}
              />
            </NeedsPermission>

            <NeedsPermission oneOf={[UserPermission.ManageCustomerUsers]}>
              <DashboardCard
                icon={<SupervisorAccountIcon sx={Sx.icon} />}
                link="/admin/users/awaitingApproval"
                linkText={t('dashboard.awaitingApproval')}
                message={item?.usersAwaitingApprovalCount?.toString()}
                title={t('dashboard.awaitingApproval')}
              />
            </NeedsPermission>
          </Grid>

          <Grid item sx={Sx.grid()}>
            <NeedsPermission
              oneOf={[UserPermission.ViewInventorySearchReports]}
            >
              <DashboardInventorySearchReport
                link="/admin/reports/inventory-searches"
                linkText={t('dashboard.reports')}
                products={item?.mostSearchedProducts}
                title={t('dashboard.inventorySearchReport')}
                loading={loading}
              />
            </NeedsPermission>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};
