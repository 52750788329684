import { Box, Container, MenuItem, Toolbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import GetWired from '../../assets/GetWired';
import { usePortalSettings } from '../../hooks';
import { translateString } from '../../i18n';

import { ConsentNoticeMenuItem } from '../ConsentNoticeMenuItem/ConsentNoticeMenuItem.component';
import * as Sx from './Footer.styles';

export const Footer = () => {
  const { t, i18n } = useTranslation();
  const { item: portalSettings } = usePortalSettings();

  return (
    <Box component="footer" sx={Sx.box}>
      <Container maxWidth={false}>
        <Toolbar disableGutters role="menu" sx={Sx.toolbar}>
          <Typography color="#c2c2c2" sx={Sx.copyrights}>
            &copy; Northern Cables Inc.
          </Typography>
          <Typography color="#c2c2c2" sx={Sx.separator}>
            /
          </Typography>
          <MenuItem
            tabIndex={0}
            component="a"
            href={translateString(portalSettings?.termsAndConditionsURL, i18n)}
            target="_blank"
            sx={Sx.terms}
          >
            {t('termsAndConditions.title')}
          </MenuItem>
          <MenuItem
            tabIndex={0}
            component="a"
            href={translateString(portalSettings?.contactURL, i18n)}
            target="_blank"
            sx={Sx.contactLink}
          >
            {t('contact.title')}
          </MenuItem>
          <ConsentNoticeMenuItem sx={Sx.consentLink} />
          <GetWired />
        </Toolbar>
      </Container>
    </Box>
  );
};
