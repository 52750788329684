import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Stack, Theme, useMediaQuery } from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import { DateTime } from 'luxon';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';

import {
  Container,
  DecoratedHeader,
  ReelReturnDatePicker,
  ReelReturnInfoPopup,
  Select,
  Show,
  TextField,
} from '../components';
import {
  useCompany,
  useCurrentUser,
  useForm,
  useFormFieldValue,
  useNotification,
  usePageTitle,
  useProgress,
  useReelDepots,
  useReelReturn,
  useReelSizes,
} from '../hooks';
import { ReelReturnTable } from '../components/ReelReturnTable/ReelReturnTable.component';
import { ReelReturnDrawer } from '../components/ReelReturnDrawer/ReelReturnDrawer.component';
import { ReelReturn as ReelReturnModel } from '../models';
import { ReelSize } from '../models/ReelSize';
import { ReelReturnStatusBadge } from '../components/ReelReturnStatusBadge/ReelReturnStatusBadge.component';
import {
  ModificationMode,
  NotificationLevel,
  ReelReturnError,
  ReelReturnErrorMapping,
  ReelReturnStatus,
} from '../enums';
import { CurrencyFormatter } from '../utils/currency';
import { formatReelDepotLabel } from '../models/ReelDepot';
import { ReelReturnErrorFields } from '../enums/ReelReturn';
import { ValidationError } from '../types';
import { getLinearFeet, round } from '../components/ReelReturnDrawer/utils';
import { TRUCK_LOAD_CAPACITY } from '../components/ReelReturnDrawer/constants';
import { getPageTitle } from '../utils/pages';
import { ReelReturnContext } from './admin/modals/ImpersonateCustomer/ImpersonateCustomer.interface';

const ReelReturn = ({ isAdmin }: { isAdmin: boolean }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { reelReturnId } = useParams();
  const { showProgress } = useProgress();

  const { impersonatedUser } = useOutletContext<ReelReturnContext>();

  const currentUser = useCurrentUser();

  const user = impersonatedUser || currentUser;

  const { item: company } = useCompany(user.companyRef.id);
  const reelReturn = useReelReturn(user.companyRef.id, reelReturnId || '');
  const { list: reelDepots, loading: loadingReelDepots } = useReelDepots();
  const { list: reelSizes, loading: loadingReelSizes } = useReelSizes();
  const { addNotification } = useNotification();
  const [saving, setSaving] = useState<boolean>(false);
  usePageTitle(
    reelReturnId === 'new'
      ? getPageTitle('reelReturns.new')
      : reelReturn.item?.nciRmaNumber
      ? getPageTitle('reelReturns.editNCI', {
          nciRmaNumber: reelReturn.item?.nciRmaNumber,
        })
      : getPageTitle('reelReturns.edit')
  );

  const functions = useFunctions();
  const generateRmaFunction = httpsCallable(functions, 'generateRma');

  const reelDepotOptions = reelDepots.map((depot) => depot.id);
  const reelSizeOptions = reelSizes.filter((reel) => reel.isActive);

  const currencyFormatter = new CurrencyFormatter(user.country);

  const isNewReelReturn = reelReturnId === 'new';

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const [errors, setErrors] = useState<ValidationError<ReelReturnErrorFields>>(
    {}
  );

  const form = useForm(reelReturn, {
    mode: isNewReelReturn ? ModificationMode.Add : ModificationMode.Edit,
    beforeSave: (item) => {
      if (isNewReelReturn) {
        item.clientRmaNumber = item.clientRmaNumber ?? '';
        item.companyId = user.companyRef.id;
        item.companyName = company?.name ?? '';
        item.creatorId = user.id;
        item.creatorName = user.name;
        item.dateCreated = Timestamp.now();
        item.depotName =
          reelDepots.find((depot) => depot.id === item.depotId)?.name ?? '';
        item.nciRmaNumber = '';
        item.returnByDate = item.returnByDate ?? Timestamp.now();
        item.status = ReelReturnStatus.Draft;
      } else if (
        user.id !== item.creatorId &&
        !item.editorIds?.some((id) => id === user.id)
      ) {
        item.editorIds = (item.editorIds || []).concat([user.id]);
      }
    },
    onSaveSuccess: (savedItem) => {
      addNotification(
        savedItem.status === ReelReturnStatus.Draft
          ? t('reelReturns.edit.drawer.save.successDraft')
          : t('reelReturns.edit.drawer.save.successUnsubmitted', {
              rmaNumber: savedItem.nciRmaNumber,
            }),
        NotificationLevel.Success
      );

      if (isNewReelReturn) {
        navigate(`${isAdmin ? '/admin' : ''}/reel-returns/${savedItem.id}`, {
          replace: true,
        });
      }

      setErrors({});
    },
  });

  const saveAsDraft = () => {
    form.save(true);
  };

  const sendReturn = async () => {
    const currentErrors = checkErrors();
    if (Object.values(currentErrors).some((error) => error)) {
      addNotification(
        t('forms.savedUnsuccessfullyDueToValidation'),
        NotificationLevel.Error,
        true
      );
    } else if (
      !form.item?.reels ||
      !Object.values(form.item?.reels ?? {}).some((value) => value)
    ) {
      addNotification(
        t('reelReturns.edit.drawer.save.emptyReels'),
        NotificationLevel.Error,
        true
      );
    } else {
      if (form.item) {
        setSaving(true);
        addNotification(t('forms.saving'), NotificationLevel.Information, true);
        form.setWithPrompt(false);

        try {
          const depot = reelDepots.find(
            (depot) => depot.id === form.item?.depotId
          );
          const itemToSave = {
            ...form.item,
            depotName: depot ? formatReelDepotLabel(depot) : '',
            ...(isNewReelReturn && {
              companyId: user.companyRef.id,
              companyName: company?.name ?? '',
              creatorId: user.id,
              creatorName: user.name,
            }),
          };

          const nciRmaNumber = form.item.nciRmaNumber;
          await generateRmaFunction({
            impersonatedUserId: impersonatedUser?.id || null,
            reelReturn: itemToSave,
          });
          addNotification(
            t(
              `reelReturns.edit.drawer.save.successRma${
                nciRmaNumber ? 'Updated' : 'Generated'
              }`,
              { rmaNumber: nciRmaNumber }
            ),
            NotificationLevel.Success
          );
          navigate(`${isAdmin ? '/admin' : ''}/reel-returns`);
        } catch (e) {
          const error = e as Error;
          addNotification(
            t(
              `reelReturns.edit.drawer.save.${
                ReelReturnErrorMapping[error.message as ReelReturnError] ??
                'error'
              }`
            ),
            NotificationLevel.Error,
            true
          );
        } finally {
          setSaving(false);
        }
      }
    }
  };

  const checkErrors = () => {
    if (!form?.item) return {};

    const { depotId, returnByDate, clientRmaNumber } = form.item;

    const currentErrors: ValidationError<ReelReturnErrorFields> = {};

    currentErrors[ReelReturnErrorFields.DepotId] = !depotId;
    currentErrors[ReelReturnErrorFields.ClientRma] = !clientRmaNumber;

    const selectedDate = DateTime.fromJSDate(returnByDate.toDate());
    const today = DateTime.now();
    currentErrors[ReelReturnErrorFields.ReturnByDate] =
      selectedDate < today && !today.hasSame(selectedDate, 'day');

    setErrors(currentErrors);

    return currentErrors;
  };

  const { value: returnByDate, setValue: setReturnByDate } = useFormFieldValue<
    ReelReturnModel,
    Timestamp
  >(form, 'returnByDate');

  const formattedValue = returnByDate
    ? DateTime.fromJSDate(returnByDate.toDate())
    : DateTime.now();

  const onDateChange = (value: DateTime | null): void => {
    if (!value || value.invalidReason || value.invalidExplanation) {
      setReturnByDate(Timestamp.now());
    } else {
      const formDate = form.item?.['returnByDate'];

      if (
        !formDate ||
        !value?.hasSame(DateTime.fromJSDate(formDate.toDate()), 'day')
      ) {
        setReturnByDate(Timestamp.fromDate(value.toJSDate()));
      }
    }
  };

  useEffect(() => {
    if (form.item?.returnByDate === undefined) {
      form.setItem(
        { ...form.item, returnByDate: Timestamp.now() } as ReelReturnModel,
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.item?.returnByDate]);

  useEffect(() => {
    if (form.item?.status === ReelReturnStatus.Generated && form.dirty) {
      form.setItem({
        ...form.item,
        status: ReelReturnStatus.Unsubmitted,
      } as ReelReturnModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.dirty]);

  useEffect(() => {
    showProgress(saving);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving]);

  useEffect(() => {
    if (
      reelReturn.item?.status === ReelReturnStatus.Cancelled ||
      reelReturn.item?.status === ReelReturnStatus.Received
    ) {
      navigate('/reel-returns');
    }
  }, [navigate, reelReturn.item?.status]);

  const setQuantity = (reel: ReelSize, quantity: number) => {
    const reels = Object.assign({}, form.item?.reels);
    if (quantity > 0) {
      reels[reel.id] = quantity;
    } else {
      delete reels[reel.id];
    }

    const newLinearFeet = Object.entries(reels).reduce(
      (acc, [reelId, reelQuantity]) => {
        const reelInfo = reelSizes.find((reelSize) => reelId === reelSize.id);
        if (reelInfo) {
          return acc + getLinearFeet(reelQuantity, reelInfo);
        }

        return acc;
      },
      0
    );

    const totalTrailerNeeded = round(newLinearFeet / TRUCK_LOAD_CAPACITY);

    form.setItem({
      ...form.item,
      linearFeet: round(newLinearFeet),
      totalTrailerNeeded,
      reels,
    } as ReelReturnModel);
  };

  return (
    <Container
      sx={{ paddingTop: '1em', paddingBottom: { xs: '22vh', md: '4.5em' } }}
    >
      <Container>
        <DecoratedHeader
          title={t(
            `reelReturns.edit.title.${isNewReelReturn ? 'new' : 'edit'}`
          )}
          status={
            !isNewReelReturn &&
            form.item?.status && (
              <ReelReturnStatusBadge
                status={form.item?.status}
                text={t(
                  `reelReturns.status.${form.item?.status}`
                ).toUpperCase()}
              />
            )
          }
        >
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, md: 4 }}
            sx={{
              mt: { xs: 5, md: 0 },
              mb: { xs: 2, md: 0 },
              width: '100%',
            }}
          >
            <Show if={form.dirty}>
              <Button
                fullWidth
                sx={{ minWidth: '225px' }}
                variant="outlined"
                onClick={saveAsDraft}
              >
                {t('reelReturns.edit.saveAsDraft')}
              </Button>
            </Show>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() =>
                navigate(`${isAdmin ? '/admin' : ''}/reel-returns`)
              }
            >
              {t('reelReturns.edit.back')}
            </Button>
          </Stack>
        </DecoratedHeader>
        <Grid
          container
          spacing={3}
          direction={'row'}
          justifyContent={'flex-start'}
          alignItems={'center'}
        >
          <Grid item xs={12} md={4}>
            <Select
              form={form}
              field="depotId"
              required
              displayCustomError={errors[ReelReturnErrorFields.DepotId]}
              customError={t('common.validations.fieldRequired')}
              disabled={!!reelReturn.item?.nciRmaNumber}
              label={t('reelReturns.edit.dropOffLocation')}
              options={!loadingReelDepots ? reelDepotOptions : []}
              getOptionLabel={(reelDepotId) => {
                const reelDepot = reelDepots.find(
                  (depot) => depot.id === reelDepotId
                );
                return reelDepot ? formatReelDepotLabel(reelDepot) : '';
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ReelReturnDatePicker
              label={t('reelReturns.edit.dropOffDate')}
              displayError={errors[ReelReturnErrorFields.ReturnByDate]}
              minDate={
                form.item?.status !== ReelReturnStatus.Received
                  ? DateTime.now()
                  : undefined
              }
              onDateChange={onDateChange}
              value={formattedValue}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              form={form}
              field="clientRmaNumber"
              displayCustomError={errors[ReelReturnErrorFields.ClientRma]}
              customError={t('common.validations.fieldRequired')}
              required
              label={t('reelReturns.edit.yourRMA')}
              maxLength={80}
            />
          </Grid>
        </Grid>
      </Container>
      <ReelReturnTable
        currencyFormatter={currencyFormatter}
        form={form}
        isMobile={isMobile}
        loadingReelSizes={loadingReelSizes}
        reelSizes={reelSizeOptions}
        setQuantity={setQuantity}
      />
      <ReelReturnDrawer
        currencyFormatter={currencyFormatter}
        form={form}
        saving={saving}
        isDesktop={isDesktop}
        isMobile={isMobile}
        loading={loadingReelSizes}
        reelSizes={reelSizeOptions}
        sendReturn={sendReturn}
        isAdmin={isAdmin}
        impersonatedUser={user}
        isNewReelReturn={isNewReelReturn}
      />
      <ReelReturnInfoPopup loading={loadingReelSizes && loadingReelDepots} />
    </Container>
  );
};
export default ReelReturn;
