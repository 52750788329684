import { useTranslation } from 'react-i18next';
import {
  Typography,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Grid,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ReactNode, useCallback } from 'react';
import { getDocs } from 'firebase/firestore';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import { useFirestore } from 'reactfire';
import { useAuthentication, useCurrentUser, usePermission } from '../../hooks';
import { DataTable, DataTableColumn } from '../../tables';
import {
  ConsultationMode,
  DefaultValue,
  QuoteStatus,
  QuoteTypeOfBuy,
  TimeZone,
  UserPermission,
  UserRole,
  UserRoleCategoryPath,
} from '../../enums';
import { StatusIndicator } from '../StatusIndicator/StatusIndicator.component';
import { PricedQuoteStatuses, QuoteStatusColors } from '../../mappings';
import { FormattedTimestamp } from '../FormattedTimestamp/FormattedTimestamp.component';
import { CurrencyFormatter } from '../../utils/currency';
import { Quote } from '../../models';
import { Show } from '../Show/Show.component';
import { ExportMenu } from '../ExportMenu/ExportMenu.component';
import { quotesCsv, quotesPdf } from '../../reports';
import { cloneQuote } from '../../utils/quote';
import { QuotesTableProps } from './QuotesTable.props';

export const QuotesTable = ({
  pagination,
  quotes,
  sorting,
  mode,
  fetchAllQuery,
}: QuotesTableProps) => {
  const { t, i18n } = useTranslation();
  const { isAgent, isAdmin, role, userRoleCategory } = useAuthentication();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const { hasPermission } = usePermission();
  const currencyFormatter = new CurrencyFormatter(
    currentUser.country,
    Number.MAX_VALUE
  );
  const firestore = useFirestore();

  const prefixQuotePath = `${UserRoleCategoryPath[userRoleCategory]}/quotes`;

  const cancelQuoteLink = useCallback(
    (quote: Quote) => {
      if (
        quote.status === QuoteStatus.Draft &&
        mode === ConsultationMode.Current &&
        hasPermission(UserPermission.RequestQuotes)
      ) {
        return `${prefixQuotePath}/${quote.id}/delete`;
      } else if (
        role === UserRole.NciAdmin &&
        mode === ConsultationMode.Current &&
        [
          QuoteStatus.Submitted,
          QuoteStatus.InProgress,
          QuoteStatus.Quoted,
        ].includes(quote.status)
      ) {
        return `${prefixQuotePath}/${quote.id}/cancel`;
      }

      return '';
    },
    [hasPermission, mode, prefixQuotePath, role]
  );

  const cloneQuoteItem = async (quote: Quote) => {
    await cloneQuote(quote, isAdmin, isAgent, currentUser, firestore);
  };

  const exportCSV = async () => {
    const data = await getExportData();
    quotesCsv.generate(data, t);
  };

  const exportPDF = async () => {
    const data = await getExportData();

    quotesPdf.generate(data, mode, t, i18n);
  };

  const getExportData = async () => {
    const result = await getDocs(fetchAllQuery);
    return result.docs.map((doc) => doc.data() as Quote);
  };

  const renderProjectName = (_: string, quote: Quote) => (
    <Typography>
      {quote?.typeOfBuy === QuoteTypeOfBuy.InventoryBuy
        ? t('quotes.edit.summary.quoteDetails.typeOfBuy.inventoryBuy')
        : quote?.projectName}
    </Typography>
  );

  const menuItems = (item: Quote) => {
    const menuItems: ReactNode[] | null = [];
    if (cancelQuoteLink(item)) {
      menuItems.push(
        <MenuItem key="cancel" component={Link} to={cancelQuoteLink(item)}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>{t('quotes.cancel.label')}</ListItemText>
        </MenuItem>
      );
    }
    if (
      hasPermission(UserPermission.RequestQuotes) ||
      (isAdmin && PricedQuoteStatuses.includes(item.status))
    ) {
      const isRequote = item.status === QuoteStatus.Expired;
      menuItems.push(
        <MenuItem key="clone" onClick={() => cloneQuoteItem(item)}>
          <ListItemIcon>
            {isRequote ? <ReplayCircleFilledIcon /> : <ContentCopyIcon />}
          </ListItemIcon>
          <ListItemText>
            {t(`quotes.${isRequote ? 'requote' : 'clone'}`)}
          </ListItemText>
        </MenuItem>
      );
    }
    return menuItems.length ? menuItems : undefined;
  };

  return (
    <>
      <Show
        if={!!(quotes?.length && hasPermission(UserPermission.ManageQuotes))}
      >
        <Grid display="flex" justifyContent="flex-end" mt={1}>
          <ExportMenu
            onCSVClicked={() => exportCSV()}
            onPDFClicked={() => exportPDF()}
          />
        </Grid>
      </Show>
      <DataTable
        rowOnClick={(q) => navigate(`${prefixQuotePath}/${q.id}`)}
        menuItems={menuItems}
        data={quotes}
        sorting={sorting}
        pagination={pagination}
        // less padding to the menu items column to avoid horizontal scrollbar
        tableStyles={{ '& .MuiTableCell-root.menu-items': { padding: 1 } }}
      >
        <DataTableColumn
          property="status"
          title={t('quotes.fields.status')}
          output={(status) => (
            <StatusIndicator
              color={QuoteStatusColors[status as QuoteStatus]}
              title={t(`quotes.status.${status}`)}
            />
          )}
          width="10%"
        />
        <DataTableColumn
          property="quoteNumber"
          title={t('quotes.fields.quoteNumber')}
          width="15%"
          output={(quoteNumber) => quoteNumber || DefaultValue.Dash}
        />
        <DataTableColumn
          property="projectName"
          title={t('quotes.fields.projectName')}
          output={renderProjectName}
          width="15%"
        />
        {isAdmin && (
          <DataTableColumn
            property="nciProjectId"
            title={t('quotes.fields.nciProjectId')}
            width="15%"
          />
        )}
        {(isAgent || isAdmin) && (
          <DataTableColumn
            property="companyName"
            title={t('quotes.fields.companyName')}
            width="15%"
          />
        )}
        {isAdmin && (
          <DataTableColumn
            property="agencyName"
            title={t('quotes.fields.agencyName')}
            width="15%"
          />
        )}
        {(isAdmin ||
          hasPermission(UserPermission.ViewAgencyQuotes) ||
          hasPermission(UserPermission.ViewCompanyQuotes)) && (
          <DataTableColumn
            property="customerName"
            title={
              isAdmin
                ? t('quotes.fields.customerName')
                : t('quotes.fields.requestor')
            }
            width="20%"
          />
        )}
        {isAdmin && (
          <DataTableColumn
            property="employeeName"
            title={t('quotes.fields.employeeName')}
            width="20%"
          />
        )}
        <DataTableColumn
          property="lastModified"
          title={t('quotes.fields.lastModified')}
          width="15%"
          output={(date) => (
            <FormattedTimestamp
              timestamp={date}
              timeZone={TimeZone.EasternTime}
            />
          )}
        />
        {(mode === ConsultationMode.Current || isAdmin) && (
          <DataTableColumn
            property="expirationTime"
            title={t('quotes.fields.expirationTime')}
            width="15%"
            output={(date) => (
              <FormattedTimestamp
                showTime={false}
                timestamp={date}
                timeZone={TimeZone.EasternTime}
              />
            )}
          />
        )}
        <DataTableColumn
          property="totalCost"
          title={
            isAdmin
              ? t('quotes.fields.totalSell')
              : t('quotes.fields.totalCost')
          }
          width="15%"
          output={(cost) => (cost ? currencyFormatter.format(cost) : '')}
        />
      </DataTable>
    </>
  );
};
