import { SxProps } from '@mui/system';

export const tableStyles: SxProps = {
  '&.MuiTableContainer-root': {
    overflowX: 'visible',
  },

  '& .MuiTable-root': {
    borderCollapse: 'collapse',
  },
};

export const tableHeaderStyles: SxProps = {
  '&.MuiTableHead-root': {
    borderBottom: 'inherit',
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium': {
        paddingLeft: '16px',
      },
    },
  },
};

export const tableBodyStyles: SxProps = {
  '&.MuiTableBody-root': {
    '& .MuiTableRow-root': {
      backgroundColor: 'inherit',
      py: { xs: '0.2em' },
      borderBottom: '1px solid #e0e0e0',
    },

    'tr > td': {
      border: 'none',
      '&:nth-of-type(n+4)': {
        py: {
          xs: '0.5em',
        },
      },

      '& .MuiInputBase-root': {
        height: {
          xs: '40px',
        },
      },
    },
  },
};
