import { SxProps } from '@mui/system';

export const textFieldNumericStyles: SxProps = {
  '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
    {
      '-webkit-appearance': 'none',
      margin: 0,
    },
};

export const toolTipError: SxProps = {
  mr: '-5px',
  ml: '5px',
};
