import { Grid, Typography } from '@mui/material';
import TwoFactorIcon from '../../assets/TwoFactorIcon';
import { TwoFactorLayoutProps } from './TwoFactorLayout.props';
import * as Sx from './TwoFactorLayout.styles';

export const TwoFactorLayout = ({ title, children }: TwoFactorLayoutProps) => (
  <Grid container direction="column" justifyContent="center" minHeight="100%">
    <Sx.TwoFactorIconContainer>
      <TwoFactorIcon />
    </Sx.TwoFactorIconContainer>
    <Sx.TwoFactorLayoutContainer>
      <Grid container spacing={2} flexDirection="column" alignItems="center">
        <Typography variant="h1" sx={{ mb: 4 }}>
          {title}
        </Typography>
        {children}
      </Grid>
    </Sx.TwoFactorLayoutContainer>
  </Grid>
);
