import { FilterOption } from '../enums';
import { InventoryFilters } from '../types';

export const InventorySearchReportTranslationMap = {
  [FilterOption.StandardType]: 'standardType',
  [FilterOption.ProductType]: 'productType',
  [FilterOption.ProductSubType]: 'productSubType',
  [FilterOption.MaterialType]: 'material',
  [FilterOption.Voltage]: 'voltage',
  [FilterOption.NumberOfConductors]: 'numberOfConductors',
  [FilterOption.Gauge]: 'gauge',
};

export const InventorySearchReportFilterOptionsMap: {
  [key in keyof Partial<InventoryFilters>]: FilterOption;
} = {
  partNumber: FilterOption.CableId,
  standardType: FilterOption.StandardType,
  productType: FilterOption.ProductType,
  productSubType: FilterOption.ProductSubType,
  material: FilterOption.MaterialType,
  voltage: FilterOption.Voltage,
  numberOfConductors: FilterOption.NumberOfConductors,
  gauge: FilterOption.Gauge,
};
