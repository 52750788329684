import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  ListItemText,
  MenuItem,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DataTable, DataTableColumn } from '../../tables';
import { FormattedTimestamp, MeasurementUnitsToggle } from '..';
import { Cable, Quote, QuoteProduct } from '../../models';
import { CustomLeadTimeRowCollapse } from '../CustomLeadTimeRowCollapse/CustomLeadTimeRowCollapse.component';
import { CustomLeadTimeEditMode } from '../CustomLeadTimeRowCollapse/CustomLeadTimeRowCollapse.props';
import {
  useCurrentUser,
  useFormFieldValue,
  useQuoteDetails,
  useQuoteFields,
  useQuoteMarkets,
} from '../../hooks';
import { MeasurementUnits, PurchaseStatus, QuoteStatus } from '../../enums';
import {
  PricedQuoteStatuses,
  QuoteProductPurchaseStatusMapping,
  UnquotedQuoteStatuses,
} from '../../mappings';
import { QuoteProductsTableCatalog } from '../QuoteProductsTable/QuoteProductsTableCatalog.component';
import * as Sx from '../QuoteProductsTable/QuoteProductsTable.styles';
import { SplitButton } from '../SplitButton/SplitButton.component';
import {
  QuoteProductPartNumber,
  QuoteProductQuantity,
} from '../QuoteProductsTable/Components';
import {
  CatalogFiltersType,
  CatalogSearchFilters,
} from '../CatalogSearchDialog/CatalogFiltersType';
import { Inventory } from '../../types/Inventory';
import {
  convertQuoteProductQuantities,
  getQuantityErrorMessage,
} from '../../utils/quote';
import { CurrencyFormatter, isUsCompany } from '../../utils';
import { getCompanyLocation } from '../../utils/company';
import { CUSTOM_PART_NUMBER } from '../QuoteProductsTable/constants';
import { AdminQuoteProductsTableProps } from './AdminQuoteProductsTable.props';
import { ReasonForLost } from './components/ReasonForLost.component';
import { ReasonForLostPopper } from './components/ReasonForLostPopper.component';
import { CustomDescription } from './components/CustomDescription.component';
import { ProductIdSet, ProductIdTuple } from './types';
import {
  addProductIdTuple,
  deleteProductIdTuple,
  hasProductIdTuple,
} from './utils';

export const AdminQuoteProductsTable = memo(
  ({
    company,
    form,
    isReadonly,
    units,
    setUnits,
  }: AdminQuoteProductsTableProps) => {
    const { t } = useTranslation();
    const user = useCurrentUser();
    const { canSeeCSA, canSeeUL, isValid } = useQuoteMarkets(form);
    const { isPartialOrComplete, quoteIsPriced } = useQuoteDetails(form);
    const [reasonForLostOpen, setReasonForLostOpen] = useState(false);
    const [lostProduct, setLostProduct] = useState<QuoteProduct | null>(null);

    const isMobile = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down('md')
    );

    const { value: customerId } = useFormFieldValue<Quote, string>(
      form,
      'customerId'
    );

    const { value: isCustomUser } = useFormFieldValue<Quote, boolean>(
      form,
      'isCustomUser'
    );

    const { value: products, setValue: setProducts } = useFormFieldValue<
      Quote,
      Array<QuoteProduct>
    >(form, 'products');

    const { value: status } = useFormFieldValue<Quote, QuoteStatus>(
      form,
      'status'
    );

    const [openedCustomLeadTimeCollapses, setOpenedCustomLeadTimeCollapses] =
      useState<ProductIdSet>(new Set());

    const [editableLeadTimes, setEditableLeadTimes] = useState<ProductIdSet>(
      new Set()
    );

    useEffect(() => {
      if (products) {
        setOpenedCustomLeadTimeCollapses(
          products
            .filter(
              (p) =>
                p.customLeadTime ||
                hasProductIdTuple(editableLeadTimes, [p.id, p.lineItem])
            )
            .reduce(
              (acc, currentProduct) =>
                addProductIdTuple(acc, [
                  currentProduct.id,
                  currentProduct.lineItem,
                ]),
              new Set<ProductIdTuple>()
            )
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editableLeadTimes, products]);

    const customizeLeadTime = (product: QuoteProduct) => {
      const tuple: ProductIdTuple = [product.id, product.lineItem];

      if (hasProductIdTuple(openedCustomLeadTimeCollapses, tuple)) {
        setEditableLeadTimes((prev) =>
          hasProductIdTuple(prev, tuple)
            ? deleteProductIdTuple(prev, tuple)
            : addProductIdTuple(prev, tuple)
        );
      } else {
        setEditableLeadTimes((prev) => addProductIdTuple(prev, tuple));
      }
    };

    const {
      getDescription,
      getDiscount,
      getExtendedPrice,
      getLeadTime,
      getMinimumOrderQuantity,
      getNetPrice,
      getPrice,
      getQuantity,
    } = useQuoteFields(form, company);

    const [catalogFilters, setCatalogFilters] = useState<CatalogSearchFilters>({
      filters: null,
      inventory: canSeeCSA ? 'csa' : 'ul',
    });

    const onCatalogFiltersChange = (
      filters: CatalogFiltersType,
      inventory: Inventory
    ) => {
      setCatalogFilters({ filters, inventory: inventory });
    };

    const verifyCatalogFilters = () => {
      if (!canSeeCSA && catalogFilters.inventory === 'csa') {
        setCatalogFilters({ filters: null, inventory: 'ul' });
      } else if (!canSeeUL && catalogFilters.inventory === 'ul') {
        setCatalogFilters({ filters: null, inventory: 'csa' });
      }
    };

    const renderMarkAllMenuItem = (
      status: PurchaseStatus,
      onClick: () => void
    ) => (
      <MenuItem
        key={status}
        disabled={products?.every((p) => p.purchaseStatus === status)}
        onClick={() => {
          onClick();
          setProducts(
            products.map((p) => {
              return { ...p, purchaseStatus: status, reasonForLost: null };
            })
          );
        }}
      >
        {t(`quotes.purchaseStatus.${status}`)}
      </MenuItem>
    );

    const renderLineItem = (product: QuoteProduct) => {
      if (quoteIsPriced && product.purchaseStatus !== undefined) {
        return (
          <Stack
            direction="row"
            justifyContent={
              product.purchaseStatus !== PurchaseStatus.Pending
                ? 'space-between'
                : isPartialOrComplete
                ? 'flex-end'
                : 'flex-start'
            }
            alignItems="center"
          >
            {QuoteProductPurchaseStatusMapping[product.purchaseStatus].icon}
            <Typography variant="body2">{product.lineItem}</Typography>
          </Stack>
        );
      }

      return <Typography variant="body2">{product.lineItem}</Typography>;
    };

    const removeProduct = (lineItem: number) =>
      setProducts(
        products
          .filter((p) => p.lineItem !== lineItem)
          .map((p) =>
            p.lineItem > lineItem ? { ...p, lineItem: p.lineItem - 1 } : p
          )
      );

    const handleNumericValueChange = (
      lineItem: number,
      value: number,
      field: keyof QuoteProduct
    ) => {
      const newProducts = products?.map((p) =>
        p.lineItem === lineItem ? { ...p, [field]: value } : p
      );
      setProducts(newProducts);
    };

    const handlePurchaseStatusChange = useCallback(
      (lineItem: number, status: PurchaseStatus) => {
        const newProducts = products?.map((p) =>
          p.lineItem === lineItem
            ? { ...p, purchaseStatus: status, reasonForLost: null }
            : p
        );

        setProducts(newProducts);
      },
      [products, setProducts]
    );

    const handleReasonForLostOpen = (product: QuoteProduct) => {
      setLostProduct(product);
      setReasonForLostOpen(true);
    };

    const handleReasonForLostClose = () => {
      setLostProduct(null);
      setReasonForLostOpen(false);
    };

    const handleOnLostReasonSave = (reason: string) => {
      const lostReason = reason.trim();
      if (lostProduct) {
        const newProducts = products?.map((p) =>
          p.id === lostProduct.id
            ? { ...p, reasonForLost: lostReason || null }
            : p
        );

        setProducts(newProducts);
        handleReasonForLostClose();
      }
    };

    const renderRowCollapse = (product: QuoteProduct) => (
      <CustomLeadTimeRowCollapse
        mode={
          hasProductIdTuple(editableLeadTimes, [product.id, product.lineItem])
            ? 'edit'
            : 'view'
        }
        product={product}
        products={products}
        setMode={(mode: CustomLeadTimeEditMode) => {
          setEditableLeadTimes((prev) => {
            if (mode === 'edit') {
              return addProductIdTuple(prev, [product.id, product.lineItem]);
            } else {
              return deleteProductIdTuple(prev, [product.id, product.lineItem]);
            }
          });
        }}
        setProducts={setProducts}
      />
    );

    const getMenuItems = (product: QuoteProduct) => {
      const customerLeadTime = (
        <MenuItem
          onClick={() => customizeLeadTime(product)}
          disabled={hasProductIdTuple(editableLeadTimes, [
            product.id,
            product.lineItem,
          ])}
          key="customizeLeadTimeAction"
        >
          <ListItemText>
            {t('quotes.edit.products.table.actions.customizeLeadTime')}
          </ListItemText>
        </MenuItem>
      );

      const removeItem = (
        <MenuItem
          key="remove-menu-item"
          onClick={() => removeProduct(product.lineItem)}
        >
          <ListItemText>
            {t('quotes.edit.products.table.actions.delete')}
          </ListItemText>
        </MenuItem>
      );

      if (!isReadonly) {
        return isMobile ? [removeItem] : [customerLeadTime, removeItem];
      }

      if (quoteIsPriced) {
        let unusedStatuses = [PurchaseStatus.Won, PurchaseStatus.Lost];
        if (product.purchaseStatus === PurchaseStatus.Lost) {
          unusedStatuses = [PurchaseStatus.Won, PurchaseStatus.Pending];
        } else if (product.purchaseStatus === PurchaseStatus.Won) {
          unusedStatuses = [PurchaseStatus.Lost, PurchaseStatus.Pending];
        }

        const purchaseStatusMenuItems = unusedStatuses.map((s) => [
          <MenuItem
            key={`${s}-menu-item`}
            onClick={() => handlePurchaseStatusChange(product.lineItem, s)}
          >
            <ListItemText>
              {t(
                `quotes.edit.products.table.actions.${QuoteProductPurchaseStatusMapping[s].i18nKey}`
              )}
            </ListItemText>
          </MenuItem>,
        ]);

        if (product.purchaseStatus === PurchaseStatus.Lost) {
          const reasonForLost = (
            <MenuItem
              key="reason-lost-menu-item"
              onClick={() => handleReasonForLostOpen(product)}
            >
              <ListItemText>
                {t('quotes.edit.products.table.actions.reasonForLost')}
              </ListItemText>
            </MenuItem>
          );
          return [reasonForLost, ...purchaseStatusMenuItems];
        }

        return [purchaseStatusMenuItems];
      }

      return undefined;
    };

    const onUnitsChangeHandler = (value: MeasurementUnits) => {
      setUnits((prev) => (value ? (prev = value) : prev));
      if (value && value !== units && !isReadonly) {
        setProducts(convertQuoteProductQuantities(products, value));
      }
    };

    useEffect(() => {
      if (
        !isReadonly &&
        form.item?.id !== 'new' &&
        units !== form.item?.measurementUnits
      ) {
        setProducts(convertQuoteProductQuantities(products, units));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReadonly]);

    const displayCustomFirstRow = useMemo(() => {
      if (isCustomUser) return true;

      return (
        !!company &&
        !!customerId &&
        (!status || UnquotedQuoteStatuses.includes(status))
      );
    }, [company, customerId, status, isCustomUser]);

    const getCustomFirstRow = () =>
      displayCustomFirstRow ? (
        <QuoteProductsTableCatalog
          setUnits={onUnitsChangeHandler}
          units={units}
          form={form}
          catalogFilters={catalogFilters}
          onCatalogFiltersChange={onCatalogFiltersChange}
          verifyCatalogFilters={verifyCatalogFilters}
        />
      ) : undefined;

    const currencyFormatter = useMemo(
      () =>
        new CurrencyFormatter(getCompanyLocation(company), Number.MAX_VALUE),
      [company]
    );

    return (
      <>
        <Grid container direction="column" justifyContent="flex-start">
          <Grid
            item
            container
            my={1}
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography variant="h3">
              {t('quotes.edit.products.header', {
                count: form.item?.products?.length ?? 0,
              })}
            </Typography>
            <Stack direction="row" spacing={3}>
              {status && PricedQuoteStatuses.includes(status) && (
                <SplitButton
                  label={t('quotes.edit.products.table.actions.markAll')}
                  options={(onClick) => [
                    renderMarkAllMenuItem(PurchaseStatus.Won, onClick),
                    renderMarkAllMenuItem(PurchaseStatus.Lost, onClick),
                    renderMarkAllMenuItem(PurchaseStatus.Pending, onClick),
                  ]}
                />
              )}
              <MeasurementUnitsToggle
                units={units}
                setUnits={onUnitsChangeHandler}
              />
            </Stack>
          </Grid>
          <DataTable
            menuItems={getMenuItems}
            data={products || []}
            noResultElement={
              <Typography variant="h4" sx={{ my: 4 }} align="center">
                {t('quotes.edit.products.noResults')}
              </Typography>
            }
            tableStyles={Sx.tableStyles}
            tableBodyStyles={Sx.tableBodyStyles(displayCustomFirstRow)}
            tableHeaderStyles={Sx.tableHeaderStyles}
            rowCollapseOpened={(row: QuoteProduct) =>
              hasProductIdTuple(openedCustomLeadTimeCollapses, [
                row.id,
                row.lineItem,
              ]) && !isMobile
            }
            rowCollapseContent={renderRowCollapse}
            specialFirstBodyRow={getCustomFirstRow()}
            isRowOnError={(row) => !isValid(row)}
            customRowKey={(row: QuoteProduct) => row.id + row.lineItem}
          >
            <DataTableColumn
              property="lineItemNumber"
              width="5%"
              title={t('quotes.edit.products.table.labels.lineItem')}
              output={(_, row: QuoteProduct) => renderLineItem(row)}
            />
            <DataTableColumn
              property="partNumber"
              title={t('quotes.edit.products.table.labels.partNumber')}
              width="10%"
              output={(_: string, row: QuoteProduct) => (
                <QuoteProductPartNumber
                  row={row}
                  isValid={isValid}
                  status={status}
                />
              )}
            />
            <DataTableColumn
              property="description"
              width="35%"
              title={t('quotes.edit.products.table.labels.productDescription')}
              output={(_, row: QuoteProduct) => {
                if (row.partNumber !== CUSTOM_PART_NUMBER || isReadonly) {
                  return getDescription(row);
                } else {
                  const rowId = products?.findIndex(
                    (p) => p.id === row.id && p.lineItem === row.lineItem
                  );
                  return <CustomDescription form={form} index={rowId} />;
                }
              }}
            />
            <DataTableColumn
              property="quantity"
              title={t('quotes.edit.products.table.labels.quantity')}
              width="10%"
              output={(_, product: QuoteProduct) =>
                isReadonly ? (
                  getQuantity(product, units)
                ) : (
                  <QuoteProductQuantity
                    showError
                    quantity={product.quantity}
                    onChange={(val) => {
                      handleNumericValueChange(
                        product.lineItem,
                        val,
                        'quantity'
                      );
                    }}
                    inputAdornment={t(`units.${units}.length`)}
                    minValue={product.minimumOrderQuantity || 1}
                    width="140px"
                    numberValidation={(val) =>
                      getQuantityErrorMessage(val, product as Cable, units)
                    }
                  />
                )
              }
            />
            <DataTableColumn
              property="minimumOrderQuantity"
              title={t('quotes.edit.products.table.labels.minOrderQuantity')}
              width="5%"
              output={(_, product: QuoteProduct) =>
                isReadonly ? (
                  getMinimumOrderQuantity(product, quoteIsPriced, units)
                ) : (
                  <QuoteProductQuantity
                    quantity={product.minimumOrderQuantity}
                    onChange={(val) => {
                      handleNumericValueChange(
                        product.lineItem,
                        val,
                        'minimumOrderQuantity'
                      );
                    }}
                    inputAdornment={t(`units.${units}.length`)}
                    minValue={0}
                    width="100px"
                  />
                )
              }
            />
            <DataTableColumn
              property="price"
              title={t('quotes.edit.products.table.labels.price')}
              width="12%"
              output={(_, product: QuoteProduct) =>
                isReadonly ? (
                  getPrice(product, quoteIsPriced)
                ) : (
                  <QuoteProductQuantity
                    currencyFormatter={currencyFormatter}
                    showError
                    quantity={product.price}
                    onChange={(val) => {
                      handleNumericValueChange(product.lineItem, val, 'price');
                    }}
                    inputAdornment={
                      company && isUsCompany(company) ? '/1000ft' : '/km'
                    }
                    width={company && isUsCompany(company) ? '175px' : '135px'}
                  />
                )
              }
            />
            <DataTableColumn
              property="discount"
              title={t('quotes.edit.products.table.labels.discount')}
              width="5%"
              output={(_, product: QuoteProduct) =>
                isReadonly ? (
                  getDiscount(product, quoteIsPriced)
                ) : (
                  <QuoteProductQuantity
                    quantity={product.discount}
                    onChange={(val) => {
                      handleNumericValueChange(
                        product.lineItem,
                        val,
                        'discount'
                      );
                    }}
                    inputAdornment="%"
                    minValue={0}
                    maxValue={100}
                    width="90px"
                  />
                )
              }
            />
            <DataTableColumn
              property="netPrice"
              title={t('quotes.edit.products.table.labels.netPrice')}
              width="5%"
              output={(_, row: QuoteProduct) => getNetPrice(row)}
            />
            <DataTableColumn
              property="extendedPrice"
              title={t('quotes.edit.products.table.labels.extendedPrice')}
              width="5%"
              output={(_, row: QuoteProduct) =>
                getExtendedPrice(
                  row,
                  isReadonly && form.item?.measurementUnits
                    ? form.item?.measurementUnits
                    : units
                )
              }
            />
            <DataTableColumn
              property="standardLeadTime"
              title={t('quotes.edit.products.table.labels.leadTime')}
              otherStyling={{
                opacity: 0.6,
              }}
              width="10%"
              output={(_, row: QuoteProduct) => getLeadTime(row, status)}
            />
            <DataTableColumn
              property="customLeadTime"
              hidden
              mobileHidden={false}
            />
            <DataTableColumn
              property="reasonForLost"
              output={(_, row: QuoteProduct) => (
                <ReasonForLostPopper
                  reason={row.reasonForLost}
                  onOpen={() => setLostProduct(row)}
                  onClose={() => setLostProduct(null)}
                  onEditClick={() => setReasonForLostOpen(true)}
                />
              )}
            />
          </DataTable>
          {form.item?.quotedTime && (
            <Typography
              mt={{ xs: 1, md: 2 }}
              mr={{ md: 5 }}
              mb={1}
              textAlign="right"
            >
              {t('quotes.edit.summary.quoteDetails.leadTimesAsOf')}
              <FormattedTimestamp
                timestamp={form.item?.quotedTime}
                timeZone={user.timeZone}
              />
            </Typography>
          )}
        </Grid>
        <ReasonForLost
          isOpen={reasonForLostOpen}
          onClose={handleReasonForLostClose}
          onSave={handleOnLostReasonSave}
          lostProduct={lostProduct}
        />
      </>
    );
  }
);
