import { useTranslation } from 'react-i18next';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { useNavigate, useParams } from 'react-router-dom';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';

import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import {
  AdminQuoteProductsTable,
  AdminQuoteSummaryDetails,
  QuestionDrawer,
  QuoteHeader,
  QuoteNotesDrawer,
  QuoteSubmit,
} from '../../components';
import {
  useCurrentUser,
  useDownloadFiles,
  useForm,
  useNotification,
  usePageTitle,
  useProgress,
  useQuote,
  useQuoteDetails,
  useQuoteMarkets,
} from '../../hooks';
import firebaseHooks from '../../firebase/hooks';
import {
  MeasurementUnits,
  ModificationMode,
  NotificationLevel,
  PurchaseStatus,
  QuoteStatus,
  QuoteTypeOfBuy,
} from '../../enums';
import { getPageTitle } from '../../utils/pages';
import {
  Company,
  FirebaseFile,
  QuoteInternalNote,
  Quote as QuoteModel,
} from '../../models';
import getQuoteSchema from '../../validation/QuoteValidations';
import { PricedQuoteStatuses } from '../../mappings';
import { MAX_DATE } from '../../hooks/useQuotesFilters';
import { getLanguage } from '../../utils/locale';
import { QuoteLayout } from '../../layouts';

const Quote = () => {
  const firestore = firebaseHooks.useFirestore();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { quoteId } = useParams();
  const { addNotification } = useNotification();
  const currentUser = useCurrentUser();
  const [company, setCompany] = useState<Company | null>(null);
  const [showSendQuoteConfirm, setShowSendQuoteConfirm] = useState(false);
  const [showNotesDrawer, setShowNotesDrawer] = useState(false);
  const [draftInternalNotes, setDraftInternalNotes] = useState<
    QuoteInternalNote[]
  >([]);
  const [isSaving, setIsSaving] = useState(false);
  const functions = useFunctions();
  const generatePricedQuoteFunction = httpsCallable<QuoteModel>(
    functions,
    'generatePricedQuote'
  );
  const { showProgress } = useProgress();

  const quote = useQuote(quoteId || '');

  const { downloadFromFireStorage } = useDownloadFiles();

  const isNewQuote = quoteId === 'new';

  usePageTitle(
    quoteId === 'new' ? getPageTitle('quote.new') : getPageTitle('quote.edit')
  );

  const form = useForm(quote, {
    mode: isNewQuote ? ModificationMode.Add : ModificationMode.Edit,
    defaultValue: {
      id: 'new',
      agencyId: null,
      agencyName: null,
      companyId: null,
      companyName: null,
      companyAccountNumber: null,
      createdById: currentUser.id,
      createdByName: currentUser.name,
      createdByNCEmployee: currentUser.isNCEmployee,
      customerEmail: '',
      customerId: '',
      customerName: '',
      employeeId: currentUser.id,
      employeeName: currentUser.name,
      expirationTime: null,
      expirationTimeOption: null,
      expirationTimeNormalized: Timestamp.fromMillis(
        MAX_DATE.startOf('day').toMillis()
      ),
      totalCost: null,
      quoteNumber: null,
      reportFile: null,
    } as QuoteModel,
    beforeSave: (dirtyQuote) => {
      const now = Timestamp.now();

      if (isNewQuote) {
        dirtyQuote.dateCreated = now;
      }

      if (quote.item && quote.item?.customerNote) {
        dirtyQuote.customerNote = quote.item.customerNote;
      }

      if (
        dirtyQuote.products?.length &&
        PricedQuoteStatuses.includes(dirtyQuote.status)
      ) {
        const allWon = dirtyQuote.products.every(
          (p) => p.purchaseStatus === PurchaseStatus.Won
        );
        const allLost = dirtyQuote.products.every(
          (p) => p.purchaseStatus === PurchaseStatus.Lost
        );
        const someWon = dirtyQuote.products.some(
          (p) => p.purchaseStatus === PurchaseStatus.Won
        );

        const somePending = dirtyQuote.products.some(
          (p) =>
            !p.purchaseStatus || p.purchaseStatus === PurchaseStatus.Pending
        );

        if (allWon) {
          dirtyQuote.status = QuoteStatus.Won;
        } else if (someWon) {
          dirtyQuote.status = somePending
            ? QuoteStatus.PartiallyWonPending
            : QuoteStatus.PartiallyWon;
        } else if (allLost) {
          dirtyQuote.status = QuoteStatus.Lost;
        } else if (
          dirtyQuote.expirationTime?.toDate() &&
          dirtyQuote.expirationTime?.toDate() < now.toDate()
        ) {
          dirtyQuote.status = QuoteStatus.Expired;
        } else {
          dirtyQuote.status = QuoteStatus.Quoted;
        }
      }

      if (
        !PricedQuoteStatuses.includes(dirtyQuote.status) &&
        dirtyQuote.status !== QuoteStatus.Cancelled
      ) {
        dirtyQuote.status = QuoteStatus.InProgress;
      }

      if (
        !dirtyQuote.typeOfBuy ||
        dirtyQuote.typeOfBuy === QuoteTypeOfBuy.InventoryBuy
      ) {
        dirtyQuote.projectName = null;
        dirtyQuote.contractorName = null;
        dirtyQuote.shippingYearMonth = null;
        dirtyQuote.nciProjectId = null;
      }
      dirtyQuote.measurementUnits = units;
      dirtyQuote.lastModified = now;

      if (dirtyQuote.employeeId !== currentUser.id) {
        dirtyQuote.employeeId = currentUser.id;
        dirtyQuote.employeeName = currentUser.name ?? null;
      }
    },
    onSaveSuccess: async (savedQuote) => {
      addNotification(
        t('quotes.edit.drawer.save.success'),
        NotificationLevel.Success
      );

      if (isNewQuote && savedQuote.status === QuoteStatus.InProgress) {
        // Save internal notes added before the quote is first saved
        for (const note of draftInternalNotes) {
          const savedNote = await addDoc(
            collection(firestore, `Quotes/${savedQuote.id}/InternalNotes`),
            note
          );
          await updateDoc(savedNote, { id: savedNote.id });
        }
        navigate(`/admin/quotes/${savedQuote.id}`, {
          replace: true,
        });
      } else if (
        savedQuote.status === QuoteStatus.Quoted &&
        showSendQuoteConfirm
      ) {
        setShowSendQuoteConfirm(false);
        navigateBackToQuotes();
      }
    },
  });

  const { markets } = useQuoteMarkets(form);
  const { isCurrentTab, isReadonly } = useQuoteDetails(form);

  const [units, setUnits] = useState<MeasurementUnits>(
    currentUser.measurementUnits
  );

  const { pricingSchema } = getQuoteSchema(markets, units);

  useEffect(() => {
    if (form.originalItem?.measurementUnits) {
      setUnits(form.originalItem.measurementUnits);
    }
  }, [form.originalItem]);

  const saveAsInProgress = () => {
    form.save(true);
  };

  useEffect(() => {
    (async () => {
      if (!form.item?.companyId) {
        setCompany(null);
      } else if (company?.id !== form.item?.companyId) {
        const companyRef = doc(firestore, 'Companies', form.item?.companyId);
        const company = (await getDoc(companyRef)).data();
        setCompany(company as Company);
      }
    })();
  }, [company?.id, firestore, form.item?.companyId]);

  const sendPricedQuote = async () => {
    const errors = await form.validate(
      {
        ...form.item,
        status: QuoteStatus.Quoted,
      } as QuoteModel,
      false,
      true,
      pricingSchema
    );

    const errorsObj = Object.entries(errors);
    if (errorsObj.length) {
      if (errorsObj[0][1] === t('common.validations.fieldRequired')) {
        addNotification(
          t('quotes.validation.requiredFields'),
          NotificationLevel.Error
        );
      } else {
        addNotification(t(errorsObj[0][1]), NotificationLevel.Error);
      }
    } else {
      setShowSendQuoteConfirm(true);
    }
  };

  const confirmSendPricedQuote = async () => {
    if (form.item && !isSaving) {
      showProgress(true);
      setIsSaving(true);
      addNotification(t('forms.saving'), NotificationLevel.Information);
      form.setWithPrompt(false);

      generatePricedQuoteFunction({
        ...form.item,
        measurementUnits: units,
        id: isNewQuote ? '' : form.item.id,
      } as QuoteModel)
        .then(() => {
          addNotification(
            t('quotes.edit.drawer.save.successPricedSent'),
            NotificationLevel.Success
          );
          navigateBackToQuotes();
        })
        .catch(() => {
          form.setWithPrompt(true);
          addNotification(
            t('quotes.edit.drawer.save.error'),
            NotificationLevel.Error
          );
        })
        .finally(() => {
          setIsSaving(false);
          setShowSendQuoteConfirm(false);
          showProgress(false);
        });
    }
  };

  const cancelSendPricedQuote = () => {
    setShowSendQuoteConfirm(false);
  };

  const navigateBackToQuotes = () => {
    const isCurrentQuote = !quote.item || isNewQuote || isCurrentTab;

    navigate('/admin/quotes' + (!isCurrentQuote ? '/history' : ''));
  };

  const downloadQuote = async () => {
    const file = quote.item?.reportFile?.[getLanguage(i18n)] as FirebaseFile;
    downloadFromFireStorage(
      `${file.storageBucket}/${file.storageId}`,
      file.fileName
    );
  };

  const getHeaderElement = () => (
    <QuoteHeader
      form={form}
      isNewQuote={isNewQuote}
      onSave={saveAsInProgress}
      navigateBack={navigateBackToQuotes}
      saveButtonText={t('quotes.edit.saveQuote')}
      showSaveButton={form.dirty}
      units={units}
      setUnits={setUnits}
    />
  );
  const getBodyElement = () => (
    <>
      <AdminQuoteSummaryDetails
        isReadonly={isReadonly}
        form={form}
        draftInternalNotes={draftInternalNotes}
        setShowNotesDrawer={setShowNotesDrawer}
      />
      <AdminQuoteProductsTable
        company={company}
        form={form}
        units={units}
        setUnits={setUnits}
        isReadonly={isReadonly}
      />
    </>
  );
  const getFooterElement = () => (
    <QuoteSubmit
      company={company}
      form={form}
      units={units}
      submitQuote={sendPricedQuote}
      downloadQuote={downloadQuote}
    />
  );

  return (
    <>
      <QuoteLayout
        headerElement={getHeaderElement()}
        bodyElement={getBodyElement()}
        footerElement={getFooterElement()}
      />
      {quoteId && (
        <QuoteNotesDrawer
          draftInternalNotes={draftInternalNotes}
          setDraftInternalNotes={setDraftInternalNotes}
          quoteId={quoteId}
          open={showNotesDrawer}
          setOpen={setShowNotesDrawer}
        />
      )}
      <QuestionDrawer
        disabled={isSaving}
        open={showSendQuoteConfirm}
        icon={<RequestQuoteIcon sx={{ color: 'secondary.main' }} />}
        message={t('quotes.edit.summary.sendPricedQuoteDescription')}
        onConfirm={confirmSendPricedQuote}
        onCancel={cancelSendPricedQuote}
        title={t('quotes.edit.summary.sendPricedQuoteButtonLabel')}
      />
    </>
  );
};

export default Quote;
