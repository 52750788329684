import { useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Timestamp } from 'firebase/firestore';
import { sumBy } from 'lodash';
import { Show } from '../Show/Show.component';
import {
  useAuthentication,
  useCurrentUser,
  usePricedQuotes,
} from '../../hooks';
import { Filter } from '../../types';
import {
  quotesConversionRateCsv,
  quotesConversionRatePdf,
} from '../../reports';
import { ExportMenu } from '../ExportMenu/ExportMenu.component';
import { SanitizedQuoteConversionRate } from '../../models';
import { QuoteConversionRateTableProps } from './QuoteConversionRateTable.props';
import { QuoteCompanyConversionRateTable } from './QuoteCompanyConversionRateTable.component';
import { QuoteProductConversionRateTable } from './QuoteProductConversionRateTable.component';
import * as Sx from './QuoteConversionRateTable.styles';

export const QuoteConversionRateTable = ({
  filters,
  expandedCompanies,
  setExpandedCompanies,
  userProductTypes,
}: QuoteConversionRateTableProps) => {
  const { t, i18n } = useTranslation();
  const [conversionData, setConversionData] = useState<
    SanitizedQuoteConversionRate[]
  >([]);

  const { isAgent } = useAuthentication();
  const currentUser = useCurrentUser();

  const queryFilters = useMemo(() => {
    if (!filters?.startDate || !filters?.endDate) {
      return undefined;
    }

    const result: Filter[] = [];
    result.push([
      'quotedTime',
      '>=',
      Timestamp.fromMillis(filters.startDate.startOf('day').toMillis()),
    ]);

    result.push([
      'quotedTime',
      '<=',
      Timestamp.fromMillis(filters.endDate.endOf('day').toMillis()),
    ]);

    if (isAgent) {
      result.push(['agencyId', '==', currentUser.companyRef.id]);
    }

    return result;
  }, [
    filters?.startDate,
    filters?.endDate,
    isAgent,
    currentUser.companyRef.id,
  ]);

  const { list: quotes } = usePricedQuotes({ filters: queryFilters });

  const formattedConversion = useMemo(() => {
    if (conversionData?.length) {
      const won = sumBy(conversionData, 'totalWon');
      const quoted = sumBy(conversionData, 'totalQuoted');
      return Math.round((won / quoted) * 100);
    }
    return 0;
  }, [conversionData]);

  const exportCSV = async () => {
    if (!filters) {
      return;
    }
    quotesConversionRateCsv.generate(conversionData, filters, t);
  };

  const exportPDF = async () => {
    if (!filters) {
      return;
    }
    quotesConversionRatePdf.generate(conversionData, quotes, filters, t, i18n);
  };

  const renderTotalConversion = () => (
    <Show if={!!conversionData?.length}>
      <Grid container justifyContent="space-between">
        <Grid item sx={Sx.totalConversionRateBox}>
          <Typography fontSize="1em" fontWeight="500">
            {t('reports.quoteConversionRate.totalConversion')}
          </Typography>
          <Typography fontSize="3em" fontWeight="500">
            {formattedConversion}%
          </Typography>
        </Grid>
        <Grid item display="flex" alignItems="end">
          <ExportMenu
            onCSVClicked={() => exportCSV()}
            onPDFClicked={() => exportPDF()}
          />
        </Grid>
      </Grid>
    </Show>
  );

  const productTypesTableFilter = useMemo(
    () =>
      filters?.productTypes?.length ? filters.productTypes : userProductTypes,
    [filters?.productTypes, userProductTypes]
  );

  if (!filters) {
    return null;
  }

  return (
    <>
      {renderTotalConversion()}
      {filters.searchByCompany ? (
        <QuoteCompanyConversionRateTable
          companyIds={filters.companyIds}
          productTypes={productTypesTableFilter}
          expandedCompanies={expandedCompanies}
          setExpandedCompanies={setExpandedCompanies}
          setConversionData={setConversionData}
          quotes={quotes}
        />
      ) : (
        <QuoteProductConversionRateTable
          companyIds={filters.companyIds}
          productTypes={productTypesTableFilter}
          setConversionData={setConversionData}
          quotes={quotes}
        />
      )}
    </>
  );
};
