import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Grid } from '@mui/material';
import { SanitizedQuoteConversionRate } from '../../models';
import {
  usePagination,
  useQuoteCompanyConversionRates,
  useSorting,
} from '../../hooks';
import { DataTable, DataTableColumn } from '../../tables';
import { QuoteCompanyConversionRateTableProps } from './QuoteCompanyConversionRateTable.props';
import * as Sx from './QuoteConversionRateTable.styles';
import { QuoteUserConversionRateTable } from './QuoteUserConversionRateTable.component';

export const QuoteCompanyConversionRateTable = ({
  expandedCompanies,
  setExpandedCompanies,
  setConversionData,
  quotes,
  productTypes,
  companyIds,
}: QuoteCompanyConversionRateTableProps) => {
  const [openedCollapses, setOpenedCollapses] = useState<string[]>(
    expandedCompanies ?? []
  );
  const { t } = useTranslation();
  const pagination = usePagination();
  const sorting = useSorting('name', { sortDirection: 'asc' });

  const {
    list: quoteConversionRate,
    unpaginatedResults,
    loading,
  } = useQuoteCompanyConversionRates(quotes, companyIds, productTypes, {
    pagination,
    sorting,
  });

  useEffect(() => {
    setConversionData && setConversionData(unpaginatedResults);
  }, [setConversionData, unpaginatedResults]);

  useEffect(() => {
    setOpenedCollapses(expandedCompanies ?? []);
  }, [expandedCompanies]);

  return (
    <DataTable
      loading={loading}
      data={quoteConversionRate}
      pagination={pagination}
      sorting={sorting}
      rowCollapseContent={(item: SanitizedQuoteConversionRate) => (
        <Grid sx={Sx.breakdownCollapseContainer}>
          <QuoteUserConversionRateTable
            companyId={item.id}
            quotes={quotes}
            productTypes={productTypes}
            parent={item}
          />
        </Grid>
      )}
      rowCollapseOpened={(item: SanitizedQuoteConversionRate) =>
        !!openedCollapses && openedCollapses.indexOf(item.id) > -1
      }
      rowOnClick={(item: SanitizedQuoteConversionRate) => {
        if (!!openedCollapses && openedCollapses.indexOf(item.id) === -1) {
          setExpandedCompanies([...openedCollapses, item.id]);
        } else {
          setExpandedCompanies(
            openedCollapses?.filter((i) => i !== item.id) ?? [item.id]
          );
        }
      }}
    >
      <DataTableColumn
        property="name"
        title={t('reports.quoteConversionRate.company')}
        width="20%"
      />
      <DataTableColumn
        property="quoted"
        title={t('reports.quoteConversionRate.quoted')}
        width="8%"
      />
      <DataTableColumn
        property="expired"
        title={t('reports.quoteConversionRate.expired')}
        width="8%"
      />
      <DataTableColumn
        property="won"
        title={t('reports.quoteConversionRate.won')}
        width="8%"
      />
      <DataTableColumn
        property="lost"
        title={t('reports.quoteConversionRate.lost')}
        width="8%"
      />
      <DataTableColumn
        property="rate"
        title={t('reports.quoteConversionRate.rate')}
        output={(value) => `${value}%`}
        width="10%"
      />
      <DataTableColumn
        property="totalQuotedFormatted"
        title={t('reports.quoteConversionRate.totalQuoted')}
        width="10%"
      />
      <DataTableColumn
        property="totalWonFormatted"
        title={t('reports.quoteConversionRate.totalWon')}
        width="10%"
      />
      <DataTableColumn
        property="totalWonRate"
        title={t('reports.quoteConversionRate.totalWonRate')}
        width="10%"
        output={(value) => `${value}%`}
      />
      <DataTableColumn
        property="expand"
        output={(_, item) => (
          <KeyboardArrowDownIcon
            sx={{
              transform: `rotate(${
                !!openedCollapses && openedCollapses.indexOf(item.id) > -1
                  ? '-180deg'
                  : '0'
              })`,
              transition: 'transform 0.1s',
            }}
          />
        )}
        width="5%"
      />
    </DataTable>
  );
};
