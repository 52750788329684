import { useMemo } from 'react';
import firebaseHooks from '../firebase/hooks';
import authFunctions from '../firebase/auth';

export const useHasSecondAuthFactors = () => {
  const multiFactor = authFunctions.multiFactor;
  const { data } = firebaseHooks.useSigninCheck();
  const user = useMemo(() => data?.user, [data]);

  const multiFactorUser = useMemo(
    () => (user ? multiFactor(user) : null),
    [user, multiFactor]
  );

  const hasEnrolledFactors = useMemo(() => {
    if (!user) {
      return false;
    }
    return (multiFactorUser?.enrolledFactors?.length ?? 0) > 0;
  }, [user, multiFactorUser?.enrolledFactors?.length]);

  return hasEnrolledFactors;
};
