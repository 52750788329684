import { DateTime } from 'luxon';
import { i18n, TFunction } from 'i18next';

import quote_icon from '../../assets/QuoteIcon.png';
import { Quote } from '../../models';
import { ConsultationMode } from '../../enums';
import { getSanitizedExportQuote } from '../../utils/quote';
import { CurrencyFormatter } from '../../utils/currency';
import report, { MARGIN, PDF_CONFIG } from './report';

const PREFIX = 'NC_Quotes';
const NAMESPACE = 'quotes.fields';

class quotesPdf extends report {
  private readonly quotes: Quote[];
  private mode: ConsultationMode;
  private readonly DEFAULT_ROW_HEIGHT = 13;
  private readonly currencyFormatter = new CurrencyFormatter(
    'CA',
    Number.MAX_VALUE
  );

  constructor(
    quotes: Quote[],
    mode: ConsultationMode,
    t: TFunction,
    i18n: i18n
  ) {
    super(NAMESPACE, t, i18n);

    this.mode = mode;
    this.quotes = quotes;
  }

  protected getFilename(): string {
    const now = DateTime.now().toFormat('LLddyyyyHHmmss');
    return `${PREFIX}_${now}.pdf`;
  }

  protected buildHeader(): void {
    this.outputDocumentDate();

    this.curHeight += PDF_CONFIG.LINE_SIZE + 5;

    this.outputImage(quote_icon, MARGIN.X, this.curHeight, 10, 10);

    const prefixTitle =
      this.mode === 'current'
        ? this.t('quotes.current')
        : this.t('quotes.history');

    const formattedTitle = `${prefixTitle} ${this.t('pages.quotes.pageTitle')}`;
    this.outputH1(formattedTitle, this.curWidth + 15, this.curHeight + 7);

    this.curHeight += PDF_CONFIG.LINE_SIZE / 2 + 4;
  }

  protected buildBody(): void {
    this.drawLine(0.5);

    this.curHeight += 5;

    this.quotes.forEach((quote, index) => this.buildRow(quote, index));
  }

  private buildRow(rawQuote: Quote, index: number): void {
    const quote = getSanitizedExportQuote(
      rawQuote,
      this.t,
      this.currencyFormatter
    );

    this.addDataColumn('quoteNumber', quote.quoteNumber, MARGIN.X, 20);
    this.addDataColumn('projectName', quote.projectName, MARGIN.X + 25, 40);
    this.addDataColumn('nciProjectId', quote.nciProjectId, MARGIN.X + 70, 40);
    this.addDataColumn('customerName', quote.customerName, MARGIN.X + 115, 40);

    this.curHeight += 10;

    this.addDataColumn('status', quote.status, MARGIN.X, 20);
    this.addDataColumn('companyName', quote.companyName, MARGIN.X + 25, 40);
    this.addDataColumn('agencyName', quote.agencyName, MARGIN.X + 70, 40);
    this.addDataColumn('employeeName', quote.employeeName, MARGIN.X + 115, 40);

    this.curHeight += 10;

    this.addDataColumn('totalSell', quote.totalSell.toString(), MARGIN.X, 20);
    this.addDataColumn('lastModified', quote.lastModified, MARGIN.X + 25, 40);
    this.addDataColumn('typeOfBuy', quote.typeOfBuy, MARGIN.X + 70, 40);
    this.addDataColumn(
      'expirationTime',
      quote.expirationTime,
      MARGIN.X + 115,
      40
    );

    this.curHeight += 8;

    this.drawLine(0.1);

    this.curHeight += 5;

    this.nextRow(this.DEFAULT_ROW_HEIGHT, index, this.quotes.length);
  }

  static generate = (
    quotes: Quote[],
    mode: ConsultationMode,
    t: TFunction,
    i18n: i18n
  ) => {
    const report = new quotesPdf(quotes, mode, t, i18n);
    report.generate();
  };
}

export default quotesPdf;
