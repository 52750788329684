import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { RequirePermission } from '../../security';
import {
  CompanyType,
  ConsultationMode,
  Region,
  UserPermission,
} from '../../enums';
import {
  useCompanies,
  useLocalStorage,
  usePageTitle,
  useRouterOutlet,
} from '../../hooks';
import { getPageTitle } from '../../utils/pages';
import {
  Container,
  ContrastButtonMain,
  CurrentQuotesTable,
  DecoratedHeader,
  QuotesFilters,
  QuotesHistoryTable,
} from '../../components';
import { Tab } from '../../navigation';
import {
  Filter,
  Nullable,
  QuotesFilters as QuotesFiltersType,
} from '../../types';

interface Props {
  mode?: ConsultationMode;
}

const defaultQuoteFilters: QuotesFiltersType = {
  companyId: null,
  employeeId: null,
  endDate: null,
  expirationTime: null,
  requestorId: null,
  searchField: null,
  startDate: null,
  status: null,
  region: null,
  typeOfBuy: null,
};

const Quotes: React.FC<Props> = ({
  mode = ConsultationMode.Current,
}: Props) => {
  const {
    item: region,
    setItem,
    isLoaded: isRegionLoaded,
  } = useLocalStorage<Region>('quoteFilters', 'region');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const showCurrent = mode === ConsultationMode.Current;
  const { showOutletOnly } = useRouterOutlet();
  const [filters, setFilters] = useState<Nullable<QuotesFiltersType>>(null);

  const { list: companies } = useCompanies({
    filters: [['type', '==', CompanyType.Customer] as Filter],
  });

  const getPageTitleKey = (): string =>
    showCurrent ? 'quotes' : 'quotes.history';

  usePageTitle(getPageTitle(getPageTitleKey()));

  const currentQuotesPath = '/admin/quotes';
  const historyQuotesPath = '/admin/quotes/history';

  useEffect(() => {
    if (isRegionLoaded) {
      setFilters({
        ...defaultQuoteFilters,
        startDate: showCurrent
          ? null
          : DateTime.now()
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .minus({ month: 1 }),
        endDate: showCurrent ? null : DateTime.now(),
        region: region || defaultQuoteFilters.region,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRegionLoaded, showCurrent]);

  useEffect(() => {
    if (filters && region !== filters.region) {
      setItem(filters.region || null);
    }
  }, [setItem, region, filters]);

  return (
    <RequirePermission oneOf={[UserPermission.ManageQuotes]}>
      {showOutletOnly || (
        <>
          <Container maxWidth="xxl">
            <DecoratedHeader
              icon={<RequestQuoteIcon />}
              title={t('navigation.quotes')}
              to={showCurrent ? currentQuotesPath : historyQuotesPath}
              variant="colored"
            >
              <Tab title={t('quotes.current')} to={currentQuotesPath} />
              <Tab title={t('quotes.history')} to={historyQuotesPath} />
            </DecoratedHeader>
          </Container>
          <Container
            backgroundColor="secondary.light"
            maxWidth="xxl"
            roundedTopCorners={true}
            sx={{ pt: { xs: showCurrent ? 2 : 0, md: 0 } }}
          >
            <Stack
              direction="row"
              sx={{ justifyContent: { xs: 'flex-end', md: 'space-between' } }}
            >
              <Typography
                variant="h2"
                sx={{ mt: 4, mb: 3, display: { xs: 'none', md: 'block' } }}
              >
                {showCurrent ? t('quotes.current') : t('quotes.history')}
              </Typography>
              {showCurrent && (
                <ContrastButtonMain
                  onClick={() => navigate(currentQuotesPath + '/new')}
                  variant="contained"
                  sx={{ mt: 4, mb: 3 }}
                >
                  {t('quotes.build')}
                </ContrastButtonMain>
              )}
            </Stack>
          </Container>
          {!!filters && (
            <>
              <QuotesFilters
                maxWidth="xxl"
                filters={filters}
                setFilters={
                  setFilters as React.Dispatch<
                    React.SetStateAction<QuotesFiltersType>
                  >
                }
                mode={mode}
                companies={companies}
              />
              <Container maxWidth="xxl">
                {showCurrent ? (
                  <CurrentQuotesTable mode={mode} filters={filters} />
                ) : (
                  <QuotesHistoryTable mode={mode} filters={filters} />
                )}
              </Container>
            </>
          )}
        </>
      )}
      <Outlet />
    </RequirePermission>
  );
};

export default Quotes;
