import { styled, SxProps } from '@mui/system';
import { Box, Stack } from '@mui/material';

export const StyledHeaderContainer = styled(Box)(() => {
  return {
    backgroundColor: '#2C2C2C',
    padding: '25px 10px 25px 20px',
  };
});

export const StyledShoppingTruckContainer = styled(Stack)(() => {
  return {
    height: '100%',
    overflowY: 'auto',
  };
});

export const drawerPaper = (reserving = false) => {
  return {
    bottom: 0,
    top: 'unset',
    borderRadius: '10px 10px 0 0',
    width: '500px',
    height: '70%',
    overflow: 'hidden',
    ...(reserving && {
      pointerEvents: 'none',
      opacity: 0.7,
    }),
  };
};

export const cardSection = {
  p: '15px',
};

export const buttonsSection = (showNote: boolean): SxProps => {
  return {
    paddingTop: 1,
    paddingBottom: 2,
    paddingX: '15px',
    '& .MuiInputBase-root.MuiFilledInput-root': {
      backgroundColor: 'white',
    },
    ...(!showNote && {
      '& button:first-of-type': {
        fontWeight: 'bold',
        backgroundColor: 'white',

        '&:hover, &:disabled': {
          backgroundColor: 'white',
        },
      },
    }),
  };
};
