import { DateTime } from 'luxon';
import { i18n, TFunction } from 'i18next';

import reservation_icon from '../../assets/ReservationIcon.png';
import { CompletedReelReturn } from '../../models';
import { Nullable, ReelReturnsFilters } from '../../types';

import {
  ReelReturnCancelReason,
  ReelReturnCancelReasonMapping,
  ReelReturnStatus,
  TimeZone,
} from '../../enums';
import { formatReelDepotLabel } from '../../models/ReelDepot';

import report, { MARGIN, PDF_CONFIG } from './report';

const PREFIX = 'NC_ReelReturnLog_';
const NAMESPACE = 'reports.reelReturns';

class reelReturnsReport extends report {
  private reelReturns: CompletedReelReturn[];
  private filters: ReelReturnsFilters;
  private readonly DEFAULT_ROW_HEIGHT = 21;
  private startDate: Nullable<DateTime>;
  private endDate: Nullable<DateTime>;

  constructor(
    reelReturns: CompletedReelReturn[],
    filters: ReelReturnsFilters,
    t: TFunction<'translation', undefined>,
    i18n: i18n
  ) {
    super(NAMESPACE, t, i18n);

    this.reelReturns = reelReturns;
    this.filters = filters;
    this.startDate = this.filters.startDate?.setLocale('en') ?? null;
    this.endDate = this.filters.endDate?.setLocale('en') ?? null;
  }

  protected getFilename(): string {
    const fromDate = this.startDate?.toFormat('LLddyyyy');
    const toDate = this.endDate?.toFormat('LLddyyyy');

    return `${PREFIX}_${fromDate}-${toDate}.pdf`;
  }
  protected buildHeader(): void {
    this.outputDocumentDate();

    this.curHeight += PDF_CONFIG.LINE_SIZE + 5;

    this.outputImage(reservation_icon, MARGIN.X, this.curHeight, 10, 10);

    this.outputH1(
      this.t(`${NAMESPACE}.pdfTitle`),
      this.curWidth + 15,
      this.curHeight + 7
    );

    this.curHeight += PDF_CONFIG.LINE_SIZE / 2 + 2;

    let filters = this.t(`${NAMESPACE}.dateFilter`, {
      from: this.startDate?.toLocaleString(),
      to: this.endDate?.toLocaleString(),
    });

    if (this.filters.companyIds) {
      [...new Set(this.reelReturns.map((r) => r.companyName))].forEach(
        (companyName, index, arr) => {
          filters += index === 0 ? '\n(' : '';
          filters += companyName;
          filters += index === arr.length - 1 ? ')' : ', ';
        }
      );
    }

    if (this.filters.depotLocation) {
      filters += `\n(${formatReelDepotLabel(this.filters.depotLocation)})`;
    }

    const addedLines = this.outputH3(
      filters,
      this.curWidth,
      this.curHeight + 7,
      225
    );

    this.curHeight += PDF_CONFIG.LINE_SIZE / 2 + addedLines * 5;
  }

  protected buildBody(): void {
    this.drawLine(0.5);

    this.curHeight += 5;

    this.reelReturns.forEach((reelReturn, index) =>
      this.buildRow(reelReturn, index)
    );
  }

  private buildRow(reelReturn: CompletedReelReturn, index: number): void {
    const {
      returnByDate,
      depotName,
      nciRmaNumber,
      clientRmaNumber,
      companyName,
      creatorName,
      reels,
      status,
      cancelReason,
      cancelCustomReason,
      receivedDate,
    } = reelReturn;

    const formattedReturnByDate = DateTime.fromMillis(returnByDate.toMillis())
      .setLocale('en')
      .setZone(TimeZone.EasternTime)
      .toLocaleString(DateTime.DATE_MED);

    this.addDataColumn('nciRmaNumber', String(nciRmaNumber), MARGIN.X, 50);
    this.addDataColumn('depotName', depotName, MARGIN.X + 55, 65);
    this.addDataColumn(
      'returnByDate',
      formattedReturnByDate,
      MARGIN.X + 125,
      25
    );

    this.curHeight += 12;

    this.addDataColumn('clientRmaNumber', clientRmaNumber, MARGIN.X, 50);
    this.addDataColumn('companyName', companyName, MARGIN.X + 55, 65);
    this.addDataColumn('userName', creatorName ?? '', MARGIN.X + 125, 30);

    this.curHeight += 12;

    const translatedStatus = this.t(
      `reports.reelReturns.statusValue.${status}`
    );

    const translatedCancelReason =
      (cancelReason &&
        this.t(
          `reelReturns.cancelReelReturn.reason.labels.${
            ReelReturnCancelReasonMapping[
              cancelReason as ReelReturnCancelReason
            ]
          }`
        )) ||
      '';

    let statusOutput =
      status === ReelReturnStatus.Cancelled
        ? `${translatedStatus} - ${translatedCancelReason}`
        : translatedStatus;

    if (cancelCustomReason) {
      statusOutput += ` - ${cancelCustomReason}`;
    }

    const splitText = this.doc.splitTextToSize(statusOutput, 120);

    this.addDataColumn(
      'status',
      splitText[0],
      MARGIN.X,
      status === ReelReturnStatus.Cancelled ? 115 : 50
    );

    if (receivedDate && status === ReelReturnStatus.Received) {
      const formattedReceivedDate = DateTime.fromMillis(receivedDate.toMillis())
        .setLocale('en')
        .setZone(TimeZone.EasternTime)
        .toLocaleString(DateTime.DATE_MED);

      this.addDataColumn(
        'receivedDate',
        formattedReceivedDate,
        MARGIN.X + 55,
        25
      );
    }

    if (this.filters.reelSize && reels) {
      this.addDataColumn(
        'amountOfReels',
        String(reels[this.filters.reelSize.id]),
        MARGIN.X + 125,
        25,
        false,
        { reel: this.filters.reelSize.reelType }
      );
    }

    this.curHeight += 9;

    this.drawLine(0.1);

    this.curHeight += 6;

    this.nextRow(this.DEFAULT_ROW_HEIGHT, index, this.reelReturns.length);
  }

  static generate = (
    reelReturns: CompletedReelReturn[],
    filters: ReelReturnsFilters,
    t: TFunction<'translation', undefined>,
    i18n: i18n
  ) => {
    const report = new reelReturnsReport(reelReturns, filters, t, i18n);

    report.generate();
  };
}

export default reelReturnsReport;
