import { where } from 'firebase/firestore';

import { Quote } from '../models';
import { CollectionQueryOptions } from '../types';
import { QuoteHistoryTabStatuses } from '../mappings';
import { useCollection } from './useCollection';
import { useAuthentication } from '.';

export function useQuotesHistory(options?: CollectionQueryOptions) {
  const { userRoleCategory } = useAuthentication();

  return useCollection<Quote>(
    'Quotes',
    [where('status', 'in', QuoteHistoryTabStatuses[userRoleCategory])],
    options?.sorting === undefined
      ? { sortField: 'lastModified', sortDirection: 'desc' }
      : options?.sorting || undefined,
    options?.pagination,
    options?.filters,
    options?.compositeFilters
  );
}
