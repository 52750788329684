import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { InventorySearchesFilters as Filters, Nullable } from '../../types';
import { FilterContainer } from '../FilterContainer/FilterContainer.component';
import { useProductTypes } from '../../hooks';
import { Material, ProductSubType, ProductType } from '../../enums';
import { InventorySearchesFiltersProps } from './InventorySearchesFilters.props';

const emptyFilters = {
  startDate: DateTime.now().plus({ month: -1 }),
  endDate: DateTime.now(),
} as Filters;

export const InventorySearchesFilters = ({
  onSearch,
  loadedFilters,
}: InventorySearchesFiltersProps) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<Filters>(
    loadedFilters || emptyFilters
  );

  const { item: productTypes, loading: loadingProductTypes } =
    useProductTypes();

  const selectedMaterial = useMemo(() => {
    if (!filters.material || !filters.productType || !productTypes) return null;

    return productTypes.types[filters.productType].materials[
      filters.material as Material
    ];
  }, [filters, productTypes]);

  const productTypeOptions = useMemo(() => {
    if (loadingProductTypes || !productTypes) return [];
    const productTypesValues = Object.entries(productTypes.types)
      .map(([key, value]) => {
        value.id = parseInt(key);
        return value;
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    return productTypesValues.map((p) => p.id.toString());
  }, [loadingProductTypes, productTypes]);

  const productSubTypeOptions = useMemo<string[]>(() => {
    if (
      !productTypes ||
      !filters.productType ||
      !productTypes.types[filters.productType].subTypes ||
      productTypes.types[filters.productType].subTypes.length === 0
    )
      return [];
    return productTypes.types[filters.productType].subTypes.map((s) =>
      s.toString()
    );
  }, [filters.productType, productTypes]);

  const materialOptions = useMemo(() => {
    if (!filters.productType || !productTypes) return [];
    return Object.keys(productTypes.types[filters.productType].materials);
  }, [filters.productType, productTypes]);

  const voltageOptions = useMemo(() => {
    const options = Object.keys(selectedMaterial?.voltages ?? []);

    return options;
  }, [selectedMaterial]);

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        material: (materialOptions.length === 1
          ? materialOptions[0]
          : null) as Nullable<Material>,
      };
    });
  }, [materialOptions]);

  useEffect(() => {
    setFilters((prev) => {
      return {
        ...prev,
        voltage:
          voltageOptions.length === 1 && voltageOptions[0] !== 'N/A'
            ? voltageOptions[0]
            : null,
      };
    });
  }, [selectedMaterial, voltageOptions]);

  const clearFilters = () => {
    setFilters(emptyFilters);
    onSearch(null);
  };

  const getValue = (
    value: Nullable<string | number | undefined>,
    options: string[],
    raw = false
  ): Nullable<string | number> => {
    if (value && options.includes(value.toString())) {
      return raw ? value : value === 'N/A' ? null : value.toString();
    }

    return null;
  };

  return (
    <FilterContainer
      title={t('reports.inventorySearches.title')}
      buttonsDisabled={!filters.startDate || !filters.endDate}
      onSearch={() => onSearch(filters)}
      onClear={clearFilters}
    >
      <Grid sx={{ my: { xs: 2, sm: 4 } }}>
        <Grid display="flex" justifyContent="space-evenly" gap="1em">
          <DatePicker
            label={t('reports.reservationConversionRate.startDate')}
            value={filters.startDate}
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
              textField: {
                fullWidth: true,
                required: true,
                sx: { flex: '1 1 0' },
              },
            }}
            onChange={(value) =>
              setFilters((prev) => {
                return { ...prev, startDate: value };
              })
            }
            format={t('formats.date')}
            minDate={(filters.endDate ?? DateTime.now()).plus({ year: -1 })}
            maxDate={filters.endDate || DateTime.now()}
          />

          <DatePicker
            label={t('reports.reservationConversionRate.endDate')}
            value={filters.endDate}
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
              textField: {
                fullWidth: true,
                sx: { flex: '1 1 0' },
              },
            }}
            onChange={(value) =>
              setFilters((prev) => {
                return { ...prev, endDate: value };
              })
            }
            format={t('formats.date')}
            minDate={filters.startDate || undefined}
            maxDate={
              filters.startDate &&
              filters.startDate.plus({ year: 1 }) < DateTime.now()
                ? filters.startDate.plus({ year: 1 })
                : DateTime.now()
            }
          />
          <Autocomplete
            popupIcon={<KeyboardArrowDownIcon />}
            getOptionLabel={(value) => t(`productTypes.${value}`)}
            options={productTypeOptions}
            value={getValue(filters.productType, productTypeOptions) as string}
            onChange={(_, value) => {
              setFilters((prev) => {
                return {
                  ...prev,
                  productType: value as Nullable<ProductType>,
                };
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('inventory.filters.productType')}
              />
            )}
            sx={{ flex: '1 1 0' }}
          />
          <Autocomplete
            popupIcon={<KeyboardArrowDownIcon />}
            getOptionLabel={(value) => t(`productSubTypes.${value}`)}
            options={productSubTypeOptions}
            value={
              getValue(filters.productSubType, productSubTypeOptions) as string
            }
            onChange={(_, value) => {
              setFilters((prev) => {
                return {
                  ...prev,
                  productSubType: value as Nullable<ProductSubType>,
                };
              });
            }}
            disabled={productSubTypeOptions.length === 0}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('inventory.filters.productSubType')}
              />
            )}
            sx={{ flex: '1 1 0' }}
          />
          <Autocomplete
            popupIcon={<KeyboardArrowDownIcon />}
            getOptionLabel={(value) => t(`materials.${value}`)}
            options={materialOptions}
            value={getValue(filters.material, materialOptions) as string}
            onChange={(_, value) => {
              setFilters((prev) => {
                return {
                  ...prev,
                  material: value as Nullable<Material>,
                };
              });
            }}
            disabled={!filters.productType || materialOptions.length === 1}
            renderInput={(params) => (
              <TextField {...params} label={t('inventory.filters.material')} />
            )}
            sx={{ flex: '1 1 0' }}
          />
          <Autocomplete
            popupIcon={<KeyboardArrowDownIcon />}
            options={voltageOptions}
            value={getValue(filters.voltage, voltageOptions) as string}
            onChange={(_, value) =>
              setFilters((prev) => {
                return {
                  ...prev,
                  voltage: value === 'N/A' ? null : value ?? null,
                };
              })
            }
            disabled={!filters.material || (voltageOptions.length ?? 0) <= 1}
            renderInput={(params) => (
              <TextField {...params} label={t('inventory.filters.voltage')} />
            )}
            sx={{ flex: '1 1 0' }}
          />
        </Grid>
      </Grid>
    </FilterContainer>
  );
};
