import { Stack, Typography } from '@mui/material';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Container,
  CurrentReservationTable,
  DecoratedHeader,
  ReservationCounter,
  ReservationFilters,
  ReservationHistoryTable,
} from '../components';
import { RequirePermission } from '../security';
import { ConsultationMode, UserPermission } from '../enums/';
import { useCurrentUser, usePageTitle, usePermission } from '../hooks/';
import { Tab } from '../navigation';
import { ReservationFilters as ReservationFiltersType } from '../types';
import { getPageTitle } from '../utils/pages';
import { getDefaultReservationFilters } from '../utils/reservationFilters';

interface Props {
  mode?: ConsultationMode;
}

const Reservations: React.FC<Props> = ({ mode = ConsultationMode.Current }) => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const [filters, setFilters] = useState<ReservationFiltersType>(
    getDefaultReservationFilters()
  );
  const { hasPermission } = usePermission();
  const canViewCompanyReservations = hasPermission(
    UserPermission.ManageCompanyReservations
  );

  const showCurrent = mode === ConsultationMode.Current;
  const getPageTitleKey = (): string =>
    showCurrent ? 'reservations' : 'reservations.history';
  usePageTitle(getPageTitle(getPageTitleKey()));

  return (
    <RequirePermission
      oneOf={[
        UserPermission.ManageCompanyReservations,
        UserPermission.ManageOwnReservations,
      ]}
    >
      <Container>
        <DecoratedHeader
          icon={<HourglassTopIcon />}
          title={t('navigation.reservations')}
          variant="colored"
        >
          <Tab title={t('reservations.current')} to={'/reservations'} />
          <Tab title={t('reservations.history')} to={'/reservations/history'} />
        </DecoratedHeader>
      </Container>
      <Container
        backgroundColor="secondary.light"
        roundedTopCorners={true}
        sx={{ pt: { xs: showCurrent ? 2 : 0, md: 0 } }}
      >
        <Stack>
          <Typography
            variant="h2"
            sx={{ mt: 4, mb: 3, display: { xs: 'none', md: 'block' } }}
          >
            {showCurrent
              ? t('reservations.current')
              : t('reservations.history')}
          </Typography>
          {showCurrent && (
            <ReservationCounter
              variant="detailed"
              currentReservations={user?.reservationCount}
              madeForReservations={user?.reservationByNCCount}
              maxReservations={user?.maxReservationsPerPeriod}
              totalReservations={user?.reservationCount}
            />
          )}
        </Stack>
      </Container>
      <ReservationFilters
        filters={filters}
        setFilters={setFilters}
        mode={mode}
      />
      {canViewCompanyReservations !== undefined &&
        (showCurrent ? (
          <CurrentReservationTable
            // Key is used to force the data to be reset when switching between
            // permission modes, as useLocalCollection attempts to filter
            // locally
            key={filters.showOnlyMyReservations.toString()}
            isAdmin={false}
            canViewCompanyReservations={canViewCompanyReservations}
            filters={filters}
            mode={mode}
          />
        ) : (
          <ReservationHistoryTable
            canViewCompanyReservations={canViewCompanyReservations}
            filters={filters}
            mode={mode}
          />
        ))}
    </RequirePermission>
  );
};

export default Reservations;
