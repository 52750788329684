import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Nullable } from '../../../types';
import { QuoteProduct } from '../../../models';

interface ReasonForLostProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (reason: string) => void;
  lostProduct?: Nullable<QuoteProduct>;
}

export const ReasonForLost = ({
  isOpen,
  onClose,
  onSave,
  lostProduct,
}: ReasonForLostProps) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState<string>(
    lostProduct?.reasonForLost ?? ''
  );

  useEffect(() => {
    if (lostProduct && lostProduct.reasonForLost !== reason) {
      setReason((lostProduct.reasonForLost || '').trim());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lostProduct]);

  const handleClose = () => {
    setReason('');
    onClose();
  };

  const handleSave = () => {
    onSave(reason);
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      fullWidth
      PaperProps={{ sx: { borderRadius: '0.5rem', p: 1 } }}
      onClose={() => handleClose()}
    >
      <DialogTitle sx={{ py: 1 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h3">
            {t('quotes.edit.products.table.reasonForLost.title')}
          </Typography>
          <IconButton size="large" onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          multiline
          fullWidth
          placeholder={t('quotes.edit.products.table.reasonForLost.label')}
          rows={3}
          value={reason}
          onChange={(e) => {
            setReason(e.target.value);
          }}
          inputProps={{
            maxLength: 255,
          }}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end', px: 3 }}>
        <Button variant="outlined" onClick={handleSave}>
          {t('forms.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
