import PieChartIcon from '@mui/icons-material/PieChart';
import { useTranslation } from 'react-i18next';

import { Container, DecoratedHeader } from '../../../components';
import { Tab } from '../../../navigation';
import NeedsPermission from '../../../security/NeedsPermission';
import { UserPermission } from '../../../enums';

interface Props {
  children: React.ReactNode;
}

const Reports = ({ children }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <DecoratedHeader
          icon={<PieChartIcon />}
          title={t('reports.title')}
          variant="colored"
        >
          <NeedsPermission oneOf={[UserPermission.ViewSalesReports]}>
            <Tab
              title={t('reports.fileTypes.agencySalesReportTitle')}
              to="/agent/reports/sales"
            />
          </NeedsPermission>
          <NeedsPermission oneOf={[UserPermission.ViewCommissionsReports]}>
            <Tab
              title={t('reports.fileTypes.agencyCommissionsReportTitle')}
              to="/agent/reports/commissions"
            />
          </NeedsPermission>
          <NeedsPermission
            oneOf={[
              UserPermission.ViewReservationConversionRatesReports,
              UserPermission.ViewQuoteReports,
            ]}
          >
            <Tab
              title={t('reports.reservationConversionRate.title')}
              to="/agent/reports/conversion-rate"
              match="/agent/reports/conversion-rate/*"
            />
          </NeedsPermission>
        </DecoratedHeader>
      </Container>
      {children}
    </>
  );
};

export default Reports;
