import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import {
  Container,
  AdminQuoteConversionRateFilters,
  AgentQuoteConversionRateFilters,
  QuoteConversionRateTable,
  Show,
} from '../components';
import {
  useAuthentication,
  useCurrentUser,
  useLocalStorage,
  usePageTitle,
  useProductTypes,
} from '../hooks';
import { UserPermission } from '../enums';
import { getPageTitle } from '../utils/pages';
import { RequirePermission } from '../security';
import { QuoteConversionRateFilters as Filters, Nullable } from '../types';
import { ProductType as ProductTypeClass } from '../models/ProductTypes';

const QuoteConversionRate = () => {
  usePageTitle(getPageTitle('reports.quoteConversionRate'));
  const { isAdmin } = useAuthentication();
  const currentUser = useCurrentUser();

  const {
    item: filters,
    setItem: setFilters,
    isLoaded,
  } = useLocalStorage<Filters>('quoteConversionRates', 'conversion');
  const { item: expandedCompanies, setItem: setExpandedCompanies } =
    useLocalStorage<string[]>('quoteConversionRates', 'expandedCompanies');

  const { item: productTypes, loading: loadingProductTypes } =
    useProductTypes();

  const parseDateFilters = (filters: Nullable<Filters>) => {
    if (filters) {
      const { startDate, endDate } = filters;
      if (startDate && typeof startDate === 'string') {
        filters.startDate = DateTime.fromISO(startDate);
      }

      if (endDate && typeof endDate === 'string') {
        filters.endDate = DateTime.fromISO(endDate);
      }
    }
  };

  const handleSearch = (newFilters: Nullable<Filters>) => {
    parseDateFilters(newFilters);
    if (!isEqual(newFilters, filters)) {
      setExpandedCompanies([]);
    }
    setFilters(newFilters);
  };

  parseDateFilters(filters);

  const userProductTypes = useMemo(() => {
    if (!productTypes || (currentUser.canSeeCSA && currentUser.canSeeUL)) {
      return null;
    }

    return {
      id: productTypes.id,
      types: Object.keys(productTypes.types)
        .filter(
          (productType) =>
            (productTypes.types[productType].csa && currentUser.canSeeCSA) ||
            (productTypes.types[productType].ul && currentUser.canSeeUL)
        )
        .reduce((obj, key) => {
          obj[key] = productTypes.types[key];
          return obj;
        }, {} as Record<string, ProductTypeClass>),
    };
  }, [currentUser.canSeeCSA, currentUser.canSeeUL, productTypes]);

  const userProductTypeValues = useMemo(
    () =>
      userProductTypes
        ? Object.keys(userProductTypes.types).map((k) => parseInt(k))
        : null,
    [userProductTypes]
  );

  return isLoaded ? (
    <RequirePermission oneOf={[UserPermission.ViewQuoteReports]}>
      {isAdmin ? (
        <AdminQuoteConversionRateFilters
          loadedFilters={filters}
          onSearch={handleSearch}
          productTypes={userProductTypes ?? productTypes}
          loadingProductTypes={loadingProductTypes}
        />
      ) : (
        <AgentQuoteConversionRateFilters
          loadedFilters={filters}
          onSearch={handleSearch}
          productTypes={userProductTypes ?? productTypes}
          loadingProductTypes={loadingProductTypes}
        />
      )}
      <Container>
        <Show if={!!filters}>
          <QuoteConversionRateTable
            expandedCompanies={expandedCompanies}
            setExpandedCompanies={setExpandedCompanies}
            filters={filters}
            userProductTypes={userProductTypeValues}
          />
        </Show>
      </Container>
    </RequirePermission>
  ) : null;
};

export default QuoteConversionRate;
